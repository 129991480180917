import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'form-warning',
  templateUrl: './form-warning.component.html',
  styleUrls: ['./form-warning.component.scss']
})
export class FormWarningComponent implements OnInit {

  @Input() message: string = '';
 
  constructor() { }

  ngOnInit(): void {
  }
}
