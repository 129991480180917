import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class RequestCacheService {
  private cache = new Map<string, [Date, HttpResponse<any>]>();

  get(key: string): HttpResponse<any> | undefined {
    const tuple = this.cache.get(key);
    if (!tuple) {
      return;
    }

    const expires = tuple[0];
    const httpResponse = tuple[1];

    /** 
     * Do not observe expired keys
     */ 
    const now = new Date();
    if (expires && expires.getTime() < now.getTime()) {
      this.cache.delete(key);
      return;
    }

    return httpResponse;
  }

  set(key: string, value: HttpResponse<any>, ttl: number) {
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + ttl);
    this.cache.set(key, [expires, value]);
  }

  clear(): void {
    this.cache.clear();
  }
}