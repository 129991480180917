import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { Program } from 'src/app/core/interfaces/program.interface';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';
import { PageType } from 'src/app/shared/interfaces/page-type.enum';
import { AnalyticsActions, ApiActions, AppInfoActions, ApplictionMessagingActions, MenuActions, MessagesActions } from 'src/app/store/actions';
import { updateNavigationFragment } from 'src/app/store/actions/user.actions';
import { selectAppData, selectApplicationStage, selectEmploymentForm, selectFeatureToggles, selectFinancialForm, selectInitialFormStatusSet, selectMortgageForm, selectNavigationFragment, selectNotEligibleForm, selectPersonalForm, selectPropertyForm, selectSelectedApplicationId, selectSignatureForm } from 'src/app/store/selectors';
import { selectIsModalOpen } from 'src/app/store/selectors/modals.selectors';
import { AlertType, AnalyticsPageIndex, ListObject, nonNegativeRegex, PageIndex, phoneRegex, ValidationClass, ValidationMsg } from '../../../shared/interfaces';
import { AlertService, NavService } from '../../../shared/services';
import { getOptionDisplayName } from '../../../shared/services/utilities';
import { ApplicationSubmmitedId, FormNames, FormStatus } from '../../interfaces';

@Component({
  selector: 'app-base-form',
  template: ''
})
export class BaseFormComponent implements OnInit, AfterViewChecked {

  destroy = new Subject();
  form!: FormGroup;
  form$!: Observable<FormGroup>;
  formName: FormNames = FormNames.Personal;
  pageIndex = PageIndex.Dashboard;
  title = '';
  ValidClass = ValidationClass;
  ValidMsg = ValidationMsg;
  nonNegativeRegex = nonNegativeRegex;
  phoneRegex = phoneRegex;
  modalOpen!: boolean;
  toggles!: FeatureToggles;
  fragment = '';
  selectedAppId = -1;

  constructor(
    protected navService: NavService,
    protected alertService: AlertService,
    protected store: Store
  ) {
    this.store.select(selectFeatureToggles).pipe(
      first(),
      map(toggles => this.toggles = toggles)
    ).subscribe();
    this.store.select(selectSelectedApplicationId).pipe(
      first(),
      map(id => this.selectedAppId = !!id ? id : -1)
    ).subscribe();
  }
  
  ngOnInit(): void {
    this.store.select(selectIsModalOpen).pipe(takeUntil(this.destroy)).subscribe(open => this.modalOpen = open);
    this.store.select(selectNavigationFragment).pipe(first()).subscribe(fragment => {
      this.fragment = fragment;
      this.store.dispatch(updateNavigationFragment({navigationFragment: ''}));
    });
    /**
     * Load discovery types list
     * This data is needed for any app PUT
     */
    this.store.dispatch(ApiActions.loadDiscoveryTypes());
    /**
     * Load user's conversations if communications feature is enabled
     */
    this.store.dispatch(ApplictionMessagingActions.loadMessages());
  }

  ngAfterViewChecked(): void {
    if (this.pageIndex > PageIndex.Dashboard && this.pageIndex < PageIndex.ApplicationSummary) {
      this.checkForNavigationFragments();
    }
  }

  checkForNavigationFragments(): void {
    if (!!this.fragment) {
      const element = document.getElementById(this.fragment);
      this.handleScroll(element);
    }
  }

  handleScroll(element: HTMLElement | null): void {
    if (!!element) {
      setTimeout(()=> {
        const y = element.getBoundingClientRect().top + window.pageYOffset + -70;
        window.scrollTo({ top: y, behavior: 'smooth' });
        element?.focus();
        this.fragment = "";
      }, 200);
    }
  }

  /**
   * Returns true if the application stage Id >= ApplicationSubmmitedId, meaning the application has been submitted
   * This is used in form templates to disable all form inputs and buttons
   */
  get isApplicationSubmitted$(): Observable<boolean> {
    return this.store.select(selectApplicationStage).pipe(
      map(stageId => stageId >= ApplicationSubmmitedId)
    );
  }

  getOptionDisplayName(item: ListObject): string {
    return getOptionDisplayName(item);
  }

  handleValueChanges() {
    this.form.valueChanges.pipe(takeUntil(this.destroy)).subscribe(_ => {
      this.store.dispatch(AppInfoActions.updateAppFormStatusAndVisited({ formName: this.formName, status: this.form.status as FormStatus }));
    });
  }

  /**
   * Return the selector for the applicable form after
   * the form statuses have been set in state
   * This occurs any time application data is fetched
   */
  protected selectForm(): Observable<FormGroup> {
    let selector = selectPersonalForm;

    switch (this.formName) {
      case FormNames.Personal:
        selector = selectPersonalForm;
        break;
      case FormNames.Employment:
        selector = selectEmploymentForm;
        break;
      case FormNames.Property:
        selector = selectPropertyForm;
        break;
      case FormNames.Mortgage:
        selector = selectMortgageForm;
        break;
      case FormNames.Financial:
        selector = selectFinancialForm;
        break;
      case FormNames.SummaryAndSignatures:
        selector = selectSignatureForm;
        break;
      case FormNames.NotEligible:
        selector = selectNotEligibleForm;
        break;
    }

    return this.store.select(selectInitialFormStatusSet).pipe(
      filter(isSet => !!isSet),
      mergeMap(_ => this.store.select(selector))
    );
  }

  ngOnDestroy(): void {
    this.updateApplication();
  }

  updateApplication(): Observable<boolean> {
    if (!!this.form && this.form.dirty) {
      this.updateDataFromForm();

      this.store.select(selectAppData).pipe(
        filter(data => !data.isUpdating),
        first(),
        map(data => {
          const error = data.updateApiError?.error;
          if (!!error) {
            this.handleErrorList(error);
            return of(false);
          }
          this.completeDestroy();
          return of(true)
        }),
      ).subscribe();

      return of(true);
    } else {
      this.completeDestroy();
      return of(true);
    }
  }

  completeDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    const pageType = this.pageIndex === PageIndex.ProgramSelection ? PageType.Static : PageType.Dynamic;
    this.store.dispatch(MenuActions.updatePageType({ pageType: pageType }));
  }

  findChild(data: any, iterations: number, child: number = 0): string {
    if (iterations == 0) {
      return data.id;
    } else {
      if (iterations == 1 && child != 0) {
        return this.findChild(data.children[child], iterations - 1, child);
      } else {
        return this.findChild(data.children[0], iterations - 1, child);
      }
    }
  }

  fetchLists(): void {}

  handleErrorList(e: any): void {
    const message: string = e.error?.Message || '';
    const errors = message.split('|') ?? [];
    errors.forEach(error => !!error ? this.alertService.error(error) : null);
  }

  isInvalid(path: string, control?: AbstractControl | null): boolean {
    const formControl = !!control ? control : this.form.get(path);
    return !!formControl && formControl.invalid && (formControl.dirty || formControl.touched);
  }

  onBack() {
    const nextPageIndex = --this.pageIndex;
    if (nextPageIndex <= PageIndex.SignSubmit) {
      this.store.dispatch(MenuActions.updateActiveIndex({ activeIndex: nextPageIndex }));
      this.navService.goToApplicationPage(nextPageIndex, this.selectedAppId);
    }
    this.dispatchNavEvent(nextPageIndex, 'back');
  }

  onSubmit() {
    const nextPageIndex = ++this.pageIndex;
    if (nextPageIndex >= PageIndex.Dashboard) {
      this.store.dispatch(MenuActions.updateActiveIndex({ activeIndex: nextPageIndex }));
      this.navService.goToApplicationPage(nextPageIndex, this.selectedAppId);
    }
    this.dispatchNavEvent(nextPageIndex, 'continue');
  }

  private dispatchNavEvent(index: number, direction: string): void {
    const pageName = AnalyticsPageIndex[index];
    this.store.dispatch(AnalyticsActions.logCustomEvent({
      event: {
        action: `${direction}_to_${pageName}`,
        category: Constants.NAVIGATION_CATEGORY,
        label: this.formName.toLowerCase()
      }
    }))
  }

  protected dispatchPrequalAnalyticsEvent(action: string, label: string): void {
    this.store.dispatch(AnalyticsActions.logCustomEvent({
      event: {
        action: action,
        category: Constants.PREQUAL_CATEGORY,
        label: label
      }
    }))
  }

  removeIllegalCharacters(event: any, type: string): void {
    if (type == 'tel') {
      event.target.value = event.target.value.replace(/[^\d{3}(-?)\d{3}(-?)\d{4}$]/g, '').replace(/(\..*)\./g, '$1');
    } else if (type == 'ssn') {
      event.target.value = event.target.value.replace(/[^\d{3}(-?)\d{2}(-?)\d{4}$]/g, '').replace(/(\..*)\./g, '$1');
    }
  }

  /**
   * Allows users to manually Save Application progress
   * Toast on error or success
   */
  saveApplication(): void {
    this.updateApplication()
      .subscribe(
        _ => this.store.dispatch(MessagesActions.displayToast({ alertType: AlertType.Success, message: translate('toast.applicationSuccessfullySaved') })), 
        e => this.store.dispatch(MessagesActions.displayToast({ alertType: AlertType.Error, message: translate('toast.applicationFailedSave') }))
      );
  }

  protected setFormAsUpdated(): void {
    this.form.markAsDirty();
    this.form.markAsTouched();
  }

  setupForm(form?: FormGroup): void {}

  showOption(isActive: boolean, group: string, control: string, id: number): boolean {
    return isActive || this.form.get(group)?.get(control)?.value==id;
  }

  sortOrder(): void {}

  /**
   * Update state based on current form data
   */
  protected updateDataFromForm(): void {}
}
