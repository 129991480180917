import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { Amplify } from '@aws-amplify/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FileSaverModule } from 'ngx-filesaver';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthorizationInterceptor } from './core/http-interceptors/authorization.interceptor';
import { CachingInterceptorService } from './core/http-interceptors/caching-interceptor.service';
import { RegistrationInterceptor } from './core/http-interceptors/registration.interceptor';
import { SharedModule } from './shared/shared.module';
import { metaReducers, reducers } from './store';
import { AnalyticsEffects } from './store/effects/analytics.effects';
import { AppInfoEffects } from './store/effects/app-info.effects';
import { AppSubmitEffects } from './store/effects/app-submit.effects';
import { AppUpdateEffects } from './store/effects/app-update.effects';
import { ApplicationMessagingEffects } from './store/effects/application-messaging.effects';
import { ConfigurationEffects } from './store/effects/configuration.effects';
import { FeatureTogglesEffects } from './store/effects/feature-toggles.effects';
import { ApiListEffects } from './store/effects/list-api.effects';
import { MessagesEffects } from './store/effects/messages.effects';
import { MultiApplicationEffects } from './store/effects/multi-application.effects';
import { RoutingEffects } from './store/effects/routing.effects';
import { SupportingFilesEffects } from './store/effects/supporting-files.effects';
import { UserEffects } from './store/effects/user.effects';
import { CustomSerializer } from './store/router/custom-route-serializer';
import { TranslocoRootModule } from './transloco/transloco-root.module';

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RegistrationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptorService, multi: true },
];

Amplify.configure({
  Auth: {
    mandatorySignIn: true, 
    region: environment.cognitoRegion,
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
    authenticationFlowType: environment.cognitoAuthenticationFlowtype
  }
});

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    NoopAnimationsModule,
    ToastNoAnimationModule.forRoot({
      disableTimeOut: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      toastClass: 'ngx-toastr custom-toast',
      iconClasses: {
        error: 'custom-toast-error',
        info: 'custom-toast-info',
        success: 'custom-toast-success',
        warning: 'custom-toast-warning'
      }
    }),
    SharedModule,
    FileSaverModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    TranslocoRootModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([
      AnalyticsEffects,
      AppInfoEffects,
      AppSubmitEffects,
      AppUpdateEffects,
      ApplicationMessagingEffects,
      ConfigurationEffects,
      FeatureTogglesEffects,
      ApiListEffects,
      MessagesEffects,
      MultiApplicationEffects,
      RoutingEffects,
      SupportingFilesEffects,
      UserEffects
    ]),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [interceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
