import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectSelectedApplication } from 'src/app/store/selectors';
import { NavService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateGuard implements CanActivate {
  constructor(
    private store: Store,
    private navService: NavService
  ) {}

  /**
   * @param _ 
   * @param state 
   * @returns true if the user can navigate to the given url for the selected app's state
   *          else false
   */
  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectSelectedApplication).pipe(
      filter(app => !!app),
      map(app => this.navService.canNavigate(state.url, app))
    );
  }
}
