import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/store/actions';
import { DialogData } from '../../interfaces';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnDestroy {

  success = false;

  @Input() data: DialogData = {action: {click: '', text: ''}, title: '', bodyText: [''], showCancel: true};
  @Input() categoryButton?: string;
  @Input() deleteButton?: string;
  @Input() elementToFocus?: string;

  constructor(public modal: NgbActiveModal, private store: Store) {
    this.store.dispatch(ModalsActions.updateIsModalOpen({ isOpen: true }));
   }

   ngOnDestroy(): void {
    this.store.dispatch(ModalsActions.updateIsModalOpen({ isOpen: false }));
     if (this.success && !!this.categoryButton && !!this.elementToFocus) {
        document.getElementById('main-content')?.focus();
        const element = !!document.getElementById(this.elementToFocus) 
          ? document.getElementById(this.elementToFocus) 
          : document.getElementById(this.categoryButton); 
        element?.focus();
        this.success = false;
     } else if (!!this.deleteButton) {
        document.getElementById('main-content')?.focus();
        document.getElementById(this.deleteButton)?.focus();
     }
   }

  cancel() {
    this.modal.close();
  }

  handleAction() {
    this.modal.close(this.data.action.click);
    if (!!this.categoryButton && !!this.deleteButton) {
      this.success = true;
    }
  }

  get secondaryActionText(): string {
    return this.data.secondaryBtnText || '';
  }
}
