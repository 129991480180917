<div *ngFor="let alert of alerts" class="alert {{cssClass(alert)}}">
  <ng-container *ngIf="alert.type === AlertType.Error">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-exclamation-circle-fill svg-error" viewBox="0 0 16 16" alt="Error">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="alert.type === AlertType.Warning">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="alert.type === AlertType.Success">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-check-circle-fill svg-valid" viewBox="0 0 16 16" alt="Form Valid">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </svg>
  </ng-container>
  <span [innerHTML]="alert.message"></span>
  <button type="button" class="btn btn-close" data-dismiss="alert" (click)="removeAlert(alert)" aria-label="dismiss alert"></button>
</div>