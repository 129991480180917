import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { IAuthService } from 'src/app/shared/services';
import { CognitoAuthService } from 'src/app/shared/services/cognito-auth-service.service';
import { selectUserIsLoggedIn } from 'src/app/store/selectors';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(
    @Inject(CognitoAuthService) private authService: IAuthService,
    private store: Store
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.select(selectUserIsLoggedIn).pipe(
      first(),
      map(isLoggedIn => {
        return !isLoggedIn || !request.url.includes(environment.apiUrl);
      }),
      mergeMap((canHandleNextRequest: boolean) => {
        /**
         * When we call any prolink api endpoint, we want to include an auth header
         */
        if (canHandleNextRequest) {
          return next.handle(request);
        } else {
          return this.authService
            .getAccessToken()
            .pipe(
              switchMap(accessToken => {
                const authReq = request.clone({ 
                  headers: request.headers.set('Authorization', `Bearer ${accessToken}`) 
                });
  
                return next.handle(authReq);
              })
            );
        }
      })
    );
  }
}
