import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Program } from 'src/app/core/interfaces/program.interface';
import { apiListFeatureKey, ListApiState } from '../reducers/list-api.reducers';
import { selectIneligibleProgramIdsFromActiveApplications } from './multi-application.selectors';

export const selectListApiFeature = createFeatureSelector<ListApiState>(apiListFeatureKey);

export const selectListApiState = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state
);

export const selectBankruptcyTypesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.bankruptcyTypes
);

export const selectBestTimesToCallList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.bestTimes
);

export const selectCountiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.counties
);

export const selectHoCountiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.homeownerCounties
);

export const selectCbCountiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.coborrowerCounties
);

export const selectSpCountiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.spouseCounties
);

export const selectHoEmploymentCountiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.homeownerEmploymentCounties
);

export const selectCbEmploymentCountiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.coborrowerEmploymentCounties
);

export const selectDelinquencyStatusesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.delinquencyStatuses
);

export const selectDiscoveryTypesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.discoveryTypes
);

export const selectEligibleExpenseCategoriesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.eligibleExpenseCategories
);

export const selectEligibleExpenseProvidersList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.eligibleExpenseProviders
);

export const selectEligibleExpenseTypesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.eligibleExpenseTypes
);

export const selectEthnicitiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.ethnicities
);

export const selectExpenseCategoriesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.expenseCategories
);

export const selectFeatureTogglesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.featureToggles
);

export const selectFileCategoriesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.fileCategories
);

export const selectGendersList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.genders
);

export const selectHardshipReasons = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.hardshipReasons
);

export const selectHowLongList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.howLongWithEmployers
);

export const selectIncomeSourcesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.incomeSources
);

export const selectLanguagesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.languages
);

export const selectLoanProgramsList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.loanPrograms
);

export const selectLoanTypesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.loanTypes
);

export const selectMaritalStatusesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.maritalStatuses
);

export const selectMortgageLenders = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.mortgageLenders
);

export const selectMortgageServicers = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.mortgageServicers
);

export const selectPaymentDueDatesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.paymentDueDates
);

export const selectPhoneTypesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.phoneTypes
);

export const selectProgramCountiesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.programCounties
);

function filterOutIneligibleProgramsOnActiveApps(programs: Program[] | null, ineligibleProgramIds: number[]): Program[] | null {
  const matches = programs?.filter(program => !ineligibleProgramIds.includes(program.Id));
  return !!matches ? matches : null;
}

export const selectProgramsList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.programs
);

export const selectAvailableProgramsList = createSelector(
  selectListApiFeature,
  selectIneligibleProgramIdsFromActiveApplications,
  (state: ListApiState, ineligibleProgramIds: number[]) => filterOutIneligibleProgramsOnActiveApps(state.programs, ineligibleProgramIds)
);

/**
 * Return true if at least one program is available, else false
 */
 export const selectCanAddPrograms = createSelector(
  selectListApiFeature,
  selectAvailableProgramsList,
  (_: ListApiState, availablePrograms: Program[] | null) => !!availablePrograms && availablePrograms.length > 0
);

export const selectRacesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.races
);

export const selectSociallyDisadvantagedList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.sociallyDisadvantaged
);

export const selectStatesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.states
);

export const selectWhoPaysList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.whoPays
);

export const selectYesNoList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.yesNo
);

export const selectYesNoPendingList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.yesNoPending
);

export const selectHelpLinksList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.helpLinks
);

export const selectProgramStagesList = createSelector(
  selectListApiFeature,
  (state: ListApiState) => state.programStages
);

