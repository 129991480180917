import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Alert, AlertType } from '../../interfaces';
import { AlertService } from '../../services';


@Component({ 
  selector: 'alert', 
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = true;
  @Input() alert?: Alert;

  AlertType = AlertType;
  alerts: Alert[] = [];
  alertSubscription!: Subscription;
  routeSubscription!: Subscription;
  destroy = new Subject();

  constructor(private router: Router, private alertService: AlertService) { }

  ngOnInit() {
    /**
     * subscribe to new alert notifications
     */
    this.alertSubscription = this.alertService.onAlert(this.id)
      .pipe(takeUntil(this.destroy))
      .subscribe(alert => {
        /**
         * clear alerts when an empty alert is received
         */
        if (!alert.message) {
          /**
           * filter out alerts without 'keepAfterRouteChange' flag
           */
          this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

          /**
           * remove 'keepAfterRouteChange' flag on the rest
           */
          this.alerts.forEach(x => delete x.keepAfterRouteChange);
          return;
        }

        /**
         * add alert to array
         */
        this.alerts.push(alert);

        /**
         * auto close alert if required
         */
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 3000);
        }
      });

    if (!!this.alert) {
      this.alerts.push(this.alert);
    }

    /**
     * clear alerts on location change
     */
    this.routeSubscription = this.router.events
      .pipe(takeUntil(this.destroy))  
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.alertService.clear(this.id);
        }
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Check if already removed to prevent error on auto close
   * Optionally fade out alert
   * Remove alert
   * 
   * @param alert 
   */
  removeAlert(alert: Alert): void {
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      const alertToFade = this.alerts.find(x => x === alert);
      if (alertToFade) alertToFade.fade = true;

      setTimeout(() => {
        this.alerts = this.alerts.filter(x => x !== alert);
      }, 250);
    } else {
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  cssClass(alert: Alert) {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];
            
    const alertTypeClass = {
      [AlertType.Success]: 'alert alert-success',
      [AlertType.Error]: 'alert alert-danger',
      [AlertType.Info]: 'alert alert-info',
      [AlertType.Warning]: 'alert alert-warning'
    }

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }
}