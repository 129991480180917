import { createAction, props } from '@ngrx/store';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';
import { FormNames, FormStatus, HomeownerApplicationApiData } from 'src/app/homeowner-application/interfaces';
import { ListObject } from 'src/app/shared/interfaces';
import { PrequalStep } from 'src/app/shared/interfaces/prequalification-step.enum';

export const updateAppFormStatusAndVisited = createAction(
  '[App State] Update Form Status',
  props<{ status: FormStatus, formName: FormNames }>()
);

export const updateApplicationStage = createAction(
  '[App State] Update Application Stage',
  props<{ stage: number }>()
);

export const updateAppState = createAction(
  '[App State] Update Homeowner Application State',
  props<{ data: HomeownerApplicationApiData }>()
);

export const updateEmail = createAction(
  '[App State] Update Borrower Email',
  props<{ email: string }>()
);

export const updateEmploymentForm = createAction(
  '[App State] Update Employment Form',
  props<{ form: any }>()
);

export const updateFinancialForm = createAction(
  '[App State] Update Financial Form',
  props<{ form: any }>()
);

export const setHasEligibleExpense = createAction(
  '[App State] Set Has Eligible Expense',
  props<{ hasExpense: boolean }>()
);

export const setInitialFormStatus = createAction(
  '[App State] Set Initial Form Statuses',
  props<{ data: HomeownerApplicationApiData }>()
);

export const updateIsActiveApplication = createAction(
  '[App State] Update Is Active Application',
  props<{ isActive: boolean }>()
);

export const updateMortgageForm = createAction(
  '[App State] Update Mortgage Form',
  props<{ form: any }>()
);

export const updateNotEligibleForm = createAction(
  '[App State] Update Not Eligible Form',
  props<{ form: any }>()
);

export const updatePersonalForm = createAction(
  '[App State] Update Personal Form',
  props<{ form: any, toggles: FeatureToggles, races: ListObject[] }>()
);

export const updatePrequalificationForm = createAction(
  '[App State] Update Prequalification Form',
  props<{ form: any }>()
);

export const updateProfileForm = createAction(
  '[App State] Update Profile Form',
  props<{ form: any }>()
);

export const updatePropertyForm = createAction(
  '[App State] Update Property Form',
  props<{ form: any, toggles: FeatureToggles }>()
);

export const updateSignatureForm = createAction(
  '[App State] Update Signature Form',
  props<{ form: any }>()
);

export const updatePrequalificationError = createAction(
  '[App State] Update Prequalification Step Error',
  props<{ hasError: boolean, error: any }>()
);

export const updatePrequalificationStep = createAction(
  '[App State] Update Prequalification Step',
  props<{ step: PrequalStep }>()
);

export const updatePrequalificationStepSuccess = createAction(
  '[App State] Update Prequalification Step Success',
  props<{ step: PrequalStep }>()
);

export const resetAppSubmittedState = createAction(
  '[App State] Mark App Submit Toast Message as Viewed'
);

export const reset = createAction(
  '[App State] Reset State'
);