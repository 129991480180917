import { createAction, props } from '@ngrx/store';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';

export const getToggles = createAction(
  '[API] GET Feature Toggles'
);

export const updateFeatureToggles = createAction(
  '[Feature Toggles State] Update Feature Toggles',
  props<{ toggles: FeatureToggles }>()
);

export const getTogglesFailure = createAction(
  '[API] GET Toggles Error',
  props<{ error: string }>()
);