import { Action, createReducer, on } from '@ngrx/store';
import { MessagesActions } from '../actions';

export interface AppMessageState {
  countdownMessage: string,
  verifyAlertMessage: string,
  isLoading: boolean,
  loadingMessage: string
}

export const initialState: AppMessageState = {
  countdownMessage: '',
  verifyAlertMessage: '',
  isLoading: false,
  loadingMessage: '',
};

const messagesReducer = createReducer(
  initialState,
  on(MessagesActions.reset, (state) => {
    return ({ ...state, ...initialState })
  }),
  on(MessagesActions.updateCountdownMessage, (state, { message }) => {
    return ({ ...state, countdownMessage: message })
  }),
  on(MessagesActions.updateVerifyAlertMessage, (state, { message }) => {
    return ({ ...state, verifyAlertMessage: message })
  }),
  on(MessagesActions.updateIsLoading, (state, { isLoading }) => {
    return ({ ...state, isLoading: isLoading })
  }),
  on(MessagesActions.updateLoadingMessage, (state, { message }) => {
    return ({ ...state, loadingMessage: message })
  })
);

export function reducer(state: AppMessageState | undefined, action: Action) {
  return messagesReducer(state, action);
}