import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HomeownerApplicationApiData } from './homeowner-application/interfaces';
import { TimeoutModalComponent } from './shared/components/timeout-modal/timeout-modal.component';
import { IAuthService, NavService } from './shared/services';
import { CognitoAuthService } from './shared/services/cognito-auth-service.service';
import { AnalyticsActions, ConfigurationActions, FeatureTogglesActions, MenuActions, MessagesActions, UserActions } from './store/actions';
import * as selectors from './store/selectors';
import { selectAppData, selectIsActiveApplication } from './store/selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  app!: HomeownerApplicationApiData;
  destroy = new Subject();

  lastPing?: Date;
  modalOpen = false;
  timedOut = false;

  activeApplication$ = this.store.select(selectIsActiveApplication);
  appLoadingMessage$ = this.store.select(selectors.selectLoadingMessage);
  countdownMessage$ = this.store.select(selectors.selectCountdownMessage);
  idPresent$ = this.store.select(selectors.selectUserId);
  isSubmitted$ = this.store.select(selectors.selectIsAppSubmitted);
  modalOpen$ = this.store.select(selectors.selectIsModalOpen).pipe(
    delay(0),
    tap(open => this.modalOpen = open)
  );
  pageType$ = this.store.select(selectors.selectPageType);

  constructor(
    @Inject(CognitoAuthService) private authService: IAuthService,
    private idle: Idle,
    private keepAlive: Keepalive,
    private navService: NavService,
    private modalService: NgbModal,
    private titleService: Title,
    private router: Router,
    private store: Store
  ) {
    this.isIE();
    /**
     * Fetch configurations & load into state
     */
    this.store.dispatch(ConfigurationActions.getConfigurations());

    /**
     * Fetch FeatureToggles & load into state
     */
    this.store.dispatch(FeatureTogglesActions.getToggles());
    this.store.dispatch(UserActions.handleUserSessionStorage());

    this.idPresent$.subscribe(value => {
      if (value != '') { 
        this.idleHandler();
      }
    });

    this.titleService.setTitle(environment.title);
  }
  
  ngOnInit(): void {
    this.store.select(selectAppData).pipe( 
      map(data => this.app = data),
      takeUntil(this.destroy)
    ).subscribe();
      
    this.loadExternalScripts();
    this.trackNavEvents();
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  logout(): void {
    this.authService.logout()
    .pipe(map(_ => this.navService.goToLogin()))
    .subscribe();
  }

  get isLoggedIn$() {
    return this.store.select(selectors.selectUserIsLoggedIn);
  }

  toggleMenu() {
    this.store.dispatch(MenuActions.toggleMenu());
  }

  idleHandler(): void { 
    this.idle.setIdle(environment.timeOut);
    this.idle.setTimeout(environment.setTimeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => { 
      this.resetTimer();
    });
    
    this.idle.onTimeout.subscribe(() => {
      this.modalService.dismissAll();
      this.store.dispatch(UserActions.updateIsForcedLogout({ isForced: true }));
      this.store.dispatch(MessagesActions.updateCountdownMessage({ message: '' }));
      this.timedOut = true;
      this.logout();
    });
    
    this.idle.onIdleStart.subscribe(() => {
      if (!this.modalOpen) {
        const modal = this.modalService.open(TimeoutModalComponent, {
          centered: true,
          scrollable: true
        });
        this.countdownMessage$.subscribe(
          value => {
            if (!!modal.componentInstance) {
              modal.componentInstance.statusMessage = value;
            }
          }
        );
      }
    });
    
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      const message = translate('timeoutDialog.countdownMessage', { context: countdown });
      this.store.dispatch(MessagesActions.updateCountdownMessage({ message: message }));
    });
  
    this.keepAlive.interval(15);

    this.keepAlive.onPing.subscribe(() => this.lastPing = new Date());

    this.resetTimer();
  }

  resetTimer(): void {
    this.idle.watch();
    this.timedOut = false;
  }

  private trackNavEvents(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy)
      )
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.store.dispatch(AnalyticsActions.logPageView({ url: event.urlAfterRedirects }));
        }
      });
  }

  isIE(): void {
    if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0) { 
      this.store.dispatch(UserActions.updateIsBrowserDeprecated({ isDeprecated: true }));
    }
  }

  private loadExternalScripts(): void {
    this.loadZendeskScript();
    this.store.dispatch(AnalyticsActions.initHotjar());
    this.store.dispatch(AnalyticsActions.initGoogleAnalytics());
  }

  private loadZendeskScript() {
    if (environment.loadZenDesk) {
      const body = <HTMLDivElement> document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = environment.zenDeskUrl;
      script.id = "ze-snippet";
      script.async = true;
      script.defer = true;
      body.appendChild(script);
    }
  }
}