import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './app-scroll-top.component.html',
  styleUrls: ['./app-scroll-top.component.scss']
})
export class AppScrollTopComponent implements OnInit {

  pageYoffset = 0;

  constructor(private scroll: ViewportScroller) {}

  @HostListener('window:scroll', ['$event']) onScroll(event: any){
    this.pageYoffset = window.pageYOffset;
  }


  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

  ngOnInit() {}
}