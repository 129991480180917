import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { UserActions } from 'src/app/store/actions';

export interface IAuthService {
  challenged: boolean;
  redirectUrl: string;

  changeEmail(newEmail: string) : Promise<any>
  changePassword(oldPassword: string, newPassword: string) : Promise<any>;
  changePhone(newPhone: string): Promise<any>;
  getMFA(): Promise<any>;
  updateMFA(value: boolean): Promise<any>;
  getUser(): Promise<any>;
  forgotPassword(username: string) : Observable<boolean>;
  register(username: string, password: string, phone: string): Observable<boolean>;
  registerVerify(username: string, code: string): Observable<boolean>;
  rememberDevice(): boolean;
  resendCode(username: string): Observable<any>;
  resetPassword(username: string, code: string, newPassword: string) : Observable<boolean>;

  login(username: string, password: string): Observable<boolean>;
  loginMfa(code: string): Observable<boolean>;
  logout(): Observable<boolean>;
  
  getAccessToken(): Observable<string>;
  getIdToken(): Observable<string>;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {
  challenged = false;
  redirectUrl: string = '/dashboard/applications';

  constructor(
    private store: Store
  ) {}
  changeEmail(newEmail: string): Promise<any> {
    return of(true).toPromise();
  }
  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return of(true).toPromise();
  }
  changePhone(newPhone: string): Promise<any> {
    return of(true).toPromise();
  }
  getMFA(): Promise<any>{
    return of(true).toPromise();
  }
  updateMFA(): Promise<any> {
    return of(true).toPromise();
  }
  getUser(): Promise<any>{
    return of(true).toPromise();
  }
  forgotPassword(username: string): Observable<boolean> {
    return of(true);
  }
  resetPassword(username: string, code: string, newPassword: string): Observable<boolean> {
    return of(true);
  }

  register(username: string, password: string, phone: string): Observable<boolean> {
    return of(true);
  }

  registerVerify(username: string, code: string): Observable<boolean> {
    return of(true);
  }

  resendCode(username: string): Observable<any> {
    return of(true);
  }

  login(username: string, password: string): Observable<boolean> {
    const user = true; //validUsers.find(user => user.username === username && user.password === password);

    if (!user) return throwError('Username or password is incorrect');

    this.store.dispatch(UserActions.updateId({ id: 'AD24DA27-8EAA-430C-A639-4360C730242E' }));

    return of(!!user);
  }

  loginMfa(code: string) : Observable<boolean> {
    return of(true);
  }

  logout(): Observable<boolean> {
    this.store.dispatch(UserActions.updateId({ id: '' }));
    return of(true);
  }

  getIdToken(): Observable<string> {
    return of('idToken');
  }

  getAccessToken(): Observable<string> {
    return of('accessToken');
  }

  rememberDevice(): boolean {
    return true;
  }
}