<ng-container *transloco="let t;">
  <div *ngIf="fileInfo.status === FormStatus.Valid; else missingRequiredFile">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#188C13" class="bi bi-file-earmark-check" viewBox="0 0 16 16" [attr.aria-labelledby]="'fileUploaded'+fileInfo.supportingFile.FileId" role="img">
      <title id="fileUploaded{{fileInfo.supportingFile.FileId}}">File Uploaded</title>
      <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
    </svg>
  </div>
  <ng-template #missingRequiredFile>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16" [attr.aria-labelledby]="'noFileUploaded'+fileInfo.supportingFile.FileId" role="img">
      <title id="noFileUploaded{{fileInfo.supportingFile.FileId}}">No File Uploaded</title>
      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
    </svg>
  </ng-template>
  <b>{{ t(fileInfo.supportingFile.FileDescription) }}</b>

  <div class="uploaded-files-container">
    <ng-container *ngIf="fileInfo.status === FormStatus.Valid; else displayError">
      <ng-container *ngFor="let uFile of fileInfo.uploadedFiles">
        <a [routerLink]="" (click)="downloadFile(uFile.Id, uFile.Title)">{{uFile.Title}}<span></span></a>
        <label>{{ fcs.formatBytes(uFile.SizeInBytes) }} | {{ fcs.formatDate(uFile.UploadedDate) }}</label>
      </ng-container>
    </ng-container>
    <ng-template #displayError>
      <form-message [cssClass]="ValidClass.Danger" [matchText]="t('supportingFiles.missingRequiredFile')"></form-message>
    </ng-template>
  </div>
</ng-container>