export enum YesNo {
  Yes = 'PL_YesNo.Yes1',
  No = 'PL_YesNo.No2'
}

export enum ProgramStatus {
  NotEligible = 'ProgramStatus.Not Eligible3',
  Inactive = 'ProgramStatus.Inactive6'
}

export enum ValidationMsg {
  Required = 'This field is required.'
}

export enum ValidationClass {
  Danger = 'text-danger'
}

export enum PropertyIntention {
  Keep = 'Keep',
  Sell = 'Sell'
}

export enum PropertyType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Investment = 'Investment'
}

export enum PropertyOccupied {
  Owner = 'Owner',
  Renter = 'Renter',
  Vacant = 'Vacant'
}

export enum PropertyApplicationSummaryValues {
  Keep = 'Keep the property',
  Sell = 'Sell the property',
  Primary = 'Primary Residence',
  Secondary = 'Secondary Home',
  Investment = 'Investment',
  Owner = 'Owner Occupied',
  Renter = 'Renter Occupied',
  Vacant = 'Vacant'
}