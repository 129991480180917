import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { APPLICATIONS_ROUTE, APPLICATION_DETAILS_ROUTE, DASHBOARD_APPLICATIONS_ROUTE, DASHBOARD_ROUTE, EDIT_ROUTE, LOGIN_ROUTE, PREQUAL_PROGRAM_SELECTION_ROUTE, PREQUAL_ROUTE, PROFILE_ROUTE, PROGRAM_SELECTION_ROUTE, QUESTIONS_ROUTE, VERIFY_ROUTE } from 'routes';
import { combineLatest } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { ApplicationStateName, stateNameMapping } from 'src/app/core/interfaces/app-state-name.interface';
import { ApplicationSubmmitedId, HomeownerApplicationApiData } from 'src/app/homeowner-application/interfaces';
import { AppInfoActions, MessagesActions, MultiApplicationActions, UserActions } from 'src/app/store/actions';
import { selectFeatureToggles, selectIsUpdatingMulti, selectSelectedApplicationId } from 'src/app/store/selectors';
import { getActiveProgramSummary } from 'src/app/store/selectors/app-data-selector.functions';
import { PageIndex } from '../interfaces';
import { PageName } from '../interfaces/page-names.enum';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor(
    private router: Router, 
    private location: Location, 
    private store: Store
  ) { 
    this.router.events
    .pipe(filter(e => e instanceof NavigationEnd))
    .subscribe(() => {
      const headerContent: HTMLElement = <HTMLElement>document.querySelector('#header-content');
      if (!!headerContent) {
        headerContent.focus();
      }
    });
  }

  goBack(): void {
    this.location.back();
  }

  /**
   * If the EnableHomeownerPortalMultipleApplications config setting is true,
   * navigate the user to the applications dashboard
   * Else navigate the user to the single application dashboard
   */
  goHome(): void {
    const selectedAppId$ = this.store.select(selectSelectedApplicationId);
    const toggles$ = this.store.select(selectFeatureToggles);
    
    combineLatest([selectedAppId$, toggles$]).pipe(
      first(),
      tap(([selectedAppId, toggles]) => {
        if (toggles.EnableHomeownerPortalMultipleApplications) {
          this.goToApplicationsDashboard();
        } else {
          this.goToAppDashboard(selectedAppId);
        }
      })
    ).subscribe();
  }

  goToLogin(): void {
    this.router.navigate([LOGIN_ROUTE]);
  }

  /**
   * Navigate to a specific application dashboard
   * If no applicationId provided, fallback to the main applications dashboard
   * 
   * @param applicationId 
   */
  goToAppDashboard(applicationId: number | undefined): void {
    if (!!applicationId) {
      this.router.navigate([DASHBOARD_ROUTE, APPLICATION_DETAILS_ROUTE, applicationId])
    } else {
      this.goToApplicationsDashboard();
    }
  }

  get isOnAppsDashboard(): boolean {
    return this.getCurrentRoute().includes(DASHBOARD_APPLICATIONS_ROUTE);
  }

  /**
   * If the user is not already on the applications dashboard page, clear out the 
   * selected application from the store and then navigate to the applications dashboard
   */
  goToApplicationsDashboard(): void {
    if (!this.isOnAppsDashboard) {
      this.store.dispatch(MultiApplicationActions.reset());
      this.store.select(selectIsUpdatingMulti).pipe(
        filter(isUpdating => !isUpdating),
        first(),
        map(_ => {
          this.router.navigate([DASHBOARD_ROUTE, APPLICATIONS_ROUTE]);
        })
      ).subscribe();
    }
  }

  goToProfile(): void {
    this.router.navigate([PROFILE_ROUTE, EDIT_ROUTE]);
  }

  goToRegistrationCode(loginPassword: string, mfaEnabled = false, cellPhone = '', isEmailAlertsEnabled = false): void {
    this.store.dispatch(UserActions.updateIsUpdatingRegistrationCode({ isUpdating: true }));
    const navExtras: NavigationExtras = { 
      state: { 
        password: loginPassword, 
        mfaEnabled: !!mfaEnabled, 
        cellPhone: cellPhone,
        isEmailAlertsEnabled: isEmailAlertsEnabled
      }
    };
    this.router.navigate([PROFILE_ROUTE, VERIFY_ROUTE], navExtras);
  }

  goToPrequalification(): void {
    this.router.navigate([PREQUAL_ROUTE, QUESTIONS_ROUTE]);
  }

  goToProgramSelection(): void {
    this.router.navigate([PREQUAL_ROUTE, PROGRAM_SELECTION_ROUTE]);
  }

  goToRoute(commands: any[]): void {
    this.router.navigate(commands);
  }

  getCurrentRoute(): string {
    return this.router.url;
  }

  getUrlTree(url: string): UrlTree {
    return this.router.parseUrl(url);
  }

  goToApplicationPage(index: PageIndex, appId: number): void {
    switch (index) {
      case PageIndex.Dashboard:
        this.router.navigate([PageName.Dashboard, APPLICATION_DETAILS_ROUTE, appId]);
        return;
      case PageIndex.PersonalInformation:
        this.router.navigate([APPLICATIONS_ROUTE, appId, PageName.PersonalInformation]);
        return;
      case PageIndex.Employment:
        this.router.navigate([APPLICATIONS_ROUTE, appId, PageName.Employment]);
        return;
      case PageIndex.Property:
        this.router.navigate([APPLICATIONS_ROUTE, appId, PageName.Property]);
        return;
      case PageIndex.Mortgage:
        this.router.navigate([APPLICATIONS_ROUTE, appId, PageName.Mortgage]);
        return;
      case PageIndex.Financial:
        this.router.navigate([APPLICATIONS_ROUTE, appId, PageName.Financial]);
        return;
      case PageIndex.ApplicationSummary:
        this.router.navigate([APPLICATIONS_ROUTE, appId, PageName.ApplicationSummary]);
        return;
      case PageIndex.SupportingFiles:
        this.router.navigate([APPLICATIONS_ROUTE, appId, PageName.SupportingFiles]);
        return;
      default:
        return;
    }
  }

  /**
   * Routing helper functions below for use in Route Guards
   */

  /**
   * Return true if the URL is valid for the given application state
   * OR
   * Return the first valid URL
   * @param url 
   * @param data 
   */
   canNavigate(url: string, data: HomeownerApplicationApiData | undefined): boolean | UrlTree {
    this.store.dispatch(MessagesActions.updateLoadingMessage({ message: '' }));
    const stateName = this.getApplicationStateName(data);
    const validUrls = !!data ? stateNameMapping[stateName] : stateNameMapping[ApplicationStateName.Active];
    return (this.isNewApplication(url, data) || this.urlIsValid(url, validUrls)) || this.router.parseUrl(validUrls[0]);
  }

  /**
   * Return true if the user is navigating to program-selection and no selected application is present
   * @param url 
   * @param data 
   * @returns boolean
   */
  private isNewApplication(url: string, data: HomeownerApplicationApiData | undefined): boolean {
    return url.includes(PREQUAL_PROGRAM_SELECTION_ROUTE) && !data;
  }

  /**
   * Return true if the url to navigate to includes any of the valid URLs for a
   * given application state
   * @param url 
   * @param validUrls 
   * @returns boolean
   */
  urlIsValid(url: string, validUrls: string[]): boolean {
    let isValid = false;
    validUrls.forEach(validUrl => url.includes(validUrl) ? isValid = true : null);
    return isValid;
  }

  /**
   * @param data
   * @returns the state name of the current application state
   */
  getApplicationStateName(data: HomeownerApplicationApiData | undefined): ApplicationStateName {
    let stateName: ApplicationStateName = ApplicationStateName.Active;
    const programSummary = getActiveProgramSummary(data);
    const isActive = data?.ApplicationStatus?.Status === 'Active';
    const statusId = programSummary?.Status?.Id || -1;
    const stageId = programSummary?.Stage?.Id || -1;
    const programSummaryId = programSummary?.Id;
    const appStatus = data?.ApplicationStatus?.Status;

    if (!!data) {
      this.store.dispatch(AppInfoActions.updateIsActiveApplication({ isActive: isActive }));
      this.store.dispatch(AppInfoActions.updateApplicationStage({ stage: stageId }));
    }

    const needsPrequal = statusId === 1 && stageId === 1;
    const notEligible = statusId === 3 && stageId === 1;
    const needsProgram = !programSummaryId || programSummaryId < 0;
    const isInactive = appStatus !== 'Active';
    const isSubmitted = !!programSummary?.Stage?.Id && (programSummary.Stage.Id >= ApplicationSubmmitedId);

    if (needsPrequal) {
      stateName = ApplicationStateName.Prequalification;
    } else if (notEligible) { 
      stateName = ApplicationStateName.NotEligible;
    } else if (needsProgram) {
      stateName = ApplicationStateName.ProgramSelection;
    } else if (isInactive) {
      stateName = ApplicationStateName.Inactive;
    } else if (isSubmitted) {
      stateName = ApplicationStateName.Submitted;
    }

    return stateName;
  }
}
