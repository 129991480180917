import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { HomeownerApplicationApiData } from 'src/app/homeowner-application/interfaces';
import { NavService } from 'src/app/shared/services';
import { ApiActions, AppInfoActions, MultiApplicationActions } from '../actions';
import { selectApplicationsDashboard, selectIsMultiAppEnabled, selectMostRecentCopyableApplicationId, selectSelectedApplication, selectSelectedApplicationId } from '../selectors';
import { getActiveProgramSummary } from '../selectors/app-data-selector.functions';

@Injectable()
export class MultiApplicationEffects {

  fetchApplications$ = createEffect(() => this.actions$.pipe(
    ofType(MultiApplicationActions.loadApplications),
    mergeMap(_ => this.store.select(selectApplicationsDashboard).pipe(
      first()
    )),
    mergeMap(list => !!list ? 
      of(MultiApplicationActions.alreadyLoadedApplications()) : 
      this.appApi.getApplications().pipe(
        first(),
        map(response => MultiApplicationActions.loadApplicationsSuccess({data: response}))
      )
    ),
    catchError(error => of(MultiApplicationActions.loadApplicationsError({error: error})))
  ));

  fetchApplicationById$ = createEffect(() => this.actions$.pipe(
    ofType(MultiApplicationActions.loadApplicationById),
    mergeMap(action => this.appApi.getApplication(action.applicationId).pipe(
      first()
    )),
    mergeMap(response => {
      const programSummary = getActiveProgramSummary(response);
      const isActive = response.ApplicationStatus?.Status === 'Active';
      const stageId = programSummary?.Stage?.Id || -1;
      return [
        AppInfoActions.updateAppState({ data: response }),
        AppInfoActions.setInitialFormStatus({ data: response }),
        AppInfoActions.updateIsActiveApplication({ isActive: isActive }),
        AppInfoActions.updateApplicationStage({ stage: stageId }),
        MultiApplicationActions.loadApplicationByIdSuccess({application: response})
      ]
    }),
    catchError(error => of(MultiApplicationActions.loadApplicationByIdError({error: error})))
  ));

  goToApplicationDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(MultiApplicationActions.goToApplicationDashboard),
    mergeMap(action => {
      this.ns.goToAppDashboard(action.applicationId);
      return of(MultiApplicationActions.goToApplicationDashboardSuccess());
    })
  ));

  /**
   * Program Selection complete flow to copy recent application data for prequal if available:
   *    - proceed if multi-app feature is enabled
   *    - proceed if selected application id is present in state
   *    - select most recent copyable application id
   *    - if an id is defined, fetch the application data from the API
   *        - dispatch the next action in the flow
   *    - else
   *        - dispatch doNotCopyAppDetails to close the flow
   */
  triggerCopyAppDetailsAfterProgramSelectionIfAvailable$ = createEffect(() => this.actions$.pipe(
    ofType(MultiApplicationActions.programSelectionComplete),
    mergeMap(_ => this.store.select(selectIsMultiAppEnabled).pipe(
      filter(isEnabled => !!isEnabled)
    )),
    mergeMap(_ => this.store.select(selectSelectedApplicationId).pipe(
      filter(id => !!id),
      first()
    )),
    mergeMap(_ => this.store.select(selectMostRecentCopyableApplicationId).pipe(
      first()
    )),
    mergeMap(appId => {
      if (!!appId) {
        return of(MultiApplicationActions.copyAppDetailsAfterProgramSelection());
      } else {
        return of(MultiApplicationActions.doNotCopyAppDetails());
      }
    })
  ));


/**
   * Copy App Details after program selection
   */
  updateDataForCopiedDetailsAfterProgramSelection$ = createEffect(() => this.actions$.pipe(
    ofType(MultiApplicationActions.copyAppDetailsAfterProgramSelection),
    mergeMap(_ => this.appApi.updateApplicationFromCopy()),
    mergeMap(data => [ApiActions.putAppDataSuccess({ data: data })]),
    catchError(error => of(ApiActions.putAppDataError({ error })))
  ));

  constructor(
    private actions$: Actions, 
    private appApi: HomeownerApplicationApiService, 
    private store: Store,
    private ns: NavService
  ) {}
}