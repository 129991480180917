import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { ListApiService } from 'src/app/core/services/list-api.service';
import { ApiActions } from '../actions';
import { selectBankruptcyTypesList, selectBestTimesToCallList, selectDelinquencyStatusesList, selectDiscoveryTypesList, selectEligibleExpenseCategoriesList, selectEthnicitiesList, selectExpenseCategoriesList, selectFeatureToggles, selectFileCategoriesList, selectGendersList, selectHardshipReasons, selectHelpLinksList, selectHowLongList, selectIncomeSourcesList, selectLanguagesList, selectLoanProgramsList, selectLoanTypesList, selectMaritalStatusesList, selectMortgageLenders, selectMortgageServicers, selectPaymentDueDatesList, selectPhoneTypesList, selectProgramCountiesList, selectProgramsList, selectProgramStagesList, selectRacesList, selectSociallyDisadvantagedList, selectStatesList, selectWhoPaysList, selectYesNoList, selectYesNoPendingList } from '../selectors';

@Injectable({
  providedIn: 'root'
})
export class ApiListEffects {

    getBankruptcyList$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadBankruptcyTypesList),
        mergeMap(_ => this.store.select(selectBankruptcyTypesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getBankruptcyTypes().pipe(
            first(),
            map(response => ApiActions.loadBankruptcyTypesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadBankruptcyTypesError({error: error})))
    ));

    getBestTimesList$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadBestTimesToCallList),
        mergeMap(_ => this.store.select(selectBestTimesToCallList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getBestTimesToCall().pipe(
            first(),
            map(response => ApiActions.loadBestTimesToCallSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadBestTimesToCallError({error: error})))
    ));

    getCounties$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadCounties),
        mergeMap(response => {
            if (!!response.state) {
                return this.listApi.getCounties(response.state).pipe(
                    first(),
                    map(response => ApiActions.loadCountiesSuccess({list: response}))
                );
            } else {
                return of(ApiActions.loadCountiesError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadCountiesError(error)))
    ));

    getHomeownerCounties$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadHoCounties),
        mergeMap(response => {
            if (!!response.state) {
                return this.listApi.getCounties(response.state).pipe(
                    first(),
                    map(response => ApiActions.loadHoCountiesSuccess({list: response}))
                );
            } else {
                return of(ApiActions.loadCountiesError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadCountiesError(error)))
    ));

    getCoBorrowerCounties$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadCbCounties),
        mergeMap(response => {
            if (!!response.state) {
                return this.listApi.getCounties(response.state).pipe(
                    first(),
                    map(response => ApiActions.loadCbCountiesSuccess({list: response}))
                );
            } else {
                return of(ApiActions.loadCountiesError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadCountiesError(error)))
    ));

    getSpouseCounties$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadSpCounties),
        mergeMap(response => {
            if (!!response.state) {
                return this.listApi.getCounties(response.state).pipe(
                    first(),
                    map(response => ApiActions.loadSpCountiesSuccess({list: response}))
                );
            } else {
                return of(ApiActions.loadCountiesError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadCountiesError(error)))
    ));

    getHomeownerEmploymentCounties$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadHoEmploymentCounties),
        mergeMap(response => {
            if (!!response.state) {
                return this.listApi.getCounties(response.state).pipe(
                    first(),
                    map(response => ApiActions.loadHoEmploymentCountiesSuccess({list: response}))
                );
            } else {
                return of(ApiActions.loadCountiesError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadCountiesError(error)))
    ));

    getCoborrowerEmploymentCounties$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadCbEmploymentCounties),
        mergeMap(response => {
            if (!!response.state) {
                return this.listApi.getCounties(response.state).pipe(
                    first(),
                    map(response => ApiActions.loadCbEmploymentCountiesSuccess({list: response}))
                );
            } else {
                return of(ApiActions.loadCountiesError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadCountiesError(error)))
    ));

    getDiscoveryTypes$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadDiscoveryTypes),
        mergeMap(_ => this.store.select(selectDiscoveryTypesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getDiscoveryTypes().pipe(
            first(),
            map(response => ApiActions.loadDiscoveryTypesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadDiscoveryTypesError({error: error})))
    ));

    getEligibleExpenseCategories$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadEligibleExpenseCategories),
        mergeMap(_ => this.store.select(selectEligibleExpenseCategoriesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getEligibleExpenseCategories().pipe(
            first(),
            map(response => ApiActions.loadEligibleExpenseCategoriesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadEligibleExpenseCategoriesError({error: error})))
    ));

    getEligibleExpenseProviders$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadEligibleExpenseProviders),
        mergeMap(response => {
            if (!!response.category) {
                return this.listApi.getEligibleExpenseProviders(response.category).pipe(
                    first(),
                    map(list => ApiActions.loadEligibleExpenseProvidersSuccess({list: list, category: response.category}))
                );
            } else {
                return of(ApiActions.loadEligibleExpenseProvidersError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadEligibleExpenseProvidersError(error)))
    ));

    getEligibleExpenseTypes$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadEligibleExpenseTypes),
        mergeMap(response => {
            if (!!response.category) {
                return this.listApi.getEligibleExpenseTypes(response.category).pipe(
                    first(),
                    map(list => ApiActions.loadEligibleExpenseTypesSuccess({list: list, category: response.category}))
                );
            } else {
                return of(ApiActions.loadEligibleExpenseTypesError({error: null}));
            }
        }), catchError(error => of(ApiActions.loadEligibleExpenseTypesError(error)))
    ));

    getEthnicities$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadEthnicities),
        mergeMap(_ => this.store.select(selectEthnicitiesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getEthnicities().pipe(
            first(),
            map(response => ApiActions.loadEthnicitiesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadEthnicitiesError({error: error})))
    ));

    getExpenseCategories$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadExpenseCategories),
        mergeMap(_ => this.store.select(selectExpenseCategoriesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getExpenseCategories().pipe(
            first(),
            map(response => ApiActions.loadExpenseCategoriesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadExpenseCategoriesError({error: error})))
    ));

    getFeatureToggles$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadFeatureToggles),
        mergeMap(_ => this.store.select(selectFeatureToggles)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getFeatureToggles().pipe(
            first(),
            map(response => ApiActions.loadFeatureTogglesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadFeatureTogglesError({error: error})))
    ));

    getFileCategories$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadFileCategories),
        mergeMap(_ => this.store.select(selectFileCategoriesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getFileCategories().pipe(
            first(),
            map(response => ApiActions.loadFileCategoriesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadFileCategoriesError({error: error})))
    ));

    getGenders$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadGenders),
        mergeMap(_ => this.store.select(selectGendersList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getGenders().pipe(
            first(),
            map(response => ApiActions.loadGendersSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadGendersError({error: error})))
    ));

    getHardshipReasons$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadHardshipReasons),
        mergeMap(_ => this.appApi.getHardshipReasons().pipe(
            first(),
            map(response => ApiActions.loadHardshipReasonsSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadHardshipReasonsError({error: error})))
    ));

    getHowLongWithEmployers$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadHowLongWithEmployers),
        mergeMap(_ => this.store.select(selectHowLongList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getHowLongWithEmployers().pipe(
            first(),
            map(response => ApiActions.loadHowLongWithEmployersSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadHowLongWithEmployersError({error: error})))
    ));

    getIncomeSources$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadIncomeSources),
        mergeMap(_ => this.store.select(selectIncomeSourcesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getIncomeSources().pipe(
            first(),
            map(response => ApiActions.loadIncomeSourcesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadIncomeSourcesError({error: error})))
    ));

    getLanguages$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadLanguages),
        mergeMap(_ => this.store.select(selectLanguagesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getLanguages().pipe(
            first(),
            map(response => ApiActions.loadLanguagesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadLanguagesError({error: error})))
    ));

    getLoanTypes$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadLoanTypes),
        mergeMap(_ => this.store.select(selectLoanTypesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getLoanTypes().pipe(
            first(),
            map(response => ApiActions.loadLoanTypesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadLoanTypesError({error: error})))
    ));

    getMaritalStatuses = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadMaritalStatuses),
        mergeMap(_ => this.store.select(selectMaritalStatusesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getMaritalStatuses().pipe(
            first(),
            map(response => ApiActions.loadMaritalStatusesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadMaritalStatusesError({error: error})))
    ));

    getMortgageLenders$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadMortgageLenders),
        mergeMap(_ => this.store.select(selectMortgageLenders)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.appApi.getMortgageLenders().pipe(
            first(),
            map(response => ApiActions.loadMortgageLendersSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadMortgageLendersError({error: error})))
    ));

    getMortgageServicers$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadMortgageServicers),
        mergeMap(_ => this.store.select(selectMortgageServicers)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.appApi.getMortgageServicers().pipe(
            first(),
            map(response => ApiActions.loadMortgageServicersSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadMortgageServicersError({error: error})))
    ));

    getDelinquencyStatuses$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadMortgageDelinquencyStatusesList),
        mergeMap(_ => this.store.select(selectDelinquencyStatusesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getMortgageDelinquencyStatuses().pipe(
            first(),
            map(response => ApiActions.loadMortgageDelinquencySuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadMortgageDelinquencyError({error: error})))
    ));

    getLoanPrograms$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadMortgageLoanProgramsList),
        mergeMap(_ => this.store.select(selectLoanProgramsList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getMortgageLoanPrograms().pipe(
            first(),
            map(response => ApiActions.loadMortgageLoanProgramsSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadMortgageLoanProgramsError({error: error})))
    ));

    getPaymentDueDates$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadMortgagePaymentDueDatesList),
        mergeMap(_ => this.store.select(selectPaymentDueDatesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getMortgagePaymentDueDates().pipe(
            first(),
            map(response => ApiActions.loadMortgagePaymentDueDatesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadMortgagePaymentDueDatesError({error: error})))
    ));

    getPhoneTypes$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadPhoneTypesList),
        mergeMap(_ => this.store.select(selectPhoneTypesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getPhoneTypes().pipe(
            first(),
            map(response => ApiActions.loadPhoneTypesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadPhoneTypesError({error: error})))
    ));

    getProgramCounties$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadProgramCountiesList),
        mergeMap(_ => this.store.select(selectProgramCountiesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getProgramCounties().pipe(
            first(),
            map(response => ApiActions.loadProgramCountiesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadProgramCountiesError({error: error})))
    ));

    getPrograms$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadProgramsList),
        mergeMap(_ => this.store.select(selectProgramsList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getPrograms().pipe(
            first(),
            map(response => ApiActions.loadProgramsSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadProgramsError({error: error})))
    ));

    getRaces$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadRacesList),
        mergeMap(_ => this.store.select(selectRacesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getRaces().pipe(
            first(),
            map(response => ApiActions.loadRacesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadRacesError({error: error})))
    ));

    getSociallyDisadvantaged$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadSociallyDisadvantagedList),
        mergeMap(_ => this.store.select(selectSociallyDisadvantagedList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getSociallyDisadvantagedList().pipe(
            first(),
            map(response => ApiActions.loadSociallyDisadvantagedSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadSociallyDisadvantagedError({error: error})))
    ));

    getStates$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadStatesList),
        mergeMap(_ => this.store.select(selectStatesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getStates().pipe(
            first(),
            map(response => ApiActions.loadStatesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadStatesError({error: error})))
    ));

    getWhoPays$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadWhoPaysList),
        mergeMap(_ => this.store.select(selectWhoPaysList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getWhoPays().pipe(
            first(),
            map(response => ApiActions.loadWhoPaysSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadWhoPaysError({error: error})))
    ));

    getYesNo$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadYesNoList),
        mergeMap(_ => this.store.select(selectYesNoList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getYesNo().pipe(
            first(),
            map(response => ApiActions.loadYesNoSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadYesNoError({error: error})))
    ));

    getYesNoPending$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadYesNoPendingList),
        mergeMap(_ => this.store.select(selectYesNoPendingList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getYesNoPending().pipe(
            first(),
            map(response => ApiActions.loadYesNoPendingSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadYesNoPendingError({error: error})))
    ));

    getHelpLinks$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadHelpLinks),
        mergeMap(_ => this.store.select(selectHelpLinksList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 
          this.listApi.getHelpLinks().pipe(
            first(),
            map(response => ApiActions.loadHelpLinksSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadHelpLinksError({error: error})))
    ));

    getProgramStages$ = createEffect(() => this.actions$.pipe(
        ofType(ApiActions.loadProgramStages),
        mergeMap(_ => this.store.select(selectProgramStagesList)),
        mergeMap(list => !!list ? 
          of(ApiActions.listAlreadyFetched()) : 

          this.listApi.getProgramStages().pipe(
            first(),
            map(response => ApiActions.loadProgramStagesSuccess({list: response}))
          )
        ),
        catchError(error => of(ApiActions.loadProgramStagesError({error: error})))
    ));

    getApplicationSummaryLists$ = createEffect(() => this.actions$.pipe(
      ofType(ApiActions.loadApplicationSummaryLists),
      mergeMap(_ => {
        return [
          ApiActions.loadHardshipReasons(),
          ApiActions.loadMortgageServicers(),
          ApiActions.loadMortgageLenders(),
          ApiActions.loadMaritalStatuses(),
          ApiActions.loadStatesList(),
          ApiActions.loadProgramCountiesList(),
          ApiActions.loadPhoneTypesList(),
          ApiActions.loadBestTimesToCallList(),
          ApiActions.loadHowLongWithEmployers(),
          ApiActions.loadMortgageDelinquencyStatusesList(),
          ApiActions.loadLoanTypes(),
          ApiActions.loadIncomeSources(),
          ApiActions.loadEthnicities(),
          ApiActions.loadGenders(),
          ApiActions.loadRacesList(),
          ApiActions.loadWhoPaysList(),
          ApiActions.loadSociallyDisadvantagedList(),
          ApiActions.loadBankruptcyTypesList(),
          ApiActions.loadYesNoList(),
          ApiActions.loadLanguages(),
          ApiActions.loadEligibleExpenseCategories()
        ];
      })
    ));

    getEmploymentLists$ = createEffect(() => this.actions$.pipe(
      ofType(ApiActions.loadEmploymentLists),
      mergeMap(_ => {
        return [
          ApiActions.loadHowLongWithEmployers(),
          ApiActions.loadStatesList(),
          ApiActions.loadYesNoList()
        ];
      })
    ));

    getFinancialLists$ = createEffect(() => this.actions$.pipe(
      ofType(ApiActions.loadFinancialLists),
      mergeMap(_ => {
        return [
          ApiActions.loadBankruptcyTypesList(),
          ApiActions.loadEligibleExpenseCategories(),
          ApiActions.loadExpenseCategories(),
          ApiActions.loadIncomeSources()
        ];
      })
    ));

    getMortgageLists$ = createEffect(() => this.actions$.pipe(
      ofType(ApiActions.loadMortgageLists),
      mergeMap(_ => {
        return [
          ApiActions.loadMortgageLenders(),
          ApiActions.loadMortgageServicers(),
          ApiActions.loadLoanTypes(),
          ApiActions.loadMortgageDelinquencyStatusesList(),
          ApiActions.loadMortgagePaymentDueDatesList(),
          ApiActions.loadMortgageLoanProgramsList(),
          ApiActions.loadYesNoPendingList()
        ];
      })
    ));

    getPersonalLists$ = createEffect(() => this.actions$.pipe(
      ofType(ApiActions.loadPersonalLists),
      mergeMap(_ => {
        return [
          ApiActions.loadRacesList(),
          ApiActions.loadBestTimesToCallList(),
          ApiActions.loadHardshipReasons(),
          ApiActions.loadMaritalStatuses(),
          ApiActions.loadPhoneTypesList(),
          ApiActions.loadStatesList(),
          ApiActions.loadEthnicities(),
          ApiActions.loadGenders(),
          ApiActions.loadSociallyDisadvantagedList(),
          ApiActions.loadLanguages()
        ];
      })
    ));

    getPropertyLists$ = createEffect(() => this.actions$.pipe(
      ofType(ApiActions.loadPropertyLists),
      mergeMap(_ => {
        return [
          ApiActions.loadWhoPaysList(),
          ApiActions.loadStatesList(),
          ApiActions.loadProgramCountiesList(),
          ApiActions.loadMortgagePaymentDueDatesList(),
          ApiActions.loadEligibleExpenseCategories()
        ];
      })
    ));

    getSupportingFilesLists$ = createEffect(() => this.actions$.pipe(
      ofType(ApiActions.loadSupportingFilesLists),
      mergeMap(_ => [ApiActions.loadFileCategories()])
    ));

    constructor(private actions$: Actions, private appApi: HomeownerApplicationApiService, private listApi: ListApiService, private store: Store) {}
}