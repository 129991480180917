import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { APPLICATION_ROUTE, DASHBOARD_ROUTE, LOGIN_ROUTE, NOT_FOUND_ROUTE, PASSWORD_ROUTE, PREQUAL_ROUTE, PROFILE_ROUTE, STATIC_ROUTE } from 'routes';
import { ApplicationStateGuard, AuthGuard } from './shared/guards';

export const routes: Routes = [
  { path: LOGIN_ROUTE, loadChildren: () => import('./login/login.module').then(m => m.LoginModule) }, 
  { path: PASSWORD_ROUTE, loadChildren: () => import('./password/password.module').then(m => m.PasswordModule) },
  { path: DASHBOARD_ROUTE, loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: PROFILE_ROUTE, loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: PREQUAL_ROUTE, loadChildren: () => import('./prequalification/prequalification.module').then(m => m.PrequalificationModule) },
  { path: STATIC_ROUTE, loadChildren: () => import('./static-pages/static-pages.module').then(m => m.StaticPagesModule) },
  { 
    path: `${APPLICATION_ROUTE}/:id`, 
    loadChildren: () => import('./homeowner-application/homeowner-application.module').then(m => m.HomeownerApplicationModule),
    canActivate: [AuthGuard, ApplicationStateGuard]
   },
  { path: '', redirectTo: LOGIN_ROUTE, pathMatch: 'full' },
  { path: '**', redirectTo: `/${STATIC_ROUTE}/${NOT_FOUND_ROUTE}`}
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  preloadingStrategy: PreloadAllModules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)]
})
export class AppRoutingModule { }
