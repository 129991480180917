import { Component, Input, OnInit } from '@angular/core';
import { TeamMember } from 'src/app/homeowner-application/interfaces';
import { FormConditionsService } from 'src/app/homeowner-application/services/form-conditions.service';

@Component({
  selector: 'app-team-member',
  templateUrl: './app-team-member.component.html',
  styleUrls: ['./app-team-member.component.scss']
})
export class AppTeamMemberComponent implements OnInit {

  @Input() teamMember: TeamMember | undefined;
  @Input() isCommunications? = false;

  ngOnInit(): void {}

  constructor(private fcs: FormConditionsService) {}

  get phoneNumber(): string {
    return this.fcs.formatPhone(!!this.teamMember?.Phone ? this.teamMember?.Phone : '');
  }

  get faxNumber(): string {
    return this.fcs.formatPhone(!!this.teamMember?.Fax ? this.teamMember?.Fax : '');
  }

}
