import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { ListApiService } from 'src/app/core/services/list-api.service';
import { ListObject, ValidationClass, YesNo } from '../../interfaces';

@Component({
  selector: 'app-form-data-display',
  templateUrl: './app-form-data-display.component.html',
  styleUrls: ['./app-form-data-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFormDataDisplayComponent implements OnInit {

  @Input() errorMsg? = '';
  @Input() field!: AbstractControl | null;
  @Input() label!: string;
  @Input() value?: number | string = '';
  @Input() list?: ListObject[] | null = [];
  @Input() values?: number[] = [];
  @Input() isMultiSelect?: boolean = false;
  @Input() isRace?: boolean = false;
  @Input() isNonNegative?: boolean = false;
  @Input() isPhone?: boolean = false;
  @Input() categoryId?: number;
  @Input() expenseType?: boolean;
  @Input() translate?: boolean = true;

  destroy = new Subject();
  eligibleExpenseProviders!: ListObject[];
  eligibleExpenseTypes!: ListObject[];
  eligibleExpenseProviders$!: Observable<ListObject[]>;
  eligibleExpenseTypes$!: Observable<ListObject[]>;

  ValidClass = ValidationClass;

  constructor(private listApiService: ListApiService) {}

  ngOnInit(): void {
    /**
     * Fetch expense providers and types for the given categoryId as needed
     * If this network call previously executed, the http cache interceptor
     *    will intercept the call and return the response rather than needing
     *    to make another HTTP request
     */
    if (!!this.expenseType && !!this.categoryId) {
      this.eligibleExpenseTypes$ = this.listApiService.getEligibleExpenseTypes(this.categoryId).pipe(
        first(),
        map(list => this.eligibleExpenseTypes = list),
        takeUntil(this.destroy)
      );
    } else if (!this.expenseType && !!this.categoryId) {
      this.eligibleExpenseProviders$ = this.listApiService.getEligibleExpenseProviders(this.categoryId).pipe(
        first(),
        map(list => this.eligibleExpenseProviders = list),
        takeUntil(this.destroy)
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  get displayName(): string {
    let name = '';
    if (!!this.list && this.isMultiSelect) {
      name = this.displayMultiName;
    } else if (!!this.list && this.isRace) {
      name = this.displayRaceName;
    } else if (!!this.list) {
      const value = this.field?.value;
      const match = this.list.find((item: ListObject) => item.Id === value);
      name = !!match ? (this.translate == true ? translate(match.Name) :  match.Name) : '';
    }
    return name;
  }

  get displayMultiName(): string {
    let name = '';

    if (!!this.list && this.isMultiSelect) {
      this.values = this.field?.value || [];
      this.values?.forEach(value => {
        const match = this.list?.find((item: ListObject) => item.Id === value);
        name += !!match ? `${translate(match.Name)} <br>` : ''
      })
    }
    return name;
  }

  get displayRaceName(): string {
    let name = '';

    if (!!this.list && this.isRace) {
      this.values = this.field?.value.map((value: any) => value);
      let selectedRaces = this.values
        ?.map((v: any, index) => {
          if (typeof(v) === 'number'){
            return translate(this.list?.find(i => i.Id == v)?.Name || '');
          } else if (v === true) {
            return translate(this.list?.find(i => i.Id == index + 1)?.Name || '')
          } else {
            return null
          }
        }).filter(v => v !== null);
        
      return selectedRaces?.join('<br />') || '';
    }

    return name;
  }

  get expenseTypeMatch(): ListObject | undefined {
    return !!this.eligibleExpenseTypes ? this.eligibleExpenseTypes.find(p => p.Id === this.field?.value) : undefined;
  }

  get providerMatch(): ListObject | undefined {
    return !!this.eligibleExpenseProviders ? this.eligibleExpenseProviders.find(p => p.Id === this.field?.value) : undefined;
  }
  
  getValue(val?: string | boolean): string {
    let displayVal = !!val ? val.toString() : '';

    if (typeof(val) === 'boolean') {
      displayVal = !!val ? translate(`${'commonWords.yes'}`) : translate(`${'commonWords.no'}`);
    }

    return displayVal;
  }

}
