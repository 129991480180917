import { Action, createReducer, on } from '@ngrx/store';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';
import { HomeownerApplicationApiData, ProgramFeatures } from 'src/app/homeowner-application/interfaces';
import { AppInfoActions, FeatureTogglesActions } from '../actions';
import { getActiveProgramSummary } from '../selectors/app-data-selector.functions';

export const togglesFeatureKey = 'featureToggles';

export const initialState: FeatureToggles = {
  isLoading: true,
  EnableHomeownerPortalCommunications: false,
  EnableHomeownerPortalDefaultMFASelection: false,
  EnableHomeownerPortalMultipleApplications: false,
  EnableHomeownerPortalRememberMyDevice: true,
  IsAddressValidationAvailable: false,
  IsDocusignEnabled: false,
  IsEligibleExpensesEnabled: false,
  HasJudgments: false,
  IsSignupEnabled: false,
  IsSociallyDisadvantagedEnabled: true,
  RequireExpenses: false,
  RequireMortgageData: true
};

function getProgramFeatures(data: HomeownerApplicationApiData): ProgramFeatures {
  const programSummary = getActiveProgramSummary(data);
  const features: ProgramFeatures = !!programSummary ? 
    {
      IsEligibleExpensesEnabled: programSummary.IsEligibleExpensesEnabled,
      IsSignUpEnabled: programSummary.IsSignUpEnabled,
      RequireExpenses: programSummary.RequireExpenses,
      RequireMortgageData: programSummary.RequireMortgageData  
    } : 
    {
      IsEligibleExpensesEnabled: false,
      IsSignUpEnabled: false,
      RequireExpenses: false,
      RequireMortgageData: false
    };
  return features;
}

const featureTogglesReducer = createReducer(
  initialState,
  on(FeatureTogglesActions.updateFeatureToggles, (state, { toggles }) => {
    return ({ ...state, ...toggles, isLoading: false })
  }),
  /**
   * When an application is fetched, update the FeatureToggles state with the
   * values from the active ProgramSummary
   */
  on(AppInfoActions.updateAppState, (state, { data }) => {
    const features = getProgramFeatures(data);
    const isEligibleExpensesEnabled = features.IsEligibleExpensesEnabled;
    const requireExpenses = features.RequireExpenses;
    const requireMortgageData = features.RequireMortgageData;
    return ({ 
      ...state, 
      IsEligibleExpensesEnabled: isEligibleExpensesEnabled, 
      RequireExpenses: requireExpenses,
      RequireMortgageData: requireMortgageData 
    })
  }),
);

export function reducer(state: FeatureToggles | undefined, action: Action) {
  return featureTogglesReducer(state, action);
}