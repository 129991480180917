<div class="mt-4" [ngClass]="appIsInactive ? inactiveBgColorClass : notInactiveBgColorClass" id="container" *transloco="let t">
  <div class="program-container px-4 py-3" >
    <div>
      <p class="fw-500" >{{t('appDashboard.appFor')}}</p>
      <div>
        <div>
          <h3>
            <a href="javascript:void(0);" (click)="goToProgramDashboard(application?.ApplicationId)">{{t(programName)}}
              <span *ngIf="totalPrograms > 1"> (+{{totalPrograms - 1}})</span>
            </a>
          </h3>
        </div>
        <div  [ngClass]="(hasUnreadMessages) ? 'unread-messages': ''">
          <div *ngIf="hasUnreadMessages">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#BC2123" class="bi bi-chat-left-text-fill" viewBox="0 0 16 16" >
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
              <title>{{t('appDashboard.unreadMessages')}}</title>
            </svg>
          </div>
        </div>
      </div>
      <p class="fw-500" ># {{t('appDashboard.ofPrograms')}}: {{totalPrograms}}</p>
    </div>
    
    <div>
      <p>{{t('appDashboard.application')}} #</p>
      <p>{{application?.ApplicationStatus?.ApplicationNumber}}</p>
      <p class="roboto-italic">{{submissionDate}}</p>
    </div>
    <div>
      <p>{{t('appDashboard.status')}}</p>
      <p>{{stageText}}</p>
      <p class="roboto-italic" *ngIf="!!application?.ProgramSummaries?.length && application?.ProgramSummaries?.length == 1">{{t('appDashboard.updated')}} {{date}}</p>
      <p class="roboto-italic" *ngIf="!application?.ProgramSummaries?.length"><a href="javascript:void(0);" (click)="goToProgramDashboard(application?.ApplicationId)">{{t('appDashboard.viewDetailsMessage')}}</a></p>
    </div>
    <button class="btn" [ngClass]="{'btn-outline-primary': !appIsInProgress, 'btn-primary': appIsInProgress}" (click)="goToProgramDashboard(application?.ApplicationId)">{{nextBtnText}}</button>
  </div>
  <div *ngIf="canShowDisbursements" class="disbursements px-4 py-3">
    <div class="total-disbursements" *ngIf="!!totalAmountDisbursed && !!totalApprovedAmount">
      <p>{{t('appDashboard.disbursements')}}</p>
      <p>{{t('appDashboard.totalToDate')}}</p>
      <p class="total-disbursed">${{totalAmountDisbursed}}</p>
      <small>{{t('appDashboard.of')}} ${{totalApprovedAmount}}</small>
    </div>
    <div *ngIf="!!nextDisbursementDate && !!remainingDisbursementsCount">
      <p>{{t('appDashboard.schedule')}}</p>
      <p>{{t('appDashboard.nextDisbursement')}}</p>
      <p>{{nextDisbursementDate}}</p>
      <small>{{t('appDashboard.remainingDisbursements')}}: {{remainingDisbursementsCount}}</small>
    </div>
  </div>
  
</div>
