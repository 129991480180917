<ng-container *transloco="let t;">
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title" tabindex="0" role="heading">{{t('smarty.header')}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="close('cancel')"></button>
    </div>
    <div class="modal-body" tabindex="0" role="form">
        <form [formGroup]="form">
            <div class="card-body">
                <p class="mw-100">{{t('smarty.infoText1')}}.</p>
                <div class="card mb-5">
                <h2 class="card-header">{{t('smarty.confirm')}}</h2>
                <div class="card-body">
                    <p class="mw-100">{{t('smarty.infoText2')}}.</p>
                    <div class="smarty-container">
                        <div class="you-entered">
                            <p class="mw-100 fw-500">{{t('smarty.youEntered')}}:</p>
                            <div class="form-check">
                            <input type="radio" class="form-check-input" id="User_Entered_Address" name="Selected_Address" formControlName="Selected_Address" [value]="0">
                            <label for="User_Entered_Address">
                                {{(!!userEnteredAddressObject.Address1 ? userEnteredAddressObject.Address1 + ' ' : '')}} <br *ngIf="!!userEnteredAddressObject.Address1">
                                {{(!!userEnteredAddressObject.Address2 ? userEnteredAddressObject.Address2 + ' ' : '')}} <br *ngIf="!!userEnteredAddressObject.Address2">
                                {{(!!userEnteredAddressObject.City ? userEnteredAddressObject.City + ', ' : '')}}
                                {{(!!userEnteredAddressObject.State ? t(userEnteredAddressObject.State) + ' ' : '')}}
                                {{(!!userEnteredAddressObject.ZipCode ? userEnteredAddressObject.ZipCode + ' ' : '')}} <br *ngIf="!!userEnteredAddressObject.ZipCode">
                                {{(!!userEnteredAddressObject.County ? userEnteredAddressObject.County + t('smarty.county'): '')}}
                            </label>
                            </div>
                        </div>
                        <div class="recommended">
                            <p class="mw-100 fw-500">{{t('smarty.recommended')}}:</p>
                            <ng-container *ngFor="let address of smartyAddress; let i = index">
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" id="Selected_Address{{i+1}}" name="Selected_Address" formControlName="Selected_Address" [value]="i+1">
                                    <label for="Selected_Address{{i+1}}">
                                        {{(!!address.Address1 ? address.Address1 + ' ' : '')}} <br *ngIf="!!address.Address1">
                                        {{(!!address.Address2 ? address.Address2 + ' ' : '')}} <br *ngIf="!!address.Address2">
                                        {{(!!address.City ? address.City + ', ' : '')}}
                                        {{(!!address.State ? address.State + ' ' : '')}}
                                        {{(!!address.ZipCode ? address.ZipCode + ' ' : '')}} <br *ngIf="!!address.ZipCode">
                                        {{(!!address.County ? address.County + t('smarty.county'): '')}}
                                    </label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="smartyAddress.length==0">
                                <p>{{t('smarty.none')}}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>  
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary float-right" (click)="close('cancel')">{{t('commonWords.back')}}</button>
        <button type="button" class="btn btn-primary float-right" (click)="close('save')" [disabled]="canSaveAddress">{{t('smarty.saveContinue')}}</button>
    </div>
</ng-container>