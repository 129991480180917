<app-form-status-icon [status]="field?.status" [isSection]="true"></app-form-status-icon>
<small class="{{cssClass}}" *transloco="let t; read:'formMessage'">
  <span *ngIf="emailErrors">{{t('email')}}</span>
  <span *ngIf="requiredErrors">{{t('required')}}</span>
  <span *ngIf="!!matchText">{{matchText}}</span>
  <span *ngIf="minLengthErrors">{{t('atLeast')}} {{field?.errors?.minlength.requiredLength}} {{t('characters')}}</span>
  <span *ngIf="maxLengthErrors">{{t('atMost')}} {{field?.errors?.maxlength.requiredLength}} {{t('characters')}}</span>
  <ng-container *ngIf="!emailErrors && !requiredErrors && !minLengthErrors">
    <span *ngIf="patternErrors">{{isPhone ? t('phoneNumber') : isNonNegative ? t('nonNegative') : t('reqNotMet')}}</span>
  </ng-container>
</small>