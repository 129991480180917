import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { DASHBOARD_APPLICATIONS_ROUTE } from 'routes';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { selectFeatureToggles } from 'src/app/store/selectors';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsGuard implements CanActivate {

  constructor(private store: Store, private router: Router) {}

  /**
   * If the communications feature is enabled, allow the user to navigate to the route
   * Else navigate the user to the applications dashboard
   */
  canActivate(_: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectFeatureToggles).pipe(
      first(), 
      filter((toggles) => !!toggles), 
      map((toggles) => !!toggles.EnableHomeownerPortalCommunications || this.router.parseUrl(DASHBOARD_APPLICATIONS_ROUTE))
    );
  }
}
