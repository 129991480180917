import { FeatureToggles } from "src/app/core/interfaces/toggles.interface";
import {
  EmploymentFormData, FinancialFormData, HomeownerApplicationApiData, HomeownerApplicationStateData,
  MortgageFormData, NotEligibleFormData, PersonalFormData, ProgramSummary, PropertyFormData
} from "src/app/homeowner-application/interfaces";
import { ListObject, PropertyIntention, PropertyOccupied, PropertyType } from "src/app/shared/interfaces";

/**
 * Functions to accomplish the following objectives
 * 
 * Transform application data into FormGroups (for use on form pages)
 * Transform FormGroups inputs back to application data interfaces (for storing in application state)
 * Transform application state data to PUT body interface (for updating the application)
 */
export function getDataToSubmit(appData: HomeownerApplicationApiData, toggles: FeatureToggles): HomeownerApplicationStateData {
  return {
    ApplicationStatus: appData.ApplicationStatus,
    Counselor: appData.Counselor,
    Admin: appData.Admin,
    Clerk: appData.Clerk,
    ProgramSummaries: appData.ProgramSummaries,
    Disbursements: appData.Disbursements,
    personal: getPersonalData(appData),
    employment: getEmploymentData(appData),
    property: getPropertyData(appData, toggles),
    mortgage: getMortgageData(appData),
    financial: getFinancialData(appData),
    notEligible: getNotEligibleData(appData)
  };
}

export function getPersonalData(data: HomeownerApplicationApiData): PersonalFormData {
  const borrowerRaceArray = data.BorrowerRaces || [];
  const coborrowerRaceArray = data.CoBorrowerRaces || [];
  const spouseRaceArray = data.SpouseRaces || [];

  return {
    CoBorrowerIsVeteran: data.CoBorrowerIsVeteran?.Id || -1,
    BorrowerIsVeteran: data.BorrowerIsVeteran?.Id || -1,
    SpouseIsVeteran: data.SpouseIsVeteran?.Id || -1,
    CoBorrowerPrimaryPhoneBestTimeToCall: data.CoBorrowerPrimaryPhoneBestTimeToCall?.Id || -1,
    CoBorrowerSecondaryPhoneBestTimeToCall: data.CoBorrowerSecondaryPhoneBestTimeToCall?.Id || -1,
    CoBorrowerEmail: data.CoBorrowerEmail,
    CoBorrowerPrimaryPhoneNumber: data.CoBorrowerPrimaryPhoneNumber,
    CoBorrowerPrimaryPhoneType: data.CoBorrowerPrimaryPhoneType?.Id || -1,
    CoBorrowerSecondaryPhoneNumber: data.CoBorrowerSecondaryPhoneNumber,
    CoBorrowerSecondaryPhoneType: data.CoBorrowerSecondaryPhoneType?.Id || -1,
    CoBorrowerDateOfBirth: data.CoBorrowerDateOfBirth,
    CoBorrowerFirstName: data.CoBorrowerFirstName,
    CoBorrowerLastName: data.CoBorrowerLastName,
    CoBorrowerMiddleName: data.CoBorrowerMiddleName,
    CoBorrowerSSN: data.CoBorrowerSSN,
    CoBorrowerAddress1: data.CoBorrowerAddress1,
    CoBorrowerAddress2: data.CoBorrowerAddress2,
    CoBorrowerCity: data.CoBorrowerCity,
    CoBorrowerCounty: data.CoBorrowerCounty?.Id || -1,
    CoBorrowerState: data.CoBorrowerState?.Id || -1,
    CoBorrowerZipCode: data.CoBorrowerZipCode,
    BorrowerEmail: data.BorrowerEmail,
    BorrowerPrimaryPhoneBestTimeToCall: data.BorrowerPrimaryPhoneBestTimeToCall?.Id || -1,
    BorrowerSecondaryPhoneBestTimeToCall: data.BorrowerSecondaryPhoneBestTimeToCall?.Id || -1,
    BorrowerPrimaryPhoneNumber: data.BorrowerPrimaryPhoneNumber,
    BorrowerPrimaryPhoneType: data.BorrowerPrimaryPhoneType?.Id || -1,
    BorrowerSecondaryPhoneNumber: data.BorrowerSecondaryPhoneNumber,
    BorrowerSecondaryPhoneType: data.BorrowerSecondaryPhoneType?.Id || -1,
    BorrowerDateOfBirth: data.BorrowerDateOfBirth,
    BorrowerFirstName: data.BorrowerFirstName,
    IsThereACoBorrower: data.IsThereACoBorrower?.Id || -1,
    IsCoBorrowerSpouse: data.IsCoBorrowerSpouse?.Id || -1,
    IsThereASpouse: data.IsThereASpouse?.Id || -1,
    BorrowerLastName: data.BorrowerLastName,
    MaritalStatus: data.MaritalStatus?.Id || -1,
    BorrowerMiddleName: data.BorrowerMiddleName,
    BorrowerSSN: data.BorrowerSSN,
    BorrowerAddress1: data.BorrowerAddress1,
    BorrowerAddress2: data.BorrowerAddress2,
    BorrowerCity: data.BorrowerCity,
    BorrowerCounty: data.BorrowerCounty?.Id || -1,
    BorrowerState: data.BorrowerState?.Id || -1,
    BorrowerZipCode: data.BorrowerZipCode,
    HardshipReason: data.HardshipReason?.Id || -1,
    HardshipStatement: data.HardshipStatement,
    SpouseFirstName: data.SpouseFirstName,
    SpouseMiddleName: data.SpouseMiddleName,
    SpouseLastName: data.SpouseLastName,
    SpouseSSN: data.SpouseSSN,
    SpouseDateOfBirth: data.SpouseDateOfBirth,
    SpouseAddress1: data.SpouseAddress1,
    SpouseAddress2: data.SpouseAddress2,
    SpouseCity: data.SpouseCity,
    SpouseCounty: data.SpouseCounty?.Id || -1,
    SpouseState: data.SpouseState?.Id || -1,
    SpouseZipCode: data.SpouseZipCode,
    SpousePrimaryPhoneNumber: data.SpousePrimaryPhoneNumber,
    SpousePrimaryPhoneType: data.SpousePrimaryPhoneType?.Id || -1,
    SpousePrimaryPhoneBestTimeToCall: data.SpousePrimaryPhoneBestTimeToCall?.Id || -1,
    SpouseEmail: data.SpouseEmail,
    SpouseSecondaryPhoneNumber: data.SpouseSecondaryPhoneNumber,
    SpouseSecondaryPhoneType: data.SpouseSecondaryPhoneType?.Id || -1,
    SpouseSecondaryPhoneBestTimeToCall: data.SpouseSecondaryPhoneBestTimeToCall?.Id || -1,
    HardshipOther: '',
    Property_Address1: data.Property_Address1,
    Property_Address2: data.Property_Address2,
    Property_City: data.Property_City,
    Property_County: data.Property_County?.Id || -1,
    Property_State: data.Property_State?.Id || -1,
    Property_ZipCode: data.Property_ZipCode,
    Property_TotalNumberofPersonsLivingAtThisAddress: data.Property_TotalNumberofPersonsLivingAtThisAddress || -1,
    Property_NumberOfDependentsAtAddress: data.Property_NumberOfDependentsAtAddress,
    Property_IsSameAsMailingAddress: data.Property_IsSameAsMailingAddress,
    BorrowerGender: data.BorrowerGender?.Id || -1,
    BorrowerRaces: selectedRaces(borrowerRaceArray),
    BorrowerEthnicity: data.BorrowerEthnicity?.Id || -1,
    CoBorrowerGender: data.CoBorrowerGender?.Id || -1,
    CoBorrowerRaces: selectedRaces(coborrowerRaceArray),
    CoBorrowerEthnicity: data.CoBorrowerEthnicity?.Id || -1,
    SpouseGender: data.SpouseGender?.Id || -1,
    SpouseRaces: selectedRaces(spouseRaceArray),
    SpouseEthnicity: data.SpouseEthnicity?.Id || -1,
    IsSociallyDisadvantaged: data.IsSociallyDisadvantaged?.Id || -1,
    PreferredLanguage: data.PreferredLanguage?.Id || -1,
    IsEmailAlertsEnabled: data.IsEmailAlertsEnabled
  };
}

export function getEmploymentData(data: HomeownerApplicationApiData): EmploymentFormData {
  return {
    IsBorrowerEligibleForUnemployment: data.IsBorrowerEligibleForUnemployment,
    IsCoBorrowerEligibleForUnemployment: data.IsCoBorrowerEligibleForUnemployment,
    IsSpouseEligibleForUnemployemnt: data.IsSpouseEligibleForUnemployemnt,
    BorrowerBenefitStartDate: data.BorrowerBenefitStartDate,
    BorrowerBenefitAmountMonthly: data.BorrowerBenefitAmountMonthly,
    BorrowerBenefitProjectedEndDate: data.BorrowerBenefitProjectedEndDate,
    CoBorrowerBenefitStartDate: data.CoBorrowerBenefitStartDate,
    CoBorrowerBenefitAmountMonthly: data.CoBorrowerBenefitAmountMonthly,
    CoBorrowerBenefitProjectedEndDate: data.CoBorrowerBenefitProjectedEndDate,
    SpouseBenefitStartDate: data.SpouseBenefitStartDate,
    SpouseBenefitAmountMonthly: data.SpouseBenefitAmountMonthly,
    SpouseBenefitProjectedEndDate: data.SpouseBenefitProjectedEndDate,
    IsBorrowerCurrentlyEmployed: data.IsBorrowerCurrentlyEmployed?.Id || -1,
    BorrowerEmployerName: data.BorrowerEmployerName,
    BorrowerEmployerAddress: data.BorrowerEmployerAddress,
    BorrowerEmployerCity: data.BorrowerEmployerCity,
    BorrowerEmployerState: data.BorrowerEmployerState?.Id || -1,
    BorrowerEmployerCounty: data.BorrowerEmployerCounty?.Id || -1,
    BorrowerEmployerZipCode: data.BorrowerEmployerZipCode,
    BorrowerEmployerPhone: data.BorrowerEmployerPhone,
    BorrowerHowLongWithEmployer: data.BorrowerHowLongWithEmployer?.Id || -1,
    BorrowerSelfEmployedCompanyName: data.BorrowerSelfEmployedCompanyName,
    IsCoBorrowerCurrentlyEmployed: data.IsCoBorrowerCurrentlyEmployed?.Id || -1,
    CoBorrowerEmployerName: data.CoBorrowerEmployerName,
    CoBorrowerEmployerAddress: data.CoBorrowerEmployerAddress,
    CoBorrowerEmployerCity: data.CoBorrowerEmployerCity,
    CoBorrowerEmployerState: data.CoBorrowerEmployerState?.Id || -1,
    CoBorrowerEmployerCounty: data.CoBorrowerEmployerCounty?.Id || -1,
    CoBorrowerEmployerZipCode: data.CoBorrowerEmployerZipCode,
    CoBorrowerEmployerPhone: data.CoBorrowerEmployerPhone,
    CoBorrowerHowLongWithEmployer: data.CoBorrowerHowLongWithEmployer?.Id || -1,
    CoBorrowerSelfEmployedCompanyName: data.CoBorrowerSelfEmployedCompanyName
  };
}

export function getPropertyData(data: HomeownerApplicationApiData, toggles: FeatureToggles): PropertyFormData {
  const propertyData: PropertyFormData = {
    Property_LastKnownAppraisedValue: data.Property_LastKnownAppraisedValue,
    Property_Intention: getPropertySubmitValue(data).intention,
    Property_Type: getPropertySubmitValue(data).type,
    Property_Occupied: getPropertySubmitValue(data).occupied,
    Property_WhoPaysTaxes: data.Property_WhoPaysTaxes?.Id || -1,
    Property_AreTaxesCurrent: data.Property_AreTaxesCurrent?.Id || -1,
    Property_HasCondoOrHoaFees: data.Property_HasCondoOrHoaFees?.Id || -1,
    Property_CondoOrHoaFeesAmount: data.Property_CondoOrHoaFeesAmount || -1,
    Property_CondoOrHoaFeesPaidTo: data.Property_CondoOrHoaFeesPaidTo,
    Property_WhoPaysHazardInsurance: data.Property_WhoPaysHazardInsurance?.Id || -1,
    Property_HazardInsuranceIsCurrent: data.Property_HazardInsuranceIsCurrent?.Id || -1,
    Property_HazardInsuranceCompanyName: data.Property_HazardInsuranceCompanyName,
    Property_HazardInsuranceCompanyPhone: data.Property_HazardInsuranceCompanyPhone,
    Property_HasJudgements: data.Property_HasJudgements,
    Property_JudgementsDescription: data.Property_JudgementsDescription || '',
    EligibleExpenses: []
  };
  
  if (toggles.IsEligibleExpensesEnabled) {
    const eligibleExpenses = data.EligibleExpenses;
    if (!!eligibleExpenses) {
      eligibleExpenses.forEach((expense) => {
        propertyData.EligibleExpenses.push({
          AccountNumber: expense.AccountNumber,
          Category: expense.Category?.Id || -1,
          ExpenseType: expense.ExpenseType?.Id || -1,
          Payment: expense.Payment,
          PaymentDueDate: expense.PaymentDueDate?.Id || -1,
          PaymentPastDue: expense.PaymentPastDue,
          Provider: expense.Provider?.Id || -1,
          IsOtherProvider: expense.IsOtherProvider,
          OtherProviderName: expense.OtherProviderName,
          ProviderPhoneNumber: expense.ProviderPhoneNumber,
          WhoPays: expense.WhoPays?.Id || -1
        });
      });
    }
  }

  return propertyData;
}

export function getMortgageData(data: HomeownerApplicationApiData): MortgageFormData {
  const mortgageData: MortgageFormData = {
    Mortgages: []
  };

  /**
   * Only include mortgages that have a Servicer
   */
  const mortgages = !!data.Mortgages ? data.Mortgages.filter(mortgage => !!mortgage.Servicer?.Id) : null;
  if (!!mortgages) {
    mortgages.forEach((mortgage) => {
      return mortgageData.Mortgages.push({
        IsOtherLender: mortgage.IsOtherLender,
        IsOtherServicer: mortgage.IsOtherServicer,
        OtherLenderName: mortgage.OtherLenderName,
        OtherServicerName: mortgage.OtherServicerName,
        Servicer: mortgage.Servicer?.Id || -1,
        Lender: mortgage.Lender?.Id || -1,
        ServicerPhoneNumber: mortgage.ServicerPhoneNumber,
        LoanNumber: mortgage.LoanNumber,
        Balance: mortgage.Balance,
        DelinquencyStatus: mortgage.DelinquencyStatus?.Id || -1,
        Payment: mortgage.Payment,
        PaymentDueDate: mortgage.PaymentDueDate?.Id || -1,
        InterestRate: mortgage.InterestRate,
        LoanType: mortgage.LoanType?.Id || -1,
        LoanProgram: mortgage.LoanProgram?.Id || -1,
        AdjustmentDueDate: mortgage.AdjustmentDueDate,
        ForeclosureReceived: mortgage.ForeclosureReceived?.Id || -1,
        ForeclosureReceivedDate: mortgage.ForeclosureReceivedDate || '',
        IsInForbearance: mortgage.IsInForbearance?.Id || -1,
        ForbearanceEndDate: mortgage.ForbearanceEndDate,
        ForbearanceDetails: mortgage.ForbearanceDetails,
        ForeclosureSaleScheduled: mortgage.ForeclosureSaleScheduled?.Id || -1,
        ForeclosureSaleDate: mortgage.ForeclosureSaleDate || '',
      });
    });
  }

  return mortgageData;
}

export function getFinancialData(data: HomeownerApplicationApiData): FinancialFormData {
  const financialData: FinancialFormData = {
    IncomeSources_BorrowerMonthly: [],
    IncomeSources_CoBorrowerMonthly: [],
    IncomeSources_SpouseMonthly: [],
    Expenses_Other: [],
    Bankruptcy_WasFiled: !!data.Bankruptcy_WasFiled,
    Bankruptcy_Type: data.Bankruptcy_Type?.Id || -1,
    Bankruptcy_IsDischarged: !!data.Bankruptcy_IsDischarged,
    Bankruptcy_CaseNumber: data.Bankruptcy_CaseNumber,
    Bankruptcy_FilingDate: data.Bankruptcy_FilingDate
  };

  const borrowerIncomes = data.IncomeSources_BorrowerMonthly;
  const coborrowerIncomes = data.IncomeSources_CoBorrowerMonthly;
  const spouseIncomes = data.IncomeSources_SpouseMonthly;
  if (!!borrowerIncomes) {
    borrowerIncomes.forEach(income => {
      financialData.IncomeSources_BorrowerMonthly.push({
        Source: {
          Id: income.Source?.Id || -1,
          Name: '',
          IsActive: true,
          SortOrder: -1
        },
        Gross: income.Gross
      });
    });
  }
  if (!!coborrowerIncomes && coborrowerIncomes.length === 0) {
    financialData.IncomeSources_CoBorrowerMonthly.push({
      Source: {
        Id: -1,
        Name: '',
        IsActive: true,
        SortOrder: -1
      },
      Gross: 0
    });
  } else if (!!coborrowerIncomes && coborrowerIncomes.length > 0) {
    coborrowerIncomes.forEach(income => {
      financialData.IncomeSources_CoBorrowerMonthly.push({
        Source: {
          Id: income.Source?.Id || -1,
          Name: '',
          IsActive: true,
          SortOrder: -1
        },
        Gross: income.Gross
      });
    });
  }
  if (!!spouseIncomes && spouseIncomes.length === 0) {
    financialData.IncomeSources_SpouseMonthly.push({
      Source: {
        Id: -1,
        Name: '',
        IsActive: true,
        SortOrder: -1
      },
      Gross: 0
    });
  } else if (!!spouseIncomes && spouseIncomes.length > 0) {
    spouseIncomes.forEach(income => {
      financialData.IncomeSources_SpouseMonthly.push({
        Source: {
          Id: income.Source?.Id || -1,
          Name: '',
          IsActive: true,
          SortOrder: -1
        },
        Gross: income.Gross
      });
    });
  }

  const otherExpenses = data.Expenses_Other;
  if (!!otherExpenses && otherExpenses.length > 0) {
    otherExpenses.forEach(expense => {
      financialData.Expenses_Other.push({
        Description: expense.Description,
        Amount: expense.Amount
      })
    });
  }

  return financialData;
}

export function getNotEligibleData(data: HomeownerApplicationApiData): NotEligibleFormData {
  return {
    IsPermissionGivenForContactByHUD: !!data.IsPermissionGivenForContactByHUD,
    IsRequestGivenForEmailOfResults: !!data.IsRequestGivenForEmailOfResults
  };
}

export function selectedRaces(raceArray: any[]): any[] {
  return raceArray
    .map((item: any, index: number) => {
      if (typeof(item) == 'number') {
        return item;
      } else {
        return null;
      }
    }).filter((i: number | null) => i !== null);
}

export function selectedRacesFormData(raceArray: any[], races: ListObject[]): any[] {
  return raceArray
    .map((item: any, index: number) => {
      if (item == true) {
        return races[index].Id;
      } else {
        return null;
      }
    }).filter((i: number | null) => i !== null);
}

export function getPropertySubmitValue(data: HomeownerApplicationApiData): any {
  let intention = data.Property_IsIntentionToKeep ? PropertyIntention.Keep : PropertyIntention.Sell;
  let type = data.Property_IsPrimaryResidence ? PropertyType.Primary : (
    data.Property_IsSecondaryResidence ? PropertyType.Secondary : PropertyType.Investment
  );
  let occupied = data.Property_IsOwnerOccupied ? PropertyOccupied.Owner : (
    data.Property_IsRenterOccupied ? PropertyOccupied.Renter : PropertyOccupied.Vacant
  );

  return {
    intention: intention,
    type: type,
    occupied: occupied
  };
}

export function getActiveProgramSummary(data: HomeownerApplicationApiData | undefined): ProgramSummary | null {
  const programSummary = !!data?.ProgramSummaries ? data.ProgramSummaries.find(summary => summary.ApplicationNumber === data.ApplicationStatus.ApplicationNumber) : null;
  return programSummary || null;
}

/**
 * Return true if a user's application is prequalified
 * Else false
 */
export function isPrequalified(data: HomeownerApplicationApiData): boolean {
  const activeProgramSummary = getActiveProgramSummary(data);
  const statusId = activeProgramSummary?.Status?.Id || -1;
  const stageId = activeProgramSummary?.Stage?.Id || -1;
  return statusId == 2 && stageId == 1;
}