<div class="form-header border-bottom mb-4" [ngClass]="{'no-divider': !showDivider}">
  <h1 class="pb-3">
    <ng-container [ngSwitch]="title">
      <svg *ngSwitchCase="'Supporting Files'" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#007BFF" class="bi bi-folder2-open mr-3 p-2" viewBox="0 0 16 16">
        <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"/>
      </svg>
      <svg *ngSwitchCase="'Application Summary'" xmlns="http://www.w3.org/2000/svg" width="50" height="50" class="bi bi-list-check mr-3 p-2" viewBox="0 0 16 16" fill="#007BFF">
        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
      </svg>
      <svg *ngSwitchCase="'Sign and Submit Application'" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#007BFF" class="bi bi-pencil-square mr-3 p-2" viewBox="0 0 16 16">
        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
      </svg>
      <svg *ngSwitchCase="'Firmar y enviar la solicitud'" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#007BFF" class="bi bi-pencil-square mr-3 p-2" viewBox="0 0 16 16">
        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
      </svg>
    </ng-container>
    {{ title }}
  </h1>
  <ng-content></ng-content>
  <div class="btn-form-group d-lg-none pb-3" role="group" aria-label="form-buttons" [ngClass]="{'btn-group-single': !canGoBack}" *transloco="let t;">
    <button *ngIf="canGoBack" type="button" class="btn btn-outline-primary" (click)="back.emit()">{{t('commonWords.back')}}</button>
    <button *ngIf="!!link && canSubmit" type="button" class="btn btn-primary" [routerLink]="link">{{submitBtnTitle ? submitBtnTitle : t('formHeader.saveNext')}}</button>
    <button *ngIf="!link && hasButtons && canSubmit" type="button" class="btn btn-primary" (click)="save.emit()" [disabled]="isDisabled">{{submitBtnTitle ? submitBtnTitle : t('formHeader.saveNext')}}</button>
  </div>
</div>