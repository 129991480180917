import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './app-image.component.html'
})
export class AppImageComponent implements OnInit {

  @Input() alt = '';
  @Input() canDisplay = true;
  @Input() class = '';
  @Input() src = '';
  @Input() style = '';
  @Input() title = '';

  constructor() { }
  ngOnInit(): void {}
}
