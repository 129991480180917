import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AlertType } from 'src/app/shared/interfaces';
import { MessagesActions } from '../actions';

@Injectable()
export class MessagesEffects {

   displayToast$ = createEffect(() => this.actions$.pipe(
    ofType(MessagesActions.displayToast),
    mergeMap(action => {
      let returnAction = null;

      if (action.alertType === AlertType.Success) {
        this.toastr.success(action.message);
        returnAction = MessagesActions.displayToastSuccess();
      } else {
        this.toastr.error(action.message);
        returnAction = MessagesActions.displayToastError();
      }

      return of(returnAction);
    }))
  );
 
  constructor(private actions$: Actions, private toastr: ToastrService) {}
}