import { Action, createReducer, on } from '@ngrx/store';
import { MenuActions } from '../actions';

export const menuFeatureKey = 'menu';

export interface AppMenuState {
  activeIndex: number,
  isOpen: boolean,
  pageType: string
}

export const initialState: AppMenuState = {
  activeIndex: 0,
  isOpen: false,
  pageType: 'dynamic'
};

const menuReducer = createReducer(
  initialState,
  on(MenuActions.closeMenu, (state) => {
    return ({ ...state, isOpen: false })
  }),
  on(MenuActions.toggleMenu, (state) => {
    return ({ ...state, isOpen: !state.isOpen })
  }),
  on(MenuActions.updateActiveIndex, (state, { activeIndex }) => {
    return ({ ...state, activeIndex: activeIndex })
  }),
  on(MenuActions.updatePageType, (state, { pageType }) => {
    return ({ ...state, pageType: pageType })
  })
);

export function reducer(state: AppMenuState | undefined, action: Action) {
  return menuReducer(state, action);
}