import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { translate, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { AnalyticsActions, ApiActions } from 'src/app/store/actions';
import { selectAppData, selectEmploymentFormStatus, selectFinancialFormStatus, selectHelpLinksList, selectIsActiveApplication, selectIsAppSubmitted, selectMenuActiveIndex, selectMenuIsOpen, selectMortgageFormStatus, selectPageType, selectPersonalFormStatus, selectPropertyFormStatus, selectUserIsLoggedIn } from 'src/app/store/selectors';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { AnalyticsPageIndex, DialogData } from '../../interfaces';
import { IAuthService, NavService } from '../../services';
import { CognitoAuthService } from '../../services/cognito-auth-service.service';
import * as util from '../../services/utilities';
import { environment as env } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  @Output() menuToggle = new EventEmitter<boolean>();

  destroy = new Subject();
  isPageStatic = false;
  util = util;

  activeIndex$ = this.store.select(selectMenuActiveIndex);
  applicationIsActive$ = this.store.select(selectIsActiveApplication);
  employmentStatus$ = this.store.select(selectEmploymentFormStatus);
  financialStatus$ = this.store.select(selectFinancialFormStatus);
  isMenuOpen$ = this.store.select(selectMenuIsOpen);
  isSubmitted$ = this.store.select(selectIsAppSubmitted);
  mortgageStatus$ = this.store.select(selectMortgageFormStatus);
  pageType$ = this.store.select(selectPageType);
  personalStatus$ = this.store.select(selectPersonalFormStatus);
  propertyStatus$ = this.store.select(selectPropertyFormStatus);

  helpLinks = this.store.select(selectHelpLinksList).pipe(filter(list => !!list));
  prequalStep = '';

  selectedOption = 1;
  languages : any = [];

  constructor(
    public navService: NavService, 
    @Inject(CognitoAuthService) private authService: IAuthService,
    private modalService: NgbModal,
    private store: Store,
    public translateService: TranslocoService,
  ) {
    this.store.dispatch(ApiActions.loadHelpLinks());
    this.store.select(selectAppData).pipe(filter(data => !data.isUpdatingPrequalStep), takeUntil(this.destroy)).subscribe(data => this.prequalStep = data.PrequalStep);
    this.pageType$.pipe(takeUntil(this.destroy)).subscribe(data => {
      this.isPageStatic = (data == 'static') ? true : false;
    });

    this.languages = env.supportedLanguages;
   }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  clickProfile(): void {
    this.trackEvent('profile');
    this.navService.goToProfile();
  }

  get currentRoute(): string {
    return this.navService.getCurrentRoute();
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.store.select(selectUserIsLoggedIn).pipe(first());
  }

  goHome(): void {
    this.isLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.navService.goHome();
      } else {
        this.navService.goToLogin();
      }
    });
  }

  logout() {
    const dialogData: DialogData = {
      title: translate('sitemap.logout'),
      bodyText: [translate('confirmDialog.areYouSure')],
      showCancel: true,
      action: {
        text: translate('sitemap.yesLogout'),
        click: 'logout'
      }
    };
    const modal = this.modalService.open(ConfirmDialogComponent, {
      centered: true
    });
    modal.componentInstance.data = dialogData;
    modal.result.then(result => {
      if (!!result) {
        this.authService.logout()
          .pipe(map(_ => this.navService.goToLogin()))
          .subscribe();
      }
    }, _ => {});
  }

  toggleMenu() {
    this.menuToggle.emit();
  }

  trackEvent(action: string): void {
    this.activeIndex$.pipe(
      first(),
      map(index => {
        const pageName = AnalyticsPageIndex[index];
        this.store.dispatch(AnalyticsActions.logCustomEvent({
          event: {
            action: action,
            category: Constants.NAVIGATION_CATEGORY,
            label: pageName
          }
        }));
      })
    ).subscribe();
  }

  skipTo(location: string): void {
    document.getElementById(location)?.focus();
  }

  get areLanguageChangesEnabled(): boolean {
    return env.allowLanguageChanges;
  }

  changeLanguage(event: any): Observable<boolean> {
    var lang = event.target.value;
    this.translateService.setActiveLang(lang);
    return of(true);
  }
}
