<div class="app-container" [ngClass]="{ 'blur': (modalOpen$ | async) }" >
  <header class="app-header" id="header-content" tabindex="-1">
    <app-header (menuToggle)="toggleMenu()"></app-header>
  </header>
  <ng-container *ngIf="pageType$ | async as pageType"> 
    <div 
      class="responsive" 
      [ngClass]="{'logged-out': !(isLoggedIn$ | async), 'static-grid': pageType==='static' || !(activeApplication$ | async), 'body': pageType==='dynamic' && (activeApplication$ | async)}"
    >
      <ng-container *ngIf="appLoadingMessage$ | async as message">
        <app-loader [message]="message"></app-loader>
      </ng-container>
      <aside [ngClass]="{'d-none': (pageType==='static' || !(isLoggedIn$ | async) || !(activeApplication$ | async))}" class="bypass-block-target" id="sidebar" tabindex="-1">
        <nav class="d-none d-lg-block bg-light sidebar" *ngIf="isLoggedIn$ | async">
          <app-menu class="d-none d-lg-block h-100" [isSubmitted]="isSubmitted$ | async"></app-menu>
        </nav>
      </aside>
      <main class="content bypass-block-target" role="main" tabindex="-1" id="main-content">
        <router-outlet></router-outlet>
          <!-- Scroll to top functionality currently disabled as it is not expected to be included in the first release
              Changes required prior to implementation: decide whether or not scroll button is shown in front of or behind navigation buttons (back, continue, etc)
          <app-scroll-top></app-scroll-top> -->
      </main>
    </div>
  </ng-container>
  <div class="footer">
    <app-footer class="mt-auto"></app-footer>
  </div>
</div>