import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { selectIsActiveApplication } from 'src/app/store/selectors';

@Component({
  selector: 'form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormHeaderComponent implements OnInit {

  userApplicationIsActive = true;

  @Input() canGoBack = true;
  @Input() canSubmit = true;
  @Input() hasButtons = true;
  @Input() isProgramSelection = false;
  @Input() isProfile = false;
  @Input() isValidForm: boolean | null = true;
  @Input() link = '';
  @Input() showDivider = true;
  @Input() submitBtnTitle?: string;
  @Input() title = '';

  @Output() back = new EventEmitter<string>();
  @Output() save = new EventEmitter<string>();

  constructor(private store: Store) { 
    this.store.select(selectIsActiveApplication).pipe(first()).subscribe(event => this.userApplicationIsActive = event);
  }

  ngOnInit(): void {}

  get isDisabled(): boolean {
    return (this.isProgramSelection || this.isProfile) ? 
      !this.isValidForm : 
      (!this.isValidForm || !this.userApplicationIsActive);
  }
}
