import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.scss']
})
export class FormMessageComponent implements OnInit {

  @Input() cssClass = '';
  @Input() field!: AbstractControl | null;
  @Input() matchText? = '';
  @Input() isPhone? = false;
  @Input() isNonNegative? = false;

  constructor() { }

  ngOnInit(): void {
  }

  get emailErrors(): boolean {
    return !!this.field?.errors?.email;
  }

  get maxLengthErrors(): boolean {
    return !!this.field?.errors?.maxlength;
  }

  get minLengthErrors(): boolean {
    return !!this.field?.errors?.minlength;
  }
  
  get patternErrors(): boolean {
    return !!this.field?.errors?.pattern;
  }
  
  get requiredErrors(): boolean {
    return !!this.field?.errors?.required;
  }
}
