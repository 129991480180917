import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-google-translate',
  templateUrl: './google-translate.component.html'
})
export class GoogleTranslateComponent implements OnInit {

  constructor(public elementRef: ElementRef) { }

  ngOnInit(): void {
    this.setUpGoogleTranslate();
  }

  setUpGoogleTranslate(): void {
    var scriptOne = document.createElement("script");
    scriptOne.type = "text/javascript";
    scriptOne.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google-translate-element'); } ";
    this.elementRef.nativeElement.appendChild(scriptOne);
    var scriptTwo = document.createElement("script");
    scriptTwo.type = "text/javascript";
    scriptTwo.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    this.elementRef.nativeElement.appendChild(scriptTwo);
  }

}
