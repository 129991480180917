import { Action, createReducer, on } from '@ngrx/store';
import { Program } from 'src/app/core/interfaces/program.interface';
import { HelpLink, ListObject } from 'src/app/shared/interfaces';
import { ApiActions } from '../actions';

export const apiListFeatureKey = 'apiLists';

export interface ListApiState {
  bankruptcyTypes: ListObject[] | null,
  bestTimes: ListObject[] | null,
  counties: ListObject[] | null,
  homeownerCounties: ListObject[] | null,
  coborrowerCounties: ListObject[] | null,
  homeownerEmploymentCounties: ListObject[] | null,
  coborrowerEmploymentCounties: ListObject[] | null,
  spouseCounties: ListObject[] | null,
  discoveryTypes: ListObject[] | null,
  eligibleExpenseCategories: ListObject[] | null,
  eligibleExpenseProviders: {
    providerList: ListObject[] | null,
    categoryId: number
  },
  eligibleExpenseTypes: {
    typesList: ListObject[] | null,
    categoryId: number
  },
  ethnicities: ListObject[] | null,
  expenseCategories: ListObject[] | null,
  featureToggles: ListObject[] | null,
  fileCategories: ListObject[] | null,
  genders: ListObject[] | null,
  hardshipReasons: ListObject[] | null,
  howLongWithEmployers: ListObject[] | null,
  incomeSources: ListObject[] | null,
  languages: ListObject[] | null,
  loanTypes: ListObject[] | null,
  maritalStatuses: ListObject[] | null,
  mortgageLenders: ListObject[] | null,
  mortgageServicers: ListObject[] | null,
  delinquencyStatuses: ListObject[] | null,
  loanPrograms: ListObject[] | null,
  paymentDueDates: ListObject[] | null,
  phoneTypes: ListObject[] | null,
  programCounties: ListObject[] | null,
  programs: Program[] | null,
  races: ListObject[] | null,
  sociallyDisadvantaged: ListObject[] | null,
  states: ListObject[] | null,
  whoPays: ListObject[] | null,
  yesNo: ListObject[] | null,
  yesNoPending: ListObject[] | null,
  bankruptcyTypesError: any,
  bestTimesError: any,
  countiesError: any,
  discoveryTypesError: any,
  eligibleExpenseCategoriesError: any,
  eligibleExpenseProvidersError: any,
  eligibleExpenseTypesError: any,
  ethnicitiesError: any,
  expenseCategoriesError: any,
  featureTogglesError: any,
  fileCategoriesError: any,
  gendersError: any,
  hardshipReasonsError: any,
  howLongWithEmployersError: any,
  incomeSourcesError: any,
  languagesError: any,
  loanTypesError: any,
  maritalStatusesError: any,
  mortgageLendersError: any,
  mortgageServicersError: any,
  delinquencyStatusesError: any,
  loanProgramsError: any,
  paymentDueDatesError: any,
  phoneTypesError: any,
  programCountiesError: any,
  programsError: any,
  racesError: any,
  sociallyDisadvantagedError: any,
  statesError: any,
  whoPaysError: any,
  yesNoError: any,
  yesNoPendingError: any,
  countiesNeedsUpdating: boolean,
  statesNeedsUpdating: boolean,
  helpLinks: HelpLink[] | null,
  helpLinksError: any,
  programStages: ListObject[] | null
  programStagesError: any,
}

export const initialState: ListApiState = {
  bankruptcyTypes: null,
  bestTimes: null,
  counties: null,
  homeownerCounties: null,
  coborrowerCounties: null,
  spouseCounties: null,
  homeownerEmploymentCounties: null,
  coborrowerEmploymentCounties: null,
  discoveryTypes: null,
  eligibleExpenseCategories: null,
  eligibleExpenseProviders: {
    providerList: null,
    categoryId: -1
  },
  eligibleExpenseTypes: {
    typesList: null,
    categoryId: -1
  },
  ethnicities: null,
  expenseCategories: null,
  featureToggles: null,
  fileCategories: null,
  genders: null,
  hardshipReasons: null,
  howLongWithEmployers: null,
  incomeSources: null,
  languages: null,
  loanTypes: null,
  maritalStatuses: null,
  mortgageLenders: null,
  mortgageServicers: null,
  delinquencyStatuses: null,
  loanPrograms: null,
  paymentDueDates: null,
  phoneTypes: null,
  programCounties: null,
  programs: null,
  races: null,
  sociallyDisadvantaged: null,
  states: null,
  whoPays: null,
  yesNo: null,
  yesNoPending: null,
  bankruptcyTypesError: null,
  bestTimesError: null,
  countiesError: null,
  discoveryTypesError: null,
  eligibleExpenseCategoriesError: null,
  eligibleExpenseProvidersError: null,
  eligibleExpenseTypesError: null,
  ethnicitiesError: null,
  expenseCategoriesError: null,
  featureTogglesError: null,
  fileCategoriesError: null,
  gendersError: null,
  hardshipReasonsError: null,
  howLongWithEmployersError: null,
  incomeSourcesError: null,
  languagesError: null,
  loanTypesError: null,
  maritalStatusesError: null,
  mortgageLendersError: null,
  mortgageServicersError: null,
  delinquencyStatusesError: null,
  loanProgramsError: null,
  paymentDueDatesError: null,
  phoneTypesError: null,
  programCountiesError: null,
  programsError: null,
  racesError: null,
  sociallyDisadvantagedError: null,
  statesError: null,
  whoPaysError: null,
  yesNoError: null,
  yesNoPendingError: null,
  countiesNeedsUpdating: false,
  statesNeedsUpdating: false,
  helpLinks: null,
  helpLinksError: null,
  programStages: null,
  programStagesError: undefined
};

const apiListReducer = createReducer(
  initialState,
  on(ApiActions.loadBankruptcyTypesSuccess, (state, {list}) => {
    return ({ ...state, bankruptcyTypes: sortOrder(list), bankruptcyTypesError: null });
  }),
  on(ApiActions.loadBestTimesToCallSuccess, (state, {list}) => {
    return ({ ...state, bestTimes: sortOrder(list), bestTimesError: null });
  }),
  on(ApiActions.loadCountiesSuccess, (state, {list}) => {
    return ({ ...state, counties: sortOrder(list), countiesError: null, countiesNeedsUpdating: false });
  }),
  on(ApiActions.loadHoCountiesSuccess, (state, {list}) => {
    return ({ ...state, homeownerCounties: sortOrder(list), countiesError: null, countiesNeedsUpdating: false });
  }),
  on(ApiActions.loadCbCountiesSuccess, (state, {list}) => {
    return ({ ...state, coborrowerCounties: sortOrder(list), countiesError: null, countiesNeedsUpdating: false });
  }),
  on(ApiActions.loadSpCountiesSuccess, (state, {list}) => {
    return ({ ...state, spouseCounties: sortOrder(list), countiesError: null, countiesNeedsUpdating: false });
  }),
  on(ApiActions.loadHoEmploymentCountiesSuccess, (state, {list}) => {
    return ({ ...state, homeownerEmploymentCounties: sortOrder(list), countiesError: null, countiesNeedsUpdating: false });
  }),
  on(ApiActions.loadCbEmploymentCountiesSuccess, (state, {list}) => {
    return ({ ...state, coborrowerEmploymentCounties: sortOrder(list), countiesError: null, countiesNeedsUpdating: false });
  }),
  on(ApiActions.loadDiscoveryTypesSuccess, (state, {list}) => {
    return ({ ...state, discoveryTypes: sortOrder(list), discoveryTypesError: null });
  }),
  on(ApiActions.loadEligibleExpenseCategoriesSuccess, (state, {list}) => {
    return ({ ...state, eligibleExpenseCategories: sortOrder(list), eligibleExpenseCategoriesError: null });
  }),
  on(ApiActions.loadEligibleExpenseProvidersSuccess, (state, {list, category}) => {
    return ({ ...state, eligibleExpenseProviders: {providerList: sortOrder(list), categoryId: category}, eligibleExpenseProvidersError: null });
  }),
  on(ApiActions.loadEligibleExpenseTypesSuccess, (state, {list, category}) => {
    return ({ ...state, eligibleExpenseTypes: {typesList: sortOrder(list), categoryId: category}, eligibleExpenseTypesError: null });
  }),
  on(ApiActions.loadEthnicitiesSuccess, (state, {list}) => {
    return ({ ...state, ethnicities: sortOrder(list), ethnicitiesError: null });
  }),
  on(ApiActions.loadExpenseCategoriesSuccess, (state, {list}) => {
    return ({ ...state, expenseCategories: sortOrder(list), expenseCategoriesError: null });
  }),
  on(ApiActions.loadFeatureTogglesSuccess, (state, {list}) => {
    return ({ ...state, featureToggles: list, featureTogglesError: null });
  }),
  on(ApiActions.loadHardshipReasonsSuccess, (state, {list}) => {
    return ({ ...state, hardshipReasons: sortOrder(list), hardshipReasonsError: null });
  }),
  on(ApiActions.loadFileCategoriesSuccess, (state, {list}) => {
    return ({ ...state, fileCategories: sortOrder(list), fileCategoriesError: null });
  }),
  on(ApiActions.loadGendersSuccess, (state, {list}) => {
    return ({ ...state, genders: sortOrder(list), gendersError: null });
  }),
  on(ApiActions.loadHowLongWithEmployersSuccess, (state, {list}) => {
    return ({ ...state, howLongWithEmployers: sortOrder(list), howLongWithEmployersError: null });
  }),
  on(ApiActions.loadIncomeSourcesSuccess, (state, {list}) => {
    return ({ ...state, incomeSources: sortOrder(list), incomeSourcesError: null });
  }),
  on(ApiActions.loadLanguagesSuccess, (state, {list}) => {
    return ({ ...state, languages: sortOrder(list), languagesError: null });
  }),
  on(ApiActions.loadLoanTypesSuccess, (state, {list}) => {
    return ({ ...state, loanTypes: sortOrder(list), loanTypesError: null });
  }),
  on(ApiActions.loadMaritalStatusesSuccess, (state, {list}) => {
    return ({ ...state, maritalStatuses: sortOrder(list), maritalStatusesError: null });
  }),
  on(ApiActions.loadMortgageDelinquencySuccess, (state, {list}) => {
    return ({ ...state, delinquencyStatuses: sortOrder(list), delinquencyStatusesError: null });
  }),
  on(ApiActions.loadMortgageLendersSuccess, (state, {list}) => {
    return ({ ...state, mortgageLenders: sortOrder(list), mortgageLendersError: null });
  }),
  on(ApiActions.loadMortgageLoanProgramsSuccess, (state, {list}) => {
    return ({ ...state, loanPrograms: sortOrder(list), loanProgramsError: null });
  }),
  on(ApiActions.loadMortgagePaymentDueDatesSuccess, (state, {list}) => {
    return ({ ...state, paymentDueDates: sortOrder(list), paymentDueDatesError: null });
  }),
  on(ApiActions.loadMortgageServicersSuccess, (state, {list}) => {
    return ({ ...state, mortgageServicers: sortOrder(list), mortgageServicersError: null });
  }),
  on(ApiActions.loadPhoneTypesSuccess, (state, {list}) => {
    return ({ ...state, phoneTypes: sortOrder(list), phoneTypesError: null });
  }),
  on(ApiActions.loadProgramCountiesSuccess, (state, {list}) => {
    return ({ ...state, programCounties: sortOrder(list), programCountiesError: null });
  }),
  on(ApiActions.loadProgramsSuccess, (state, {list}) => {
    return ({ ...state, programs: list, programsError: null });
  }),
  on(ApiActions.loadRacesSuccess, (state, {list}) => {
    return ({ ...state, races: sortOrder(list), racesError: null });
  }),
  on(ApiActions.loadSociallyDisadvantagedSuccess, (state, {list}) => {
    return ({ ...state, sociallyDisadvantaged: sortOrder(list), sociallyDisadvantagedError: null });
  }),
  on(ApiActions.loadStatesSuccess, (state, {list}) => {
    return ({ ...state, states: sortOrder(list), statesError: null, statesNeedsUpdating: false });
  }),
  on(ApiActions.loadWhoPaysSuccess, (state, {list}) => {
    return ({ ...state, whoPays: sortOrder(list), whoPaysError: null });
  }),
  on(ApiActions.loadYesNoSuccess, (state, {list}) => {
    return ({ ...state, yesNo: sortOrder(list), yesNoError: null });
  }),
  on(ApiActions.loadYesNoPendingSuccess, (state, {list}) => {
    return ({ ...state, yesNoPending: sortOrder(list), yesNoPendingError: null });
  }),
  on(ApiActions.loadHelpLinksSuccess, (state, {list}) => {
    return ({ ...state, helpLinks: list, helpLinkError: null });
  }),
  on(ApiActions.loadProgramStagesSuccess, (state, {list}) => {
    return ({ ...state, programStages: list, programStagesError: null });
  }),
  on(ApiActions.loadProgramStagesError, (state, {error}) => {
    return ({ ...state, programStages: null, programStagesError: error });
  }),
  on(ApiActions.loadBankruptcyTypesError, (state, {error}) => {
    return ({ ...state, bankruptcyTypes: null, bankruptcyTypesError: error });
  }),
  on(ApiActions.loadBestTimesToCallError, (state, {error}) => {
    return ({ ...state, bestTimes: null, bestTimesError: error });
  }),
  on(ApiActions.loadCountiesError, (state, {error}) => {
    return ({ ...state, counties: null, countiesError: error, countiesNeedsUpdating: false });
  }),
  on(ApiActions.loadDiscoveryTypesError, (state, {error}) => {
    return ({ ...state, discoveryTypes: null, discoveryTypesError: error });
  }),
  on(ApiActions.loadEligibleExpenseCategoriesError, (state, {error}) => {
    return ({ ...state, eligibleExpenseCategories: null, eligibleExpenseCategoriesError: error });
  }),
  on(ApiActions.loadEligibleExpenseProvidersError, (state, {error}) => {
    return ({ ...state, eligibleExpenseProviders: {providerList: null, categoryId: -1}, eligibleExpenseProvidersError: error });
  }),
  on(ApiActions.loadEligibleExpenseTypesError, (state, {error}) => {
    return ({ ...state, eligibleExpenseTypes: {typesList: null, categoryId: -1}, eligibleExpenseTypesError: error });
  }),
  on(ApiActions.loadEthnicitiesError, (state, {error}) => {
    return ({ ...state, ethnicities: null, ethnicitiesError: error });
  }),
  on(ApiActions.loadExpenseCategoriesError, (state, {error}) => {
    return ({ ...state, expenseCategories: null, expenseCategoriesError: error });
  }),
  on(ApiActions.loadFeatureTogglesError, (state, {error}) => {
    return ({ ...state, featureToggles: null, featureTogglesError: error });
  }),
  on(ApiActions.loadFileCategoriesError, (state, {error}) => {
    return ({ ...state, fileCategories: null, fileCategoriesError: error });
  }),
  on(ApiActions.loadGendersError, (state, {error}) => {
    return ({ ...state, genders: null, gendersError: error });
  }),
  on(ApiActions.loadHardshipReasonsError, (state, {error}) => {
    return ({ ...state, hardshipReasons: null, hardshipReasonsError: error });
  }),
  on(ApiActions.loadHowLongWithEmployersError, (state, {error}) => {
    return ({ ...state, howLongWithEmployers: null, howLongWithEmployersError: error });
  }),
  on(ApiActions.loadIncomeSourcesError, (state, {error}) => {
    return ({ ...state, incomeSources: null, incomeSourcesError: error });
  }),
  on(ApiActions.loadLanguagesError, (state, {error}) => {
    return ({ ...state, languages: null, languagesError: error });
  }),
  on(ApiActions.loadLoanTypesError, (state, {error}) => {
    return ({ ...state, loanTypes: null, loanTypesError: error });
  }),
  on(ApiActions.loadMaritalStatusesError, (state, {error}) => {
    return ({ ...state, maritalStatuses: null, maritalStatusesError: error });
  }),
  on(ApiActions.loadMortgageDelinquencyError, (state, {error}) => {
    return ({ ...state, delinquencyStatuses: null, delinquencyStatusesError: error});
  }),
  on(ApiActions.loadMortgageLendersError, (state, {error}) => {
    return ({ ...state, mortgageLenders: null, mortgageLendersError: error });
  }),
  on(ApiActions.loadMortgageLoanProgramsError, (state, {error}) => {
    return ({ ...state, loanPrograms: null, loanProgramsError: error });
  }),
  on(ApiActions.loadMortgagePaymentDueDatesError, (state, {error}) => {
    return ({ ...state, paymentDueDates: null, paymentDueDatesError: error });
  }),
  on(ApiActions.loadMortgageServicersError, (state, {error}) => {
    return ({ ...state, mortgageServicers: null, mortgageServicersError: error });
  }),
  on(ApiActions.loadPhoneTypesError, (state, {error}) => {
    return ({ ...state, phoneTypes: null, phoneTypesError: error });
  }),
  on(ApiActions.loadProgramCountiesError, (state, {error}) => {
    return ({ ...state, programCounties: null, programCountiesError: error });
  }),
  on(ApiActions.loadProgramsError, (state, {error}) => {
    return ({ ...state, programs: null, programsError: error });
  }),
  on(ApiActions.loadRacesError, (state, {error}) => {
    return ({ ...state, races: null, racesError: error });
  }),
  on(ApiActions.loadSociallyDisadvantagedError, (state, {error}) => {
    return ({ ...state, sociallyDisadvantaged: null, sociallyDisadvantagedError: error });
  }),
  on(ApiActions.loadStatesError, (state, {error}) => {
    return ({ ...state, states: null, statesError: error, statesNeedsUpdating: false });
  }),
  on(ApiActions.loadWhoPaysError, (state, {error}) => {
    return ({ ...state, whoPays: null, whoPaysError: error });
  }),
  on(ApiActions.loadYesNoError, (state, {error}) => {
    return ({ ...state, yesNo: null, yesNoError: error });
  }),
  on(ApiActions.loadYesNoPendingError, (state, {error}) => {
    return ({ ...state, yesNoPending: null, yesNoPendingError: error });
  }),
  on(ApiActions.loadHelpLinksError, (state, {error}) => {
    return ({ ...state, yesNoPending: null, helpLinkError: error });
  })
);

export function sortOrder(list: ListObject[] | null): ListObject[] | null {
  if (!!list) {
    const sortedList = [...list].sort((a, b) => {
      return a.SortOrder - b.SortOrder;
    });
    return !!sortedList ? sortedList : [];
  } else {
    return list;
  }
}

export function reducer(state: ListApiState | undefined, action: Action) {
  return apiListReducer(state, action);
}