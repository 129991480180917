import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Constants } from 'src/app/core/constants';
import { ListObject, PropertyApplicationSummaryValues, PropertyIntention, PropertyOccupied, PropertyType, YesNo } from '../../shared/interfaces';
import { FormStatus } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FormConditionsService {

  constructor() { }

  /**
   * Generic conditions
   */
  isNo(val?: string): boolean {
    return val === YesNo.No;
  }

  isValid(val?: string): boolean {
    return val === FormStatus.Valid;
  }

  /**
   * If the application program requires eligible expenses, and the user has not entered any, return
   * false so that the application summary expands the property form accordion and displays the error
   * message to the user
   */
  isPropertyFormValid(status: string, requiresMissingEligibleExpenses: boolean | null): boolean {
    return !!requiresMissingEligibleExpenses ? false : status === FormStatus.Valid;
  }

  isYes(val?: string): boolean {
    return val === YesNo.Yes;
  }

  /**
   * Personal Info conditions
   */
  hasSpouse(val?: number): boolean {
    const defaultNum = -1;
    const spouseOptions = [1,5,6];
    return spouseOptions.includes(val || defaultNum);
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 B';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Display values based on type
   */
  formatCurrency(val: number, round: boolean = false): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: round ? 0 : undefined
    });
    return formatter.format(val);
  }

  formatDate(val: string, format: string = Constants.DATE_FORMAT_MONTH_DAY_YEAR): string {
    const datePipe: DatePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(val, format);
    return formattedDate || '';
  }

  formatDateWithTimezone(val: string, format: string, timezone: string): string {
    const datePipe: DatePipe = new DatePipe('en-US');
    const utcVal = val[val.length-1] === 'Z' ? val : val+'Z';
    const formattedDate = datePipe.transform(utcVal, format, timezone);
    return formattedDate || '';
  }

  formatPhone(val: string): string {
    var cleaned = ('' + val).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return '';
  }

  formatSsn(val: string): string {
    let displayVal = '';
    if (!!val) {
      val = val.replace(new RegExp('-', 'g'), '');
      displayVal = `***-**-${val.substr(val.length-4, val.length)}`;
    }
    return displayVal;
  }

  getPropertyApplicationSummaryValue(key: string): string {
    let value = '';
    switch (key) {
      case PropertyIntention.Keep:
        value = translate('property.keep');
        return value;
      case PropertyIntention.Sell:
        value = translate('property.sell');
        return value;
      case PropertyType.Primary:
        value = translate('property.primary');
        return value;
      case PropertyType.Secondary:
        value = translate('property.second');
        return value;
      case PropertyType.Investment:
        value = translate('property.investment');
        return value;
      case PropertyOccupied.Owner:
        value = translate('property.ownerOcc');
        return value;
      case PropertyOccupied.Renter:
        value = translate('property.renterOcc');
        return value;
      case PropertyOccupied.Vacant:
        value = translate('property.vacant');
        return value;
      default:
        return value;
    }
  }

  isHardshipReasonOther(reason: number): boolean {
    return reason === 6;
  }

  isOtherText(event: any, index = 0): boolean {
    const selectedText: string = event.target.options[index]?.text || '';
    return selectedText.toLowerCase() === 'other';
  }

  getNameFromList(list: ListObject[], id: number): string {
    const match = list.find((item: ListObject) => item.Id === id);
    return !!match ? match.Name : '';
  }
}
