import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormStatus, FormStatusInfo } from '../../../homeowner-application/interfaces/homeowner-application-form.interface';


@Component({
  selector: 'app-menu-status-icon',
  templateUrl: './app-menu-status-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMenuStatusIconComponent implements OnInit {

  @Input() status$!: Observable<FormStatusInfo>;
  
  FormStatus = FormStatus;

  constructor() { }

  ngOnInit(): void {}

}
