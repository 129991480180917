import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeownerApplicationApiData, ProgramSummary } from 'src/app/homeowner-application/interfaces';
import { fromMultiApplication } from '../reducers';
import { ApplicationDashboardInfo, MultiAppState } from '../reducers/multi-application.reducers';
import { selectAppData } from './app-info.selectors';

export const getMultiApplicationFeatureState = createFeatureSelector<MultiAppState>(fromMultiApplication.multiAppFeatureKey);

function getHighestStageId(application: ApplicationDashboardInfo): number {
  let highestStageId = -1;
  let isIneligible = false;
  if (!!application?.ApplicationStatus?.Status && application?.ApplicationStatus?.Status?.toUpperCase() == 'INACTIVE') {
    isIneligible = true;
    return 100;
  }
  application.ProgramSummaries?.forEach(program => {
    if (!!program?.Stage?.Id) {
      highestStageId = program?.Stage?.Id > highestStageId ? program?.Stage?.Id : highestStageId;
      if (!!program?.Status?.Name && program?.Status?.Name?.toUpperCase() == 'NOT ELIGIBLE') {
        isIneligible = true;
      }
    }
  });
  return isIneligible ? 100  : highestStageId;
}

function getActiveApplications(data: ApplicationDashboardInfo[] | undefined): ApplicationDashboardInfo[] {
  return !!data ? data.filter(app => app.ApplicationStatus?.Status === 'Active') : [];
}

function hasEligibleProgram(programSummaries: ProgramSummary[] | null): boolean {
  const eligibleProgram = !!programSummaries && programSummaries.find(s => {
    const statusId = s?.Status?.Id || -1;
    const stageId = s?.Stage?.Id || -1;
    const programSummaryId = s?.Id;
    const needsPrequal = statusId === 1 && stageId === 1;
    const notEligible = statusId === 3 && stageId === 1;
    const needsProgram = !programSummaryId || programSummaryId < 0;
    return !needsPrequal && !notEligible && !needsProgram;
  })

  return !!eligibleProgram;
}

/**
 * @param data
 * @returns list of Active Applications with at least 1 eligible program, not including currently selected application
 */
function getActiveApplicationsWithEligibleProgram(data: ApplicationDashboardInfo[] | undefined, selectedId: number | undefined): ApplicationDashboardInfo[] {
  const activeApps = !!data ? data.filter(app => app.ApplicationStatus?.Status === 'Active') : [];
  const appsWithEligibleProgram = activeApps.filter(a => hasEligibleProgram(a.ProgramSummaries));
  return appsWithEligibleProgram.filter(a => a.ApplicationId !== selectedId);
}

function programIsNotEligible(program: ProgramSummary): boolean {
  return !!program.Stage?.Id && program.Stage.Id == 1 && !!program.Status.Id && program.Status.Id == 3;
}

function programIsNotYetInMonitoring(program: ProgramSummary): boolean {
  return !!program.Stage?.Id && program.Stage.Id < 7;
}

/**
 * Return the list of ineligible programs associated with user's applications
 * @param data 
 * @param selectedApplication 
 */
function getIneligibleProgramIdsFromApplications(applications: ApplicationDashboardInfo[], selectedApplication: HomeownerApplicationApiData | undefined): number[] {
  let programIds: number[] = [];
  
  if (!!applications && applications.length > 0) {
    applications.forEach(app => {
      if (!!app.ProgramSummaries) {
        app.ProgramSummaries.forEach(summary => {
          if (programIsNotEligible(summary) || programIsNotYetInMonitoring(summary)) {
            programIds.push(summary.Id || 0);
          }
        });
      }
    });
  } else if (!!selectedApplication && !!selectedApplication.ProgramSummaries) {
    selectedApplication.ProgramSummaries.forEach(summary => !!summary.Id ? programIds.push(summary.Id) : null);
  }

  return programIds;
}

function sortByStageId(data: ApplicationDashboardInfo[] | undefined): ApplicationDashboardInfo[] | undefined {
  return !!data ? data.sort((a: ApplicationDashboardInfo, b: ApplicationDashboardInfo) => getHighestStageId(a) - getHighestStageId(b)) : undefined;
}

/**
 * Sort applications by the highest stage ID of its programs
 */
export const selectApplicationsDashboardSortedApps = createSelector(
  getMultiApplicationFeatureState,
  (state: MultiAppState) => !!state.ApplicationsDashboard?.Applications ? sortByStageId([ ...state.ApplicationsDashboard?.Applications ]) : undefined
);

export const selectApplicationsDashboard = createSelector(
  getMultiApplicationFeatureState,
  selectApplicationsDashboardSortedApps,
  (state: MultiAppState, sortedApps: ApplicationDashboardInfo[] | undefined) => {
    return !!sortedApps ? { ...state.ApplicationsDashboard, Applications: sortedApps } : state.ApplicationsDashboard;
  }
);

export const selectSelectedApplication = createSelector(
  getMultiApplicationFeatureState,
  (state: MultiAppState) => state.SelectedApplication
);

export const selectSelectedApplicationId = createSelector(
  getMultiApplicationFeatureState,
  selectAppData,
  (state: MultiAppState, data: HomeownerApplicationApiData) => !!state.SelectedApplicationId ? state.SelectedApplicationId : (data.ApplicationId > 0 ? data.ApplicationId : undefined)
);

export const selectIsUpdatingMulti = createSelector(
  getMultiApplicationFeatureState,
  (state: MultiAppState) => state.IsUpdating
);

export const selectActiveApplications = createSelector(
  getMultiApplicationFeatureState,
  (state: MultiAppState) => getActiveApplications(state.ApplicationsDashboard?.Applications)
);

export const selectActiveApplicationsWithEligibleProgram = createSelector(
  getMultiApplicationFeatureState,
  selectSelectedApplicationId,
  (state: MultiAppState, selectedId: number | undefined) => getActiveApplicationsWithEligibleProgram(state.ApplicationsDashboard?.Applications, selectedId)
);

export const selectIneligibleProgramIdsFromActiveApplications = createSelector(
  getMultiApplicationFeatureState,
  selectActiveApplications,
  selectSelectedApplication,
  (_: MultiAppState, activeApplications: ApplicationDashboardInfo[], selectedApplication: HomeownerApplicationApiData | undefined) => getIneligibleProgramIdsFromApplications(activeApplications, selectedApplication)
);

export const selectAdmin = createSelector(
  getMultiApplicationFeatureState,
  (state: MultiAppState) => state.SelectedApplication?.Admin
);

export const selectClerk = createSelector(
  getMultiApplicationFeatureState,
  (state: MultiAppState) => state.SelectedApplication?.Clerk
);

export const selectCounselor = createSelector(
  getMultiApplicationFeatureState,
  (state: MultiAppState) => state.SelectedApplication?.Counselor
);

/**
 * Return a Date object for a given date string value
 * If the dateStr is null or undefined, return 0
 * @param dateStr 
 * @returns Date object
 */
function getDateTime(dateStr: string | null | undefined): number {
  return !!dateStr ? new Date(dateStr).getTime() : 0;
}

/**
 * Sort list of applications by ApplicationStatus.SubmissionDate (most recent to oldest) 
 *    if at least 1 application has been submitted
 * Else sort by ApplicationStatus.ApplicationNumber (highest to lowest)
 */
function sortApplications(apps: ApplicationDashboardInfo[]): ApplicationDashboardInfo[] {
  const hasSubmittedApp = !!apps.find(a => !!a.ApplicationStatus.SubmissionDate);
  const sorted = hasSubmittedApp ? 
    apps.sort((a,b) => {
      const aTime = getDateTime(a.ApplicationStatus.SubmissionDate);
      const bTime = getDateTime(b.ApplicationStatus.SubmissionDate);
      return bTime - aTime;
    }) :
    apps.sort(
      (a,b) => b.ApplicationStatus.ApplicationNumber - a.ApplicationStatus.ApplicationNumber
    );
  return sorted;
}

/**
 * If any copyable applications are available, sort them 
 * Set the `idToCopyFrom` value to the first sorted application's ApplicationId
 */
export const selectMostRecentCopyableApplicationId = createSelector(
  selectActiveApplicationsWithEligibleProgram,
  selectSelectedApplicationId,
  (apps: ApplicationDashboardInfo[], selectedId: number | undefined) => {
    let idToCopyFrom = null;
    const sortedApps = sortApplications(apps);
    if (!!selectedId && !!sortedApps && sortedApps.length > 0) {
      const app = sortedApps.filter(a => a.ApplicationId != selectedId)[0];
      idToCopyFrom = !!app ? app.ApplicationId : null;
    }
    return idToCopyFrom;
  }
);

export const selectHasCopyableApplication = createSelector(
  selectMostRecentCopyableApplicationId,
  (Id: number | null) => {
    return !!Id;
  }
);