import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from '../reducers/router.reducers';

export const routerFeatureKey = 'router';

export const selectRouterFeature = createFeatureSelector<RouterReducerState>(routerFeatureKey);

export const getRouterState = (state: RouterState) => state.router;

export const getCurrentRouteState = createSelector(
  getRouterState,
  (state: RouterReducerState) => state.state
);