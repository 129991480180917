import { Component, Input, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { FormStatus } from 'src/app/homeowner-application/interfaces';
import { FormConditionsService } from 'src/app/homeowner-application/services/form-conditions.service';
import { MessagesActions } from 'src/app/store/actions';
import { SupportingFileAndUploads } from 'src/app/store/reducers/supporting-files.reducers';
import { ValidationClass } from '../../interfaces';

@Component({
  selector: 'app-summary-supporting-file-display',
  templateUrl: './app-summary-supporting-file-display.component.html',
  styleUrls: ['./app-summary-supporting-file-display.component.scss']
})
export class AppSummarySupportingFileDisplayComponent implements OnInit {

  @Input() fileInfo!: SupportingFileAndUploads;

  FormStatus = FormStatus;
  ValidClass = ValidationClass;

  constructor(
    public fcs: FormConditionsService, 
    private fileSaverService: FileSaverService, 
    private store: Store,
    private appApi: HomeownerApplicationApiService
  ) { }

  ngOnInit(): void {}

  downloadFile(fileId: number, fileName: string): void {
    this.store.dispatch(MessagesActions.updateLoadingMessage({ message: translate('loading.downloading') }));
    this.appApi
      .downloadFile(fileId)
      .subscribe(response => {
        this.store.dispatch(MessagesActions.updateLoadingMessage({ message: '' }));
        this.fileSaverService.save(response, fileName);
      }, _ => {
        this.store.dispatch(MessagesActions.updateLoadingMessage({ message: '' }));
      });
  }

}
