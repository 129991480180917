import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { catchError, first, map, takeUntil, tap } from 'rxjs/operators';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';
import { Address, AddressValidationService } from 'src/app/core/services/address-validation.service';
import { ModalsActions } from 'src/app/store/actions';
import { selectFeatureToggles, selectPersonalForm, selectUserAddress } from 'src/app/store/selectors';
import { ListObject } from '../../interfaces';

@Component({
  selector: 'app-smarty-streets-modal',
  templateUrl: './smarty-streets-modal.component.html',
  styleUrls: ['./smarty-streets-modal.component.scss']
})
export class SmartyStreetsModalComponent implements OnInit, OnDestroy {

  destroy = new Subject();
  form!: FormGroup;
  personalForm!: FormGroup;
  smartyAddress: Address[] = [];
  stateCountyIDs: ListObject[] = [];
  userEnteredAddress!: string;
  userEnteredAddressObject!: Address;
  
  constructor(
    private addressValidationService: AddressValidationService,
    private formBuilder: FormBuilder,
    private modal: NgbActiveModal,
    private store: Store
  ) {
    this.store.dispatch(ModalsActions.updateIsModalOpen({ isOpen: true }));
    this.store.select(selectUserAddress).pipe(
      takeUntil(this.destroy),
      tap(address => this.userEnteredAddressObject = address)
    ).subscribe();

    this.store.select(selectPersonalForm).pipe(first()).subscribe(form => this.personalForm = form);
    this.createAddress();
    this.setupForm();
   }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.store.dispatch(ModalsActions.updateIsModalOpen({ isOpen: false }));
    this.destroy.next();
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      Selected_Address: [-1]
    });
  }

  get canSaveAddress(): boolean {
    return this.form.get('Selected_Address')?.value == -1;
  }

  createAddress(): void {
    this.userEnteredAddress = 
      (!!this.userEnteredAddressObject?.Address1 ? this.userEnteredAddressObject.Address1 + ' \n' : '') +
      (!!this.userEnteredAddressObject?.Address2 ? this.userEnteredAddressObject.Address2 + ' \n' : '') + 
      (!!this.userEnteredAddressObject?.City ? this.userEnteredAddressObject.City + ', ' : '') + 
      (!!this.userEnteredAddressObject?.State ? this.userEnteredAddressObject.State + ' ' : '') + 
      (!!this.userEnteredAddressObject?.ZipCode ? this.userEnteredAddressObject.ZipCode + ' \n' : '') + 
      (!!this.userEnteredAddressObject?.County ? this.userEnteredAddressObject.County + ' County': '');
    this.searchAddress(this.userEnteredAddress);
  }

  searchAddress(address: string): void {
    this.addressValidationService.lookup(address).pipe(
      first(),
      tap((val: Address[]) => {
        val.forEach(address => {
          this.applyAddressInModal(address);
        });
      }),
      catchError(() => {
        return of([]);
      })).subscribe();
  }

  applyAddressInModal(address: Address): void {
    this.smartyAddress.push(address);
  }

  close(options: string): void { 
    switch (options) {
      case 'save':
        const value = this.form.get('Selected_Address')?.value;
        value==0 ? this.modal.dismiss('save') : this.modal.close(this.smartyAddress[value - 1]);;
        
        break;
      case 'cancel':
        this.modal.dismiss('cancel');
        break;
    }
  }
}
