import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { ListObject, HelpLink } from '../../shared/interfaces';
import { Program } from '../interfaces/program.interface';
import { FeatureToggles } from '../interfaces/toggles.interface';

@Injectable({
  providedIn: 'root'
})
export class ListApiService {

  constructor(private http: HttpClient) { }

  getBankruptcyTypes(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/bankruptcytypes`);
  }

  getBestTimesToCall(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/besttimestocall`);
  }

  getCounties(stateId: number): Observable<ListObject[]> {
    const stateValue = typeof stateId === 'number' ? stateId.toString() : '';
    const options = { params: new HttpParams().set('stateId', stateValue) };
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/counties`, options);
  }

  getDiscoveryTypes(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/discoverytypes`);
  }

  getEligibleExpenseCategories(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/eligibleexpensecategory`);
  }

  getEligibleExpenseProviders(categoryId: number): Observable<ListObject[]> {
    const options = { params: new HttpParams().set('categoryId', categoryId.toString()) };
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/eligibleexpenseproviders`, options);
  }

  getEligibleExpenseTypes(categoryId: number): Observable<ListObject[]> {
    const options = { params: new HttpParams().set('categoryId', categoryId.toString()) };
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/expensetypes`, options);
  }
  
  getEthnicities(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/ethnicities`);
  }

  getExpenseCategories(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/expensecategory`);
  }

  getFeatureToggles(): Observable<FeatureToggles> {
    return this.http.get<FeatureToggles>(`${env.apiUrl}/features`);
  }

  getFileCategories(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/filecategory`);
  }

  getGenders(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/genders`);
  }

  getHowLongWithEmployers(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/howlongwithemployers`);
  }

  getIncomeSources(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/incomesources`);
  }

  getLanguages(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/languages`);
  }

  getLoanTypes(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/loantypes`);
  }

  getMaritalStatuses(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/maritalstatuses`);
  }

  getMortgageDelinquencyStatuses(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/mortgagedelinquencystatuses`);
  }

  getMortgageLoanPrograms(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/loanprograms`);
  }

  getMortgagePaymentDueDates(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/mortgagepaymentduedates`);
  }
    
  getPhoneTypes(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/phonetypes`);
  }

  getProgramCounties(): Observable<ListObject[]> {
    return this.getCounties(env.stateId);
  }
  
  getPrograms(): Observable<Program[]> {
    return this.http.get<Program[]>(`${env.apiUrl}/programs`);
  }

  getRaces(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/races`);
  }

  getSociallyDisadvantagedList(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/sociallydisadvantagedreasons`);
  }

  getStates(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/states`);
  }

  getWhoPays(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/whopays`);
  }

  getYesNo(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/yesno`);
  }

  getYesNoPending(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/yesnopending`);
  }

  getHelpLinks(): Observable<HelpLink[]> {
    return this.http.get<HelpLink[]>(`${env.apiUrl}/help/${env.clientName}/links`);
  }

  getListTranslations(lang: string): Observable<any> {
    const options = { params: new HttpParams().set('lang', lang) };
    return this.http.get(`${env.apiUrl}/lists/translations`, options);
  }

  getProgramStages(): Observable<ListObject[]> {
    return this.http.get<ListObject[]>(`${env.apiUrl}/lists/programstages`);
  }

}
