import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap } from 'rxjs/operators';
import { NavService } from 'src/app/shared/services';
import { AppInfoActions, MultiApplicationActions, RoutingActions } from '../actions';

@Injectable()
export class RoutingEffects {

  startNewApplication$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.startNewApplication),
    tap(_ => this.ns.goToProgramSelection()),
    mergeMap(_ => [
      RoutingActions.startNewApplicationSuccess(),
      MultiApplicationActions.resetSelectedApplication(),
      AppInfoActions.reset()
    ])
  ));

  constructor(
    private actions$: Actions, 
    private ns: NavService
  ) {}
}