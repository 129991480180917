import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-toggle',
  templateUrl: './app-accordion-toggle.component.html'
})
export class AppAccordionToggleComponent implements OnInit {

  @Input() isInfo = false;
  @Input() open = false;

  constructor() {}

  ngOnInit(): void {}

}
