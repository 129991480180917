export namespace Constants {
  /**
   * Configuration Keys
   */
  export const CONFIG_AUTH_FLOW: string = "MFA.Flow";
  export const CONFIG_DISPLAY_MORTGAGE_LENDER: string = "HomeownerPortalHasMortgageLender";
  export const CONFIG_MONTHLY_INCOME_WARNING_THRESHOLD: string = "HomeownerPortalMonthlyIncomeWarningThreshold";

  /**
   * Constant for USER_SRP_AUTH
   */
  export const AUTH_USER_SRP_AUTH = "USER_SRP_AUTH";

  /**
   * Constant for USER_PASSWORD_AUTH
   */
  export const AUTH_USER_PASSWORD_AUTH = "USER_PASSWORD_AUTH";

  /**
   * Constants for MFA types 
   */
  export const AUTH_NO_MFA = "NOMFA";
  export const AUTH_SMS_MFA = "SMS_MFA";
  export const AUTH_SMS_MFA_TYPE = "SMS";

  /**
   * Google Analytics: Event Name Constants
   */
  export const CHANGE_EMAIL_ACTION = "change_email";
  export const CHANGE_PASSWORD_ACTION = "change_password";
  export const CHANGE_PHONE_ACTION = "change_phone";
  export const CONFIRM_SIGNUP_ACTION = "confirm_signup";
  export const FORGOT_PASSWORD_ACTION = "forgot_password";
  export const LOGIN_ACTION = "login";
  export const LOGIN_MFA_USR_PASSWORD_ACTION = "login_mfa_usr_password_auth";
  export const LOGIN_MFA_USR_SRP_ACTION = "login_mfa_usr_srp_auth";
  export const LOGOUT_ACTION = "logout";
  export const REMEMBER_DEVICE_ACTION = "remember_device";
  export const RESEND_CODE_ACTION = "resend_code";
  export const RESET_PASSWORD_ACTION = "reset_password";
  export const SIGNUP_ACTION = "signup";

  /**
   * Google Analytics: Event Category Constants
   */
  export const AUTH_CATEGORY = "authentication";
  export const LOGIN_CATEGORY = "login";
  export const LOGOUT_CATEGORY = "logout";
  export const PASSWORD_CATEGORY = "password";
  export const SIGNUP_CATEGORY = "signup";
  export const ENGAGEMENT_CATEGORY = "engagement";
  export const NAVIGATION_CATEGORY = "navigation";
  export const PREQUAL_CATEGORY = "prequalification";
  export const SUPPORT_CATEGORY = "support";

  /**
   * Google Analytics: Event Action Constants
   */
  export const UPDATE_USER_ATTRIBUTES_ACTION = "update_user_attributes";

  /**
   * Google Analytics: Event Label Constants
   */
  export const ERROR_EVENT_LABEL = "error";
  export const SUCCESS_EVENT_LABEL = "success";

  /**
   * Google Analytics: Navigation & Submit Event Constants
   */
  export const VERTICAL_NAV = "vertical_nav";
  export const HORIZONTAL_NAV = "horizontal_nav";
  export const CONTINUE_TO_PERSONAL = "continue_to_personal";
  export const CONTINUE_TO_EMPLOYMENT = "continue_to_employment";
  export const CONTINUE_TO_PROPERTY = "continue_to_property";
  export const CONTINUE_TO_MORTGAGE = "continue_to_mortgage";
  export const CONTINUE_TO_FINANCIAL = "continue_to_financial";
  export const CONTINUE_TO_UPLOADS = "continue_to_uploads";
  export const CONTINUE_TO_SUMMARY = "continue_to_summary";
  export const CONTINUE_TO_SIGN_AND_SUBMIT = "continue_to_sign_and_submit";
  export const CONTINUE_INITIAL_SUBMIT = "continue_initial_submit";
  export const CONFIRM_APPLICATION_SUBMIT = "application_submitted";
  export const APPLICATION_SUBMITTED_SUCCESS = "application_submitted_success";
  export const APPLICATION_SUBMITTED_ERROR = "application_submitted_error";
  export const SIGNATURES_ERROR = "signatures_error";

  /**
   * Date formats
   */
  export const DATE_FORMAT_MONTH_DAY_YEAR = 'MM/dd/yyyy';
  export const DATE_FORMAT_FULL_MONTH_DAY_YEAR = 'MMMM d, yyyy';
  export const DATE_FORMAT_YEAR_MONTH_DAY = 'yyyy-MM-dd';
  export const DATE_FORMAT_MESSAGE = 'E, MMM dd; hh:mm a';
}