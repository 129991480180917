import { createFeatureSelector, createSelector } from '@ngrx/store';
import { fromMenu } from '../reducers';
import { AppMenuState } from '../reducers/menu.reducers';

export const selectMenuFeature = createFeatureSelector<AppMenuState>(fromMenu.menuFeatureKey);

export const selectMenu = createSelector(
  selectMenuFeature,
  (state: AppMenuState) => state
);

export const selectMenuActiveIndex = createSelector(
  selectMenuFeature,
  (state: AppMenuState) => state.activeIndex
);

export const selectMenuIsOpen = createSelector(
  selectMenuFeature,
  (state: AppMenuState) => state.isOpen
);

export const selectPageType = createSelector(
  selectMenuFeature,
  (state: AppMenuState) => state.pageType
);