import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormStatus, FormStatusInfo, SupportingFile, UploadedFile } from 'src/app/homeowner-application/interfaces';
import { fromSupportingFiles } from '../reducers';
import { SupportingFileAndUploads, SupportingFilesState } from '../reducers/supporting-files.reducers';

export const selectSupportingFilesFeature = createFeatureSelector<SupportingFilesState>(fromSupportingFiles.supportingFilesFeatureKey);

export const selectSupportingFilesState = createSelector(
  selectSupportingFilesFeature,
  (state: SupportingFilesState) => state
);

export const selectSupportingFiles = createSelector(
  selectSupportingFilesFeature,
  (state: SupportingFilesState) => state.supportingFiles
);

export const selectUploadedFiles = createSelector(
  selectSupportingFilesFeature,
  (state: SupportingFilesState) => state.uploadedFiles
);

export const selectAdditionalSupportingFiles = createSelector(
  selectSupportingFilesFeature,
  selectUploadedFiles,
  (state: SupportingFilesState, uploadedFiles: UploadedFile[]) => filterSupportingFiles(state.supportingFiles, uploadedFiles).filter(file => !file.RequiredToSubmitApplication)
);

export const selectRequiredSupportingFiles = createSelector(
  selectSupportingFilesFeature,
  selectUploadedFiles,
  (state: SupportingFilesState, uploadedFiles: UploadedFile[]) => filterSupportingFiles(state.supportingFiles, uploadedFiles).filter(file => !!file.RequiredToSubmitApplication)
);

/**
 * Filter out inactive supporting files unless at least 1 file is uploaded for that supporting file
 * @param files - all supporting files returned from the forms endpoint
 * @param uploadedFiles - all user uploaded files
 * @returns - an array of supporting files that are either active or have a user uploaded file
 */
export function filterSupportingFiles(files: SupportingFile[], uploadedFiles: UploadedFile[]): SupportingFile[] {
  return files.filter(file => file.IsActive || uploadedFiles.filter(uploadedFile => uploadedFile.SupportingFileId === file.FileId).length > 0);
}

export const selectAreRequiredFilesUploaded = createSelector(
  selectSupportingFilesFeature,
  (state: SupportingFilesState) => areAllRequiredFilesUploaded(state)
);

/**
 * Return an array of REQUIRED supporting files with matching uploaded files
 */
export const selectRequiredSupportingFilesAndUploads = createSelector(
  selectSupportingFilesFeature,
  selectRequiredSupportingFiles,
  selectUploadedFiles,
  (
    _: SupportingFilesState, 
    supportingFiles: SupportingFile[], 
    uploadedFiles: UploadedFile[]
  ): SupportingFileAndUploads[] => getSupportingFilesAndUploads(supportingFiles, uploadedFiles)
);

export function getSupportingFilesAndUploads(sFiles: SupportingFile[], uFiles: UploadedFile[]): SupportingFileAndUploads[] {
  return sFiles.map(sFile => {
    const matchingUploads = uFiles.filter(uFile => uFile.SupportingFileId === sFile.FileId);
    return {
      supportingFile: sFile,
      uploadedFiles: matchingUploads,
      status: matchingUploads.length > 0 ? FormStatus.Valid : FormStatus.Invalid
    }
  });
}

/**
 * Return an array of ADDITIONAL supporting files with matching uploaded files
 * Filter out results that do not have any uploaded files
 */
export const selectAdditionalSupportingFilesAndUploads = createSelector(
  selectSupportingFilesFeature,
  selectAdditionalSupportingFiles,
  selectUploadedFiles,
  (
    _: SupportingFilesState, 
    supportingFiles: SupportingFile[], 
    uploadedFiles: UploadedFile[]
  ) => getSupportingFilesAndUploads(supportingFiles, uploadedFiles).filter(fileAndUpload => fileAndUpload.uploadedFiles.length > 0)
);

/**
 * Return true if all required supporting files have a corresponding uploaded file
 * Else return false
 */
export function areAllRequiredFilesUploaded(state: SupportingFilesState): boolean {
  const requiredFiles = state.supportingFiles.filter(file => !!file.RequiredToSubmitApplication) || [];
  const uploadedFiles = state.uploadedFiles || [];
  const requiredSupportingFilesWithUpload = requiredFiles.filter(file => uploadedFiles.some(upload => upload.SupportingFileId === file.FileId));
  return requiredFiles.length === 0 || requiredFiles.length === requiredSupportingFilesWithUpload.length;
}

export const selectSupportingFilesStatus = createSelector(
  selectSupportingFilesFeature,
  selectAreRequiredFilesUploaded,
  (_: SupportingFilesState, uploaded: boolean) => getSupportingFilesFormStatusInfo(uploaded)
);

export function getSupportingFilesFormStatusInfo(requiredFilesUploaded: boolean): FormStatusInfo {
  return {
    status: !!requiredFilesUploaded ? FormStatus.Valid : FormStatus.Invalid,
    visited: true
  };
}