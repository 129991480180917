import { AbstractControl } from "@angular/forms";
import { ProgramSummary, WarningType } from "src/app/homeowner-application/interfaces";
import { HelpLink, ListObject } from "../interfaces";
import { PrequalStep } from "../interfaces/prequalification-step.enum";
import { translate } from "@ngneat/transloco";

/**
 * Append ' - Inactive' to select option display names to
 * denote inactive items in a dropdown if previously 
 * selected by a user
 * 
 * @param item: ListObject
 * @returns A string display name
 */
export function getOptionDisplayName(item: ListObject): string { 
    return `${translate(item.Name)}${ !item.IsActive ? ' - ' + translate('picklist.inactive') : ''}`;
}

export function getProgramSummaryDisplayName(program: ProgramSummary): string {
    return translate(`Program.${program?.Name}${program?.Id}`);
}

export function getHelpLink(route: string, list: HelpLink[], prequalQuestionsStep: string = PrequalStep.None): string {
    let defaultLink;
    let pageHelpLink;
    defaultLink = list.find(data => data.route == 'default')?.link;
    list.forEach(link => {
        if (prequalQuestionsStep != PrequalStep.None && link.route.includes(prequalQuestionsStep) && route.includes('questions')) {
            pageHelpLink = link.link;
        } else if (route.includes(link.route)) {
            pageHelpLink = link.link;
        }
    });
    return !!pageHelpLink ? pageHelpLink : !!defaultLink ? defaultLink : '';
}

export function hasWarning(warningType: WarningType, thresholdValue: number | null, field: AbstractControl | null): boolean {
    switch(warningType) {
        case WarningType.MaxThreshold: {
            return thresholdValue !== null && thresholdValue > 0 && field?.value > thresholdValue;
        }
        default: {
            return false;
        }
    }
}