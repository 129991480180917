import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AddressValidationService } from './services/address-validation.service';
import { RequestCacheService } from './services/request-cache.service';

/**
 * State management, singleton services
 */ 
@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  providers: [RequestCacheService, AddressValidationService]
})
export class CoreModule { }
