import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppMessageState } from '../reducers/messages.reducers';

export const messagesFeatureKey = 'messages';

export const getMessagesFeatureState = createFeatureSelector
<AppMessageState>(messagesFeatureKey);

export const selectCountdownMessage = createSelector(
  getMessagesFeatureState,
  (state: AppMessageState) => state.countdownMessage
);

export const selectVerifyAlertMessage = createSelector(
  getMessagesFeatureState,
  (state: AppMessageState) => state.verifyAlertMessage
);

export const selectIsLoading = createSelector(
  getMessagesFeatureState,
  (state: AppMessageState) => state.isLoading
);

export const selectLoadingMessage = createSelector(
  getMessagesFeatureState,
  (state: AppMessageState) => state.loadingMessage
);