 <ng-container *transloco="let t;">
    <section id="new-user" *ngIf="areFeatureTogglesLoading || applicationIntakeMessage === null; else featureTogglesLoaded">
        <app-loader></app-loader>
    </section>
    <ng-template #featureTogglesLoaded>
        <section id="new-user" *ngIf="signupEnabled; else noNewUsers">
            <h2>{{ t('login.signup') }}</h2>
            <p>{{ t('login.createMessage') }}.</p>
            <button [routerLink]="profileCreateRoute" class="btn btn-primary py-0" id="signupButton">{{ t('login.createAccount') }}</button>
        </section>
    
        <ng-template #noNewUsers>
            <section id="new-user">
                <h2>{{ t('login.noNewUsersHeader') }}:</h2>
                <div [innerHtml]="applicationIntakeMessage" id="applicationIntakeMessage"></div>
            </section>
        </ng-template>
    </ng-template>

    <section id="or">
        <div></div>
        <p>{{ t('login.or') }}</p>
        <div></div>
    </section>

    <section id="login-form">
        <h2>{{ t('login.login') }}</h2>
        <p>{{ t('login.userHasAccount') }}.</p>
        <form *ngIf="loginForm" [formGroup]="loginForm" id="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="username">{{ t('commonWords.email') }}</label>
            <input type="email" placeholder="{{ t('login.enterEmail') }}" autocomplete="email" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': hasSubmitted && loginForm.controls.username.errors }" />
            <form-message *ngIf="isInvalid('username')" [cssClass]="ValidClass.Danger" [field]="loginForm.get('username')"></form-message>
            <div *ngIf="hasSubmitted && loginForm.controls.username.errors" class="invalid-feedback">
            <div *ngIf="loginForm.controls.username.errors.required">{{ t('login.emailError') }}</div>
            </div>
        </div>
        <div class="form-group mb-4">
            <label for="password">{{ t('login.password') }}</label>
            <input type="password" placeholder="{{ t('login.enterPassword') }}" formControlName="password" class="form-control" autocomplete="on" [ngClass]="{ 'is-invalid': hasSubmitted && loginForm.controls.password.errors }" />
            <div *ngIf="hasSubmitted && loginForm.controls.password.errors" class="invalid-feedback">
            <div *ngIf="loginForm.controls.password.errors.required">{{ t('login.passwordError') }}</div>
            </div>
        </div>
        </form>
    </section>

    <section id="forgot-password"><a [routerLink]="forgotPasswordRoute">{{ t('login.forgotPassword') }}</a></section>

    <section id="login-action">
        <ng-container *ngIf="{value: isLoading | async} as context">
        <button [disabled]="!!context.value" class="btn btn-outline-primary float-right" [ngClass]="{ 'has-svg': !!context.value }" type="submit" form="loginForm">
            <span *ngIf="!!context.value" class="spinner-border spinner-border-sm mr-1" aria-hidden="true" role="status"></span>
            {{ t('login.login') }}
        </button>
        </ng-container>
    </section>

    <br>
    <!-- Currently all environments have a "completing your app" accordion, if a state would like this removed, an environment variable to show/hide and *ngIf would be necessary -->
    <section id="complete-app-info">
        <div class="accordion-container">
        <ngb-accordion #accAccordion="ngbAccordion" class="d-inline-block w-100 mt-4" [ngClass]="{'completing-open': accAccordion.isExpanded('neededItems')}" activeIds="neededItems">
            <ngb-panel id="neededItems">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex justify-content-between accordion-header-container" (click)="accAccordion.toggle('neededItems')">
                <h2 class="m-0 accordion-header" [ngClass]="{'text-primary': opened}">{{ t('login.accordionTitle' ) }}</h2>
                <button ngbPanelToggle class="btn btn-link p-0" [attr.aria-label]="'Accordion' + (accAccordion.isExpanded('neededItems') ? ', Expanded' : ', Collapsed')" (click)="accAccordion.toggle('neededItems')">
                    <app-accordion-toggle [isInfo]="opened" [open]="opened"></app-accordion-toggle>
                </button>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div [innerHtml]="t('login.accordionContent')"></div>
                <div *ngIf="showReasonableAccommodation" [innerHtml]="t('login.reasonableAccommodation')"></div>
                <div *ngIf="showDiscriminationComplaints" [innerHtml]="t('login.discriminationComplaints')"></div>
                <div *ngIf="showLanguageAssistance" [innerHtml]="t('login.languageAssistance')"></div>
            </ng-template>
            </ngb-panel>
        </ngb-accordion>
        </div>
    </section>
</ng-container>