import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { interval, Observable, Subject } from 'rxjs';
import { finalize, first, map, mergeMap, takeUntil, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { Signer } from 'src/app/homeowner-application/interfaces';
import { FormConditionsService } from 'src/app/homeowner-application/services/form-conditions.service';
import { MessagesActions } from 'src/app/store/actions';
import { selectFeatureToggles, selectPersonalForm } from 'src/app/store/selectors';
import { DialogData, ValidationClass } from '../../interfaces';
import { AlertService } from '../../services';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-signing',
  templateUrl: './app-signing.component.html',
  styleUrls: ['./app-signing.component.scss']
})
export class AppSigningComponent implements OnDestroy {
  @Input() form!: FormGroup;
  @Input() isApplicationSubmitted: boolean | null = false;
  @Input() isLoading$!: Observable<boolean>;

  @Output() error = new EventEmitter<string>();
  @Output() updateSignatures = new EventEmitter<boolean>();
  
  destroy = new Subject();
  hasCoborrower = false;
  hasSpouse = false;
  personalForm$!: Observable<FormGroup>;
  signaturesCompleted = false;
  signers: Signer[] = [];
  signersNeeded: string[] = [];
  today = new Date();
  toggles!: FeatureToggles;
  ValidClass = ValidationClass;

  constructor(
    public fcs: FormConditionsService,
    private appApi: HomeownerApplicationApiService,
    private modalService: NgbModal,
    private scroll: ViewportScroller,
    private alertService: AlertService,
    private store: Store
  ) {
    this.personalForm$ = this.store.select(selectPersonalForm);
    this.store.select(selectFeatureToggles).pipe(
      first(),
      map(toggles => this.toggles = toggles)
    ).subscribe(toggles => {
      if (toggles.IsDocusignEnabled) {
        this.refreshDocumentsStatus();
      } else {
        this.appApi.getApplicationSignatures()
          .pipe(first())
          .subscribe(signatures => {
            const borrower = signatures.filter(signature => signature.Role === 'Borrower')[0];
            const coborrower = signatures.filter(signature => signature.Role === 'CoBorrower')[0];
            const spouse = signatures.filter(signature => signature.Role === 'Spouse')[0];
            const DATE_FORMAT = Constants.DATE_FORMAT_YEAR_MONTH_DAY;
            if (!!borrower) {
              this.form.get('BorrowerSignature')?.patchValue(borrower.Name);
              const date = !!borrower.SignedOn ? borrower.SignedOn : this.today.toJSON();
              this.form.get('BorrowerSignatureDate')?.patchValue(this.fcs.formatDate(date, DATE_FORMAT));
              this.form.get('BorrowerRole')?.patchValue(borrower.Role);
            }
            if (!!coborrower) {
              this.hasCoborrower = true;
              this.form.get('CoBorrowerSignature')?.patchValue(coborrower.Name);
              const date = !!coborrower.SignedOn ? coborrower.SignedOn : this.today.toJSON();
              this.form.get('CoBorrowerSignatureDate')?.patchValue(this.fcs.formatDate(date, DATE_FORMAT));
              this.form.get('CoBorrowerRole')?.patchValue(coborrower.Role);
            }
            if (!!spouse) {
              this.hasSpouse = true;
              this.form.get('SpouseSignature')?.patchValue(spouse.Name);
              const date = !!spouse.SignedOn ? spouse.SignedOn : this.today.toJSON();
              this.form.get('SpouseSignatureDate')?.patchValue(this.fcs.formatDate(date, DATE_FORMAT));
              this.form.get('SpouseRole')?.patchValue(spouse.Role);
            }
          });
      }
    });
  }
  
  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  displayError(message: string) {
    this.error.emit(message);
  }

  private displaySignaturesAlert(): void {
    this.alertService.clear();
    const sentNotSigned: Signer[] = this.signers.filter(s => !!s.SentOn && !s.Signed);

    if (this.signaturesCompleted) {
      this.alertService.success(translate('appSigning.signatureAlert.allSetSigned'));
    } else if (!!sentNotSigned && sentNotSigned.length > 0) {
      this.alertService.error(translate('appSigning.signatureAlert.waitingForSignature'));
    } else {
      this.alertService.warn(translate('appSigning.signatureAlert.readyForSignature'));
    }
  }

  get getNeedsSent(): boolean {
    return !this.signers.some(s => !!s.SentOn && !!s.Name);
  }

  isInvalid(path: string, control?: AbstractControl | null): boolean {
    const formControl = !!control ? control : this.form.get(path);
    return !!formControl && formControl.invalid && (formControl.dirty || formControl.touched);
  }

  private handleDocumentStatusUpdate(signers: Signer[]): void {
    this.signers = signers;
    this.signersNeeded = signers.filter(s => !s.Signed && s.Name != null).map(s => s.Name) || [];
    this.signaturesCompleted = signers.length > 0 && this.signersNeeded.length == 0 && this.signers.some(s => !!s.Name);
    this.displaySignaturesAlert();
    this.updateSignatures.emit(this.signaturesCompleted);
  }

  refreshDocumentsStatus(): void {
    interval(3000)
      .pipe(
        takeWhile(_ => !this.signaturesCompleted),
        takeUntil(this.destroy),
        mergeMap(_ => this.appApi.getDocumentStatus())
      )
      .subscribe(signers => this.handleDocumentStatusUpdate(signers));
  }

  reSendDocuments(role: string): void {
    this.store.dispatch(MessagesActions.updateIsLoading({ isLoading: true }));
    this.store.dispatch(MessagesActions.updateLoadingMessage({ message: translate('loading.sendingDocuments') }));
    this.appApi
      .reSendDocuments([role])
      .pipe(
        first(),
        finalize(() => {
          this.store.dispatch(MessagesActions.updateIsLoading({ isLoading: false }));
          this.store.dispatch(MessagesActions.updateLoadingMessage({ message: '' }));
        })
      )
      .subscribe(
        _ => {},
        e => {
          this.displayError(e.error?.Message || e.message)

          const dialogData: DialogData = {
            title: translate('confirmDialog.documentsResendFailedTitle'),
            bodyText: [translate('confirmDialog.documentsResendFailedBody')],
            showCancel: false,
            action: {
              text: translate('confirmDialog.ok'),
              click: 'submit'
            }
          };
          const modal = this.modalService.open(ConfirmDialogComponent, {
            centered: true
          });
          modal.componentInstance.data = dialogData;
          modal.result.then(_ => { });
        }, 
        () => {
          const dialogData: DialogData = {
            title: translate('confirmDialog.documentsResendSuccessTitle'),
            bodyText: [translate('confirmDialog.documentsResendSuccessBody')],
            showCancel: false,
            action: {
              text: translate('confirmDialog.ok'),
              click: 'submit'
            }
          };
          const modal = this.modalService.open(ConfirmDialogComponent, {
            centered: true
          });
          modal.componentInstance.data = dialogData;
          modal.result.then(_ => { });
        }
      );
  }

  sendDocuments(): void {
    this.store.dispatch(MessagesActions.updateIsLoading({ isLoading: true }));
    this.store.dispatch(MessagesActions.updateLoadingMessage({ message: translate('loading.sendingDocuments') }));
    this.appApi
      .sendDocuments()
      .pipe(
        first(),
        finalize(() => {
          this.store.dispatch(MessagesActions.updateIsLoading({ isLoading: false }));
          this.store.dispatch(MessagesActions.updateLoadingMessage({ message: '' }));
        })
      )
      .subscribe(
        _  => {}, e => {
          this.displayError(e.error?.Message || e.message);
          this.scroll.scrollToPosition([0,0]);
        }, () => {});
  }
}
