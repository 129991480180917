import { Action, createReducer, on } from '@ngrx/store';
import { ApplicationStatus, Disbursement, HomeownerApplicationApiData, ProgramSummary, TeamMember } from 'src/app/homeowner-application/interfaces';
import { AppInfoActions, MultiApplicationActions } from '../actions';

export const multiAppFeatureKey = 'multiApp';

export interface ApplicationDashboardInfo {
  ApplicationId: number,
  ApplicationStatus: ApplicationStatus,
  Counselor: TeamMember | null,
  Admin: TeamMember | null,
  Clerk: TeamMember | null,
  ProgramSummaries: ProgramSummary[] | null,
  Disbursements: Disbursement[] | null,
  IsEmailAlertsEnabled: boolean | null,
  IsExternalUserAlertsEnabled: boolean | null,
  IsRequestGivenForEmailOfResults: boolean | null,
  IsPermissionGivenForContactByHUD: boolean | null,
  PreferredLanguage: any | null,
  HasUnreadMessages: boolean
}

export interface ApplicationsDashboardData {
  BorrowerFirstName: string,
  BorrowerLastName: string,
  EmailAddress: string,
  UserId: string,
  IsEmailAlertsEnabled:boolean,
  Applications: ApplicationDashboardInfo[]
}

export interface MultiAppState {
  IsUpdating: boolean,
  GetApplicationsError?: any,
  GetApplicationByIdError?: any,
  ApplicationsDashboard?: ApplicationsDashboardData,
  SelectedApplication?: HomeownerApplicationApiData,
  SelectedApplicationId?: number
}

export const initialState: MultiAppState = {
  IsUpdating: false,
};

const multiAppplicationReducer = createReducer(
  initialState,
  on(MultiApplicationActions.loadApplications, (state) => {
    return ({ ...state, IsUpdating: true })
  }),
  on(MultiApplicationActions.loadApplicationById, (state) => {
    return ({ ...state, IsUpdating: true })
  }),
  on(MultiApplicationActions.loadApplicationsSuccess, (state, {data} ) => {
    return ({ ...state, ApplicationsDashboard: data, IsUpdating: false })
  }),
  on(MultiApplicationActions.loadApplicationByIdSuccess, (state, {application} ) => {
    return ({ 
      ...state, 
      SelectedApplication: application, 
      SelectedApplicationId: application.ApplicationId, 
      IsUpdating: false 
    })
  }),
  on(AppInfoActions.updateAppState, (state, { data } ) => {
    return ({ ...state, SelectedApplication: data, SelectedApplicationId: data.ApplicationId })
  }),
  on(MultiApplicationActions.loadApplicationsError, (state, {error} ) => {
    return ({ ...state, GetApplicationsError: error, IsUpdating: false })
  }),
  on(MultiApplicationActions.loadApplicationByIdError, (state, {error} ) => {
    return ({ ...state, GetApplicationByIdError: error, IsUpdating: false })
  }),
  on(MultiApplicationActions.reset, _ => {
    return ({ ...initialState })
  }),
  on(MultiApplicationActions.resetSelectedApplication, (state) => {
    return ({ ...state, SelectedApplication: undefined })
  })
);

export function reducer(state: any | undefined, action: Action) {
  return multiAppplicationReducer(state, action);
}