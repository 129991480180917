<footer class="footer border-top" *transloco="let t;">
    <div class="container p-2 text-center">
        <div class="translate">
            <ng-container *ngIf="pageType$ | async as pageType">
                <ng-container *ngIf="(isLoggedIn$ | async) || pageType === 'static'">
                    <app-google-translate></app-google-translate>
                </ng-container>
            </ng-container>
        </div>
        <div class="links">
            <a (click)="focusHeader()" [routerLink]="sitemapRoute">{{t('footer.sitemap')}}</a>
            <a (click)="focusHeader()" [routerLink]="termsRoute">{{t('footer.terms')}}</a>
            <a (click)="focusHeader()" [routerLink]="privacyRoute">{{t('footer.privacy')}}</a>
            <a (click)="focusHeader()" [routerLink]="contactUsRoute">{{t('footer.contact')}}</a>
            <a *ngIf="helpLinks | async as helpLink" [href]="util.getHelpLink(currentRoute, helpLink, prequalStep)" aria-label="Help" target="_blank" title="Help">{{t('commonWords.help')}}</a>
        </div>
        <div class="legal border-top">
            <p>{{t('footer.copyright')}}</p>
            <p class="powered-by">{{t('footer.poweredBy')}}</p>
            <app-image src="assets/img/prolink.png" title="ProLink Solutions" alt="ProLink Solutions"></app-image>
            <p class="version">{{t('footer.lastBuild')}}: {{timestamp}}</p>
        </div>
    </div>
</footer>