<b>{{ label }}</b>
<div *transloco="let t;">
  <ng-container *ngIf="!categoryId; else eligibleExpenseProvider" >
    <ng-container *ngIf="!!field?.valid; else displayError">
      <ng-container *ngIf="!!list && list.length > 0; else displayValue">
        <label [innerHtml]="displayName"></label>
      </ng-container>
      <ng-template #displayValue>
        <label>{{ value ? value : (getValue(field?.value)) }}</label>
      </ng-template>
    </ng-container>
    <ng-template #displayError>
      <form-message [cssClass]="ValidClass.Danger" [isNonNegative]="isNonNegative" [isPhone]="isPhone" [field]="field"></form-message>
    </ng-template>
  </ng-container>
</div>

<ng-template #eligibleExpenseProvider>
  <div *transloco="let t;">
    <ng-container *ngIf="!expenseType">
      <ng-container *ngIf="eligibleExpenseProviders$ | async">
        <label *ngIf="!!providerMatch">{{ providerMatch.Name }}</label>
        <form-message [cssClass]="ValidClass.Danger" [field]="field"></form-message>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!!expenseType">
      <ng-container *ngIf="eligibleExpenseTypes$ | async">
        <label *ngIf="!!expenseTypeMatch">{{ t(expenseTypeMatch.Name) }}</label>
        <form-message [cssClass]="ValidClass.Danger" [field]="field"></form-message>
      </ng-container>
    </ng-container>
  </div>
</ng-template>