import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationClass } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-verify-login',
  templateUrl: './verify-login.component.html',
  styleUrls: ['../../../../login/login-page/login-page.component.scss']
})
export class VerifyLoginComponent implements OnInit {

  @Input() authTypeUserSRP!: any;
  @Input() form!: FormGroup;
  @Input() isInvalidCode!: any;
  @Input() enableHomeownerPortalRememberMyDevice: boolean = true;
  @Output() verifyLoginSubmit: EventEmitter<{code: any, optIn: any}> = new EventEmitter();
  @Output() resendCode: EventEmitter<any> = new EventEmitter();
  @Output() toggleDisplay: EventEmitter<any> = new EventEmitter();

  ValidClass = ValidationClass;

  ngOnInit(): void {
  }

  resend(): void {
    this.resendCode.emit();
  }

  onSubmitVerifyLogin(): void {
    const code = this.form.controls.code.value;
    const optIn = this.form.controls.optIn.value;
    this.verifyLoginSubmit.emit({code: code, optIn: optIn});
  }

}
