import { Action, createReducer, on } from '@ngrx/store';
import { ApplicationMessage, ApplicationMessageContainer} from 'src/app/application-messaging/interfaces';
import { ApplictionMessagingActions } from '../actions';

export const communicationsFeatureKey = 'communications';

export interface ApplicationMessagingState {
  isUpdating: boolean,
  replyForm: any,
  messageContainer: ApplicationMessageContainer | null
}

export const initialState: ApplicationMessagingState = {
  isUpdating: false,
  replyForm: { reply: '' },
  messageContainer: null
};

const applicationMessagingReducer = createReducer(
  initialState,
  on(ApplictionMessagingActions.loadMessagesSuccess, (state, { messageContainer }) => {
    return ({ ...state, messageContainer: messageContainer })
  }),
  on(ApplictionMessagingActions.markMessagesAsReadSuccess, (state, { messageContainer }) => {
    return ({ ...state, messageContainer: messageContainer })
  }),
  on(ApplictionMessagingActions.createMessage, (state, { message }) => {
    return ({ ...state, isUpdating: true })
  }),
  on(ApplictionMessagingActions.createMessageSuccess, (state, { messageContainer }) => {
    return ({ ...state, replyForm: initialState.replyForm, messageContainer: messageContainer, isUpdating: false })
  }),
  on(ApplictionMessagingActions.createMessageError, (state, { error }) => {
    return ({ ...state, isUpdating: false })
  }),
  on(ApplictionMessagingActions.reset, (state) => {
    return ({ ...state, ...initialState })
  }),
);

export function reducer(state: any | undefined, action: Action) {
  return applicationMessagingReducer(state, action);
}