export enum PageIndex {
  Dashboard = 0,
  PersonalInformation = 1,
  Employment = 2,
  Property = 3,
  Mortgage = 4,
  Financial = 5,
  SupportingFiles = 6,
  ApplicationSummary = 7,
  SignSubmit = 8,
  Profile = 9,
  ProgramSelection = 10,
  ApplicationsDashboard = 11,
  Communications = 12
}

export enum AnalyticsPageIndex {
  dashboard = 0,
  personal_Information = 1,
  employment = 2,
  property = 3,
  mortgage = 4,
  financial = 5,
  supporting_files = 6,
  application_summary = 7,
  sign_and_submit = 8,
  profile = 9,
  program_selection = 10,
  applications_dashboard = 11,
  communications = 12
}