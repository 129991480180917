import { APP_SUMMARY_ROUTE, COMMS_ROUTE, DASHBOARD_APPLICATIONS_ROUTE, DASHBOARD_DETAILS_ROUTE, EMPLOYMENT_ROUTE, FINANCIAL_ROUTE, MORTGAGE_ROUTE, PERSONAL_ROUTE, PREQUAL_PROGRAM_SELECTION_ROUTE, PREQUAL_QUESTIONS_ROUTE, PROPERTY_ROUTE, SIGN_SUBMIT_ROUTE, STATIC_NOT_ELIGIBLE_ROUTE, STATIC_NO_LONGER_ACTIVE_ROUTE, SUPPORTING_FILES_ROUTE } from "routes";

export enum ApplicationStateName {
  Prequalification,
  NotEligible,
  ProgramSelection,
  Inactive,
  Submitted,
  Active
}

/**
 * Defines the accessible routes for each application state
 */
export const stateNameMapping = {
  [ApplicationStateName.Prequalification]: [
    PREQUAL_QUESTIONS_ROUTE,
    DASHBOARD_APPLICATIONS_ROUTE
  ],
  [ApplicationStateName.NotEligible]: [
    STATIC_NOT_ELIGIBLE_ROUTE,
    DASHBOARD_APPLICATIONS_ROUTE
  ],
  [ApplicationStateName.ProgramSelection]: [
    PREQUAL_PROGRAM_SELECTION_ROUTE,
    DASHBOARD_APPLICATIONS_ROUTE,
  ],
  [ApplicationStateName.Inactive]: [
    STATIC_NO_LONGER_ACTIVE_ROUTE,
    DASHBOARD_APPLICATIONS_ROUTE
  ],
  [ApplicationStateName.Submitted]: [
    DASHBOARD_APPLICATIONS_ROUTE, 
    DASHBOARD_DETAILS_ROUTE, 
    APP_SUMMARY_ROUTE, 
    SUPPORTING_FILES_ROUTE,
    COMMS_ROUTE
  ],
  [ApplicationStateName.Active]: [
    DASHBOARD_APPLICATIONS_ROUTE, 
    DASHBOARD_DETAILS_ROUTE,
    PERSONAL_ROUTE, 
    EMPLOYMENT_ROUTE,
    PROPERTY_ROUTE, 
    MORTGAGE_ROUTE, 
    FINANCIAL_ROUTE,
    APP_SUMMARY_ROUTE, 
    SUPPORTING_FILES_ROUTE, 
    SIGN_SUBMIT_ROUTE,
    COMMS_ROUTE
  ]
}