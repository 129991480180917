import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { APPLICATIONS_ROUTE, APP_SUMMARY_ROUTE, COMMS_ROUTE, DASHBOARD_APPLICATIONS_ROUTE, DASHBOARD_DETAILS_ROUTE, EMPLOYMENT_ROUTE, FINANCIAL_ROUTE, MORTGAGE_ROUTE, PERSONAL_ROUTE, PROFILE_EDIT_ROUTE, PROPERTY_ROUTE, SIGN_SUBMIT_ROUTE, SUPPORTING_FILES_ROUTE } from 'routes';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { AnalyticsActions, ApplictionMessagingActions } from 'src/app/store/actions';
import { selectAppData, selectEmploymentFormStatus, selectFinancialFormStatus, selectHasUnreadMessages, selectHelpLinksList, selectIsActiveApplication, selectIsCommunicationsEnabled, selectIsMultiAppEnabled, selectMenuActiveIndex, selectMenuIsOpen, selectMortgageFormStatus, selectPageType, selectPersonalFormStatus, selectPropertyFormStatus, selectSelectedApplicationId, selectUnreadMessageCount, selectUserIsLoggedIn } from 'src/app/store/selectors';
import { selectSupportingFilesStatus } from 'src/app/store/selectors/supporting-files.selectors';
import { FormStatus } from '../../../homeowner-application/interfaces/homeowner-application-form.interface';
import { PageIndex } from '../../interfaces';
import { NavService } from '../../services';
import * as util from '../../services/utilities';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit, OnDestroy {

  destroy = new Subject();

  @Input() isSubmitted!: boolean | null;
  @Output() menuToggle = new EventEmitter<boolean>();
  @Output() smallMenuSelected = new EventEmitter<boolean>();

  FormStatus = FormStatus;
  PageIndex = PageIndex;
  util = util;

  activeApplication$ = this.store.select(selectIsActiveApplication);
  activeIndex$ = this.store.select(selectMenuActiveIndex);
  employmentStatus$ = this.store.select(selectEmploymentFormStatus);
  financialStatus$ = this.store.select(selectFinancialFormStatus);
  isMenuOpen$ = this.store.select(selectMenuIsOpen);
  mortgageStatus$ = this.store.select(selectMortgageFormStatus);
  pageType$ = this.store.select(selectPageType);
  personalStatus$ = this.store.select(selectPersonalFormStatus);
  propertyStatus$ = this.store.select(selectPropertyFormStatus);
  supportingFilesStatus$ = this.store.select(selectSupportingFilesStatus);
  selectedAppId$ = this.store.select(selectSelectedApplicationId);
  hasUnreadMessages$ = this.store.select(selectHasUnreadMessages);

  helpLinks = this.store.select(selectHelpLinksList).pipe(filter(list => !!list));
  prequalStep = '';

  constructor(private navService: NavService, private store: Store) {
    this.store.select(selectAppData).pipe(filter(data => !data.isUpdatingPrequalStep), takeUntil(this.destroy)).subscribe(data => this.prequalStep = data.PrequalStep);
    this.store.dispatch(ApplictionMessagingActions.loadMessages());
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  get unreadMessageCount$(): Observable<number | undefined>{
    return this.store.select(selectUnreadMessageCount);
  }

  get appsRoute(): string {
    return APPLICATIONS_ROUTE;
  }
  
  get appsDashboardRoute(): string {
    return DASHBOARD_APPLICATIONS_ROUTE;
  }

  get appsDetailsDashboardRoute(): string {
    return DASHBOARD_DETAILS_ROUTE;
  }

  get commsRoute(): string {
    return COMMS_ROUTE;
  }

  get editProfileRoute(): string {
    return PROFILE_EDIT_ROUTE;
  }

  get employmentRoute(): string {
    return EMPLOYMENT_ROUTE;
  }

  get financialRoute(): string {
    return FINANCIAL_ROUTE;
  }

  get mortgageRoute(): string {
    return MORTGAGE_ROUTE;
  }

  get personalRoute(): string {
    return PERSONAL_ROUTE;
  }

  get propertyRoute(): string {
    return PROPERTY_ROUTE;
  }

  get signSubmitRoute(): string {
    return SIGN_SUBMIT_ROUTE;
  }

  get summaryRoute(): string {
    return APP_SUMMARY_ROUTE;
  }

  get supportingFilesRoute(): string {
    return SUPPORTING_FILES_ROUTE;
  }

  get currentRoute(): string {
    return this.navService.getCurrentRoute();
  }

  get isCommunicationsEnabled$(): Observable<boolean> {
    return this.store.select(selectIsCommunicationsEnabled);
  }
  
  get isMultiAppEnabled$(): Observable<boolean> {
    return this.store.select(selectIsMultiAppEnabled);
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.store.select(selectUserIsLoggedIn);
  }

  smallMenuItemSelected(eventLabel: string) {
    this.smallMenuSelected.emit(true);
    this.store.dispatch(AnalyticsActions.logCustomEvent({
      event: {
        action: Constants.VERTICAL_NAV,
        category: Constants.NAVIGATION_CATEGORY,
        label: eventLabel
      }
    }));
  }

  toggleMenu() {
    this.menuToggle.emit();
  }

  skipTo(location: string): void {
    document.getElementById(location)?.focus();
  }
}
