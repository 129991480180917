import { Component, Input, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { APPLICATIONS_ROUTE } from 'routes';
import { ListObject } from '../../interfaces';
import { NavService } from '../../services';

@Component({
  selector: 'app-financial-expense',
  templateUrl: './financial-expense.component.html'
})
export class FinancialExpenseComponent implements OnInit {

  @Input() expenseType?: string;
  @Input() appPage?: string;
  @Input() amount?: number;
  @Input() location?: string;
  @Input() list?: ListObject[] | null;
  @Input() categoryId?: number;
  @Input() appId!: number;

  constructor(private ns: NavService) { }

  ngOnInit(): void {
  }

  get displayName(): string | undefined {
    let name = this.expenseType;

    if (!!this.list) {
      const match = this.list?.find((item: ListObject) => item.Id === this.categoryId);
      name = !!match ? translate(match.Name) : '';
    }

    return name;
  }

  navigate(): void {
    this.ns.goToRoute([APPLICATIONS_ROUTE, this.appId, this.location]);
  }

}
