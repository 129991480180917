import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCacheService } from '../services/request-cache.service';

const TTL = 18000;

@Injectable({
  providedIn: 'root'
})
export class CachingInterceptorService implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    /**
     * Continue if not cacheable.
     */ 
    if (!this.isCacheable(req)) { 
      return next.handle(req); 
    }

    const cachedResponse = this.cache.get(req.urlWithParams);
    return cachedResponse ?
      of(cachedResponse) : this.sendRequest(req, next);
  }

  isCacheable(req: HttpRequest<any>): boolean {
    const isGet = req.method === 'GET';
    const isList = req.url.includes('/lists/') || req.url.includes('hardshipreasons');
    const isServicerLender = req.url.includes('servicers') || req.url.includes('lenders');
    
    return (isGet && isList) || isServicerLender;
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: any) => {
      if (event instanceof HttpResponse) {
        this.cache.set(req.urlWithParams, event, TTL);
      }
    }));
  }

}
