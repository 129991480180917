import { DatePipe } from "@angular/common";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Constants } from "src/app/core/constants";
import { FeatureToggles } from "src/app/core/interfaces/toggles.interface";
import { EligibleExpense, EligibleExpenseFields, EmploymentData, ExpenseObject, FinancialData, FormStatus, HomeownerApplicationApiData, MortgageData, MortgageFormFields, NotEligibleData, PersonalData, PrequalificationData, ProfileFormData, PropertyData } from "src/app/homeowner-application/interfaces";
import { EmailMustHaveDot, ListObject, nonNegativeRegex, phoneRegex, PropertyIntention, PropertyOccupied, PropertyType, SSNRegex, YesNo } from "src/app/shared/interfaces";
import { selectedRacesFormData} from "./app-data-selector.functions";

const DATE_FORMAT = Constants.DATE_FORMAT_YEAR_MONTH_DAY;

/**
 * Generic conditions
 */
export function isNo(val?: string): boolean {
  return val === YesNo.No;
}

export function isValid(val?: string): boolean {
  return val === FormStatus.Valid;
}

export function isYes(val?: string): boolean {
  return val === YesNo.Yes;
}

/**
 * Return either a ListObject if the value is truthy or null if falsy
 * 
 * @param value - question's value in state
 * @param isYesNo - boolean if the question is a YesNo
 * @returns a ListObject or null
 */
export function getObjectOrNull(stateValue: any, isYesNo: boolean = false): ListObject | null {
  let response = null;
  let value = stateValue;
  if (isYesNo) {
    value = !!stateValue && stateValue !== 2 ? 1 : 2;
  }
  if ((typeof value !== 'number' && !!value) || (typeof value === 'number' && value > -1)) {
    response = { Id: value, Name: '', IsActive: true, SortOrder: -1 };
  }
  return response;
}

export function getObjectListOrNull(values: number[] = []): ListObject[] | null {
  let response: any = []

  values.forEach(value => {
    response.push({ Id: value, Name: '', IsActive: true, SortOrder: -1});
  });

  if (!response || response.length === 0) {
    response = null;
  }

  return response;
}

/**
 * Personal Info conditions
 */
 export function hasSpouse(val?: number): boolean {
  const defaultNum = -1;
  const spouseOptions = [1,5,6];
  return spouseOptions.includes(val || defaultNum);
}

/**
 * Display values based on type
 */
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatCurrency(val: number, round: boolean = false): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: round ? 0 : undefined
  });
  return formatter.format(val);
}

export function formatDate(val: string, format: string = Constants.DATE_FORMAT_MONTH_DAY_YEAR): string {
  const datePipe: DatePipe = new DatePipe('en-US');
  const formattedDate = datePipe.transform(val, format);
  return formattedDate || '';
}

export function formatPhone(val: string): string {
  var cleaned = ('' + val).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
}

export function formatSsn(val: string): string {
  let displayVal = '';
  if (!!val) {
    val = val.replace(new RegExp('-', 'g'), '');
    displayVal = `***-**-${val.substr(val.length-4, val.length)}`;
  }
  return displayVal;
}

export function getEmploymentDataFromForm(formValue: any): EmploymentData {
  return { ...{},
    IsBorrowerEligibleForUnemployment: formValue.EmploymentInfo.IsBorrowerEligibleForUnemployment,
    IsCoBorrowerEligibleForUnemployment: formValue.EmploymentInfo.IsCoBorrowerEligibleForUnemployment,
    IsSpouseEligibleForUnemployemnt: formValue.EmploymentInfo.IsSpouseEligibleForUnemployemnt,
    BorrowerBenefitStartDate: formValue.BorrowerUnemploymentInfo.BorrowerBenefitStartDate,
    BorrowerBenefitAmountMonthly: formValue.BorrowerUnemploymentInfo.BorrowerBenefitAmountMonthly,
    BorrowerBenefitProjectedEndDate: formValue.BorrowerUnemploymentInfo.BorrowerBenefitProjectedEndDate,
    CoBorrowerBenefitStartDate: formValue.CoBorrowerUnemploymentInfo.CoBorrowerBenefitStartDate,
    CoBorrowerBenefitAmountMonthly: formValue.CoBorrowerUnemploymentInfo.CoBorrowerBenefitAmountMonthly,
    CoBorrowerBenefitProjectedEndDate: formValue.CoBorrowerUnemploymentInfo.CoBorrowerBenefitProjectedEndDate,
    SpouseBenefitStartDate: formValue.SpouseUnemploymentInfo.SpouseBenefitStartDate,
    SpouseBenefitAmountMonthly: formValue.SpouseUnemploymentInfo.SpouseBenefitAmountMonthly,
    SpouseBenefitProjectedEndDate: formValue.SpouseUnemploymentInfo.SpouseBenefitProjectedEndDate,
    IsBorrowerCurrentlyEmployed: getObjectOrNull(formValue.BorrowerEmploymentInfo.IsBorrowerCurrentlyEmployed),
    BorrowerEmployerName: formValue.BorrowerEmploymentInfo.BorrowerEmployerName,
    BorrowerEmployerAddress: formValue.BorrowerEmploymentInfo.BorrowerEmployerAddress,
    BorrowerEmployerCity: formValue.BorrowerEmploymentInfo.BorrowerEmployerCity,
    BorrowerEmployerState: getObjectOrNull(formValue.BorrowerEmploymentInfo.BorrowerEmployerState),
    BorrowerEmployerCounty: getObjectOrNull(formValue.BorrowerEmploymentInfo.BorrowerEmployerCounty),
    BorrowerEmployerZipCode: formValue.BorrowerEmploymentInfo.BorrowerEmployerZipCode,
    BorrowerEmployerPhone: formValue.BorrowerEmploymentInfo.BorrowerEmployerPhone,
    BorrowerHowLongWithEmployer: getObjectOrNull(formValue.BorrowerEmploymentInfo.BorrowerHowLongWithEmployer),
    BorrowerSelfEmployedCompanyName: formValue.BorrowerEmploymentInfo.BorrowerSelfEmployedCompanyName,
    IsCoBorrowerCurrentlyEmployed: getObjectOrNull(formValue.CoBorrowerEmploymentInfo.IsCoBorrowerCurrentlyEmployed),
    CoBorrowerEmployerName: formValue.CoBorrowerEmploymentInfo.CoBorrowerEmployerName,
    CoBorrowerEmployerAddress: formValue.CoBorrowerEmploymentInfo.CoBorrowerEmployerAddress,
    CoBorrowerEmployerCity: formValue.CoBorrowerEmploymentInfo.CoBorrowerEmployerCity,
    CoBorrowerEmployerState: getObjectOrNull(formValue.CoBorrowerEmploymentInfo.CoBorrowerEmployerState),
    CoBorrowerEmployerCounty: getObjectOrNull(formValue.CoBorrowerEmploymentInfo.CoBorrowerEmployerCounty),
    CoBorrowerEmployerZipCode: formValue.CoBorrowerEmploymentInfo.CoBorrowerEmployerZipCode,
    CoBorrowerEmployerPhone: formValue.CoBorrowerEmploymentInfo.CoBorrowerEmployerPhone,
    CoBorrowerHowLongWithEmployer: getObjectOrNull(formValue.CoBorrowerEmploymentInfo.CoBorrowerHowLongWithEmployer),
    CoBorrowerSelfEmployedCompanyName: formValue.CoBorrowerEmploymentInfo.CoBorrowerSelfEmployedCompanyName
  };
}

export function getExpenseFormGroup(expense: EligibleExpense): FormGroup {
  const expenseValidators = !!expense.Category?.Id ? Validators.required : null;
  const expenseNumericValidators = !!expense.Category?.Id ? [Validators.pattern(nonNegativeRegex), Validators.required] : null;
  const provider = expense.IsOtherProvider ? -1 : expense.Provider?.Id;
  return new FormGroup({
    AccountNumber: new FormControl(expense.AccountNumber, expenseValidators),
    Category: new FormControl(expense.Category?.Id, Validators.required),
    Category_IsActive: new FormControl(expense.Category?.IsActive),
    ExpenseType: new FormControl(expense.ExpenseType?.Id, Validators.required),
    Payment: new FormControl(expense.Payment, { updateOn: 'blur', validators: expenseNumericValidators }),
    PaymentDueDate: new FormControl(expense.PaymentDueDate?.Id, expenseValidators),
    PaymentPastDue: new FormControl(expense.PaymentPastDue, { updateOn: 'blur', validators: expenseNumericValidators }),
    Provider: new FormControl(provider, expenseValidators),
    IsOtherProvider: new FormControl(expense.IsOtherProvider),
    OtherProviderName: new FormControl(expense.OtherProviderName),
    ProviderPhoneNumber: new FormControl(expense.ProviderPhoneNumber, { updateOn: 'blur', validators: expenseValidators }),
    WhoPays: new FormControl(expense.WhoPays?.Id, expenseValidators)
  });
}

export function getFinancialDataFromForm(formValue: any): FinancialData {
  const financialData: FinancialData = {
    IncomeSources_BorrowerMonthly: [],
    IncomeSources_CoBorrowerMonthly: [],
    IncomeSources_SpouseMonthly: [],
    Expenses_Other: [],
    Bankruptcy_WasFiled: formValue.Bankruptcy.Bankruptcy_WasFiled,
    Bankruptcy_Type: getObjectOrNull(formValue.Bankruptcy.Bankruptcy_Type),
    Bankruptcy_IsDischarged: getObjectOrNull(formValue.Bankruptcy.Bankruptcy_IsDischarged, true),
    Bankruptcy_CaseNumber: formValue.Bankruptcy.Bankruptcy_CaseNumber,
    Bankruptcy_FilingDate: formValue.Bankruptcy.Bankruptcy_FilingDate
  };

  const borrowerIncomes = formValue.Incomes.IncomeSources_BorrowerMonthly;
  const coborrowerIncomes = formValue.Incomes.IncomeSources_CoBorrowerMonthly;
  const spouseIncomes = formValue.Incomes.IncomeSources_SpouseMonthly;
  if (!!borrowerIncomes && borrowerIncomes.length > 0) {
    borrowerIncomes.forEach((income: any) => {
      financialData.IncomeSources_BorrowerMonthly.push({
        Source: {
          Id: income.Source,
          Name: '',
          IsActive: true,
          SortOrder: -1
        },
        Gross: income.Gross
      });
    });
  }
  if (!!coborrowerIncomes && coborrowerIncomes.length === 0) {
    financialData.IncomeSources_CoBorrowerMonthly.push({
      Source: {
        Id: -1,
        Name: '',
        IsActive: true,
        SortOrder: -1
      },
      Gross: 0
    });
  } else if (!!coborrowerIncomes && coborrowerIncomes.length > 0) {
    coborrowerIncomes.forEach((income: any) => {
      financialData.IncomeSources_CoBorrowerMonthly.push({
        Source: {
          Id: income.Source,
          Name: '',
          IsActive: true,
          SortOrder: -1
        },
        Gross: income.Gross
      });
    });
  }
  if (!!spouseIncomes && spouseIncomes.length === 0) {
    financialData.IncomeSources_SpouseMonthly.push({
      Source: {
        Id: -1,
        Name: '',
        IsActive: true,
        SortOrder: -1
      },
      Gross: 0
    });
  } else if (!!spouseIncomes && spouseIncomes.length > 0) {
    spouseIncomes.forEach((income: any) => {
      financialData.IncomeSources_SpouseMonthly.push({
        Source: {
          Id: income.Source,
          Name: '',
          IsActive: true,
          SortOrder: -1
        },
        Gross: income.Gross
      });
    });
  }

  const otherExpense = formValue.Expenses.Expenses_Other;
  if (!!otherExpense && otherExpense.length > 0) {
    otherExpense.forEach((expense: ExpenseObject) => {
      financialData.Expenses_Other.push({
        Description: expense.Description,
        Amount: expense.Amount
      })
    });
  }

  return financialData;
}

export function getMortgageDataFromForm(formValue: any): MortgageData {
  const mortgageData: MortgageData = {
    Mortgages: []
  };

  /**
   * Only include mortgages that have a Servicer
   */
  const mortgages = !!formValue.Mortgages ? formValue.Mortgages.filter((mortgage: any) => typeof mortgage.Servicer === 'number') : null;
  if (!!mortgages && mortgages.length > 0) {
    mortgages.forEach((mortgage: MortgageFormFields) => {
      mortgageData.Mortgages.push({
        IsOtherLender: mortgage.IsOtherLender,
        IsOtherServicer: mortgage.IsOtherServicer,
        OtherLenderName: mortgage.OtherLenderName,
        OtherServicerName: mortgage.OtherServicerName,
        Servicer: getObjectOrNull(mortgage.Servicer),
        Lender: getObjectOrNull(mortgage.Lender),
        ServicerPhoneNumber: mortgage.ServicerPhoneNumber,
        LoanNumber: mortgage.LoanNumber,
        Balance: mortgage.Balance,
        DelinquencyStatus: getObjectOrNull(mortgage.DelinquencyStatus),
        Payment: mortgage.Payment,
        PaymentDueDate: getObjectOrNull(mortgage.PaymentDueDate),
        InterestRate: mortgage.InterestRate,
        LoanType: getObjectOrNull(mortgage.LoanType),
        LoanProgram: getObjectOrNull(mortgage.LoanProgram || null),
        AdjustmentDueDate: mortgage.AdjustmentDueDate,
        ForeclosureReceived: getObjectOrNull(mortgage.ForeclosureReceived, true),
        ForeclosureReceivedDate: mortgage.ForeclosureReceivedDate,
        IsInForbearance: getObjectOrNull(mortgage.IsInForbearance, true),
        ForbearanceEndDate: mortgage.ForbearanceEndDate,
        ForbearanceDetails: mortgage.ForbearanceDetails,
        ForeclosureSaleScheduled: getObjectOrNull(mortgage.ForeclosureSaleScheduled, true),
        ForeclosureSaleDate: mortgage.ForeclosureSaleDate
      });
    });
  }
  return mortgageData;
}

export function getNotEligibleDataFromForm(formValue: any): NotEligibleData {
  return {
    IsPermissionGivenForContactByHUD: formValue.IsPermissionGivenForContactByHUD,
    IsRequestGivenForEmailOfResults: formValue.IsRequestGivenForEmailOfResults
  }
}

export function getPersonalDataFromForm(formValue: any, toggles: FeatureToggles, races: ListObject[]): PersonalData {
  const borrowerRaceArray = formValue.Government.BorrowerRaces || [];
  const coborrowerRaceArray = formValue.Government.CoBorrowerRaces || [];
  const spouseRaceArray = formValue.Government.SpouseRaces || [];

  return {
    CoBorrowerIsVeteran: getObjectOrNull(formValue.CoBorrower.CoBorrowerIsVeteran, true),
    BorrowerIsVeteran: getObjectOrNull(formValue.Borrower.BorrowerIsVeteran, true),
    SpouseIsVeteran: getObjectOrNull(formValue.Spouse.SpouseIsVeteran, true),
    CoBorrowerPrimaryPhoneBestTimeToCall: getObjectOrNull(formValue.CoBorrower.CoBorrowerPrimaryPhoneBestTimeToCall),
    CoBorrowerSecondaryPhoneBestTimeToCall: getObjectOrNull(formValue.CoBorrower.CoBorrowerSecondaryPhoneBestTimeToCall),
    CoBorrowerEmail: formValue.CoBorrower.CoBorrowerEmail,
    CoBorrowerPrimaryPhoneNumber: formValue.CoBorrower.CoBorrowerPrimaryPhoneNumber,
    CoBorrowerPrimaryPhoneType: getObjectOrNull(formValue.CoBorrower.CoBorrowerPrimaryPhoneType),
    CoBorrowerSecondaryPhoneNumber: formValue.CoBorrower.CoBorrowerSecondaryPhoneNumber,
    CoBorrowerSecondaryPhoneType: getObjectOrNull(formValue.CoBorrower.CoBorrowerSecondaryPhoneType),
    CoBorrowerDateOfBirth: formValue.CoBorrower.CoBorrowerDateOfBirth,
    CoBorrowerFirstName: formValue.CoBorrower.CoBorrowerFirstName,
    CoBorrowerLastName: formValue.CoBorrower.CoBorrowerLastName,
    CoBorrowerMiddleName: formValue.CoBorrower.CoBorrowerMiddleName,
    CoBorrowerSSN: formValue.CoBorrower.CoBorrowerSSN || null,
    CoBorrowerAddress1: formValue.CoBorrower.CoBorrowerAddress1,
    CoBorrowerAddress2: formValue.CoBorrower.CoBorrowerAddress2,
    CoBorrowerCity: formValue.CoBorrower.CoBorrowerCity,
    CoBorrowerCounty: getObjectOrNull(formValue.CoBorrower.CoBorrowerCounty),
    CoBorrowerState: getObjectOrNull(formValue.CoBorrower.CoBorrowerState),
    CoBorrowerZipCode: formValue.CoBorrower.CoBorrowerZipCode,
    BorrowerEmail: formValue.Borrower.BorrowerEmail,
    BorrowerPrimaryPhoneBestTimeToCall: getObjectOrNull(formValue.Borrower.BorrowerPrimaryPhoneBestTimeToCall),
    BorrowerSecondaryPhoneBestTimeToCall: getObjectOrNull(formValue.Borrower.BorrowerSecondaryPhoneBestTimeToCall),
    BorrowerPrimaryPhoneNumber: formValue.Borrower.BorrowerPrimaryPhoneNumber,
    BorrowerPrimaryPhoneType: getObjectOrNull(formValue.Borrower.BorrowerPrimaryPhoneType),
    BorrowerSecondaryPhoneNumber: formValue.Borrower.BorrowerSecondaryPhoneNumber,
    BorrowerSecondaryPhoneType: getObjectOrNull(formValue.Borrower.BorrowerSecondaryPhoneType),
    BorrowerDateOfBirth: formValue.Borrower.BorrowerDateOfBirth,
    BorrowerFirstName: formValue.Borrower.BorrowerFirstName,
    IsThereACoBorrower: getObjectOrNull(formValue.Borrower.IsThereACoBorrower, true),
    IsCoBorrowerSpouse: getObjectOrNull(formValue.Borrower.IsCoBorrowerSpouse, true),
    IsThereASpouse: getObjectOrNull(formValue.Borrower.IsThereASpouse, true),
    BorrowerLastName: formValue.Borrower.BorrowerLastName,
    MaritalStatus: getObjectOrNull(formValue.Borrower.MaritalStatus),
    BorrowerMiddleName: formValue.Borrower.BorrowerMiddleName,
    BorrowerSSN: formValue.Borrower.BorrowerSSN || null,
    BorrowerAddress1: formValue.Borrower.BorrowerAddress1,
    BorrowerAddress2: formValue.Borrower.BorrowerAddress2,
    BorrowerCity: formValue.Borrower.BorrowerCity,
    BorrowerCounty: getObjectOrNull(formValue.Borrower.BorrowerCounty),
    BorrowerState: getObjectOrNull(formValue.Borrower.BorrowerState),
    BorrowerZipCode: formValue.Borrower.BorrowerZipCode,
    HardshipReason: getObjectOrNull(formValue.Unemployment.HardshipReason),
    HardshipStatement: formValue.Unemployment.HardshipStatement,
    SpouseFirstName: formValue.Spouse.SpouseFirstName,
    SpouseMiddleName: formValue.Spouse.SpouseMiddleName,
    SpouseLastName: formValue.Spouse.SpouseLastName,
    SpouseSSN: formValue.Spouse.SpouseSSN || null,
    SpouseDateOfBirth: formValue.Spouse.SpouseDateOfBirth,
    SpouseAddress1: formValue.Spouse.SpouseAddress1,
    SpouseAddress2: formValue.Spouse.SpouseAddress2,
    SpouseCity: formValue.Spouse.SpouseCity,
    SpouseCounty: getObjectOrNull(formValue.Spouse.SpouseCounty),
    SpouseState: getObjectOrNull(formValue.Spouse.SpouseState),
    SpouseZipCode: formValue.Spouse.SpouseZipCode,
    SpousePrimaryPhoneNumber: formValue.Spouse.SpousePrimaryPhoneNumber,
    SpousePrimaryPhoneType: getObjectOrNull(formValue.Spouse.SpousePrimaryPhoneType),
    SpousePrimaryPhoneBestTimeToCall: getObjectOrNull(formValue.Spouse.SpousePrimaryPhoneBestTimeToCall),
    SpouseEmail: formValue.Spouse.SpouseEmail,
    SpouseSecondaryPhoneNumber: formValue.Spouse.SpouseSecondaryPhoneNumber,
    SpouseSecondaryPhoneType: getObjectOrNull(formValue.Spouse.SpouseSecondaryPhoneType),
    SpouseSecondaryPhoneBestTimeToCall: getObjectOrNull(formValue.Spouse.SpouseSecondaryPhoneBestTimeToCall),
    HardshipOther: formValue.Borrower.HardshipOther,
    Property_Address1: formValue.Borrower.Property_Address1,
    Property_Address2: formValue.Borrower.Property_Address2,
    Property_City: formValue.Borrower.Property_City,
    Property_County: getObjectOrNull(formValue.Borrower.Property_County),
    Property_State: getObjectOrNull(formValue.Borrower.Property_State),
    Property_ZipCode: formValue.Borrower.Property_ZipCode,
    Property_TotalNumberofPersonsLivingAtThisAddress: formValue.Borrower.Property_TotalNumberofPersonsLivingAtThisAddress,
    Property_NumberOfDependentsAtAddress: formValue.Borrower.Property_NumberOfDependentsAtAddress,
    Property_IsSameAsMailingAddress: formValue.Borrower.Property_IsSameAsMailingAddress,
    BorrowerGender: getObjectOrNull(formValue.Government.BorrowerGender),
    BorrowerRaces: getObjectListOrNull(selectedRacesFormData(formValue.Government.BorrowerRaces, races)),
    BorrowerEthnicity: getObjectOrNull(formValue.Government.BorrowerEthnicity),
    CoBorrowerGender: getObjectOrNull(formValue.Government.CoBorrowerGender),
    CoBorrowerRaces: getObjectListOrNull(selectedRacesFormData(coborrowerRaceArray, races)),
    CoBorrowerEthnicity: getObjectOrNull(formValue.Government.CoBorrowerEthnicity),
    SpouseGender: getObjectOrNull(formValue.Government.SpouseGender),
    SpouseRaces: getObjectListOrNull(selectedRacesFormData(spouseRaceArray, races)),
    SpouseEthnicity: getObjectOrNull(formValue.Government.SpouseEthnicity),
    IsSociallyDisadvantaged: !!toggles.IsSociallyDisadvantagedEnabled ? getObjectOrNull(formValue.SociallyDisadvantaged.IsSociallyDisadvantaged) : null,
    PreferredLanguage: getObjectOrNull(formValue.Borrower.PreferredLanguage),
    IsEmailAlertsEnabled: formValue.Borrower.IsEmailAlertsEnabled
  };
}

export function getPrequalificationDataFromForm(formValue: any): PrequalificationData {
  return {
    BorrowerFirstName: formValue.Borrower.BorrowerFirstName,
    BorrowerLastName: formValue.Borrower.BorrowerLastName,
    Property_County: getObjectOrNull(formValue.Borrower.Property_County),
    Property_TotalNumberofPersonsLivingAtThisAddress: formValue.Borrower.Property_TotalNumberofPersonsLivingAtThisAddress
  };
}

export function getProfileDataFromForm(formValue: any): ProfileFormData {
  return {
    IsEmailAlertsEnabled: formValue.IsEmailAlertsEnabled
  };
}

export function getPropertyDataFromForm(formValue: any, toggles: FeatureToggles): PropertyData {
  const propertyData: PropertyData = {
    Property_LastKnownAppraisedValue: formValue.propertyInfo.Property_LastKnownAppraisedValue,
    Property_IsIntentionToKeep: formValue.propertyInfo.Property_Intention === PropertyIntention.Keep,
    Property_IsIntentionToSell: formValue.propertyInfo.Property_Intention === PropertyIntention.Sell,
    Property_IsPrimaryResidence: formValue.propertyInfo.Property_Type === PropertyType.Primary,
    Property_IsSecondaryResidence: formValue.propertyInfo.Property_Type === PropertyType.Secondary,
    Property_IsInvestment: formValue.propertyInfo.Property_Type === PropertyType.Investment,
    Property_IsOwnerOccupied: formValue.propertyInfo.Property_Occupied === PropertyOccupied.Owner,
    Property_IsRenterOccupied: formValue.propertyInfo.Property_Occupied === PropertyOccupied.Renter,
    Property_IsVacant: formValue.propertyInfo.Property_Occupied === PropertyOccupied.Vacant,
    
    Property_WhoPaysTaxes: getObjectOrNull(formValue.propertyTaxesFees.Property_WhoPaysTaxes),
    Property_AreTaxesCurrent: getObjectOrNull(formValue.propertyTaxesFees.Property_AreTaxesCurrent, true),
    Property_HasCondoOrHoaFees: getObjectOrNull(formValue.propertyTaxesFees.Property_HasCondoOrHoaFees, true),
    Property_CondoOrHoaFeesAmount: formValue.propertyTaxesFees.Property_CondoOrHoaFeesAmount,
    Property_CondoOrHoaFeesPaidTo: formValue.propertyTaxesFees.Property_CondoOrHoaFeesPaidTo,
    
    Property_WhoPaysHazardInsurance: getObjectOrNull(formValue.hazardInsurance.Property_WhoPaysHazardInsurance),
    Property_HazardInsuranceIsCurrent: getObjectOrNull(formValue.hazardInsurance.Property_HazardInsuranceIsCurrent, true),
    Property_HazardInsuranceCompanyName: formValue.hazardInsurance.Property_HazardInsuranceCompanyName,
    Property_HazardInsuranceCompanyPhone: formValue.hazardInsurance.Property_HazardInsuranceCompanyPhone,
    
    Property_HasJudgements: formValue.judgmentsProperty.Property_HasJudgements,
    Property_JudgementsDescription: formValue.judgmentsProperty.Property_JudgementsDescription,
    
    EligibleExpenses: []
  };
  
  if (toggles.IsEligibleExpensesEnabled) {
    const eligibleExpenses: EligibleExpenseFields[] = formValue.eligibleExpenses;
    if (!!eligibleExpenses && eligibleExpenses.length > 0) {
      eligibleExpenses.forEach((expense: EligibleExpenseFields) => {
        const provider = expense.Provider;
        const expenseType = expense.ExpenseType;
        /**
         * Only include eligible expense in PUT if the user selected at least 
         * a provider or an expenseType
         */
        if (typeof provider === 'number' || typeof expenseType === 'number') {
          propertyData.EligibleExpenses.push({
            AccountNumber: expense.AccountNumber,
            Category: getObjectOrNull(expense.Category),
            ExpenseType: getObjectOrNull(expense.ExpenseType),
            Payment: expense.Payment,
            PaymentDueDate: getObjectOrNull(expense.PaymentDueDate),
            PaymentPastDue: expense.PaymentPastDue,
            Provider: getObjectOrNull(expense.Provider),
            IsOtherProvider: expense.IsOtherProvider,
            OtherProviderName: expense.OtherProviderName,
            ProviderPhoneNumber: expense.ProviderPhoneNumber,
            WhoPays: getObjectOrNull(expense.WhoPays)
          });
        }
      });
    }
  }

  return propertyData;
}

/**
 * @param data current state of HomeownerApplicationApiData 
 * @returns a FormGroup including validators based on data
 */
export function stateDataToEmploymentForm(data: HomeownerApplicationApiData): FormGroup {
  const homeownerUnemploymentValidators = !!data.IsBorrowerEligibleForUnemployment ? Validators.required : null;
  const coborrowerUnemploymentValidators = !!data.IsCoBorrowerEligibleForUnemployment ? Validators.required : null;
  const spouseUnemploymentValidators = !!data.IsSpouseEligibleForUnemployemnt ? Validators.required : null;
  const homeownerEmpInfoValidators = isYes(data.IsBorrowerCurrentlyEmployed?.Name) ? Validators.required : null;
  const coborrowerEmpInfoValidators = isYes(data.IsCoBorrowerCurrentlyEmployed?.Name) ? Validators.required : null;
  const shouldValidateCoborrower = isYes(data.IsThereACoBorrower?.Name);
  const coborrowerValidators = shouldValidateCoborrower ? Validators.required : undefined;

  const form = new FormGroup({
    EmploymentInfo: new FormGroup({
      IsBorrowerEligibleForUnemployment: new FormControl(data.IsBorrowerEligibleForUnemployment),
      IsCoBorrowerEligibleForUnemployment: new FormControl(data.IsCoBorrowerEligibleForUnemployment),
      IsSpouseEligibleForUnemployemnt: new FormControl(data.IsSpouseEligibleForUnemployemnt)
    }),
    BorrowerUnemploymentInfo: new FormGroup({
      BorrowerBenefitStartDate: new FormControl(formatDate(data.BorrowerBenefitStartDate, DATE_FORMAT), { validators: homeownerUnemploymentValidators }),
      BorrowerBenefitAmountMonthly: new FormControl(data.BorrowerBenefitAmountMonthly, { validators: homeownerUnemploymentValidators }),
      BorrowerBenefitProjectedEndDate: new FormControl(formatDate(data.BorrowerBenefitProjectedEndDate, DATE_FORMAT), { validators: homeownerUnemploymentValidators })
    }),
    CoBorrowerUnemploymentInfo: new FormGroup({
      CoBorrowerBenefitStartDate: new FormControl(formatDate(data.CoBorrowerBenefitStartDate, DATE_FORMAT), { validators: coborrowerUnemploymentValidators }),
      CoBorrowerBenefitAmountMonthly: new FormControl(data.CoBorrowerBenefitAmountMonthly, { validators: coborrowerUnemploymentValidators }),
      CoBorrowerBenefitProjectedEndDate: new FormControl(formatDate(data.CoBorrowerBenefitProjectedEndDate, DATE_FORMAT), { validators: coborrowerUnemploymentValidators })
    }),
    SpouseUnemploymentInfo: new FormGroup({
      SpouseBenefitStartDate: new FormControl(formatDate(data.SpouseBenefitStartDate, DATE_FORMAT), { validators: spouseUnemploymentValidators }),
      SpouseBenefitAmountMonthly: new FormControl(data.SpouseBenefitAmountMonthly, { validators: spouseUnemploymentValidators }),
      SpouseBenefitProjectedEndDate: new FormControl(formatDate(data.SpouseBenefitProjectedEndDate, DATE_FORMAT), { validators: spouseUnemploymentValidators })
    }),
    BorrowerEmploymentInfo: new FormGroup({
      IsBorrowerCurrentlyEmployed: new FormControl(data.IsBorrowerCurrentlyEmployed?.Id, { validators: Validators.required }),
      BorrowerEmployerName: new FormControl(data.BorrowerEmployerName, { validators: homeownerEmpInfoValidators }),
      BorrowerEmployerAddress: new FormControl(data.BorrowerEmployerAddress, { validators: homeownerEmpInfoValidators }),
      BorrowerEmployerCity: new FormControl(data.BorrowerEmployerCity, { validators: homeownerEmpInfoValidators }),
      BorrowerEmployerState: new FormControl(data.BorrowerEmployerState?.Id, { validators: homeownerEmpInfoValidators }),
      BorrowerEmployerCounty: new FormControl(data.BorrowerEmployerCounty?.Id),
      BorrowerEmployerZipCode: new FormControl(data.BorrowerEmployerZipCode, { validators: homeownerEmpInfoValidators }),
      BorrowerEmployerPhone: new FormControl(data.BorrowerEmployerPhone, { validators: homeownerEmpInfoValidators }),
      BorrowerHowLongWithEmployer: new FormControl(data.BorrowerHowLongWithEmployer?.Id, { validators: homeownerEmpInfoValidators }),
      BorrowerSelfEmployedCompanyName: new FormControl(data.BorrowerSelfEmployedCompanyName)
    }),
    CoBorrowerEmploymentInfo: new FormGroup({
      IsCoBorrowerCurrentlyEmployed: new FormControl(data.IsCoBorrowerCurrentlyEmployed?.Id, { validators: coborrowerValidators }),
      CoBorrowerEmployerName: new FormControl(data.CoBorrowerEmployerName, { validators: coborrowerEmpInfoValidators }),
      CoBorrowerEmployerAddress: new FormControl(data.CoBorrowerEmployerAddress, { validators: coborrowerEmpInfoValidators }),
      CoBorrowerEmployerCity: new FormControl(data.CoBorrowerEmployerCity, { validators: coborrowerEmpInfoValidators }),
      CoBorrowerEmployerState: new FormControl(data.CoBorrowerEmployerState?.Id, { validators: coborrowerEmpInfoValidators }),
      CoBorrowerEmployerCounty: new FormControl(data.CoBorrowerEmployerCounty?.Id),
      CoBorrowerEmployerZipCode: new FormControl(data.CoBorrowerEmployerZipCode, { validators: coborrowerEmpInfoValidators }),
      CoBorrowerEmployerPhone: new FormControl(data.CoBorrowerEmployerPhone, { validators: coborrowerEmpInfoValidators }),
      CoBorrowerHowLongWithEmployer: new FormControl(data.CoBorrowerHowLongWithEmployer?.Id, { validators: coborrowerEmpInfoValidators }),
      CoBorrowerSelfEmployedCompanyName: new FormControl(data.CoBorrowerSelfEmployedCompanyName)
    })
  });
  return form;
}

export function stateDataToFinancialForm(data: HomeownerApplicationApiData): FormGroup {
  const isCoborrowerSpouse = isYes(data.IsCoBorrowerSpouse?.Name);
  const shouldValidateCoborrower = isYes(data.IsThereACoBorrower?.Name);
  const shouldValidateSpouse = hasSpouse(data.MaritalStatus?.Id) && !isCoborrowerSpouse;
  const coborrowerValidators = shouldValidateCoborrower ? Validators.required : undefined;
  const spouseValidators = shouldValidateSpouse ? Validators.required : undefined;
  const bankruptcyValidators = !!data.Bankruptcy_WasFiled ? Validators.required : undefined;
  
  const financialFormGroup = new FormGroup({
    Incomes: new FormGroup({
      IncomeSources_BorrowerMonthly: new FormArray([]),
      IncomeSources_CoBorrowerMonthly: new FormArray([]),
      IncomeSources_SpouseMonthly: new FormArray([])
    }),
    Expenses: new FormGroup({
      Expenses_Other: new FormArray([])
    }),
    Bankruptcy: new FormGroup({
      Bankruptcy_WasFiled: new FormControl(!!data.Bankruptcy_WasFiled, { validators: Validators.required }),
      Bankruptcy_Type: new FormControl(data.Bankruptcy_Type?.Id, { validators: bankruptcyValidators }),
      Bankruptcy_IsDischarged: new FormControl(isYes(data.Bankruptcy_IsDischarged?.Name), { validators: bankruptcyValidators }),
      Bankruptcy_CaseNumber: new FormControl(data.Bankruptcy_CaseNumber, { updateOn: 'blur', validators: bankruptcyValidators }),
      Bankruptcy_FilingDate: new FormControl(formatDate(data.Bankruptcy_FilingDate, DATE_FORMAT), { validators: bankruptcyValidators })
    })
  });

  if (!!data.IncomeSources_BorrowerMonthly && data.IncomeSources_BorrowerMonthly.length > 0) {
    data.IncomeSources_BorrowerMonthly.forEach(group => {
      (financialFormGroup.get('Incomes.IncomeSources_BorrowerMonthly') as FormArray).push(new FormGroup({
        Source: new FormControl(group.Source?.Id, [Validators.required]),
        Gross: new FormControl(group.Gross, [Validators.required, Validators.pattern(nonNegativeRegex)])
      }));
    });
  } else if (!!data.IncomeSources_BorrowerMonthly && data.IncomeSources_BorrowerMonthly.length === 0) {
    (financialFormGroup.get('Incomes.IncomeSources_BorrowerMonthly') as FormArray).push(new FormGroup({
      Source: new FormControl('', [Validators.required]),
      Gross: new FormControl('', [Validators.required, Validators.pattern(nonNegativeRegex)])
    }));
  }
  if (!!data.IncomeSources_CoBorrowerMonthly && data.IncomeSources_CoBorrowerMonthly.length > 0) {
    data.IncomeSources_CoBorrowerMonthly.forEach(group => {
      (financialFormGroup.get('Incomes.IncomeSources_CoBorrowerMonthly') as FormArray).push(new FormGroup({
        Source: new FormControl(group.Source?.Id, coborrowerValidators),
        Gross: new FormControl(group.Gross, coborrowerValidators)
      }));
    });
  } else if (!!data.IncomeSources_CoBorrowerMonthly && data.IncomeSources_CoBorrowerMonthly.length === 0) {
    (financialFormGroup.get('Incomes.IncomeSources_CoBorrowerMonthly') as FormArray).push(new FormGroup({
      Source: new FormControl('', coborrowerValidators),
      Gross: new FormControl('', coborrowerValidators)
    }));
  }
  if (!!data.IncomeSources_SpouseMonthly && data.IncomeSources_SpouseMonthly.length > 0) {
    data.IncomeSources_SpouseMonthly.forEach(group => {
      (financialFormGroup.get('Incomes.IncomeSources_SpouseMonthly') as FormArray).push(new FormGroup({
        Source: new FormControl(group.Source?.Id, spouseValidators),
        Gross: new FormControl(group.Gross, spouseValidators)
      }));
    });
  } else if (!!data.IncomeSources_SpouseMonthly && data.IncomeSources_SpouseMonthly.length === 0) {
    (financialFormGroup.get('Incomes.IncomeSources_SpouseMonthly') as FormArray).push(new FormGroup({
      Source: new FormControl('', spouseValidators),
      Gross: new FormControl('', spouseValidators)
    }));
  }
  if (!!data.Expenses_Other && data.Expenses_Other.length > 0) {
    data.Expenses_Other.forEach(group => {
      (financialFormGroup.get('Expenses.Expenses_Other') as FormArray).push(new FormGroup({
        Description: new FormControl(group.Description, [Validators.required]),
        Amount: new FormControl(group.Amount, [Validators.required, Validators.pattern(nonNegativeRegex)])
      }));
    });
  }
  return financialFormGroup;
}

export function stateDataToMortgageForm(data: HomeownerApplicationApiData, toggles: FeatureToggles, displayMortgageLender: boolean): FormGroup {
  const mortgageFormGroup = new FormGroup({
    Mortgages: new FormArray([])
  });
  const requireMortgageData = toggles.RequireMortgageData;

  if (!!data.Mortgages && data.Mortgages.length > 0) {
    data.Mortgages.forEach(mortgage => {
      const hasServicer = !!mortgage.Servicer?.Id && mortgage.Servicer?.Id > -1;
      const mortgageValidators = hasServicer ? Validators.required : null;
      const mortgageNonNegValidators = hasServicer ? [Validators.required, Validators.pattern(nonNegativeRegex)] : Validators.pattern(nonNegativeRegex);
      const otherLenderValidators = mortgage.IsOtherLender && displayMortgageLender ? Validators.required : null;
      const otherServicerValidators = mortgage.IsOtherServicer ? Validators.required : null;
      const foreclosureValidators = isYes(mortgage.ForeclosureReceived?.Name) ? Validators.required : null;
      (mortgageFormGroup.get('Mortgages') as FormArray).push(new FormGroup({
        IsOtherLender: new FormControl(mortgage.IsOtherLender),
        OtherLenderName: new FormControl(mortgage.OtherLenderName, { updateOn: 'blur', validators: otherLenderValidators }),
        IsOtherServicer: new FormControl(mortgage.IsOtherServicer),
        OtherServicerName: new FormControl(mortgage.OtherServicerName, { updateOn: 'blur', validators: otherServicerValidators }),
        Servicer: new FormControl(mortgage.IsOtherServicer ? -1 : mortgage.Servicer?.Id, { validators: requireMortgageData ? Validators.required : null }),
        Lender: new FormControl(mortgage.IsOtherLender ? -1 : mortgage.Lender?.Id),
        ServicerPhoneNumber: new FormControl(mortgage.ServicerPhoneNumber, { updateOn: 'blur', validators: mortgageValidators }),
        LoanNumber: new FormControl(mortgage.LoanNumber, { updateOn: 'blur', validators: mortgageValidators }),
        Balance: new FormControl(mortgage.Balance, { updateOn: 'blur', validators: mortgageNonNegValidators }),
        DelinquencyStatus: new FormControl(mortgage.DelinquencyStatus?.Id, { validators: mortgageValidators }),
        Payment: new FormControl(mortgage.Payment, { updateOn: 'blur', validators: mortgageNonNegValidators }),
        PaymentDueDate: new FormControl(mortgage.PaymentDueDate?.Id, { validators: mortgageValidators }),
        InterestRate: new FormControl(mortgage.InterestRate, { updateOn: 'blur', validators: Validators.pattern(nonNegativeRegex) }),
        LoanType: new FormControl(mortgage.LoanType?.Id, { validators: mortgageValidators }),
        LoanProgram: new FormControl(mortgage.LoanProgram?.Id, { updateOn: 'blur' }),
        AdjustmentDueDate: new FormControl(formatDate(mortgage.AdjustmentDueDate, DATE_FORMAT), { updateOn: 'blur' }),
        ForeclosureReceived: new FormControl(isYes(mortgage.ForeclosureReceived?.Name)),
        ForeclosureReceivedDate: new FormControl(formatDate(mortgage.ForeclosureReceivedDate!, DATE_FORMAT), { updateOn: 'blur', validators: foreclosureValidators}),
        IsInForbearance: new FormControl(mortgage.IsInForbearance?.Id),
        ForbearanceEndDate: new FormControl(formatDate(mortgage.ForbearanceEndDate, DATE_FORMAT), { updateOn: 'blur' }),
        ForbearanceDetails: new FormControl(mortgage.ForbearanceDetails),
        ForeclosureSaleScheduled: new FormControl(isYes(mortgage.ForeclosureSaleScheduled?.Name)),
        ForeclosureSaleDate: new FormControl(formatDate(mortgage.ForeclosureSaleDate!, DATE_FORMAT), { updateOn: 'blur' }),
      }));
    });
  } else if (!!data.Mortgages && data.Mortgages.length === 0) {
    (mortgageFormGroup.get('Mortgages') as FormArray).push(new FormGroup({
      IsOtherLender: new FormControl(false),
      OtherLenderName: new FormControl('', { updateOn: 'blur' }),
      IsOtherServicer: new FormControl(false),
      OtherServicerName: new FormControl('', { updateOn: 'blur' }),
      Servicer: new FormControl('', { validators: requireMortgageData ? Validators.required: null }),
      Lender: new FormControl(''),
      ServicerPhoneNumber: new FormControl('', { updateOn: 'blur' }),
      LoanNumber: new FormControl('', { updateOn: 'blur' }),
      Balance: new FormControl('', { updateOn: 'blur', validators: Validators.pattern(nonNegativeRegex) }),
      DelinquencyStatus: new FormControl(''),
      Payment: new FormControl('', { updateOn: 'blur', validators: Validators.pattern(nonNegativeRegex) }),
      PaymentDueDate: new FormControl(''),
      InterestRate: new FormControl('', { updateOn: 'blur', validators: Validators.pattern(nonNegativeRegex) }),
      LoanType: new FormControl(''),
      LoanProgram: new FormControl('', { updateOn: 'blur' }),
      AdjustmentDueDate: new FormControl('', { updateOn: 'blur' }),
      ForeclosureReceived: new FormControl(''),
      ForeclosureReceivedDate: new FormControl('',{ updateOn: 'blur'}),
      IsInForbearance: new FormControl(''),
      ForbearanceEndDate: new FormControl('', { updateOn: 'blur' }),
      ForbearanceDetails: new FormControl(''),
      ForeclosureSaleScheduled: new FormControl(''),
      ForeclosureSaleDate: new FormControl('',{ updateOn: 'blur'}),
    }));
  }
  return mortgageFormGroup;
}

export function stateDataToNotEligibleForm(data: HomeownerApplicationApiData): FormGroup {
  return new FormGroup({
    IsPermissionGivenForContactByHUD: new FormControl(data.IsPermissionGivenForContactByHUD),
    IsRequestGivenForEmailOfResults: new FormControl(data.IsRequestGivenForEmailOfResults)
  });
}

export function stateDataToPersonalForm(data: HomeownerApplicationApiData): FormGroup {
  const isCoborrowerSpouse = isYes(data.IsCoBorrowerSpouse?.Name);
  const shouldValidateCoborrower = isYes(data.IsThereACoBorrower?.Name);
  const shouldValidateSpouse = hasSpouse(data.MaritalStatus?.Id) && !isCoborrowerSpouse;
  const ssnValidators = [Validators.required, Validators.pattern(SSNRegex)];
  const coborrowerValidators = shouldValidateCoborrower ? Validators.required : undefined;
  const coborrowerEmailValidators = shouldValidateCoborrower ? [Validators.pattern(EmailMustHaveDot), Validators.required] : undefined;
  const coborrowerSSNValidators = shouldValidateCoborrower ? ssnValidators : undefined;
  const spouseValidators = shouldValidateSpouse ? Validators.required : undefined;
  const spouseEmailValidators = shouldValidateSpouse ? [Validators.pattern(EmailMustHaveDot), Validators.required] : undefined;
  const spouseSSNValidators = shouldValidateSpouse ? ssnValidators : undefined;
  const borrowerRaces: number[] = !!data.BorrowerRaces ? data.BorrowerRaces.map(race => race.Id) : [];
  const coborrowerRaces: number[] = !!data.CoBorrowerRaces ? data.CoBorrowerRaces.map(race => race.Id) : [];
  const spouseRaces: number[] = !!data.SpouseRaces ? data.SpouseRaces.map(race => race.Id) : [];
  const form = new FormGroup({
    Borrower: new FormGroup({
      BorrowerFirstName: new FormControl(data.BorrowerFirstName, { updateOn: 'blur', validators: Validators.required }),
      BorrowerMiddleName: new FormControl(data.BorrowerMiddleName),
      BorrowerLastName: new FormControl(data.BorrowerLastName, { updateOn: 'blur', validators: Validators.required }),
      BorrowerSSN: new FormControl(data.BorrowerSSN, { updateOn: 'blur', validators: ssnValidators }),
      BorrowerDateOfBirth: new FormControl(formatDate(data.BorrowerDateOfBirth, DATE_FORMAT), { updateOn: 'blur', validators: Validators.required }),
      BorrowerEmail: new FormControl(data.BorrowerEmail),
      IsThereACoBorrower: new FormControl(isYes(data.IsThereACoBorrower?.Name), { validators: Validators.required }),
      IsCoBorrowerSpouse: new FormControl(isYes(data.IsCoBorrowerSpouse?.Name)),
      IsThereASpouse: new FormControl(data.IsThereASpouse?.Id),
      MaritalStatus: new FormControl(data.MaritalStatus?.Id, { validators: Validators.required }),
      BorrowerAddress1: new FormControl(data.BorrowerAddress1, { updateOn: 'blur', validators: Validators.required }),
      BorrowerAddress2: new FormControl(data.BorrowerAddress2, { updateOn: 'blur' }),
      BorrowerCity: new FormControl(data.BorrowerCity, { updateOn: 'blur', validators: Validators.required }),
      BorrowerState: new FormControl(data.BorrowerState?.Id, { validators: Validators.required }),
      BorrowerCounty: new FormControl(data.BorrowerCounty?.Id, { validators: [Validators.required, Validators.min(0)] }),
      BorrowerZipCode: new FormControl(data.BorrowerZipCode, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(5)] }),
      BorrowerPrimaryPhoneNumber: new FormControl(data.BorrowerPrimaryPhoneNumber, { updateOn: 'blur', validators: [Validators.required] }),
      BorrowerPrimaryPhoneType: new FormControl(data.BorrowerPrimaryPhoneType?.Id, { updateOn: 'blur', validators: Validators.required }),
      BorrowerPrimaryPhoneBestTimeToCall: new FormControl(data.BorrowerPrimaryPhoneBestTimeToCall?.Id, { validators: Validators.required }),
      BorrowerSecondaryPhoneNumber: new FormControl(data.BorrowerSecondaryPhoneNumber, { updateOn: 'blur' }),
      BorrowerSecondaryPhoneType: new FormControl(data.BorrowerSecondaryPhoneType?.Id, { updateOn: 'blur' }),
      BorrowerSecondaryPhoneBestTimeToCall: new FormControl(data.BorrowerSecondaryPhoneBestTimeToCall?.Id),
      BorrowerIsVeteran: new FormControl(isYes(data.BorrowerIsVeteran?.Name)),
      PreferredLanguage: new FormControl(data.PreferredLanguage?.Id, { validators: Validators.required }),
      IsEmailAlertsEnabled: new FormControl(data.IsEmailAlertsEnabled),

      Property_Address1: new FormControl(data.Property_Address1, { updateOn: 'blur', validators: Validators.required }),
      Property_Address2: new FormControl(data.Property_Address2, { updateOn: 'blur' }),
      Property_City: new FormControl(data.Property_City, { updateOn: 'blur', validators: Validators.required }),
      Property_County: new FormControl(data.Property_County?.Id, { validators: [Validators.required, Validators.min(0)] }),
      Property_State: new FormControl(data.Property_State?.Id, { validators: Validators.required }),
      Property_ZipCode: new FormControl(data.Property_ZipCode, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(5)] }),
      Property_TotalNumberofPersonsLivingAtThisAddress: new FormControl(data.Property_TotalNumberofPersonsLivingAtThisAddress, { validators: [Validators.required, Validators.pattern(nonNegativeRegex) ]}),
      Property_NumberOfDependentsAtAddress: new FormControl(data.Property_NumberOfDependentsAtAddress, { validators: [Validators.required, Validators.pattern(nonNegativeRegex) ]}),
      Property_IsSameAsMailingAddress: new FormControl(data.Property_IsSameAsMailingAddress)
    }),
    Unemployment: new FormGroup({
      HardshipReason: new FormControl(data.HardshipReason?.Id, { validators: Validators.required }),
      HardshipStatement: new FormControl(data.HardshipStatement, { updateOn: 'blur', validators: Validators.required }),
      HardshipOther: new FormControl('', { updateOn: 'blur' })
    }),
    Spouse: new FormGroup({
      SpouseFirstName: new FormControl(data.SpouseFirstName, { updateOn: 'blur', validators: spouseValidators }),
      SpouseMiddleName: new FormControl(data.SpouseMiddleName),
      SpouseLastName: new FormControl(data.SpouseLastName, { updateOn: 'blur', validators: spouseValidators }),
      SpouseSSN: new FormControl(data.SpouseSSN, { updateOn: 'blur', validators: spouseSSNValidators }),
      SpouseDateOfBirth: new FormControl(formatDate(data.SpouseDateOfBirth, DATE_FORMAT), { validators: spouseValidators }),
      SpouseAddress1: new FormControl(data.SpouseAddress1, { updateOn: 'blur', validators: spouseValidators }),
      SpouseAddress2: new FormControl(data.SpouseAddress2, { updateOn: 'blur' }),
      SpouseCity: new FormControl(data.SpouseCity, { updateOn: 'blur', validators: spouseValidators }),
      SpouseCounty: new FormControl(data.SpouseCounty?.Id, { validators: spouseValidators }),
      SpouseState: new FormControl(data.SpouseState?.Id, { validators: spouseValidators }),
      SpouseZipCode: new FormControl(data.SpouseZipCode, { updateOn: 'blur', validators: spouseValidators }),
      SpousePrimaryPhoneNumber: new FormControl(data.SpousePrimaryPhoneNumber, { updateOn: 'blur', validators: spouseValidators }),
      SpousePrimaryPhoneType: new FormControl(data.SpousePrimaryPhoneType?.Id, { validators: spouseValidators }),
      SpousePrimaryPhoneBestTimeToCall: new FormControl(data.SpousePrimaryPhoneBestTimeToCall?.Id, { validators: spouseValidators }),
      SpouseEmail: new FormControl(data.SpouseEmail, { updateOn: 'blur', validators: spouseEmailValidators }),
      SpouseSecondaryPhoneNumber: new FormControl(data.SpouseSecondaryPhoneNumber, { updateOn: 'blur', validators: Validators.pattern(phoneRegex) }),
      SpouseSecondaryPhoneType: new FormControl(data.SpouseSecondaryPhoneType?.Id),
      SpouseSecondaryPhoneBestTimeToCall: new FormControl(data.SpouseSecondaryPhoneBestTimeToCall?.Id),
      SpouseIsVeteran: new FormControl(isYes(data.SpouseIsVeteran?.Name))
    }),
    CoBorrower: new FormGroup({
      CoBorrowerFirstName: new FormControl(data.CoBorrowerFirstName, { updateOn: 'blur', validators: coborrowerValidators }),
      CoBorrowerMiddleName: new FormControl(data.CoBorrowerMiddleName),
      CoBorrowerLastName: new FormControl(data.CoBorrowerLastName, { updateOn: 'blur', validators: coborrowerValidators }),
      CoBorrowerSSN: new FormControl(data.CoBorrowerSSN, { updateOn: 'blur', validators: coborrowerSSNValidators }),
      CoBorrowerDateOfBirth: new FormControl(formatDate(data.CoBorrowerDateOfBirth, DATE_FORMAT), { updateOn: 'blur', validators: coborrowerValidators }),
      CoBorrowerAddress1: new FormControl(data.CoBorrowerAddress1, { updateOn: 'blur', validators: coborrowerValidators }),
      CoBorrowerAddress2: new FormControl(data.CoBorrowerAddress2, { updateOn: 'blur' }),
      CoBorrowerCity: new FormControl(data.CoBorrowerCity, { updateOn: 'blur', validators: coborrowerValidators }),
      CoBorrowerCounty: new FormControl(data.CoBorrowerCounty?.Id, { validators: coborrowerValidators }),
      CoBorrowerState: new FormControl(data.CoBorrowerState?.Id, { validators: coborrowerValidators }),
      CoBorrowerZipCode: new FormControl(data.CoBorrowerZipCode, { updateOn: 'blur', validators: coborrowerValidators }),
      CoBorrowerPrimaryPhoneNumber: new FormControl(data.CoBorrowerPrimaryPhoneNumber, { updateOn: 'blur', validators: coborrowerValidators }),
      CoBorrowerPrimaryPhoneType: new FormControl(data.CoBorrowerPrimaryPhoneType?.Id, { validators: coborrowerValidators }),
      CoBorrowerPrimaryPhoneBestTimeToCall: new FormControl(data.CoBorrowerPrimaryPhoneBestTimeToCall?.Id, { validators: coborrowerValidators }),
      CoBorrowerEmail: new FormControl(data.CoBorrowerEmail, { updateOn: 'blur', validators: coborrowerEmailValidators }),
      CoBorrowerSecondaryPhoneNumber: new FormControl(data.CoBorrowerSecondaryPhoneNumber, { updateOn: 'blur', validators: Validators.pattern(phoneRegex) }),
      CoBorrowerSecondaryPhoneType: new FormControl(data.CoBorrowerSecondaryPhoneType?.Id),
      CoBorrowerSecondaryPhoneBestTimeToCall: new FormControl(data.CoBorrowerSecondaryPhoneBestTimeToCall?.Id),
      CoBorrowerIsVeteran: new FormControl(isYes(data.CoBorrowerIsVeteran?.Name))
    }),
    Government: new FormGroup({
      BorrowerGender: new FormControl(data.BorrowerGender?.Id, [Validators.required]),
      BorrowerRaces: new FormArray([]),
      BorrowerEthnicity: new FormControl(data.BorrowerEthnicity?.Id, [Validators.required]),
      CoBorrowerGender: new FormControl(data.CoBorrowerGender?.Id, { validators: coborrowerValidators }), 
      CoBorrowerRaces: new FormArray([]),
      CoBorrowerEthnicity: new FormControl(data.CoBorrowerEthnicity?.Id, { validators: coborrowerValidators }),
      SpouseGender: new FormControl(data.SpouseGender?.Id, { validators: spouseValidators }), 
      SpouseRaces: new FormArray([]),
      SpouseEthnicity: new FormControl(data.SpouseEthnicity?.Id, { validators: spouseValidators })
    }),
    SociallyDisadvantaged: new FormGroup({
      IsSociallyDisadvantaged: new FormControl(data.IsSociallyDisadvantaged?.Id)
    })
  });
  borrowerRaces.forEach((id: number) => {
    (form.get('Government.BorrowerRaces') as FormArray).push(new FormControl(id));
  });
  coborrowerRaces.forEach((id: number) => {
    (form.get('Government.CoBorrowerRaces') as FormArray).push(new FormControl(id));
  });
  spouseRaces.forEach((id: number) => {
    (form.get('Government.SpouseRaces') as FormArray).push(new FormControl(id));
  });
  return form;
}

export function stateDataToPropertyForm(data: HomeownerApplicationApiData, toggles: FeatureToggles): FormGroup {
  const condoHoaValidators = isYes(data.Property_HasCondoOrHoaFees?.Name) ? Validators.required : undefined;
  const judgmentValidators = toggles.HasJudgments && !!data.Property_HasJudgements ? Validators.required : undefined;
  const judgmentDescriptionValidators = !!data.Property_HasJudgements ? Validators.required : undefined;

  const keep = data.Property_IsIntentionToKeep;
  const sell = data.Property_IsIntentionToSell;
  const primary = data.Property_IsPrimaryResidence;
  const secondary = data.Property_IsSecondaryResidence;
  const investment = data.Property_IsInvestment;
  const owner = data.Property_IsOwnerOccupied;
  const renter = data.Property_IsRenterOccupied;
  const vacant = data.Property_IsVacant;

  const propertyIntention = keep ? PropertyIntention.Keep : (sell ? PropertyIntention.Sell : '');
  const propertyType = primary ? PropertyType.Primary : (secondary ? PropertyType.Secondary : (investment ? PropertyType.Investment : ''));
  const propertyOccupied = owner ? PropertyOccupied.Owner : (renter ? PropertyOccupied.Renter : (vacant ? PropertyOccupied.Vacant : ''));

  const form = new FormGroup({
    propertyInfo: new FormGroup({
      Property_LastKnownAppraisedValue: new FormControl(data.Property_LastKnownAppraisedValue, { updateOn: 'blur', validators: [Validators.required, Validators.pattern(nonNegativeRegex)] }),
      Property_Intention: new FormControl(propertyIntention, [Validators.required]),
      Property_Type: new FormControl(propertyType, [Validators.required]),
      Property_Occupied: new FormControl(propertyOccupied, [Validators.required])
    }),
    propertyTaxesFees: new FormGroup({
      Property_WhoPaysTaxes: new FormControl(data.Property_WhoPaysTaxes?.Id, { validators: Validators.required}),
      Property_AreTaxesCurrent: new FormControl(isYes(data.Property_AreTaxesCurrent?.Name), { validators: Validators.required}),
      Property_HasCondoOrHoaFees: new FormControl(isYes(data.Property_HasCondoOrHoaFees?.Name), { validators: Validators.required}),
      Property_CondoOrHoaFeesAmount: new FormControl(data.Property_CondoOrHoaFeesAmount, { updateOn: 'blur', validators: condoHoaValidators}),
      Property_CondoOrHoaFeesPaidTo: new FormControl(data.Property_CondoOrHoaFeesPaidTo, { updateOn: 'blur', validators: condoHoaValidators})
    }),
    hazardInsurance: new FormGroup({
      Property_WhoPaysHazardInsurance: new FormControl(data.Property_WhoPaysHazardInsurance?.Id, { validators: Validators.required}),
      Property_HazardInsuranceIsCurrent: new FormControl(isYes(data.Property_HazardInsuranceIsCurrent?.Name), { validators: Validators.required}),
      Property_HazardInsuranceCompanyName: new FormControl(data.Property_HazardInsuranceCompanyName, { updateOn: 'blur', validators: Validators.required}),
      Property_HazardInsuranceCompanyPhone: new FormControl(data.Property_HazardInsuranceCompanyPhone, { updateOn: 'blur', validators: [Validators.required]}),
    }),
    judgmentsProperty: new FormGroup({
      Property_HasJudgements: new FormControl(data.Property_HasJudgements, { validators: judgmentValidators}),
      Property_JudgementsDescription: new FormControl(data.Property_JudgementsDescription, { updateOn: 'blur', validators: judgmentDescriptionValidators})
    }),
    eligibleExpenses: new FormArray([])
  });

  if (toggles.IsEligibleExpensesEnabled) {
    if (!!data.EligibleExpenses && data.EligibleExpenses.length > 0) {
      data.EligibleExpenses.forEach(expense => {
        (form.get('eligibleExpenses') as FormArray).push(getExpenseFormGroup(expense));
      });
    }
  }

  return form;
}

export function stateDataToSignatureForm(data: HomeownerApplicationApiData, toggles: FeatureToggles): FormGroup {
  const isDocusignDisabled = !toggles.IsDocusignEnabled;
  const isCoborrowerSpouse = isYes(data.IsCoBorrowerSpouse?.Name);
  const shouldValidateCoborrower = isYes(data.IsThereACoBorrower?.Name);
  const shouldValidateSpouse = hasSpouse(data.MaritalStatus?.Id) && !isCoborrowerSpouse;
  const borrowerValidators = isDocusignDisabled ? Validators.required : undefined;
  const coborrowerValidators = isDocusignDisabled && shouldValidateCoborrower ? Validators.required : undefined;
  const spouseValidators = isDocusignDisabled && shouldValidateSpouse ? Validators.required : undefined;

  const form = new FormGroup({
    BorrowerSignature: new FormControl('', borrowerValidators),
    BorrowerSignatureDate: new FormControl('', borrowerValidators),
    BorrowerRole: new FormControl(''),
    CoBorrowerSignature: new FormControl('', coborrowerValidators),
    CoBorrowerRole: new FormControl(''),
    CoBorrowerSignatureDate: new FormControl('', coborrowerValidators),
    SpouseSignature: new FormControl('', spouseValidators),
    SpouseSignatureDate: new FormControl('', spouseValidators),
    SpouseRole: new FormControl('')
  });
  return form;
}