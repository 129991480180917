import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { APPLICATIONS_ROUTE, SUPPORTING_FILES_ROUTE } from 'routes';
import { selectSelectedApplicationId } from 'src/app/store/selectors';

@Component({
  selector: 'app-dashboard-data',
  templateUrl: './app-dashboard-data.component.html',
  styleUrls: ['./app-dashboard-data.component.scss']
})
export class AppDashboardDataComponent implements OnInit {

  @Input() hasContinueButton = false;
  @Input() isTotal = false;
  @Input() subtext? = '';
  @Input() text: string | undefined = '';
  @Input() title: string | undefined = '';
  // @Input() subtext2? = '';
  @Input() title2: string | undefined = '';
  @Input() text2: string | undefined = '';

  selectedAppId$ = this.store.select(selectSelectedApplicationId);

  constructor(private store: Store) { }

  ngOnInit(): void {}

  get appsRoute(): string {
    return APPLICATIONS_ROUTE;
  }

  get supportingFilesRoute(): string {
    return SUPPORTING_FILES_ROUTE;
  }

}
