<ng-container *transloco="let t;">
  <div class="modal-header">
    <h2 class="modal-title" tabindex="0" role="heading">{{t('timeoutDialog.userInactive')}}</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body" tabindex="0" role="text">
    <p>{{statusMessage}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary float-right" aria-label="Log Out" (click)="logout()">{{t('commonWords.logOut')}}</button>
    <button type="button" class="btn btn-primary float-right" aria-label="Stay Logged In" (click)="close()">{{t('timeoutDialog.stayLoggedIn')}}</button>
  </div>
</ng-container>