import { ProfileFormData, UserProfile } from "src/app/homeowner-application/interfaces";
import { UserState } from "../reducers/user.reducers";

export function getProfileDataFromForm(formValue: any): ProfileFormData {
    return {
      IsEmailAlertsEnabled: formValue.IsEmailAlertsEnabled
    };
  }

  export function stateDataToUserProfile(data: UserState): UserProfile{
    const userProfile: UserProfile = {
        IsEmailAlertsEnabled: data.isEmailAlertsEnabled,
        EmailAddress: data.username
    };

    return userProfile;
  }