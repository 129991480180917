import { FormControl, FormGroup, Validators } from '@angular/forms';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApplicationMessageContainer} from 'src/app/application-messaging/interfaces';
import { fromApplictionMessaging } from '../reducers';
import { ApplicationMessagingState } from '../reducers/application-messaging.reducers';

export const getApplicationMessagingFeatureState = createFeatureSelector<ApplicationMessagingState>(fromApplictionMessaging.communicationsFeatureKey);

export const selectMessages = createSelector(
  getApplicationMessagingFeatureState,
  (state: ApplicationMessagingState) => state.messageContainer
);

export const selectHasUnreadMessages = createSelector(
  selectMessages,
  (messageContainer: ApplicationMessageContainer | null) => (!!messageContainer && !!messageContainer.ApplicationMessages && messageContainer.ApplicationMessages.length > 0 && messageContainer.ApplicationMessages.find(x => x.MessageSourceId != 2 && x.IsReadByApplicant == false))
);

export const selectUnreadMessageCount = createSelector(
  selectMessages,
  (messageContainer: ApplicationMessageContainer | null) => messageContainer?.ApplicationMessages?.filter(x => x.MessageSourceId != 2 && x.IsReadByApplicant == false)?.length
);

export const selectIsUpdatingMessages = createSelector(
  getApplicationMessagingFeatureState,
  (state: ApplicationMessagingState) => state.isUpdating
);

export const selectReplyForm = createSelector(
  getApplicationMessagingFeatureState,
  (state: ApplicationMessagingState) => new FormGroup({ reply: new FormControl(state.replyForm.reply, Validators.required)})
);