import { createAction, props } from "@ngrx/store";
import { Program } from "src/app/core/interfaces/program.interface";
import { HomeownerApplicationApiData, HomeownerApplicationStateData } from "src/app/homeowner-application/interfaces";
import { HelpLink, ListObject, PrequalAnswer } from "src/app/shared/interfaces";

export const checkEligibility = createAction(
  '[API] Check Eligibility',
  props<{ answersCertifiedAccurate: boolean, answers: PrequalAnswer[] }>()
);

export const checkEligibilityError = createAction(
  '[API] Check Eligibility Error',
  props<{ error: any }>()
);

export const checkEligibilitySuccess = createAction(
  '[API] Check Eligibility Success',
  props<{ response: boolean }>()
);

export const putAppData = createAction(
  '[API] PUT Homeowner Application Data'
);

export const putAppDataSuccess = createAction(
  '[API] PUT Homeowner Application Data Success',
  props<{ data: HomeownerApplicationApiData }>()
);

export const putAppDataError = createAction(
  '[API] PUT Homeowner Application Data Error',
  props<{ error: any }>()
);

export const initiateSubmitAppFlow = createAction(
  '[App Info] Begin App Submission Flow',
  props<{ signatureFormValue: any }>()
);

export const getDataToSubmit = createAction(
  '[App Info] Select App Data to Submit'
);

export const completeSubmitApp = createAction(
  '[API] Trigger POST Homeowner Application Submit',
  props<{ data: HomeownerApplicationStateData }>()
);

export const submitAppError = createAction(
  '[API] POST Homeowner Application Submit Error',
  props<{ error: any }>()
);

export const submitAppSuccess = createAction(
  '[API] POST Homeowner Application Submit Success'
);

export const submitSignatures = createAction(
  '[API] PUT Non-docusign Signatures',
  props<{ formValue: any, hasCoborrower: boolean, hasSpouse: boolean }>()
);

export const submitSignaturesError = createAction(
  '[API] PUT Non-docusign Signatures Error',
  props<{ error: any }>()
);

export const submitSignaturesSuccess = createAction(
  '[API] PUT Non-docusign Signatures Success',
  props<{ formValue: any, hasCoborrower: boolean, hasSpouse: boolean }>()
);

export const loadBankruptcyTypesList = createAction(
  '[API] GET Bankruptcy Types List'
);

export const loadBankruptcyTypesSuccess = createAction(
  '[API] GET Bankruptcy Types Success',
  props<{ list: ListObject[] }>()
);

export const loadBankruptcyTypesError = createAction(
  '[API] GET Bankruptcy Types Error',
  props<{ error: any }>()
);

export const loadBestTimesToCallList = createAction(
  '[API] GET Best Times to Call List'
);

export const loadBestTimesToCallSuccess = createAction(
  '[API] GET Best Times to Call Success',
  props<{ list: ListObject[] }>()
);

export const loadBestTimesToCallError = createAction(
  '[API] GET Best Times to Call Error',
  props<{ error: any }>()
);

export const loadCounties = createAction(
  '[API] GET Mailing Counties List',
  props<{ state: number }>()
);

export const loadHoCounties = createAction(
  '[API] GET Homeowner Counties List',
  props<{ state: number }>()
);

export const loadCbCounties = createAction(
  '[API] GET Co-Borrower Counties List',
  props<{ state: number }>()
);

export const loadSpCounties = createAction(
  '[API] GET Spouse Counties List',
  props<{ state: number }>()
);

export const loadHoEmploymentCounties = createAction(
  '[API] GET Homeowner Employment Counties List',
  props<{ state: number }>()
);

export const loadCbEmploymentCounties = createAction(
  '[API] GET Co-Borrower Employment Counties List',
  props<{ state: number }>()
);

export const loadCountiesSuccess = createAction(
  '[API] GET Mailing Counties Success',
  props<{ list: ListObject[] }>()
);

export const loadHoCountiesSuccess = createAction(
  '[API] GET Homeowner Counties Success',
  props<{ list: ListObject[] }>()
);

export const loadCbCountiesSuccess = createAction(
  '[API] GET Co-Borrower Counties Success',
  props<{ list: ListObject[] }>()
);

export const loadSpCountiesSuccess = createAction(
  '[API] GET Spouse Counties Success',
  props<{ list: ListObject[] }>()
);

export const loadHoEmploymentCountiesSuccess = createAction(
  '[API] GET Homeowner Employment Counties Success',
  props<{ list: ListObject[] }>()
);

export const loadCbEmploymentCountiesSuccess = createAction(
  '[API] GET Co-Borrower Employment Counties Success',
  props<{ list: ListObject[] }>()
);

export const loadCountiesError = createAction(
  '[API] GET Counties Error',
  props<{ error: any }>()
);

export const loadDiscoveryTypes = createAction(
  '[API] GET Discovery Types List'
);

export const loadDiscoveryTypesSuccess = createAction(
  '[API] GET Discovery Types Success',
  props<{ list: ListObject[] }>()
);

export const loadDiscoveryTypesError = createAction(
  '[API] GET Discovery Types Error',
  props<{ error: any }>()
);

export const loadEligibleExpenseCategories = createAction(
  '[API] GET Eligible Expense Categories List'
);

export const loadEligibleExpenseCategoriesSuccess = createAction(
  '[API] GET Eligible Expense Categories Success',
  props<{ list: ListObject[] }>()
);

export const loadEligibleExpenseCategoriesError = createAction(
  '[API] GET Eligible Expense Categories Error',
  props<{ error: any }>()
);

export const loadEligibleExpenseProviders = createAction(
  '[API] GET Eligible Expense Providers List',
  props<{ category: number }>()
);

export const loadEligibleExpenseProvidersSuccess = createAction(
  '[API] GET Eligible Expense Providers Success',
  props<{ list: ListObject[], category: number }>()
);

export const loadEligibleExpenseProvidersError = createAction(
  '[API] GET Eligible Expense Providers Error',
  props<{ error: any }>()
);

export const loadEligibleExpenseTypes = createAction(
  '[API] GET Eligible Expense Types List',
  props<{ category: number }>()
);

export const loadEligibleExpenseTypesSuccess = createAction(
  '[API] GET Eligible Expense Types Success',
  props<{ list: ListObject[], category: number }>()
);

export const loadEligibleExpenseTypesError = createAction(
  '[API] GET Eligible Expense Types Error',
  props<{ error: any }>()
);

export const loadEthnicities = createAction(
  '[API] GET Ethnicities List'
);

export const loadEthnicitiesSuccess = createAction(
  '[API] GET Ethnicities Success',
  props<{ list: ListObject[] }>()
);

export const loadEthnicitiesError = createAction(
  '[API] GET Ethnicities Error',
  props<{ error: any }>()
);

export const loadExpenseCategories = createAction(
  '[API] GET Expense Categories List'
);

export const loadExpenseCategoriesSuccess = createAction(
  '[API] GET Expense Categories Success',
  props<{ list: ListObject[] }>()
);

export const loadExpenseCategoriesError = createAction(
  '[API] GET Expense Categories Error',
  props<{ error: any }>()
);

export const loadFeatureToggles = createAction(
  '[API] GET Feature Toggles List'
);

export const loadFeatureTogglesSuccess = createAction(
  '[API] GET Feature Toggles Success',
  props<{ list: any }>()
);

export const loadFeatureTogglesError = createAction(
  '[API] GET Feature Toggles Error',
  props<{ error: any }>()
);

export const loadFileCategories = createAction(
  '[API] GET File Categories List'
);

export const loadFileCategoriesSuccess = createAction(
  '[API] GET File Categories Success',
  props<{ list: ListObject[] }>()
);

export const loadFileCategoriesError = createAction(
  '[API] GET File Categories Error',
  props<{ error: any }>()
);

export const loadGenders = createAction(
  '[API] GET Genders List'
);

export const loadGendersSuccess = createAction(
  '[API] GET Genders Success',
  props<{ list: ListObject[] }>()
);

export const loadGendersError = createAction(
  '[API] GET Genders Error',
  props<{ error: any }>()
);

export const loadHardshipReasons = createAction(
  '[API] GET Hardship Reasons List'
);

export const loadHardshipReasonsSuccess = createAction(
  '[API] GET Hardship Reasons Success',
  props<{ list: ListObject[] }>()
);

export const loadHardshipReasonsError = createAction(
  '[API] GET Hardship Reasons Error',
  props<{ error: any }>()
);

export const loadHowLongWithEmployers = createAction(
  '[API] GET How Long With Employers List'
);

export const loadHowLongWithEmployersSuccess = createAction(
  '[API] GET How Long With Employers Success',
  props<{ list: ListObject[] }>()
);

export const loadHowLongWithEmployersError = createAction(
  '[API] GET How Long With Employers Error',
  props<{ error: any }>()
);

export const loadIncomeSources = createAction(
  '[API] GET Income Sources List'
);

export const loadIncomeSourcesSuccess = createAction(
  '[API] GET Income Sources Success',
  props<{ list: ListObject[] }>()
);

export const loadIncomeSourcesError = createAction(
  '[API] GET Income Sources Error',
  props<{ error: any }>()
);
export const loadLanguages = createAction(
  '[API] GET Languages List'
);

export const loadLanguagesSuccess = createAction(
  '[API] GET Languages Success',
  props<{ list: ListObject[] }>()
);

export const loadLanguagesError = createAction(
  '[API] GET Languages Error',
  props<{ error: any }>()
);

export const loadLoanTypes = createAction(
  '[API] GET Loan Types List'
);

export const loadLoanTypesSuccess = createAction(
  '[API] GET Loan Type Success',
  props<{ list: ListObject[] }>()
);

export const loadLoanTypesError = createAction(
  '[API] GET Loan Type Error',
  props<{ error: any }>()
);

export const loadMaritalStatuses = createAction(
  '[API] GET Marital Statuses List'
);

export const loadMaritalStatusesSuccess = createAction(
  '[API] GET Marital Statuses Success',
  props<{ list: ListObject[] }>()
);

export const loadMaritalStatusesError = createAction(
  '[API] GET Marital Statuses Error',
  props<{ error: any }>()
);

export const loadMortgageDelinquencyStatusesList = createAction(
  '[API] GET Mortgage Delinquency Statuses List'
);

export const loadMortgageDelinquencySuccess = createAction(
  '[API] GET Mortgage Delinquency Success',
  props<{ list: ListObject[] }>()
);

export const loadMortgageDelinquencyError = createAction(
  '[API] GET Mortgage Delinquency Error',
  props<{ error: any }>()
);

export const loadMortgageLenders = createAction(
  '[API] GET Mortgage Lenders List'
);

export const loadMortgageLendersSuccess = createAction(
  '[API] GET Mortgage Lenders Success',
  props<{ list: ListObject[] }>()
);

export const loadMortgageLendersError = createAction(
  '[API] GET Mortgage Lenders Error',
  props<{ error: any }>()
);

export const loadMortgageLoanProgramsList = createAction(
  '[API] GET Mortgage Loan Programs List'
);

export const loadMortgageLoanProgramsSuccess = createAction(
  '[API] GET Mortgage Loan Programs Success',
  props<{ list: ListObject[] }>()
);

export const loadMortgageLoanProgramsError = createAction(
  '[API] GET Mortgage Loan Programs Error',
  props<{ error: any }>()
);

export const loadMortgagePaymentDueDatesList = createAction(
  '[API] GET Mortgage Payment Due Dates List'
);

export const loadMortgagePaymentDueDatesSuccess = createAction(
  '[API] GET Mortgage Payment Due Dates Success',
  props<{ list: ListObject[] }>()
);

export const loadMortgagePaymentDueDatesError = createAction(
  '[API] GET Mortgage Payment Due Dates Error',
  props<{ error: any }>()
);

export const loadMortgageServicers = createAction(
  '[API] GET Mortgage Servicers List'
);

export const loadMortgageServicersSuccess = createAction(
  '[API] GET Mortgage Servicers Success',
  props<{ list: ListObject[] }>()
);

export const loadMortgageServicersError = createAction(
  '[API] GET Mortgage Servicers Error',
  props<{ error: any }>()
);

export const loadPhoneTypesList = createAction(
  '[API] GET Phone Types List'
);

export const loadPhoneTypesSuccess = createAction(
  '[API] GET Phone Types Success',
  props<{ list: ListObject[] }>()
);

export const loadPhoneTypesError = createAction(
  '[API] GET Phone Types Error',
  props<{ error: any }>()
);

export const loadProgramCountiesList = createAction(
  '[API] GET Program Counties List'
);

export const loadProgramCountiesSuccess = createAction(
  '[API] GET Program Counties Success',
  props<{ list: ListObject[] }>()
);

export const loadProgramCountiesError = createAction(
  '[API] GET Program Counties Error',
  props<{ error: any }>()
);

export const loadProgramsList = createAction(
  '[API] GET Programs List'
);

export const loadProgramsSuccess = createAction(
  '[API] GET Programs Success',
  props<{ list: Program[] }>()
);

export const loadProgramsError = createAction(
  '[API] GET Programs Error',
  props<{ error: any }>()
);

export const loadRacesList = createAction(
  '[API] GET Races List'
);

export const loadRacesSuccess = createAction(
  '[API] GET Races Success',
  props<{ list: ListObject[] }>()
);

export const loadRacesError = createAction(
  '[API] GET Races Error',
  props<{ error: any }>()
);

export const loadSociallyDisadvantagedList = createAction(
  '[API] GET Socially Disadvantaged List'
);

export const loadSociallyDisadvantagedSuccess = createAction(
  '[API] GET Socially Disadvantaged Success',
  props<{ list: ListObject[] }>()
);

export const loadSociallyDisadvantagedError= createAction(
  '[API] GET Socially Disadvantaged Error',
  props<{ error: any }>()
);

export const loadStatesList = createAction(
  '[API] GET States List'
);

export const loadStatesSuccess = createAction(
  '[API] GET States Success',
  props<{ list: ListObject[] }>()
);

export const loadStatesError = createAction(
  '[API] GET States Error',
  props<{ error: any }>()
);

export const loadWhoPaysList = createAction(
  '[API] GET Who Pays List'
);

export const loadWhoPaysSuccess = createAction(
  '[API] GET Who Pays Success',
  props<{ list: ListObject[] }>()
);

export const loadWhoPaysError = createAction(
  '[API] GET Who Pays Error',
  props<{ error: any }>()
);

export const loadYesNoList = createAction(
  '[API] GET Yes/No List'
);

export const loadYesNoSuccess = createAction(
  '[API] GET Yes/No Success',
  props<{ list: ListObject[] }>()
);

export const loadYesNoError = createAction(
  '[API] GET Yes/No Error',
  props<{ error: any }>()
);

export const loadYesNoPendingList = createAction(
  '[API] GET Yes/No/Pending List'
);

export const loadYesNoPendingSuccess = createAction(
  '[API] GET Yes/No/Pending Success',
  props<{ list: ListObject[] }>()
);

export const loadYesNoPendingError = createAction(
  '[API] GET Yes/No/Pending Error',
  props<{ error: any }>()
);

export const loadHelpLinks = createAction(
  '[API] GET Help Links List'
);

export const loadHelpLinksSuccess = createAction(
  '[API] GET Help Links List Success',
  props<{ list: HelpLink[] }>()
);

export const loadHelpLinksError = createAction(
  '[API] GET Help Links List Error',
  props<{ error: any }>()
);

export const listAlreadyFetched = createAction(
  '[API] List Already Fetched'
);

export const loadApplicationSummaryLists = createAction(
  '[API] Load Application Summary Lists'
);

export const loadEmploymentLists = createAction(
  '[API] Load Employment Lists'
);

export const loadFinancialLists = createAction(
  '[API] Load Financial Lists'
);

export const loadMortgageLists = createAction(
  '[API] Load Mortgage Lists'
);

export const loadPersonalLists = createAction(
  '[API] Load Personal Lists'
);

export const loadPropertyLists = createAction(
  '[API] Load Property Lists'
);

export const loadSupportingFilesLists = createAction(
  '[API] Load Supporting Files Lists'
);

export const loadProgramStages = createAction(
  '[API] Load Program Stages List'
);

export const loadProgramStagesSuccess = createAction(
  '[API] GET Program Stage List Success',
  props<{ list: ListObject[] }>()
);

export const loadProgramStagesError = createAction(
  '[API] GET Program Stage List Error',
  props<{ error: any }>()
);

