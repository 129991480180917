<ng-container *transloco="let t;">
  <form *ngIf="form" [formGroup]="form">
    <form-header 
      [title]="t('profile.title')"
      [submitBtnTitle]="t('profile.nextBtnText')"
      [isProfile]="true"
      [isValidForm]="true"
      (back)="goBack()"
      (save)="toContinue()">
    </form-header>
    <alert [alert]="alert"></alert>
    <div class="card mb-5">
      <h2 class="card-header">{{t('profile.loginInfo')}}</h2>
      <div class="card-body">
        <div class="card-container">
            <div class="form-group field">
                <label for="email">{{t('commonWords.email')}}</label>
                <input type="email" class="form-control" id="email" formControlName="email"  placeholder="user@domain.com" readonly>
                <form-message *ngIf="isInvalid('email')" [cssClass]="ValidClass.Danger" [field]="form.get('email')"></form-message>
            </div>
            <div class="form-group change-password field">
                <button type="submit" class="btn btn-primary" (click)="changePassword()">{{t('profile.changePW')}}</button>
            </div>
            <div class="form-group field email-alerts">
                <input class="form-check-input" aria-labelledby="IsEmailAlertsEnabledLabel" formControlName="IsEmailAlertsEnabled" id="IsEmailAlertsEnabled" type="checkbox">
                <label class="form-check-inline mx-0" id="IsEmailAlertsEnabledLabel" aria-hidden="true" for="IsEmailAlertsEnabled">&nbsp;{{t('profile.emailAlerts')}}</label>
            </div>
        </div>
      </div>
    </div>

    <div class="card mb-5">
      <h2 class="card-header">{{t('profile.updateEmailAddress')}}</h2>
      <div class="card-body">
          <p>{{t('profile.updateEmailInfo')}}.</p>
        <div class="card-container">
          <div class="form-group field">
            <label for="newEmail">{{t('commonWords.email')}}</label>
            <input type="email" class="form-control" id="newEmail" formControlName="newEmail" placeholder="user@domain.com" required>
            <form-message *ngIf="isInvalid('newEmail')" [cssClass]="ValidClass.Danger" [field]="form.get('newEmail')"></form-message>
          </div>
          <div class="form-group field">
            <label for="confirmNewEmail">{{t('register.confirmEmail')}}</label>
            <input type="email" class="form-control" id="confirmNewEmail" formControlName="confirmNewEmail" placeholder="user@domain.com" required>
            <ng-container *ngIf="f.confirmNewEmail.errors?.mustMatch; else otherErrors">
              <form-message [cssClass]="ValidClass.Danger" [matchText]="'Email addresses must match'"></form-message>
            </ng-container>
            <ng-template #otherErrors>
              <form-message *ngIf="isInvalid('confirmNewEmail')" [cssClass]="ValidClass.Danger" [field]="form.get('confirmNewEmail')"></form-message>
            </ng-template>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-4" (click)="updateEmail()" [disabled]="canUpdateEmail">{{t('profile.updateEmailAddress')}}</button>
      </div>
    </div>
    
    <div class="card mb-5">
      <h2 class="card-header">{{t('profile.mfa')}}</h2>
      <div class="card-body">
          <p>{{t('profile.mfaInfo')}}.</p>
        <div class="card-container">
          <div class="form-group field email-alerts mb-2">
            <input class="form-check-input" formControlName="isMFAEnabled" id="isMFAEnabled" type="checkbox" unchecked>
            <label class="form-check-inline mx-0" for="isMFAEnabled">&nbsp;{{t('profile.optIn')}}</label>
          </div>
        </div>
        <p *ngIf="form.get('isMFAEnabled')?.value">{{t('profile.updatePhoneInfo')}}.</p>
        <div class="card-container">
          <div class="form-group field" *ngIf="form.get('isMFAEnabled')?.value">
            <label [ngClass]="{'required': form.get('isMFAEnabled')?.value }" for="cellNumber">{{t('profile.cell')}}</label>
            <input type="tel" [pattern]="phoneRegex" (input)="removeIllegalCharacters($event, 'tel')" class="form-control" id="cellNumber" formControlName="cellNumber" placeholder="###-###-####">
            <form-message *ngIf="isInvalid('cellNumber')" [isPhone]="true" [cssClass]="ValidClass.Danger" [field]="form.get('cellNumber')" [isPhone]="true"></form-message>
          </div>
          <div class="form-group field" *ngIf="form.get('isMFAEnabled')?.value">
            <label [ngClass]="{'required': form.get('isMFAEnabled')?.value }" for="confirmCellNumber">{{t('commonWords.confirm')}} {{t('profile.cell')}}</label>
            <input type="tel" [pattern]="phoneRegex" (input)="removeIllegalCharacters($event, 'tel')" class="form-control" id="confirmCellNumber" formControlName="confirmCellNumber" placeholder="###-###-####">
            <ng-container *ngIf="f.confirmCellNumber.errors?.mustMatch; else otherPwErrors">
              <form-message [cssClass]="ValidClass.Danger" [isPhone]="true" [matchText]="'Phone numbers must match'"></form-message>
            </ng-container>
            <ng-template #otherPwErrors>
              <form-message *ngIf="isInvalid('confirmCellNumber')" [cssClass]="ValidClass.Danger" [field]="form.get('confirmCellNumber')" [isPhone]="true"></form-message>
            </ng-template>
          </div>
        </div>
        <button *ngIf="showButton(1)" type="submit" class="btn btn-primary mt-4" (click)="mfaOptIn()" [disabled]="canUpdatePhone">{{t('profile.updatePhone')}}</button>
        <button *ngIf="showButton(2)" type="submit" class="btn btn-primary mt-4" (click)="mfaOptIn()" [disabled]="canUpdatePhone">{{t('profile.updateMFA')}}</button>
        <button *ngIf="showButton(3)" type="submit" class="btn btn-primary mt-4" (click)="mfaOptOut()">{{t('profile.updateMFA')}}</button>
      </div>
    </div>
    <div class="btn-form-group py-2" role="group" aria-label="form-buttons">
      <button class="btn btn-outline-primary" (click)="goBack()">{{t('commonWords.back')}}</button>
      <button type="submit" class="btn btn-primary" (click)="toContinue()">{{t('profile.nextBtnText')}}</button>
    </div>
  </form>
</ng-container>