import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { APPLICATIONS_ROUTE, DASHBOARD_DETAILS_ROUTE, LOGIN_ROUTE, PREQUAL_ROUTE } from 'routes';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { AppInfoActions, MessagesActions, MultiApplicationActions, SupportingFilesActions, UserActions } from 'src/app/store/actions';
import { selectFeatureToggles, selectSelectedApplication, selectUserIsLoggedIn } from 'src/app/store/selectors';
import { NavService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private appApi: HomeownerApplicationApiService, 
    private router: Router,
    private navService: NavService,
    private store: Store
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.fetchApplication(state.url);
  }

  isPrequal(url: string): boolean {
    return url.includes(PREQUAL_ROUTE);
  }

  fetchApplication(url: string): Observable<boolean | UrlTree> {
    const isLoggedIn$ = this.store.select(selectUserIsLoggedIn);
    const selectedApp$ = this.store.select(selectSelectedApplication);
    const singleAppApiData$ = this.appApi.fetchSingleApplicationForLogin();
    const toggles$ = this.store.select(selectFeatureToggles);

    return selectedApp$.pipe(
      first(),
      mergeMap(selectedApp => {
        if (!selectedApp || this.isPrequal(url)) {
          this.store.dispatch(MessagesActions.updateLoadingMessage({ message: translate('loading.loadingApplication') }));
          return combineLatest([toggles$, isLoggedIn$, singleAppApiData$]).pipe(
            first(),
            map(([toggles, isLoggedIn, singleAppData]) => {
              if (!!toggles && isLoggedIn) {
                this.store.dispatch(SupportingFilesActions.loadFilesState());

                if (!toggles.EnableHomeownerPortalMultipleApplications) {
                  this.store.dispatch(AppInfoActions.updateAppState({ data: singleAppData }));
                  this.store.dispatch(AppInfoActions.setInitialFormStatus({ data: singleAppData }));
                  return this.navService.canNavigate(url, singleAppData);
                } else {
                  this.handleMultiAppIdNav(url);
                  return this.navService.canNavigate(url, selectedApp);
                }
              } else {
                this.store.dispatch(UserActions.resetAll());
                return this.router.parseUrl(`/${LOGIN_ROUTE}`);
              }
            }),
            catchError(_ => {
              this.store.dispatch(UserActions.resetAll());
              return of(this.navService.getUrlTree(`/${LOGIN_ROUTE}`));
            })
          );
        } else {
          this.store.dispatch(AppInfoActions.setInitialFormStatus({ data: selectedApp }));
          this.store.dispatch(SupportingFilesActions.loadFilesState());
          return of(this.navService.canNavigate(url, selectedApp));
        }
      })
    );
  }

  /**
   * If the multiple applications feature is enabled...
   * If the user is navigating to an application-specific page but does
   * not have data for the application in the store, load the data
   * @param url 
   */
  handleMultiAppIdNav(url: string): void {
    const dashDetailsUrlFragment = DASHBOARD_DETAILS_ROUTE;
    const appPageUrlFragment = `/${APPLICATIONS_ROUTE}/`;
    if (url.includes(dashDetailsUrlFragment)) {
      const appId = +(url.split(dashDetailsUrlFragment)[1].replace('/',''));
      this.store.dispatch(MultiApplicationActions.loadApplicationById({ applicationId: appId }));
    } else if (url.includes(appPageUrlFragment)) {
      const appId = +url.split('/')[2];
      this.store.dispatch(MultiApplicationActions.loadApplicationById({ applicationId: appId }));
    }
  }
}