import { FormGroup } from "@angular/forms";
import { ListObject } from "src/app/shared/interfaces";
import { PrequalStep } from "src/app/shared/interfaces/prequalification-step.enum";
import { EligibleExpense, EligibleExpenseFields } from "./expenses.interface";

export const ApplicationSubmmitedId = 2;

export enum FormNames {
  Personal = 'PERSONAL',
  Employment = 'EMPLOYMENT',
  Property = 'PROPERTY',
  Mortgage = 'MORTGAGE',
  Financial = 'FINANCIAL',
  SummaryAndSignatures = 'SUMMARY_SIGNATURES',
  Profile = 'PROFILE',
  ProgramSelection = 'PROGRAM_SELECTION',
  Prequalification = 'PREQUALIFICATION',
  NotEligible = 'NOT_ELIGIBLE'
}

export enum FormStatus {
  Invalid = 'INVALID',
  Valid = 'VALID',
  Pending = 'PENDING',
  Disabled = 'DISABLED'
}

export interface ApplicationStatus {
  ApplicationNumber: number,
  SubmissionDate?: string | null,
  Status?: string
}

export interface AppFormState {
  personal: FormGroup,
  employment: FormGroup,
  property: FormGroup,
  mortgage: FormGroup,
  financial: FormGroup,
  signatures: FormGroup,
  notEligible: FormGroup
}

/**
 * API Interfaces
 */
export interface IncomeSource {
  Source?: ListObject,
  Gross: number,
  Net?: number
}

export interface ExpenseObject {
  Description: string,
  Amount: number
}

export interface TeamMember {
  Email: string | null,
  Fax: string | null,
  Name: string | null,
  Phone: string | null,
  Website?: string | null
}

export interface Signature {
  Name: string,
  SignedOn?: string,
  Role: string
}

export interface MortgageObject {
  IsOtherLender: boolean,
  OtherLenderName: string,
  IsOtherServicer: boolean,
  OtherServicerName: string,
  Servicer: ListObject | null,
  ServicerPhoneNumber: string,
  Lender: ListObject | null,
  LoanNumber: string,
  Balance: number,
  DelinquencyStatus: ListObject | null,
  Payment: number,
  PaymentDueDate: ListObject | null,
  InterestRate: number,
  LoanType: ListObject | null,
  LoanProgram: ListObject | null,
  AdjustmentDueDate: string,
  ForeclosureReceived: ListObject | null,
  ForeclosureReceivedDate: string | null,
  IsInForbearance: ListObject | null,
  ForbearanceEndDate: string,
  ForbearanceDetails: string,
  ForeclosureSaleScheduled: ListObject | null,
  ForeclosureSaleDate: string | null,
}

export interface SupportingFile {
  AllowDeletion: boolean,
  FileId: number,
  FileName: string,
  FileDescription: string,
  FileAttachmentId: number,
  CategoryId: number,
  SortOrder: number,
  RequiredToSubmitApplication: boolean,
  IsActive: boolean,
  TemplateFileName: string
}

export interface UploadedFile {
  Id: number,
  Title: string,
  CategoryId: number,
  SizeInBytes: number,
  UploadedDate: string,
  SupportingFileId: number
}

export interface Signer {
  Name: string,
  Role: string,
  Signed: boolean, 
  SentOn: string, 
  SignedOn: string
}

export interface FormStatusInfo {
  status: FormStatus,
  visited: boolean
}

export interface HomeownerApplicationApiData {
  /**
   * Metadata fields
   */
  IsActive: boolean,
  initialFormStatusesSet: boolean,
  personalFormStatus: FormStatusInfo,
  employmentFormStatus: FormStatusInfo,
  propertyFormStatus: FormStatusInfo,
  mortgageFormStatus: FormStatusInfo,
  financialFormStatus: FormStatusInfo,
  hasEligibleExpense?: boolean,
  isUpdating?: boolean,
  updateApiError: {
    error?: any,
    hasError?: boolean
  },
  updatePrequalificationError: {
    error: any,
    hasError: boolean
  },
  isSubmissionComplete?: boolean,
  submitApiError: {
    error?: any,
    hasError?: boolean
  },
  updateSignaturesApiError: {
    error?: any,
    hasError?: boolean
  },
  isCheckingEligibility: boolean,
  checkEligibilityError: {
    error: any,
    hasError: boolean
  },
  /**
   * API Interface fields
   */
  ApplicationId: number,
  ApplicationStatus: ApplicationStatus,
  Counselor?: TeamMember,
  Admin?: TeamMember,
  Clerk?: TeamMember,
  ProgramSummaries?: ProgramSummary[],
  Disbursements?: Disbursement[],
  DiscoveryTypeId: number,
  DiscoveryOther: string | null,
  IsEmploymentEligibilityRequired?: boolean,
  IsBorrowerEligibleForUnemployment: boolean,
  IsCoBorrowerEligibleForUnemployment: boolean,
  IsSpouseEligibleForUnemployemnt: boolean,
  BorrowerBenefitStartDate: string,
  BorrowerBenefitProjectedEndDate: string,
  BorrowerBenefitAmountMonthly: number,
  CoBorrowerBenefitStartDate: string,
  CoBorrowerBenefitProjectedEndDate: string,
  CoBorrowerBenefitAmountMonthly: number,
  SpouseBenefitStartDate: string,
  SpouseBenefitProjectedEndDate: string,
  SpouseBenefitAmountMonthly: number,
  IsBorrowerCurrentlyEmployed: ListObject | null,
  BorrowerEmployerName: string,
  BorrowerEmployerAddress: string,
  BorrowerEmployerCity: string,
  BorrowerEmployerState: ListObject | null,
  BorrowerEmployerCounty: ListObject | null,
  BorrowerEmployerZipCode: string,
  BorrowerEmployerPhone: string,
  BorrowerHowLongWithEmployer: ListObject | null,
  BorrowerSelfEmployedCompanyName: string,
  IsCoBorrowerCurrentlyEmployed: ListObject | null,
  CoBorrowerEmployerName: string,
  CoBorrowerEmployerAddress: string,
  CoBorrowerEmployerCity: string,
  CoBorrowerEmployerState: ListObject | null,
  CoBorrowerEmployerCounty: ListObject | null,
  CoBorrowerEmployerZipCode: string,
  CoBorrowerEmployerPhone: string,
  CoBorrowerHowLongWithEmployer: ListObject | null,
  CoBorrowerSelfEmployedCompanyName: string,
  /**
   * PERSONAL fields
   */
  MaritalStatus: ListObject | null,
  BorrowerFirstName: string,
  BorrowerMiddleName: string,
  BorrowerLastName: string,
  BorrowerSSN: string | null,
  BorrowerDateOfBirth: string,
  BorrowerAddress1: string,
  BorrowerAddress2: string,
  BorrowerCity: string,
  BorrowerState: ListObject | null,
  BorrowerCounty: ListObject | null,
  BorrowerZipCode: string,
  BorrowerPrimaryPhoneNumber: string,
  BorrowerPrimaryPhoneType: ListObject | null,
  BorrowerPrimaryPhoneBestTimeToCall: ListObject | null,
  BorrowerSecondaryPhoneNumber: string,
  BorrowerSecondaryPhoneType: ListObject | null,
  BorrowerSecondaryPhoneBestTimeToCall: ListObject | null,
  BorrowerEmail: string,
  BorrowerRaces: ListObject[] | null,
  BorrowerGender: ListObject | null,
  BorrowerEthnicity: ListObject | null,
  IsThereACoBorrower: ListObject | null,
  IsThereASpouse: ListObject | null,
  IsCoBorrowerSpouse: ListObject | null,
  HardshipReason: ListObject | null,
  HardshipStatement: string | null,
  CoBorrowerFirstName: string,
  CoBorrowerMiddleName: string,
  CoBorrowerLastName: string,
  CoBorrowerSSN: string | null,
  CoBorrowerDateOfBirth: string,
  CoBorrowerAddress1: string,
  CoBorrowerAddress2: string,
  CoBorrowerCity: string,
  CoBorrowerState: ListObject | null,
  CoBorrowerCounty: ListObject | null,
  CoBorrowerZipCode: string,
  CoBorrowerPrimaryPhoneNumber: string,
  CoBorrowerPrimaryPhoneType: ListObject | null,
  CoBorrowerPrimaryPhoneBestTimeToCall: ListObject | null,
  CoBorrowerSecondaryPhoneNumber: string,
  CoBorrowerSecondaryPhoneType: ListObject | null,
  CoBorrowerSecondaryPhoneBestTimeToCall: ListObject | null,
  CoBorrowerEmail: string,
  CoBorrowerRaces: ListObject[] | null,
  CoBorrowerGender: ListObject | null,
  CoBorrowerEthnicity: ListObject | null,
  SpouseFirstName: string,
  SpouseMiddleName: string,
  SpouseLastName: string,
  SpouseSSN: string | null,
  SpouseDateOfBirth: string,
  SpouseAddress1: string,
  SpouseAddress2: string,
  SpouseCity: string,
  SpouseState: ListObject | null,
  SpouseCounty: ListObject | null,
  SpouseZipCode: string,
  SpousePrimaryPhoneNumber: string,
  SpousePrimaryPhoneType: ListObject | null,
  SpousePrimaryPhoneBestTimeToCall: ListObject | null,
  SpouseSecondaryPhoneNumber: string,
  SpouseSecondaryPhoneType: ListObject | null,
  SpouseSecondaryPhoneBestTimeToCall: ListObject | null,
  SpouseEmail: string,
  SpouseRaces: ListObject[] | null,
  SpouseGender: ListObject | null,
  SpouseEthnicity: ListObject | null,
  IsSociallyDisadvantaged: ListObject | null,
  CoBorrowerIsVeteran: ListObject | null,
  BorrowerIsVeteran: ListObject | null,
  SpouseIsVeteran: ListObject | null,
  PreferredLanguage: ListObject | null,
  IsEmailAlertsEnabled: boolean,
  /**
   * PERSONAL / PROPERTY fields
   */
  Property_IsSameAsMailingAddress: boolean,
  Property_NumberOfDependentsAtAddress: number,
  Property_LastKnownAppraisedValue: number,
  Property_TotalNumberofPersonsLivingAtThisAddress: number | null,
  Property_Address1: string,
  Property_Address2: string,
  Property_City: string,
  Property_State: ListObject | null,
  Property_County: ListObject | null,
  Property_ZipCode: string,
  /**
   * PROPERTY fields
   */
  Property_IsIntentionToKeep: boolean,
  Property_IsIntentionToSell: boolean,
  Property_IsPrimaryResidence: boolean,
  Property_IsSecondaryResidence: boolean,
  Property_IsInvestment: boolean,
  Property_IsOwnerOccupied: boolean,
  Property_IsRenterOccupied: boolean,
  Property_IsVacant: boolean,
  Property_WhoPaysTaxes: ListObject | null,
  Property_AreTaxesCurrent: ListObject | null,
  Property_HasCondoOrHoaFees: ListObject | null,
  Property_CondoOrHoaFeesPaidTo: string,
  Property_CondoOrHoaFeesAmount?: number,
  Property_WhoPaysHazardInsurance: ListObject | null,
  Property_HazardInsuranceIsCurrent: ListObject | null,
  Property_HazardInsuranceCompanyName: string,
  Property_HazardInsuranceCompanyPhone: string,
  Property_HasJudgements: boolean,
  Property_JudgementsDescription: string | null,
  EligibleExpenses: EligibleExpense[],
  /**
   * FINANCIAL fields
   */
  IncomeSources_BorrowerMonthly: IncomeSource[],
  IncomeSources_CoBorrowerMonthly: IncomeSource[],
  IncomeSources_SpouseMonthly: IncomeSource[],
  Expenses_Other: ExpenseObject[],
  Bankruptcy_WasFiled?: boolean,
  Bankruptcy_Type: ListObject | null,
  Bankruptcy_IsDischarged: ListObject | null,
  Bankruptcy_CaseNumber: string,
  Bankruptcy_FilingDate: string,
  /**
   * MORTGAGE fields
   */
  Mortgages: MortgageObject[],
  /**
   * Not eligible checkboxes
   */
  IsRequestGivenForEmailOfResults: boolean | null,
  IsPermissionGivenForContactByHUD: boolean | null,
  /**
   * Prequalification Question Step
   */
  PrequalStep: PrequalStep,
  isUpdatingPrequalStep: boolean
}

export interface ProgramSummary {
  Id?: number,
  Name?: string,
  Stage?: ListObject,
  TotalApprovedAmount?: number,
  TotalDisbursedToDate?: number,
  FirstDisbursementDate?: string,
  FinalDisbursementDate?: string,
  IsApproved?: boolean,
  Status: ListObject,
  CategoryName?: string | null,
  ApplicationNumber: number,
  IsEligibleExpensesEnabled: boolean,
  IsSignUpEnabled: boolean,
  RequireExpenses: boolean,
  RequireMortgageData: boolean,
  InactiveReason?: ListObject,
  LastUpdatedStageDate?: string,
}

export interface ProgramFeatures {
  IsEligibleExpensesEnabled: boolean,
  IsSignUpEnabled: boolean,
  RequireExpenses: boolean,
  RequireMortgageData: boolean
}

export interface Disbursement {
  DisbursementDate?: string,
  Servicer: string,
  Amount?: number,
  Status: string,
  Type: string
}

export interface HomeownerApplicationStateData {
  ApplicationStatus: ApplicationStatus,
  Counselor?: TeamMember,
  Admin?: TeamMember,
  Clerk?: TeamMember,
  ProgramSummaries?: ProgramSummary[],
  Disbursements?: Disbursement[],
  personal: PersonalFormData,
  employment: EmploymentFormData,
  property: PropertyFormData,
  mortgage: MortgageFormData,
  financial: FinancialFormData,
  notEligible: NotEligibleFormData
}

export interface NotEligibleFormData {
  IsRequestGivenForEmailOfResults: boolean,
  IsPermissionGivenForContactByHUD: boolean
}

export interface PersonalFormData {
  CoBorrowerPrimaryPhoneBestTimeToCall: number,
  CoBorrowerSecondaryPhoneBestTimeToCall: number,
  CoBorrowerEmail: string,
  CoBorrowerPrimaryPhoneNumber: string,
  CoBorrowerPrimaryPhoneType: number,
  CoBorrowerSecondaryPhoneNumber: string,
  CoBorrowerSecondaryPhoneType: number,
  CoBorrowerDateOfBirth: string,
  CoBorrowerFirstName: string,
  CoBorrowerLastName: string,
  CoBorrowerMiddleName: string,
  CoBorrowerSSN: string | null,
  CoBorrowerAddress1: string,
  CoBorrowerAddress2: string,
  CoBorrowerCity: string,
  CoBorrowerCounty: number,
  CoBorrowerState: number,
  CoBorrowerZipCode: string,
  CoBorrowerIsVeteran: number,
  BorrowerEmail: string,
  BorrowerPrimaryPhoneBestTimeToCall: number,
  BorrowerSecondaryPhoneBestTimeToCall: number,
  BorrowerPrimaryPhoneNumber: string,
  BorrowerPrimaryPhoneType: number,
  BorrowerSecondaryPhoneNumber: string,
  BorrowerSecondaryPhoneType: number,
  BorrowerDateOfBirth: string,
  BorrowerFirstName: string,
  IsThereACoBorrower: number,
  IsCoBorrowerSpouse: number,
  IsThereASpouse: number,
  BorrowerLastName: string,
  MaritalStatus: number,
  BorrowerMiddleName: string,
  BorrowerSSN: string | null,
  BorrowerAddress1: string,
  BorrowerAddress2: string,
  BorrowerCity: string,
  BorrowerCounty: number,
  BorrowerState: number,
  BorrowerZipCode: string,
  BorrowerIsVeteran: number,
  HardshipReason: number,
  HardshipStatement: string | null,
  SpouseFirstName: string,
  SpouseMiddleName: string,
  SpouseLastName: string,
  SpouseSSN: string | null,
  SpouseDateOfBirth: string,
  SpouseAddress1: string,
  SpouseAddress2: string,
  SpouseCity: string,
  SpouseCounty: number,
  SpouseState: number,
  SpouseZipCode: string,
  SpouseIsVeteran: number,
  SpousePrimaryPhoneNumber: string,
  SpousePrimaryPhoneType: number,
  SpousePrimaryPhoneBestTimeToCall: number,
  SpouseEmail: string,
  SpouseSecondaryPhoneNumber: string,
  SpouseSecondaryPhoneType: number,
  SpouseSecondaryPhoneBestTimeToCall: number,
  HardshipOther: string,
  Property_Address1: string,
  Property_Address2: string,
  Property_City: string,
  Property_County: number,
  Property_State: number,
  Property_ZipCode: string,
  Property_TotalNumberofPersonsLivingAtThisAddress: number,
  Property_NumberOfDependentsAtAddress: number,
  Property_IsSameAsMailingAddress: boolean,
  BorrowerGender: number,
  BorrowerRaces: number[],
  BorrowerEthnicity: number,
  CoBorrowerGender: number,
  CoBorrowerRaces: number[],
  CoBorrowerEthnicity: number,
  SpouseGender: number,
  SpouseRaces: number[],
  SpouseEthnicity: number,
  IsSociallyDisadvantaged: number,
  PreferredLanguage: number,
  IsEmailAlertsEnabled: boolean
}
export interface EmploymentFormData {
  IsBorrowerEligibleForUnemployment: boolean,
  IsCoBorrowerEligibleForUnemployment: boolean,
  IsSpouseEligibleForUnemployemnt: boolean,
  BorrowerBenefitStartDate: string,
  BorrowerBenefitAmountMonthly: number,
  BorrowerBenefitProjectedEndDate: string,
  CoBorrowerBenefitStartDate: string,
  CoBorrowerBenefitAmountMonthly: number,
  CoBorrowerBenefitProjectedEndDate: string,
  SpouseBenefitStartDate: string,
  SpouseBenefitAmountMonthly: number,
  SpouseBenefitProjectedEndDate: string,
  IsBorrowerCurrentlyEmployed: number,
  BorrowerEmployerName: string,
  BorrowerEmployerAddress: string,
  BorrowerEmployerCity: string,
  BorrowerEmployerState: number,
  BorrowerEmployerCounty: number,
  BorrowerEmployerZipCode: string,
  BorrowerEmployerPhone: string,
  BorrowerHowLongWithEmployer: number,
  BorrowerSelfEmployedCompanyName: string,
  IsCoBorrowerCurrentlyEmployed: number,
  CoBorrowerEmployerName: string,
  CoBorrowerEmployerAddress: string,
  CoBorrowerEmployerCity: string,
  CoBorrowerEmployerState: number,
  CoBorrowerEmployerCounty: number,
  CoBorrowerEmployerZipCode: string,
  CoBorrowerEmployerPhone: string,
  CoBorrowerHowLongWithEmployer: number,
  CoBorrowerSelfEmployedCompanyName: string
}
export interface PropertyFormData {
  Property_LastKnownAppraisedValue: number,
  Property_Intention: string,
  Property_Type: string,
  Property_Occupied: string,
  Property_WhoPaysTaxes: number,
  Property_AreTaxesCurrent: number,
  Property_HasCondoOrHoaFees: number,
  Property_CondoOrHoaFeesPaidTo: string,
  Property_CondoOrHoaFeesAmount: number,
  Property_WhoPaysHazardInsurance: number,
  Property_HazardInsuranceIsCurrent: number,
  Property_HazardInsuranceCompanyName: string,
  Property_HazardInsuranceCompanyPhone: string,
  Property_HasJudgements: boolean,
  Property_JudgementsDescription: string,
  EligibleExpenses: EligibleExpenseFields[]
}

export interface MortgageFormFields {
  IsOtherLender: boolean,
  OtherLenderName: string,
  IsOtherServicer: boolean,
  OtherServicerName: string,
  Servicer: number,
  ServicerPhoneNumber: string,
  Lender: number,
  LoanNumber: string,
  Balance: number,
  DelinquencyStatus: number,
  Payment: number,
  PaymentDueDate: number,
  InterestRate: number,
  LoanType: number,
  LoanProgram: number,
  AdjustmentDueDate: string,
  ForeclosureReceived: number,
  ForeclosureReceivedDate: string,
  IsInForbearance: number,
  ForbearanceEndDate: string,
  ForbearanceDetails: string,
  ForeclosureSaleScheduled: number,
  ForeclosureSaleDate: string
}

export interface MortgageFormData {
  Mortgages: MortgageFormFields[]
}

export interface FinancialFormData {
  IncomeSources_BorrowerMonthly: IncomeSource[],
  IncomeSources_CoBorrowerMonthly: IncomeSource[],
  IncomeSources_SpouseMonthly: IncomeSource[],
  Expenses_Other: ExpenseObject[],
  Bankruptcy_WasFiled: boolean,
  Bankruptcy_Type: number,
  Bankruptcy_IsDischarged: boolean,
  Bankruptcy_CaseNumber: string,
  Bankruptcy_FilingDate: string
}

export enum WarningType {
  MaxThreshold = 'MAXTHRESHOLD'
}

export interface ProfileFormData {
  IsEmailAlertsEnabled: boolean
}

export interface UserProfile {
  IsEmailAlertsEnabled: boolean
  EmailAddress: string
}
