import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { ListObject } from 'src/app/shared/interfaces';
import { PrequalAnswer } from 'src/app/shared/interfaces/prequal-answer.interface';
import { PrequalQuestion } from 'src/app/shared/interfaces/prequal-question.interface';
import { ApplicationsDashboardData } from 'src/app/store/reducers/multi-application.reducers';
import { selectAppData, selectDiscoveryTypesList, selectFeatureToggles, selectMostRecentCopyableApplicationId, selectSelectedApplicationId, selectUserId, selectUsername, selectUserProfile } from 'src/app/store/selectors';
import { environment as env } from '../../../environments/environment';
import { HomeownerApplicationApiData, HomeownerApplicationStateData, Signature, Signer, SupportingFile, UploadedFile, UserProfile } from '../../homeowner-application/interfaces/homeowner-application-form.interface';

@Injectable({
  providedIn: 'root'
})
export class HomeownerApplicationApiService {

  userId = '';

  constructor(private http: HttpClient, private store: Store) {
    this.store.select(selectUserId).subscribe(value => this.userId = value);
  }

  get baseApiUrl(): string {
    return `${env.apiUrl}/applicants/${this.userId}/applications`;
  }

  checkEligibility(answersCertifiedAccurate: boolean, answers: PrequalAnswer[]): Observable<any> {
    const params = new HttpParams().set('answersCertifiedAccurate', answersCertifiedAccurate.toString());
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      first(),
      mergeMap(applicationId => this.http.post(`${this.baseApiUrl}/${applicationId}/answers`, answers, { params: params }))
    );
  }

  /**
   * 2 flows for choosing a program:
   *  (1) A user creates a profile which in turn creates an application that they will use to select a program for
   *  (2) A user already has an application or applications, and they "Start A New Application" from the applications
   *      dashboard. This will select undefined for selectedApplicationId and pass -1 to the api endpoint. In turn,
   *      the API will not find a matching application and will create a new application with the given program.
   * 
   * Returns the newly created application
   */
  chooseProgram(programId: number, categoryId: number = 0, discoveryTypeId: number, discoveryOtherText: string): Observable<HomeownerApplicationApiData> {
    let params = new HttpParams().set('programId', programId.toString());
    if (!!categoryId) {
      params = params.append('programCategoryId', categoryId.toString());
    }
    params = params.append('discoveryTypeId', discoveryTypeId.toString());
    if (!!discoveryOtherText) {
      params = params.append('discoveryOther', discoveryOtherText);
    }

    return this.store.select(selectSelectedApplicationId).pipe(
      first(),
      mergeMap(applicationId => {
        const appId = !!applicationId ? applicationId : -1;
        return this.http.post<HomeownerApplicationApiData>(`${this.baseApiUrl}/${appId}/program`, null, { params: params })
      })
    );
  }

  deleteFile(fileId: number): Observable<any> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.delete(`${this.baseApiUrl}/${applicationId}/uploadedfiles/${fileId}`))
    );
  }

  downloadFile(fileId: number): Observable<any> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get(`${this.baseApiUrl}/${applicationId}/uploadedfiles/${fileId}`, { responseType: 'blob' }))
    );
  }

  downloadSupportingFile(fileId: number): Observable<any> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get(`${this.baseApiUrl}/${applicationId}/supportingfiles/${fileId}`, { responseType: 'blob' }))
    );
  }

  fetchSingleApplicationForLogin(): Observable<HomeownerApplicationApiData> {
    return this.http.get<HomeownerApplicationApiData>(`${env.apiUrl}/applicants/${this.userId}/application`);
  }
  
  /**
   * Fetch an application by ID
   * ID parameter is fetched when an application is loaded for the first time
   * Else fetch the application data based on the store's selected application ID value
   * @param applicationId 
   * @returns 
   */
  getApplication(applicationId: number | null = null): Observable<HomeownerApplicationApiData> {
    if (!!applicationId) {
      return this.http.get<HomeownerApplicationApiData>(`${this.baseApiUrl}/${applicationId}`);
    } else {
      return this.store.select(selectSelectedApplicationId).pipe(
        filter(applicationId => !!applicationId),
        mergeMap(applicationId => this.http.get<HomeownerApplicationApiData>(`${this.baseApiUrl}/${applicationId}`))
      );
    }
  }

  getApplications(): Observable<ApplicationsDashboardData> {
    return this.http.get<ApplicationsDashboardData>(`${this.baseApiUrl}`);
  }

  getApplicationSignatures(): Observable<Signature[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<Signature[]>(`${this.baseApiUrl}/${applicationId}/signatures`))
    );
  }

  getDocumentStatus(): Observable<Signer[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<Signer[]>(`${this.baseApiUrl}/${applicationId}/documents`))
    );
  }

  getHardshipReasons(): Observable<ListObject[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<ListObject[]>(`${this.baseApiUrl}/${applicationId}/hardshipreasons`))
    );
  }

  getMortgageLenders(): Observable<ListObject[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<ListObject[]>(`${this.baseApiUrl}/${applicationId}/lenders`))
    );
  }

  getMortgageServicers(): Observable<ListObject[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<ListObject[]>(`${this.baseApiUrl}/${applicationId}/servicers`))
    );
  }

  getPrequalQuestions(): Observable<PrequalQuestion[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<PrequalQuestion[]>(`${this.baseApiUrl}/${applicationId}/questions`))
    );
  }

  getSupportingFiles(): Observable<SupportingFile[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<SupportingFile[]>(`${this.baseApiUrl}/${applicationId}/supportingfiles`))
    );
  }

  getUploadedFiles(): Observable<UploadedFile[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.get<UploadedFile[]>(`${this.baseApiUrl}/${applicationId}/uploadedfiles`))
    );
  }

  registerUser(isEmailAlertsEnabled = false): Observable<any>{
    let params = new HttpParams();
    if (isEmailAlertsEnabled) {
      params = params.append('isEmailAlertsEnabled', true);
    }
    return this.http.post(`${env.apiUrl}/users`, null, { params: params });
  }

  reSendDocuments(roles: string[]): Observable<any> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.put(`${this.baseApiUrl}/${applicationId}/documents`, roles))
    );
  }

  sendDocuments(): Observable<any> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.post(`${this.baseApiUrl}/${applicationId}/documents`, null))
    );
  }

  submitApplication(data: HomeownerApplicationStateData): Observable<any> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.post(`${this.baseApiUrl}/${applicationId}/submit`, data))
    );
  }

  updateApplicationFromCopy(){
    const data$ = this.store.select(selectAppData);
    const username$ = this.store.select(selectUsername);
    const copyFromApplicationId$ = this.store.select(selectMostRecentCopyableApplicationId);

    return combineLatest([
      copyFromApplicationId$, 
      data$, 
      username$
    ]).pipe(
      first(),
      mergeMap(([copyFromApplicationId, data, username]) => {
        const bodyData: HomeownerApplicationApiData = { ...data, BorrowerEmail: username };
        
        return this.http.put<HomeownerApplicationApiData>(`${this.baseApiUrl}/${copyFromApplicationId}/copy`, bodyData);
      })
    );
  }

  updateUser(){
    const data$ = this.store.select(selectUserProfile);
    return data$.pipe(
      first(),
      mergeMap(data => {
        const bodyData: UserProfile = { ...data};
        return this.http.put<UserProfile>(`${env.apiUrl}/users/${this.userId}`, bodyData);
      })
    );
  }

  /**
   * Set DiscoveryTypeId on the PUT body if it is not yet defined
   * Set or null out the IsSociallyDisadvantaged value on the body based on the feature toggle value
   * @param isPrequalStepOne 
   */
  updateApplication(isPrequalStepOne = false): Observable<HomeownerApplicationApiData> {
    const data$ = this.store.select(selectAppData);
    const username$ = this.store.select(selectUsername);
    const applicationId$ = this.store.select(selectSelectedApplicationId);
    const toggles$ = this.store.select(selectFeatureToggles);
    const discoveryTypes$ = this.store.select(selectDiscoveryTypesList).pipe(
      filter(d => !!d && d.length > 0)
    );
    /**
    * Ensure that the BorrowerEmail in the application and the email being used by Cognito remain in sync.
    */
    return combineLatest([
      applicationId$, 
      data$, 
      username$, 
      toggles$,
      discoveryTypes$
    ]).pipe(
      first(),
      mergeMap(([applicationId, data, username, toggles, discoveryTypes]) => {
        const bodyData: HomeownerApplicationApiData = { ...data, BorrowerEmail: username };
        /**
         * Only PUT mortgages that have a Servicer selected OR
         * if an "other" servicer is entered
         */
        if (!!bodyData.Mortgages && bodyData.Mortgages.length > 0) {
          bodyData.Mortgages = bodyData.Mortgages.filter(mortgage => typeof mortgage.Servicer?.Id === 'number' || !!mortgage.IsOtherServicer);
        }
        const body = !!isPrequalStepOne ? {
          ...bodyData, 
          Property_TotalNumberofPersonsLivingAtThisAddress: null,
          IsSociallyDisadvantaged: !!toggles.IsSociallyDisadvantagedEnabled ? bodyData.IsSociallyDisadvantaged : null,
          DiscoveryTypeId: this.getDiscoveryTypeId(bodyData.DiscoveryTypeId, discoveryTypes)
        } : {
          ...bodyData,
          IsSociallyDisadvantaged: !!toggles.IsSociallyDisadvantagedEnabled ? bodyData.IsSociallyDisadvantaged : null,
          DiscoveryTypeId: this.getDiscoveryTypeId(bodyData.DiscoveryTypeId, discoveryTypes)
        };
        return this.http.put<HomeownerApplicationApiData>(`${this.baseApiUrl}/${applicationId}`, body);
      })
    );
  }

  /**
   * Return the existing id if it is truthy and a number
   * Else return the id associated with the 'other' discovery type
   * Fallback will default to 6 (current value for other) if no id chose from first 2 paths
   * @param id 
   * @param discoveryTypes (from ngrx store)
   */
  getDiscoveryTypeId(id: number, discoveryTypes: ListObject[] | null): number {
    const otherId = discoveryTypes?.find(d => d?.Name?.toLowerCase().includes('other'));
    return !!id && typeof(id) === 'number' ? id : (otherId?.Id || 6);
  }

  updateApplicationSignatures(body: Signature[]): Observable<Signature[]> {
    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.put<Signature[]>(`${this.baseApiUrl}/${applicationId}/signatures`, body))
    );
  }

  uploadFile(file: File, categoryId: number, supportingFileId?: number): Observable<any> {
    let params = new HttpParams().set('fileCategory', categoryId.toString());
    if (typeof supportingFileId === 'number') {
      params = params.append('supportingFile', supportingFileId.toString());
    }
    const formData = new FormData();
    formData.append('file', file);

    return this.store.select(selectSelectedApplicationId).pipe(
      filter(applicationId => !!applicationId),
      mergeMap(applicationId => this.http.put(`${this.baseApiUrl}/${applicationId}/uploadedfiles`, formData, { params: params }))
    );
  }
}
