import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IAuthService } from 'src/app/shared/services';
import { CognitoAuthService } from 'src/app/shared/services/cognito-auth-service.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RegistrationInterceptor implements HttpInterceptor {

  constructor(
    @Inject(CognitoAuthService) private authService: IAuthService,
  ) {}

    /**
     * For the users endpoint, include x-id-token in the headers
     */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(`${environment.apiUrl}/users`)) {
      return next.handle(request);
    }

    return this.authService
      .getIdToken()
      .pipe(
        switchMap(idToken => {
          const authReq = request.clone({ 
            headers: request.headers.set('x-id-token', idToken) 
          });
      
          return next.handle(authReq);
        })
      );
  }
}
