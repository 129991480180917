import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { Signature } from 'src/app/homeowner-application/interfaces';
import { ApiActions, AppInfoActions } from '../actions';
import { selectAppDataToSubmit, selectFeatureToggles, selectPersonalForm } from '../selectors';
import { hasSpouse } from '../selectors/app-form-selector.functions';

@Injectable()
export class AppSubmitEffects {

  /**
   * Trigger an application submit flow
   */
  initiateAppSubmitFlow$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.initiateSubmitAppFlow),
      mergeMap(action => combineLatest([
        this.store.select(selectFeatureToggles), 
        this.store.select(selectPersonalForm)
      ]).pipe(
        first(),
        map(([toggles, personalForm]) => {
          let returnAction = null;
          if (!toggles.IsDocusignEnabled) {
            const appHasSpouse = !personalForm.get('Borrower.IsCoBorrowerSpouse')?.value && hasSpouse(personalForm.get('Borrower.MaritalStatus')?.value) ? true : false;
            const appHasCoborrower = !!personalForm.get('Borrower.IsThereACoBorrower')?.value ? true : false;
            returnAction = ApiActions.submitSignatures({ formValue: action.signatureFormValue, hasCoborrower: appHasCoborrower, hasSpouse: appHasSpouse });
          } else {
            returnAction = ApiActions.getDataToSubmit();
          }
          return returnAction;
        }))
      ))
  );

  /**
   * Submit Application Signatures
   * 
   * On success, continue to submit the application
   * On error, return action that updates error state
   */
  submitSignatures$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.submitSignatures),
      mergeMap(action => {
        const body: Signature[] = [{
          Name: action.formValue.BorrowerSignature,
          Role: action.formValue.BorrowerRole,
          SignedOn: action.formValue.BorrowerSignatureDate
        }];
        if (action.hasCoborrower) {
          body.push({
            Name: action.formValue.CoBorrowerSignature,
            Role: action.formValue.CoBorrowerRole,
            SignedOn: action.formValue.CoBorrowerSignatureDate
          });
        }
        if (action.hasSpouse) { 
          body.push({
            Name: action.formValue.SpouseSignature,
            Role: action.formValue.SpouseRole,
            SignedOn: action.formValue.SpouseSignatureDate
          });
        }
        return this.appApi.updateApplicationSignatures(body).pipe(
          first(),
          map(_ => ApiActions.getDataToSubmit()),
          catchError(e => of(ApiActions.submitSignaturesError({ error: e })))
        )})
  ));

  /**
   * Select Homeowner Application Data to Submit
   */
   getAppDataToSubmit$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.getDataToSubmit),
      mergeMap(() => this.store.select(selectAppDataToSubmit).pipe(
        first(),
        map(data => ApiActions.completeSubmitApp({ data: data }))
      ))
  ));

  /**
   * Submit Homeowner Application
   * Success or error will update submitted state
   */
  completeSubmitApp$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.completeSubmitApp),
      mergeMap(action => this.appApi.submitApplication(action.data).pipe(
        first(),
        map(_ => ApiActions.submitAppSuccess()),
        catchError(e => of(ApiActions.submitAppError({ error: e })))
      ))
  ));

  /**
   * On App Submit Success, GET latest application and update in state
   */
  fetchNewApplicationOnSubmitSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.submitAppSuccess),
      mergeMap(_ => this.appApi.getApplication().pipe(
        map(data => AppInfoActions.updateAppState({ data: data }))
      ))
  ));
 
  constructor(private actions$: Actions, private store: Store, private appApi: HomeownerApplicationApiService) {}
}