import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { FormNames, FormStatus } from 'src/app/homeowner-application/interfaces';
import { AnalyticsActions, ApiActions, AppInfoActions, MessagesActions, UserActions } from '../actions';
import { selectMainFlowForms } from '../selectors';
import { isPrequalified } from '../selectors/app-data-selector.functions';

@Injectable()
export class AppInfoEffects {
  /**
   * Update the initial form statuses based on the data
   */
  setInitialFormStatus$ = createEffect(() => this.actions$.pipe(
    ofType(AppInfoActions.setInitialFormStatus),
    mergeMap(() => {
      return this.store.select(selectMainFlowForms).pipe(
        first(),
        mergeMap(forms => of(
          AppInfoActions.updateAppFormStatusAndVisited({ status: forms.personal.status as FormStatus, formName: FormNames.Personal }),
          AppInfoActions.updateAppFormStatusAndVisited({ status: forms.employment.status as FormStatus, formName: FormNames.Employment }),
          AppInfoActions.updateAppFormStatusAndVisited({ status: forms.property.status as FormStatus, formName: FormNames.Property }),
          AppInfoActions.updateAppFormStatusAndVisited({ status: forms.mortgage.status as FormStatus, formName: FormNames.Mortgage }),
          AppInfoActions.updateAppFormStatusAndVisited({ status: forms.financial.status as FormStatus, formName: FormNames.Financial })
        ))
      )
    })
  ));

  updatePrequalificationStep$ = createEffect(() => this.actions$.pipe(
    ofType(AppInfoActions.updatePrequalificationForm),
    mergeMap(_ => this.appApi.updateApplication(true)),
    mergeMap(_ => this.appApi.updateApplication()),
    mergeMap(data => [
      AppInfoActions.updateAppState({ data: data }),
      AppInfoActions.updatePrequalificationError({hasError: false, error: null})
    ]),
    catchError(e => of(AppInfoActions.updatePrequalificationError({hasError: true, error: e})))
  ));

  /**
   * Check Eligibility as part of prequalification process
   */
  checkEligibility$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.checkEligibility),
    mergeMap(action => this.appApi.checkEligibility(action.answersCertifiedAccurate, action.answers).pipe(first())),
    mergeMap(_ => this.appApi.getApplication().pipe(first())),
    mergeMap(appData => {
      const checkEligibilityResponse = isPrequalified(appData);
      return [
        MessagesActions.updateLoadingMessage({ message: '' }),
        AppInfoActions.updateAppState({ data: appData }),
        UserActions.updateJustPrequalified({ justPrequalified: true }),
        ApiActions.checkEligibilitySuccess({ response: checkEligibilityResponse })
      ]
    }),
    catchError(e => [
      ApiActions.checkEligibilityError({ error: e }),
      MessagesActions.updateLoadingMessage({ message: '' })
    ])
  ));

  /**
   * Dispatch Analytics action based on successful resposne from 
   * /answers POST endpoint
   */
  checkEligibilitySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.checkEligibilitySuccess),
    mergeMap(action => of(AnalyticsActions.logCustomEvent({
      event: {
        action: !!action.response ? 'approved' : 'denied',
        category: Constants.PREQUAL_CATEGORY,
        label: Constants.PREQUAL_CATEGORY
      }
    })))
  ));

  updatePrequalStep$ = createEffect(() => this.actions$.pipe(
    ofType(AppInfoActions.updatePrequalificationStep),
    mergeMap(action => of(AppInfoActions.updatePrequalificationStepSuccess({step: action.step})))
  ));
 
  constructor(private actions$: Actions, private appApi: HomeownerApplicationApiService, private store: Store) {}
}