<ng-container *ngIf="pageType$ | async as pageType">
    <nav class="navbar navbar-dark fixed-top px-2 pt-0" [ngClass]="{'static-header-tablet': pageType=='static' || !(isLoggedIn$ | async) || !(applicationIsActive$ | async)}" title="Top Navbar" *transloco="let t;">
      <button class="d-lg-none navbar-toggler" [ngClass]="{'d-md-none': pageType=='static' || !(isLoggedIn$ | async) || !(applicationIsActive$ | async)}" type="button" data-toggle="collapse" data-target="#appNavbar" aria-controls="appNavbar" aria-expanded="false" aria-label="Toggle menu" (click)="toggleMenu()">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16" *ngIf="isMenuOpen$ | async" alt="Menu Open">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!(isMenuOpen$ | async)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16" alt="Menu Closed">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </ng-container>{{t('header.menu')}}
      </button>
      <div class="agency-container">
        <button (click)="skipTo(isPageStatic ? 'main-content' : 'sidebar')" class="bypass-block-menu d-none d-lg-block" [attr.aria-label]="isPageStatic ? t('header.skipMain') : t('header.skipNav')" tabindex="0">{{isPageStatic ? t('header.skipMain') : t('header.skipNav')}}</button>
        <button (click)="skipTo('main-content')" class="bypass-block-menu d-block d-lg-none" aria-label="Skip to Main Content" tabindex="0">{{t('header.skipMain')}}</button>
        <button (click)="goHome()" class="btn btn-outline-none p-0" aria-label="Go home"><app-image src="assets/img/logo.png" class="app-logo" title="Agency Logo" alt="Agency Logo"></app-image></button>
        <div *ngIf="areLanguageChangesEnabled" class="languages">
          <select (change)="changeLanguage($event)">
            <option *ngFor="let lang of languages" [value]="lang.value">{{lang.name}}
            </option>
          </select>
        </div>
      </div>
      
      <div class="nav-links">
        <a 
          *ngIf="isLoggedIn$ | async" 
          (click)="clickProfile()" 
          href="javascript:void(0);"
          title="My Profile"
          class="d-none d-md-block">
          <span class="mx-1">{{t('pages.profile')}}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
          </svg>
        </a>
        <a class="d-none d-md-block" *ngIf="helpLinks | async as helpLink" [href]="util.getHelpLink(currentRoute, helpLink, prequalStep)" target="_blank" title="Help" (click)="trackEvent('commonWords.help')">
          <span class="mx-1">{{t('commonWords.help')}}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
          </svg>
        </a>
        <a 
          *ngIf="isLoggedIn$ | async" 
          href="javascript:void(0);"
          (click)="logout()" 
          title="Log Out"
          class="d-block">
          <span class="mx-1">{{t('commonWords.logOut')}}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
          </svg>
        </a>
      </div>
      <app-menu *ngIf="(isMenuOpen$ | async)"
        class="d-lg-none"
        [isSubmitted]="isSubmitted$ | async"
        (smallMenuSelected)="toggleMenu()">
      </app-menu>
    </nav>
</ng-container>