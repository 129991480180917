import { Action, createReducer, on } from '@ngrx/store';
import { FormStatus, SupportingFile, UploadedFile } from 'src/app/homeowner-application/interfaces';
import { SupportingFilesActions } from '../actions';

export const supportingFilesFeatureKey = 'supportingFiles';

export interface SupportingFileAndUploads {
  supportingFile: SupportingFile,
  uploadedFiles: UploadedFile[],
  status: FormStatus
}

export interface SupportingFilesState {
  supportingFiles: SupportingFile[],
  uploadedFiles: UploadedFile[]
}

export const initialState: SupportingFilesState = {
  supportingFiles: [],
  uploadedFiles: []
};

const supportingFilesReducer = createReducer(
  initialState,
  on(SupportingFilesActions.updateUploadedFiles, (state, { files }) => {
    return ({ ...state, uploadedFiles: files })
  }),
  on(SupportingFilesActions.updateSupportingAndUploadedFiles, (state, { sFiles, uFiles}) => {
    return ({ ...state, supportingFiles: sFiles, uploadedFiles: uFiles})
  }),
  on(SupportingFilesActions.reset, (state) => {
    return ({ ...state, supportingFiles: [], uploadedFiles: []})
  })
);

export function reducer(state: SupportingFilesState | undefined, action: Action) {
  return supportingFilesReducer(state, action);
}