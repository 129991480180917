import { createAction, props } from '@ngrx/store';
import { AnalyticsCustomEvent } from 'src/app/shared/interfaces/analytics.interface';

export const initGoogleAnalytics = createAction(
  '[Google Analytics] Init'
);

export const logCustomEvent = createAction(
  '[Google Analytics] Log Custom event',
  props<{ event: AnalyticsCustomEvent }>()
);

export const logPageView = createAction(
  '[Google Analytics] Log Page View',
  props<{ url: string }>()
);

export const analyticEventCompleted = createAction(
  '[Google Analytics] Completed Analytics Event'
);

export const analyticEventNotLogged = createAction(
  '[Google Analytics] Did NOT Log Analytics Event'
);

export const initHotjar = createAction(
  '[Hotjar] Init'
);

export const initHotjarComplete = createAction(
  '[Hotjar] Init Complete'
);