<div class="card mb-5" *transloco="let t;">
    <div class="card-header align-items-center">
        <h2 [id]="category.Id+'header'+categoryIndex" class="mt-1" tabindex="0">{{type}} {{t('propertyCard.expenses')}}</h2>
    </div>
    <div class="card-body">
        <ng-container *ngFor="let formGroup of formGroups; let i=index">
            <div class="mt-2" [formGroup]="formGroup">
                <div class="card-container" *ngIf="i==0">
                    <div class="field mb-4">
                        <label for="has{{type}}Expense{{i}}" class="required">{{t('propertyCard.wantToAdd', {context: type.toLowerCase()})}}</label>
                        <div class="form-check form-switch">
                            <div class="mt-2">
                                <input class="form-check-input" type="checkbox" [attr.aria-labelledby]="'has'+formatLabel(type)+'Expense'+i+'Label'" role="switch" id="has{{formatLabel(type)}}Expense{{i}}Input" (change)="handleToggle($event)" [checked]="displayExpenses">
                                <label for="has{{formatLabel(type)}}Expense{{i}}Input" id="has{{formatLabel(type)}}Expense{{i}}Label" aria-hidden="true" class="form-check-label">{{ displayExpenses ? t('commonWords.yes') : t('commonWords.no') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="displayExpenses">
                    <div class="pt-4 pb-3" *ngIf="i>0">
                        <label class="text-uppercase border-bottom w-100" id="additionalExpenseLabel{{category.Id}}{{i}}" tabindex="-1" for="additionalExpense{{i}}">{{t('propertyCard.additionalExpense')}} {{i}}</label>
                    </div>
                    <div class="card-container">
                        <div class="field mb-4">
                            <label for="{{type}}ExpenseType{{i}}" class="required">{{t('propertyCard.type')}}</label>
                            <select class="form-select" formControlName="ExpenseType" id="{{type}}ExpenseType{{i}}">
                                <option selected value></option>
                                <ng-container *ngIf="(expenseTypes$ | async) as expenseTypes" >
                                    <ng-container *ngFor="let expenseType of expenseTypes.typesList">
                                        <option *ngIf="showOption(expenseType.IsActive, i, 'ExpenseType', expenseType.Id)" [ngValue]="expenseType.Id">{{getOptionDisplayName(expenseType)}}</option>
                                    </ng-container>
                                </ng-container>
                            </select>
                            <form-message *ngIf="isInvalid('ExpenseType', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('ExpenseType')"></form-message>
                        </div>
                        <div class="field mb-4" *ngIf="(providerList$ | async) as providerList">
                            <label for="{{type}}Provider{{i}}" class="required">{{t('propertyCard.provider')}}</label>
                            <select class="form-select" formControlName="Provider" id="{{type}}Provider{{i}}" (change)="checkOtherProvider($event, i)">
                                <option selected value></option>
                                <ng-container *ngFor="let provider of providerList.providerList">
                                    <option *ngIf="showOption(provider.IsActive, i, 'Provider', provider.Id)" [ngValue]="provider.Id">{{getOptionDisplayName(provider)}}</option>
                                </ng-container>
                                <option [ngValue]="-1">{{t('picklist.other')}}</option>
                            </select>
                            <form-message *ngIf="isInvalid('Provider', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('Provider')"></form-message>
                        </div>
                        <div class="field mb-4" *ngIf="formGroup.get('IsOtherProvider')?.value">
                            <label for="{{type}}OtherProviderName{{i}}" class="required">{{t('propertyCard.providerOther')}}</label>
                            <input type="text" class="form-control" formControlName="OtherProviderName" id="{{type}}OtherProviderName{{i}}">
                            <form-message *ngIf="isInvalid('OtherProviderName', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('OtherProviderName')"></form-message>
                        </div>
                        <div class="field mb-4">
                            <label for="{{type}}ProviderPhoneNumber{{i}}" class="required">{{t('propertyCard.phone')}}</label>
                            <input type="tel" mask="000-000-0000" class="form-control" formControlName="ProviderPhoneNumber" id="{{type}}ProviderPhoneNumber{{i}}" placeholder="###-###-####">
                            <form-message *ngIf="isInvalid('ProviderPhoneNumber', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('ProviderPhoneNumber')"></form-message>
                        </div>
                        <div class="field mb-4">
                            <label for="{{type}}AccountNumber{{i}}" class="required">{{t('propertyCard.accountNumber')}}</label>
                            <input type="text" class="form-control" formControlName="AccountNumber" id="{{type}}AccountNumber{{i}}">
                            <form-message *ngIf="isInvalid('AccountNumber', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('AccountNumber')"></form-message>
                        </div>
                        <div class="field mb-4">
                            <label for="{{type}}PaymentDueDate{{i}}" class="required">{{t('propertyCard.dueDate')}}</label>
                            <select class="form-select" *ngIf="paymentDueDateList$ | async as dueDates" formControlName="PaymentDueDate" id="{{type}}PaymentDueDate{{i}}">
                                <option selected value></option>
                                <ng-container *ngFor="let dueDate of dueDates">
                                    <option *ngIf="showOption(dueDate.IsActive, i, 'PaymentDueDate', dueDate.Id)" [ngValue]="dueDate.Id">{{getOptionDisplayName(dueDate)}}</option>
                                </ng-container>
                            </select>
                            <form-message *ngIf="isInvalid('PaymentDueDate', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('PaymentDueDate')"></form-message>
                        </div>
                        <div class="field mb-4">
                            <label for="{{type}}Payment{{i}}" class="required">{{t('propertyCard.monthlyAmt')}}</label>
                            <input type="number" class="form-control" formControlName="Payment" id="{{type}}Payment{{i}}" placeholder="$">
                            <form-message *ngIf="isInvalid('Payment', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('Payment')"></form-message>
                        </div>
                        <div class="field mb-4">
                            <label for="{{type}}PaymentPastDue{{i}}" class="required">{{t('propertyCard.pastDue')}}</label>
                            <input type="number" class="form-control" formControlName="PaymentPastDue" id="{{type}}PaymentPastDue{{i}}" placeholder="$">
                            <form-message *ngIf="isInvalid('PaymentPastDue', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('PaymentPastDue')"></form-message>
                        </div>
                        <div class="field mb-4">
                            <label for="{{type}}WhoPays{{i}}" class="required">{{t('propertyCard.whoPays')}}</label>
                            <select class="form-select" formControlName="WhoPays" id="{{type}}WhoPays{{i}}">
                                <option selected value></option>
                                <ng-container *ngFor="let party of whoPaysList$ | async">
                                    <option *ngIf="showOption(party.IsActive, i, 'WhoPays', party.Id)" [ngValue]="party.Id">{{getOptionDisplayName(party)}}</option>
                                </ng-container>
                            </select>
                            <form-message *ngIf="isInvalid('WhoPays', i)" [cssClass]="ValidClass.Danger" [field]="formGroup.get('WhoPays')"></form-message>
                        </div>
                    </div>
                    <div class="property-btn-grid">
                        <button *ngIf="i>0"
                            [title]="'Delete Expense ' + (i+1)" 
                            [attr.alt]="'Delete Expense ' + (i+1)"
                            [id]="deleteButtonId"
                            class="btn btn-link delete has-svg my-3" 
                            target="_blank" 
                            rel="noopener" 
                            type="button"
                            (click)="delete(i)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>{{t('propertyCard.delete')}} {{t('propertyCard.additionalExpense')}} {{i}}
                        </button>
                        <button *ngIf="i === formGroups.length-1" 
                            type="button" 
                            class="btn btn-primary has-svg my-3" 
                            id="add{{category.Id}}ExpenseButton" 
                            (click)="addExpenseCard()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                            </svg>{{t('propertyCard.addAnother')}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>