import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { RequestCacheService } from 'src/app/core/services/request-cache.service';
import { IAuthService } from 'src/app/shared/services';
import { CognitoAuthService } from 'src/app/shared/services/cognito-auth-service.service';
import { AnalyticsActions, ApiActions, AppInfoActions, ApplictionMessagingActions, MessagesActions, ModalsActions, MultiApplicationActions, SupportingFilesActions, UserActions } from '../actions';
import { selectUserId, selectUsername } from '../selectors';

/**
 * Handle SessionStorage items for a user which allows a user to stay logged
 * in and make api calls even after a refresh.
 *   - Fetching userId & username from sessionStorage
 *     and setting in state
 *   - Setting userId & username in sessionStorage
 */
@Injectable()
export class UserEffects {

  private idKey: string = 'userId';
  private usernameKey: string = 'username';

  handleUserSessionStorage$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.handleUserSessionStorage),
    mergeMap(() => {
      let id = sessionStorage.getItem(this.idKey);
      if (!!id) {
        this.store.dispatch(UserActions.updateId({ id: id }));
      }

      let username = sessionStorage.getItem(this.usernameKey);
      if (!!username) {
        this.store.dispatch(UserActions.updateUsername({ username: username }));
      }

      return of(UserActions.handleUserSessionStorageSuccess());
    }))
  );

  handleUserSessionStorageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.handleUserSessionStorageSuccess),
    mergeMap(() => {
      this.store.select(selectUserId).subscribe(id => {
        sessionStorage.setItem(this.idKey, id);
      });

      this.store.select(selectUsername).subscribe(username => {
        sessionStorage.setItem(this.usernameKey, username);
      });

      return of(UserActions.userIdUpdatedSuccess());
    }))
  );

  handleUserUpdatedEmail$ = createEffect(() => this.actions$.pipe(
    ofType(AppInfoActions.updateEmail),
      mergeMap(action => {
          return from(this.authService.changeEmail(action.email)).pipe(
            map(response => {
              return UserActions.updateEmailSuccess({ data: response });
            }),
            catchError(error => {
              return of(UserActions.updateEmailError({ error: error }));
            })
      )})
    )
  );

  /**
   * This function is called if a user's email address is succesfully changed through Cognito.
   * The function updates Loading events, and logs an event with google analytics.
   * If the PUT application fails following a successful cognito email change, the error is logged, but an event
   * noting that the email changed successfully is still logged with Google Analytics.
   */
  handleUpdateEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.updateEmailSuccess),
      mergeMap(() => this.appApi.updateUser().pipe(
        first(),
        catchError(error => {
          return [
            ApiActions.putAppDataError({ error }),
            MessagesActions.updateIsLoading({ isLoading: false }),
            MessagesActions.updateLoadingMessage({ message: '' }),
            AnalyticsActions.logCustomEvent({
              event: {
                action: Constants.CHANGE_EMAIL_ACTION,
                category: Constants.AUTH_CATEGORY,
                label: Constants.SUCCESS_EVENT_LABEL
              }
            })
          ]
        })
      )),
      mergeMap(() => {
        return [
          MessagesActions.updateIsLoading({ isLoading: false }),
          MessagesActions.updateLoadingMessage({ message: '' }),
          AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.CHANGE_EMAIL_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          })
        ]
      })
    )
  );

  handleUpdateEmailError = createEffect(() => this.actions$.pipe(
    ofType(UserActions.updateEmailError),
      mergeMap(value => {
        return [
          MessagesActions.updateIsLoading({ isLoading: false }),
          MessagesActions.updateLoadingMessage({ message: '' }),
          AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.CHANGE_EMAIL_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: value.error.message
            }
          })
        ]
      })
    )
  );

  /**
   * Reset all state data back to initial values
   * Clear the request cache and session storage
   */
  resetAll$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.resetAll),
      mergeMap(_ => {
        this.requestCacheService.clear();
        sessionStorage.clear();

        return [
          UserActions.reset(),
          MessagesActions.reset(),
          SupportingFilesActions.reset(),
          AppInfoActions.reset(),
          MultiApplicationActions.reset(),
          ModalsActions.updateIsModalOpen({ isOpen: false }),
          ApplictionMessagingActions.reset()
        ];
      })
    )
  );
 
  constructor(
    private actions$: Actions,
    private appApi: HomeownerApplicationApiService,
    private store: Store,
    private requestCacheService: RequestCacheService,
    @Inject(CognitoAuthService) private authService: IAuthService
  ) {}
}