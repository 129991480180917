import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import {
  AlertComponent,
  AppAccordionToggleComponent, AppFormDataDisplayComponent, AppFormStatusIconComponent,
  AppHeaderComponent, AppImageComponent, AppMenuComponent, AppMenuStatusIconComponent,
  AppSigningComponent, AppTileComponent,
  ConfirmDialogComponent,
  FooterComponent,
  FormHeaderComponent,
  FormMessageComponent,
  FormWarningComponent
} from './components';
import { AppBannerComponent } from './components/app-banner/app-banner.component';
import { AppDashboardDataComponent } from './components/app-dashboard-data/app-dashboard-data.component';
import { AppLoaderComponent } from './components/app-loader/app-loader.component';
import { AppScrollTopComponent } from './components/app-scroll-top/app-scroll-top.component';
import { AppSummarySupportingFileDisplayComponent } from './components/app-summary-supporting-file-display/app-summary-supporting-file-display.component';
import { AppTeamMemberComponent } from './components/app-team-member/app-team-member.component';
import { FinancialExpenseComponent } from './components/financial-expense/financial-expense.component';
import { GoogleTranslateComponent } from './components/google-translate/google-translate.component';
import { LoginComponent } from './components/login-workflow/login/login.component';
import { VerifyLoginComponent } from './components/login-workflow/verify-login/verify-login.component';
import { ProfileComponent } from './components/profile-page/profile/profile.component';
import { PropertyInfoCardComponent } from './components/property-info-card/property-info-card.component';
import { SmartyStreetsModalComponent } from './components/smarty-streets-modal/smarty-streets-modal.component';
import { TimeoutModalComponent } from './components/timeout-modal/timeout-modal.component';
import { AlertService, NavService } from './services';
import { ApplicationCardComponent } from './components/application-card/application-card.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

/**
 * Common components/pipes/directives & export commonly used Angular modules
 */
@NgModule({
  declarations: [
    AlertComponent,
    FormHeaderComponent,
    ConfirmDialogComponent,
    FooterComponent,
    FormMessageComponent,
    AppMenuComponent,
    AppHeaderComponent,
    AppMenuStatusIconComponent,
    AppFormStatusIconComponent,
    AppFormDataDisplayComponent,
    GoogleTranslateComponent,
    AppScrollTopComponent,
    AppAccordionToggleComponent,
    AppTeamMemberComponent,
    TimeoutModalComponent,
    AppBannerComponent,
    AppDashboardDataComponent,
    AppLoaderComponent,
    AppTileComponent,
    SmartyStreetsModalComponent,
    FinancialExpenseComponent,
    PropertyInfoCardComponent,
    ProfileComponent,
    LoginComponent,
    VerifyLoginComponent,
    AppSigningComponent,
    AppSummarySupportingFileDisplayComponent,
    AppImageComponent,
    AppBannerComponent,
    ApplicationCardComponent,
    FormWarningComponent
  ],
  exports: [
    AlertComponent,
    FormHeaderComponent,
    ConfirmDialogComponent,
    FooterComponent,
    FormMessageComponent,
    AppMenuComponent,
    AppHeaderComponent,
    AppImageComponent,
    AppFormStatusIconComponent,
    AppFormDataDisplayComponent,
    GoogleTranslateComponent,
    AppScrollTopComponent,
    AppAccordionToggleComponent,
    AppSigningComponent,
    AppSummarySupportingFileDisplayComponent,
    AppTileComponent,
    AppTeamMemberComponent,
    AppBannerComponent,
    AppDashboardDataComponent,
    AppLoaderComponent,
    CommonModule, 
    FormsModule, 
    NgbModule, 
    ReactiveFormsModule, 
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SmartyStreetsModalComponent,
    TimeoutModalComponent,
    FinancialExpenseComponent,
    PropertyInfoCardComponent,
    ProfileComponent,
    LoginComponent,
    VerifyLoginComponent,
    TranslocoModule,
    ApplicationCardComponent,
    FormWarningComponent
  ],
  imports: [
    CommonModule, 
    FormsModule, 
    NgbModule, 
    ReactiveFormsModule, 
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslocoModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  providers: [AlertService, NavService]
})
export class SharedModule { }
