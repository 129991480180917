<ng-container *transloco="let t;">
  <section id="login-form">
      <h2>{{ t('login.twoStep') }}</h2>
      <p>{{ t('login.texted') }}.</p>
      <form *ngIf="form" [formGroup]="form" id="codeForm" (ngSubmit)="onSubmitVerifyLogin()">
        <div class="pb-3" [ngClass]="{'verify-login': authTypeUserSRP}">
          <div class="field">
            <label for="code">{{ t('login.sms') }}</label>
            <input type="tel" class="form-control" [ngClass]="{'border-danger': isInvalidCode}" id="code" formControlName="code" autocomplete="off" placeholder="{{ t('login.enterCode') }}" autofocus>
            <form-message *ngIf="isInvalidCode" [cssClass]="ValidClass.Danger" matchText="Invalid code. Please try again."></form-message>
          </div>
        </div>

        <button class="btn btn-link ps-0 pt-0" type="button" (click)="resend()">{{ t('login.resend') }}</button>

        <div class="py-3" [ngClass]="{'verify-login': authTypeUserSRP}" *ngIf="enableHomeownerPortalRememberMyDevice">
          <div class="field checkbox" [ngClass]="{'d-none': !authTypeUserSRP}">
            <input type="checkbox" class="form-check-input" aria-labelledby="optInLabel" id="optIn" formControlName="optIn">
            <label for="optIn" id="optInLabel" aria-hidden="true">&nbsp;{{ t('login.remember') }}</label>
          </div>
        </div>
      </form>
  </section>
    
  <section id="login-action">
      <button class="btn btn-primary mb-3" (click)="onSubmitVerifyLogin()">
        {{ t('login.verify') }}
      </button>
      <button class="btn btn-outline-primary" (click)="toggleDisplay.emit()">
        {{ t('commonWords.back') }}
      </button>
  </section>
</ng-container>