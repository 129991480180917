import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';

export interface Address {
  Address1: string,
  Address2: string,
  City: string,
  State: string,
  County: string,
  ZipCode: string
}

@Injectable({
  providedIn: 'root'
})
export class AddressValidationService {

  constructor(private http: HttpClient) { }

  lookup(address: string): Observable<Address[]> {
    let params = new HttpParams().set('street', address);
    params = params.append('prefer_states', env.stateAbbreviation);
    params = params.append('candidates', '10');
    return this.http.get<any>(
      `https://us-street.api.smartystreets.com/street-address?key=${env.smartyStreetsKey}`
      , { params })
    .pipe(
      map(response => response.map((val: any) => this.buildAddress(val)))
    );
  }

  /**
   * Build out address 
   * @param a - address response from SmartyStreets
   * @returns Address interface
   */
  buildAddress(a: any): Address {
    const comp = a.components;
    const meta = a.metadata;
    return {
      Address1: `${comp.primary_number} ${comp.street_name} ${comp.street_suffix}`,
      Address2: comp.secondary_designator ? `${comp.secondary_designator} ${comp.secondary_number}` : '',
      City: comp.city_name,
      County: meta.county_name,
      State: comp.state_abbreviation,
      ZipCode: comp.zipcode
    };
  }
}
