import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalyticsActions } from '../actions';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsEffects {
  /**
   * Register Google Analytics scripts if an ID is present
   */
  initGoogleAnalytics$ = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.initGoogleAnalytics),
    mergeMap(_ => {
      if (!!environment.googleAnalyticsId) {
        const gTagManagerScript = document.createElement('script');
        gTagManagerScript.async = true;
        gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
        document.head.appendChild(gTagManagerScript);

        const gaScript = document.createElement('script');
        gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${environment.googleAnalyticsId}');
        `;
        document.head.appendChild(gaScript);
        return of(AnalyticsActions.analyticEventCompleted());
      } else {
        return of(AnalyticsActions.analyticEventNotLogged());
      }
    }))
  );
  
  /**
   * Log custom events if an ID is present
   */
  logCustomEvent$ = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.logCustomEvent),
      mergeMap(action => {
        if (!!environment.googleAnalyticsId) {
          gtag("event", action.event.action, {
            event_category: action.event.category,
            event_label: action.event.label || '',
            value: action.event.value || ''
          });
          return of(AnalyticsActions.analyticEventCompleted());
        } else {
          return of(AnalyticsActions.analyticEventNotLogged());
        }
    }))
  );

  /**
   * Log custom page views if an ID is present
   */
  logPageView$ = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.logPageView),
      mergeMap(action => {
        if (!!environment.googleAnalyticsId) {
          gtag("set", "page", action.url);
          gtag("send", "pageview");
          return of(AnalyticsActions.analyticEventCompleted());
        } else {
          return of(AnalyticsActions.analyticEventNotLogged());
        }
    }))
  );

  /**
   * Register Hotjar scripts if an environment variable hotjarId is present
   */
   initHotjar$ = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.initHotjar),
    mergeMap(_ => {
      if (!!environment.hotjarId) {
        const hotjarScript = document.createElement('script');
        hotjarScript.innerHTML = `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${environment.hotjarId},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(hotjarScript);
      }
      return of(AnalyticsActions.initHotjarComplete());
    }))
  );
 
  constructor(private actions$: Actions) {}
}