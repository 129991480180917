import { Action, createReducer, on } from '@ngrx/store';
import { FormNames, HomeownerApplicationApiData } from 'src/app/homeowner-application/interfaces';
import { ApiActions, AppInfoActions, MultiApplicationActions } from '../actions';
import {
  getEmploymentDataFromForm, getFinancialDataFromForm,
  getMortgageDataFromForm, getNotEligibleDataFromForm, getPersonalDataFromForm,
  getPrequalificationDataFromForm,
  getProfileDataFromForm,
  getPropertyDataFromForm
} from '../selectors/app-form-selector.functions';
import { appInfoInitialState } from './app-info-initial.state';

export const appInfoFeatureKey = 'appInfo';
export const initialState: HomeownerApplicationApiData = appInfoInitialState;

const appFormReducer = createReducer(
  initialState,
  on(ApiActions.putAppDataError, (state, { error }) => {
    return ({ ...state, ...{ updateApiError: { error: error, hasError: true } }, isUpdating: false })
  }),
  on(ApiActions.putAppDataSuccess, (state, { data }) => {
    return ({ ...state, ...data, ...{ updateApiError: { error: null, hasError: false } }, isUpdating: false })
  }),
  on(AppInfoActions.setHasEligibleExpense, (state, { hasExpense }) => {
    return ({ ...state, hasEligibleExpense: hasExpense })
  }),
  on(ApiActions.submitSignaturesError, (state, { error }) => {
    return ({ ...state, ...{ updateSignaturesApiError: { error: error, hasError: true } } })
  }),
  on(ApiActions.submitAppError, (state, { error }) => {
    return ({ ...state, ...{ submitApiError: { error: error, hasError: true } } })
  }),
  on(ApiActions.submitAppSuccess, (state) => {
    return ({ ...state, isSubmissionComplete: true })
  }),
  on(AppInfoActions.resetAppSubmittedState, (state) => {
    return ({ ...state, isSubmissionComplete: false })
  }),
  on(ApiActions.checkEligibility, (state) => {
    return ({ ...state, isCheckingEligibility: true })
  }),
  on(ApiActions.checkEligibilityError, (state, { error }) => {
    return ({ ...state, isCheckingEligibility: false, checkEligibilityError: {
      error: error, 
      hasError: true
    }})
  }),
  on(ApiActions.checkEligibilitySuccess, (state) => {
    return ({ ...state, isCheckingEligibility: false, checkEligibilityError: {
      error: null, 
      hasError: false
    }})
  }),
  on(AppInfoActions.reset, (state) => {
    return ({ ...state, ...initialState })
  }),
  on(AppInfoActions.updateAppFormStatusAndVisited, (state, { status, formName }) => {
    switch (formName) {
      case FormNames.Personal:
        return ({ ...state, ...{ personalFormStatus: { status: status, visited: true } } });
      case FormNames.Employment:
        return ({ ...state, ...{ employmentFormStatus: { status: status, visited: true } } });
      case FormNames.Property:
        return ({ ...state, ...{ propertyFormStatus: { status: status, visited: true } } });
      case FormNames.Mortgage:
        return ({ ...state, ...{ mortgageFormStatus: { status: status, visited: true } } });
      case FormNames.Financial:
        return ({ ...state, ...{ financialFormStatus: { status: status, visited: true } } });
      default:
        return state;
    }
  }),
  on(AppInfoActions.setInitialFormStatus, (state, _) => {
    return ({ ...state, initialFormStatusesSet: true })
  }),
  on(AppInfoActions.updateApplicationStage, (state, { stage }) => {
    return ({ ...state, ApplicationStage: stage })
  }),
  on(AppInfoActions.updateAppState, (state, { data }) => {
    const hasEligibleExpense = !!data.EligibleExpenses ? data.EligibleExpenses.length > 0 : false;
    return ({ ...state, ...data, isUpdating: true, hasEligibleExpense: hasEligibleExpense })
  }),
  on(AppInfoActions.updateEmail, (state, { email }) => {
    return ({ ...state, BorrowerEmail: email })
  }),
  on(AppInfoActions.updateIsActiveApplication, (state, { isActive }) => {
    return ({ ...state, IsActive: isActive })
  }),
  on(AppInfoActions.updateEmploymentForm, (state, { form }) => {
    const updatedState = getEmploymentDataFromForm(form);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updateFinancialForm, (state, { form }) => {
    const updatedState = getFinancialDataFromForm(form);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updateMortgageForm, (state, { form }) => {
    const updatedState = getMortgageDataFromForm(form);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updateNotEligibleForm, (state, { form }) => {
    const updatedState = getNotEligibleDataFromForm(form);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updatePersonalForm, (state, { form, toggles, races }) => {
    const updatedState = getPersonalDataFromForm(form, toggles, races);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updatePrequalificationForm, (state, { form }) => {
    const updatedState = getPrequalificationDataFromForm(form);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updateProfileForm, (state, { form }) => {
    const updatedState = getProfileDataFromForm(form);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updatePropertyForm, (state, { form, toggles }) => {
    const updatedState = getPropertyDataFromForm(form, toggles);
    return ({ ...state, ...updatedState, isUpdating: true })
  }),
  on(AppInfoActions.updatePrequalificationError, (state, {hasError, error}) => {
    return ({ ...state, isUpdating: false, updatePrequalificationError: { hasError: hasError, error: error } });
  }),
  on(AppInfoActions.updatePrequalificationStep, (state) => {
    return ({ ...state, isUpdatingPrequalStep: true });
  }),
  on(AppInfoActions.updatePrequalificationStepSuccess, (state, {step}) => {
    return ({ ...state, PrequalStep: step, isUpdatingPrequalStep: false });
  }),
);

export function reducer(state: any | undefined, action: Action) {
  return appFormReducer(state, action);
}