import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';
import { ApplicationMessage, ApplicationMessageContainer } from 'src/app/application-messaging/interfaces';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { AlertType } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { ApplictionMessagingActions, MessagesActions } from '../actions';
import { selectIsCommunicationsEnabled, selectSelectedApplicationId, selectUserId } from '../selectors';

@Injectable()
export class ApplicationMessagingEffects {

  getMessages$ = createEffect(() => this.actions$.pipe(
    ofType(ApplictionMessagingActions.loadMessages),
    mergeMap(_ => this.store.select(selectIsCommunicationsEnabled).pipe(
      filter(isEnabled => !!isEnabled)
    )),
    mergeMap(_ => this.store.select(selectSelectedApplicationId).pipe(
      filter(id => !!id)
    )),
    mergeMap(id => this.http.get<ApplicationMessageContainer>(`${this.appApi.baseApiUrl}/${id}/messages`).pipe(
      first(),
      map(response => ApplictionMessagingActions.loadMessagesSuccess({ messageContainer: response }))
    )),
    catchError(error => of(ApplictionMessagingActions.loadMessagesError({error: error})))
  ));


  markMessagesAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(ApplictionMessagingActions.markMessagesAsRead),
    mergeMap(_ => this.store.select(selectSelectedApplicationId).pipe(
      filter(id => !!id),
      first()
    )),
    mergeMap((applicationId) => this.http.put<ApplicationMessageContainer>(`${this.appApi.baseApiUrl}/${applicationId}/messages/read`, null).pipe(
      first(),
      map(messageContainer => ApplictionMessagingActions.markMessagesAsReadSuccess({ messageContainer: messageContainer }))
    )),
    catchError(error => of(ApplictionMessagingActions.markMessagesAsReadError({error: error})))
  ));

  sendMessage$ = createEffect(() => this.actions$.pipe(
    ofType(ApplictionMessagingActions.createMessage),
    mergeMap(action => this.store.select(selectSelectedApplicationId).pipe(
      first(),
      mergeMap(applicationId => this.http.post<ApplicationMessageContainer>(`${this.appApi.baseApiUrl}/${applicationId}/messages/create`, action.message).pipe(
        first(),
        map(messageContainer => ApplictionMessagingActions.createMessageSuccess({ messageContainer: messageContainer }))
      )
    ),
    catchError(error => [
      ApplictionMessagingActions.createMessageError({error: error}),
      MessagesActions.displayToast({ alertType: AlertType.Error, message: translate('communications.replyFailure') })
    ])
  ))));

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store, 
    private appApi: HomeownerApplicationApiService
  ) {}
}