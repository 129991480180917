import { Component, Input, OnInit } from '@angular/core';

import { FormStatus } from '../../../homeowner-application/interfaces/homeowner-application-form.interface';

@Component({
  selector: 'app-form-status-icon',
  templateUrl: './app-form-status-icon.component.html'
})
export class AppFormStatusIconComponent implements OnInit {

  @Input() isSection = false;
  @Input() status!: string | undefined;
  
  FormStatus = FormStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
