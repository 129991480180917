import { FormStatus, HomeownerApplicationApiData } from "src/app/homeowner-application/interfaces";
import { PrequalStep } from "src/app/shared/interfaces/prequalification-step.enum";

export const appInfoInitialState: HomeownerApplicationApiData = {
  // Metadata
  initialFormStatusesSet: false,
  personalFormStatus: { status: FormStatus.Invalid, visited: false },
  employmentFormStatus: { status: FormStatus.Invalid, visited: false },
  propertyFormStatus: { status: FormStatus.Invalid, visited: false },
  mortgageFormStatus: { status: FormStatus.Invalid, visited: false },
  financialFormStatus: { status: FormStatus.Invalid, visited: false },
  IsActive: false,
  submitApiError: {
    error: null,
    hasError: false
  },
  updateApiError: {
    error: null,
    hasError: false
  },
  updatePrequalificationError: {
    error: null,
    hasError: false
  },
  updateSignaturesApiError: {
    error: null,
    hasError: false
  },
  isCheckingEligibility: false,
  checkEligibilityError: {
    error: null,
    hasError: false
  },
  // API Interface
  ApplicationId: -1,
  ApplicationStatus: {
    ApplicationNumber: -1,
    SubmissionDate: '',
    Status: ''
  },
  DiscoveryTypeId: -1,
  DiscoveryOther: '',
  IsEmploymentEligibilityRequired: false,
  IsBorrowerEligibleForUnemployment: false,
  IsCoBorrowerEligibleForUnemployment: false,
  IsSpouseEligibleForUnemployemnt: false,
  BorrowerBenefitStartDate: '',
  BorrowerBenefitProjectedEndDate: '',
  BorrowerBenefitAmountMonthly: -1,
  CoBorrowerBenefitStartDate: '',
  CoBorrowerBenefitProjectedEndDate: '',
  CoBorrowerBenefitAmountMonthly: -1,
  SpouseBenefitStartDate: '',
  SpouseBenefitProjectedEndDate: '',
  SpouseBenefitAmountMonthly: -1,
  IsBorrowerCurrentlyEmployed: null,
  BorrowerEmployerName: '',
  BorrowerEmployerAddress: '',
  BorrowerEmployerCity: '',
  BorrowerEmployerState: null,
  BorrowerEmployerCounty: null,
  BorrowerEmployerZipCode: '',
  BorrowerEmployerPhone: '',
  BorrowerHowLongWithEmployer: null,
  BorrowerSelfEmployedCompanyName: '',
  IsCoBorrowerCurrentlyEmployed: null,
  CoBorrowerEmployerName: '',
  CoBorrowerEmployerAddress: '',
  CoBorrowerEmployerCity: '',
  CoBorrowerEmployerState: null,
  CoBorrowerEmployerCounty: null,
  CoBorrowerEmployerZipCode: '',
  CoBorrowerEmployerPhone: '',
  CoBorrowerHowLongWithEmployer: null,
  CoBorrowerSelfEmployedCompanyName: '',
  /**
   * PERSONAL fields
   */
  MaritalStatus: null,
  BorrowerFirstName: '',
  BorrowerMiddleName: '',
  BorrowerLastName: '',
  BorrowerSSN: '',
  BorrowerDateOfBirth: '',
  BorrowerAddress1: '',
  BorrowerAddress2: '',
  BorrowerCity: '',
  BorrowerState: null,
  BorrowerCounty: null,
  BorrowerZipCode: '',
  BorrowerPrimaryPhoneNumber: '',
  BorrowerPrimaryPhoneType: null,
  BorrowerPrimaryPhoneBestTimeToCall: null,
  BorrowerSecondaryPhoneNumber: '',
  BorrowerSecondaryPhoneType: null,
  BorrowerSecondaryPhoneBestTimeToCall: null,
  BorrowerEmail: '',
  BorrowerRaces: null,
  BorrowerGender: null,
  BorrowerEthnicity: null,
  IsThereACoBorrower: null,
  IsThereASpouse: null,
  IsCoBorrowerSpouse: null,
  HardshipReason: null,
  HardshipStatement: null,
  CoBorrowerFirstName: '',
  CoBorrowerMiddleName: '',
  CoBorrowerLastName: '',
  CoBorrowerSSN: null,
  CoBorrowerDateOfBirth: '',
  CoBorrowerAddress1: '',
  CoBorrowerAddress2: '',
  CoBorrowerCity: '',
  CoBorrowerState: null,
  CoBorrowerCounty: null,
  CoBorrowerZipCode: '',
  CoBorrowerPrimaryPhoneNumber: '',
  CoBorrowerPrimaryPhoneType: null,
  CoBorrowerPrimaryPhoneBestTimeToCall: null,
  CoBorrowerSecondaryPhoneNumber: '',
  CoBorrowerSecondaryPhoneType: null,
  CoBorrowerSecondaryPhoneBestTimeToCall: null,
  CoBorrowerEmail: '',
  CoBorrowerRaces: null,
  CoBorrowerGender: null,
  CoBorrowerEthnicity: null,
  SpouseFirstName: '',
  SpouseMiddleName: '',
  SpouseLastName: '',
  SpouseSSN: null,
  SpouseDateOfBirth: '',
  SpouseAddress1: '',
  SpouseAddress2: '',
  SpouseCity: '',
  SpouseState: null,
  SpouseCounty: null,
  SpouseZipCode: '',
  SpousePrimaryPhoneNumber: '',
  SpousePrimaryPhoneType: null,
  SpousePrimaryPhoneBestTimeToCall: null,
  SpouseSecondaryPhoneNumber: '',
  SpouseSecondaryPhoneType: null,
  SpouseSecondaryPhoneBestTimeToCall: null,
  SpouseEmail: '',
  SpouseRaces: null,
  SpouseGender: null,
  SpouseEthnicity: null,
  IsSociallyDisadvantaged: null,
  CoBorrowerIsVeteran: null,
  BorrowerIsVeteran: null,
  SpouseIsVeteran: null,
  PreferredLanguage: null,
  IsEmailAlertsEnabled: false,
  /**
   * PERSONAL / PROPERTY fields
   */
  Property_IsSameAsMailingAddress: false,
  Property_NumberOfDependentsAtAddress: -1,
  Property_LastKnownAppraisedValue: -1,
  Property_TotalNumberofPersonsLivingAtThisAddress: null,
  Property_Address1: '',
  Property_Address2: '',
  Property_City: '',
  Property_State: null,
  Property_County: null,
  Property_ZipCode: '',
  /**
   * PROPERTY fields
   */
  Property_IsIntentionToKeep: false,
  Property_IsIntentionToSell: false,
  Property_IsPrimaryResidence: false,
  Property_IsSecondaryResidence: false,
  Property_IsInvestment: false,
  Property_IsOwnerOccupied: false,
  Property_IsRenterOccupied: false,
  Property_IsVacant: false,
  Property_WhoPaysTaxes: null,
  Property_AreTaxesCurrent: null,
  Property_HasCondoOrHoaFees: null,
  Property_CondoOrHoaFeesPaidTo: '',
  Property_CondoOrHoaFeesAmount: -1,
  Property_WhoPaysHazardInsurance: null,
  Property_HazardInsuranceIsCurrent: null,
  Property_HazardInsuranceCompanyName: '',
  Property_HazardInsuranceCompanyPhone: '',
  Property_HasJudgements: false,
  Property_JudgementsDescription: null,
  EligibleExpenses: [],
  /**
   * FINANCIAL fields
   */
  IncomeSources_BorrowerMonthly: [],
  IncomeSources_CoBorrowerMonthly: [],
  IncomeSources_SpouseMonthly: [],
  Expenses_Other: [],
  Bankruptcy_WasFiled: false,
  Bankruptcy_Type: null,
  Bankruptcy_IsDischarged: null,
  Bankruptcy_CaseNumber: '',
  Bankruptcy_FilingDate: '',
  /**
   * MORTGAGE fields
   */
  Mortgages: [],
  /**
   * Not eligible checkboxes
   */
  IsRequestGivenForEmailOfResults: null,
  IsPermissionGivenForContactByHUD: null,
  
  PrequalStep: PrequalStep.None,
  isUpdatingPrequalStep: false
}