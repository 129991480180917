import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { ApiActions, AppInfoActions } from '../actions';

@Injectable()
export class AppUpdateEffects {

  /**
   * When a user updates a form and navigates to a different page,
   * trigger a PUT to update the application
   */
  triggerPutAppDataOnFormUpdateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      AppInfoActions.updatePersonalForm, AppInfoActions.updateEmploymentForm, AppInfoActions.updatePropertyForm,
      AppInfoActions.updateMortgageForm, AppInfoActions.updateFinancialForm, AppInfoActions.updateNotEligibleForm
    ),
    mergeMap(_ => of(ApiActions.putAppData())))
  );
  
  /**
   * PUT the application data
   * Return either success or error depending on the API response
   */
  putAppDataOnFormUpdateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.putAppData),
    mergeMap(_ => this.appApi.updateApplication().pipe(
      map(response => ApiActions.putAppDataSuccess({ data: response })),
      catchError(error => of(ApiActions.putAppDataError({ error })))
    ))
  ));
 
  constructor(private actions$: Actions, private store: Store, private appApi: HomeownerApplicationApiService) {}
}