import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../environments/environment';
import { FeatureToggles } from '../core/interfaces/toggles.interface';
import { HomeownerApplicationApiData } from '../homeowner-application/interfaces';
import {
  fromApiLists, fromAppInfo, fromApplictionMessaging, fromConfig, fromFeatureToggles, fromMenu,
  fromMessages, fromModals, fromMultiApplication, fromRouter, fromSupportingFiles, fromUser
} from './reducers';

/**
 * NgRx store's interface for application-wide state
 * See README.md for NgRx resources 
 */
export interface AppState {
  apiLists: fromApiLists.ListApiState,
  appInfo: HomeownerApplicationApiData,
  communications: fromApplictionMessaging.ApplicationMessagingState,
  configurations: fromConfig.ConfigurationState,
  featureToggles: FeatureToggles,
  menu: fromMenu.AppMenuState,
  messages: fromMessages.AppMessageState,
  modals: fromModals.AppModalState,
  multiApp: fromMultiApplication.MultiAppState,
  router: fromRouter.RouterState,
  supportingFiles: fromSupportingFiles.SupportingFilesState,
  user: fromUser.UserState
}

export const reducers: ActionReducerMap<AppState> = {
  apiLists: fromApiLists.reducer,
  appInfo: fromAppInfo.reducer,
  communications: fromApplictionMessaging.reducer,
  configurations: fromConfig.reducer,
  featureToggles: fromFeatureToggles.reducer,
  menu: fromMenu.reducer,
  messages: fromMessages.reducer,
  modals: fromModals.reducer,
  multiApp: fromMultiApplication.reducer,
  user: fromUser.reducer,
  router: fromRouter.reducer,
  supportingFiles: fromSupportingFiles.reducer
};

export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];
