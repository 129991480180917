import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { STATIC_CONTACT_US_ROUTE, STATIC_PRIVACY_POLICY_ROUTE, STATIC_SITEMAP_ROUTE, STATIC_TERMS_OF_USE_ROUTE } from 'routes';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { selectAppData, selectHelpLinksList, selectPageType, selectUserIsLoggedIn } from 'src/app/store/selectors';
import { environment as env } from '../../../../environments/environment';
import { NavService } from '../../services';
import * as util from '../../services/utilities';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  destroy = new Subject();

  env = env;
  pageType$!: Observable<string>;

  helpLinks = this.store.select(selectHelpLinksList).pipe(filter(list => !!list));
  prequalStep = '';
  util = util;

  constructor(
    private navService: NavService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.select(selectAppData).pipe(filter(data => !data.isUpdatingPrequalStep), takeUntil(this.destroy)).subscribe(data => this.prequalStep = data.PrequalStep);
    this.pageType$ = this.store.select(selectPageType);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  get sitemapRoute(): string {
    return STATIC_SITEMAP_ROUTE;
  }

  get termsRoute(): string {
    return STATIC_TERMS_OF_USE_ROUTE;
  }

  get privacyRoute(): string {
    return STATIC_PRIVACY_POLICY_ROUTE;
  }

  get contactUsRoute(): string {
    return STATIC_CONTACT_US_ROUTE;
  }

  focusHeader(): void {
    const pageContent: HTMLElement = <HTMLElement>document.querySelector('#header-content');
    if (!!pageContent) {
      pageContent.focus();
    }
  }

  get currentRoute(): string {
    return this.navService.getCurrentRoute();
  }
  get isLoggedIn$(): Observable<boolean> {
    return this.store.select(selectUserIsLoggedIn);
  }

  get timestamp(): string {
    return env.timestamp;
  }
  
}
