import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './app-banner.component.html'
})
export class AppBannerComponent implements OnInit {
  @Input() link: string | null = null;
  @Input() message: string | null = null;

  ngOnInit(): void {}

  /**
   * Do not display `https://` in the link that the user sees
   */
  get displayLink(): string | null {
    const formattedLink = this.link?.replace('https://', '');
    return formattedLink || this.link;
  }
}