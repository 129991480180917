import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { HomeownerApplicationApiService } from 'src/app/core/services/homeowner-application-api.service';
import { SupportingFile, UploadedFile } from 'src/app/homeowner-application/interfaces';
import { SupportingFilesActions } from '../actions';
import { selectSupportingFilesState } from '../selectors/supporting-files.selectors';

@Injectable()
export class SupportingFilesEffects {
  /**
   * If Supporting Files do not exist in state, fetch them
   */
  loadFilesState$ = createEffect(() => this.actions$.pipe(
    ofType(SupportingFilesActions.loadFilesState),
      mergeMap(() => this.store.select(selectSupportingFilesState).pipe(
        first(),
        map(state => {
          const sFiles: SupportingFile[] = state.supportingFiles;
          const uFiles: UploadedFile[] = state.uploadedFiles;
          if (sFiles.length > 0 || uFiles.length > 0) {
            return SupportingFilesActions.updateSupportingAndUploadedFiles({ sFiles: sFiles, uFiles: uFiles })
          } else {
            return SupportingFilesActions.fetchFiles();
          }
        }))
      )
  ));

  /**
   * Fetch supporting files and uploaded files
   * Update state with the responses
   */
  fetchFiles$ = createEffect(() => this.actions$.pipe(
    ofType(SupportingFilesActions.fetchFiles),
      mergeMap(() => {
        const getSupportingFiles$ = this.appApi.getSupportingFiles();
        const getUploadedFiles$ = this.appApi.getUploadedFiles();
        return combineLatest([getSupportingFiles$, getUploadedFiles$]).pipe(
          map(([supportingFiles, uploadedFiles]) => {
            const sortedSupportingFiles = [...supportingFiles].sort((a, b) => a.SortOrder - b.SortOrder);
            return SupportingFilesActions.updateSupportingAndUploadedFiles({ sFiles: sortedSupportingFiles, uFiles: uploadedFiles })
          })
        )}
      )
  ));
 
  constructor(
    private actions$: Actions,
    private store: Store,
    private appApi: HomeownerApplicationApiService
  ) {}
}