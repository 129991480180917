import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { loadEligibleExpenseProviders, loadEligibleExpenseTypes } from 'src/app/store/actions/api.actions';
import { selectEligibleExpenseCategoriesList, selectEligibleExpenseProvidersList, selectEligibleExpenseTypesList, selectPaymentDueDatesList, selectWhoPaysList } from 'src/app/store/selectors';
import { ConfirmDialogComponent } from '..';
import { DialogData, ListObject, nonNegativeRegex, phoneRegex, ValidationClass } from '../../interfaces';
import { getOptionDisplayName } from '../../services/utilities';

@Component({
  selector: 'app-property-info-card',
  templateUrl: './property-info-card.component.html',
  styleUrls: ['./property-info-card.component.scss']
})
export class PropertyInfoCardComponent implements OnInit, OnDestroy {

  @Input() category: ListObject = { Id: -1, Name: '', SortOrder: -1, IsActive: false };
  @Input() type!: string;
  @Input() categoryIndex!: number;
  @Input() formGroups!: FormGroup[];
  @Input() deleteButtonId!: string;
  @Output() deleteExpense: EventEmitter<any> = new EventEmitter();
  @Output() updateFormGroup: EventEmitter<any> = new EventEmitter();
  @Output() addExpense: EventEmitter<any> = new EventEmitter();
  @Output() toggleExpense: EventEmitter<{ isOpen: boolean, index: number }> = new EventEmitter();

  destroy = new Subject();
  ValidClass = ValidationClass;
  displayExpenses = false;

  expenseCategories$ = this.store.select(selectEligibleExpenseCategoriesList).pipe(filter(list => !!list));
  expenseTypes$ = this.store.select(selectEligibleExpenseTypesList).pipe(
    filter(types => !!types && types.categoryId == this.category.Id)
  );
  paymentDueDateList$ = this.store.select(selectPaymentDueDatesList).pipe(filter(list => !!list));
  providerList$ = this.store.select(selectEligibleExpenseProvidersList).pipe(
    filter(providers => !!providers && providers.categoryId == this.category.Id)
  );
  whoPaysList$ = this.store.select(selectWhoPaysList).pipe(filter(list => !!list));

  constructor(private modalService: NgbModal, private store: Store) {}

  ngOnInit(): void {
    if (typeof this.formGroups[0].value.Category === 'number') {
      this.setProviderList(this.formGroups[0].value.Category);
    }
    if (!!this.formGroups[0].get('Provider')?.value || this.formGroups[0].get('IsOtherProvider')?.value) {
      this.displayExpenses = true;
    }
    this.handleProviderList();
  }

  ngOnDestroy(): void {
    /**
     * If the toggle is open but no provider or expenseType is selected , 
     * automatically close the toggle and clear the rest of the fields
     */
    if (
      this.displayExpenses && !this.formGroups[0].get('Provider')?.value && 
      !this.formGroups[0].get('IsOtherProvider')?.value && 
      !this.formGroups[0].get('ExpenseType')?.value
      ) {
      this.handleValidation(false);
      this.toggleExpense.emit({ isOpen: false, index: this.categoryIndex });
    }
    this.destroy.next();
    this.destroy.complete();
  }

  addExpenseCard(): void {
    this.addExpense.emit();
  }

  delete(i: number): void {
    this.deleteExpense.emit({ expenseIndex: i });
  }

  getOptionDisplayName(item: ListObject): string {
    return getOptionDisplayName(item);
  }

  private setProviderList(id: number): void {
    this.store.dispatch(loadEligibleExpenseProviders({category: id}));
    this.store.dispatch(loadEligibleExpenseTypes({category: id}));
  }

  getExpenseType(i: number): AbstractControl | null {
    return this.formGroups[i].get('Category');
  }

  showOption(isActive: boolean, index: number, control: string, id: number): boolean {
    return isActive || this.formGroups[index].get(control)?.value==id;
  }

  handleProviderList(): void {
    if (!!this.category.Id) {
      this.setProviderList(this.category.Id);
    }
  }

  setFieldsOptional(): void {
    this.formGroups.forEach(formGroup => {
      formGroup.get('ExpenseType')?.patchValue('');
      formGroup.get('Provider')?.patchValue('');
      formGroup.get('IsOtherProvider')?.patchValue(false);
      formGroup.get('OtherProviderName')?.patchValue('');
      formGroup.get('ProviderPhoneNumber')?.patchValue('');
      formGroup.get('AccountNumber')?.patchValue('');
      formGroup.get('PaymentDueDate')?.patchValue('');
      formGroup.get('Payment')?.patchValue('');
      formGroup.get('PaymentPastDue')?.patchValue('');
      formGroup.get('WhoPays')?.patchValue('');
      formGroup.get('Category')?.clearValidators();
      formGroup.get('ExpenseType')?.clearValidators();
      formGroup.get('Provider')?.clearValidators();
      formGroup.get('IsOtherProvider')?.clearValidators();
      formGroup.get('OtherProviderName')?.clearValidators();
      formGroup.get('ProviderPhoneNumber')?.clearValidators();
      formGroup.get('AccountNumber')?.clearValidators();
      formGroup.get('PaymentDueDate')?.clearValidators();
      formGroup.get('Payment')?.clearValidators();
      formGroup.get('PaymentPastDue')?.clearValidators();
      formGroup.get('WhoPays')?.clearValidators();
      formGroup.get('Category')?.updateValueAndValidity();
      formGroup.get('ExpenseType')?.updateValueAndValidity();
      formGroup.get('Provider')?.updateValueAndValidity();
      formGroup.get('IsOtherProvider')?.updateValueAndValidity();
      formGroup.get('OtherProviderName')?.updateValueAndValidity();
      formGroup.get('ProviderPhoneNumber')?.updateValueAndValidity();
      formGroup.get('AccountNumber')?.updateValueAndValidity();
      formGroup.get('PaymentDueDate')?.updateValueAndValidity();
      formGroup.get('Payment')?.updateValueAndValidity();
      formGroup.get('PaymentPastDue')?.updateValueAndValidity();
      formGroup.get('WhoPays')?.updateValueAndValidity();
    });
  }

  setFieldsRequired(): void {
    this.formGroups.forEach(formGroup => {
      formGroup.get('Category')?.setValidators(Validators.required);
      formGroup.get('ExpenseType')?.setValidators(Validators.required);
      formGroup.get('Provider')?.setValidators(Validators.required);
      formGroup.get('IsOtherProvider')?.setValidators(Validators.required),
      formGroup.get('ProviderPhoneNumber')?.setValidators([Validators.required, Validators.pattern(phoneRegex)]);
      formGroup.get('AccountNumber')?.setValidators(Validators.required);
      formGroup.get('PaymentDueDate')?.setValidators(Validators.required);
      formGroup.get('Payment')?.setValidators([Validators.required, Validators.pattern(nonNegativeRegex)]);
      formGroup.get('PaymentPastDue')?.setValidators([Validators.required, Validators.pattern(nonNegativeRegex)]);
      formGroup.get('WhoPays')?.setValidators(Validators.required);
      formGroup.get('Category')?.updateValueAndValidity();
      formGroup.get('ExpenseType')?.updateValueAndValidity();
      formGroup.get('Provider')?.updateValueAndValidity();
      formGroup.get('IsOtherProvider')?.updateValueAndValidity();
      formGroup.get('ProviderPhoneNumber')?.updateValueAndValidity();
      formGroup.get('AccountNumber')?.updateValueAndValidity();
      formGroup.get('PaymentDueDate')?.updateValueAndValidity();
      formGroup.get('Payment')?.updateValueAndValidity();
      formGroup.get('PaymentPastDue')?.updateValueAndValidity();
      formGroup.get('WhoPays')?.updateValueAndValidity();
    });
  }

  handleValidation(isToggledOpen: boolean): void {
    if (isToggledOpen) {
      this.setFieldsRequired();
    } else {
      this.setFieldsOptional();
    }
  }

  checkOtherProvider(event: any, index: number): void {
    if (this.formGroups[index].get('IsOtherProvider')?.value) {
      this.formGroups[index].get('OtherProviderName')?.patchValue('');
      this.formGroups[index].get('IsOtherProvider')?.patchValue(false);
      this.formGroups[index].get('IsOtherProvider')?.clearValidators();
      this.formGroups[index].get('OtherProviderName')?.clearValidators();
      this.formGroups[index].get('OtherProviderName')?.updateValueAndValidity();
      this.formGroups[index].get('IsOtherProvider')?.updateValueAndValidity();
    } else {
      const selectedValue= event.target.value.slice(-2);
      if (selectedValue == '-1') {
        this.formGroups[index].get('IsOtherProvider')?.patchValue(true);
        this.formGroups[index].get('IsOtherProvider')?.setValidators(Validators.required);
        this.formGroups[index].get('OtherProviderName')?.setValidators(Validators.required);
        this.formGroups[index].get('IsOtherProvider')?.updateValueAndValidity();
        this.formGroups[index].get('OtherProviderName')?.updateValueAndValidity();
      }
    }
  }

  /**
   * Remove spaces and '/' characters from any dynamic label
   */
  formatLabel(value: string): string {
    return value.replace(/[\s\/]/g, '');
  }

  isInvalid(path: string, index: number, control?: AbstractControl | null): boolean {
    const formControl = !!control ? control : this.formGroups[index].get(path);
    return !!formControl && formControl.invalid && (formControl.dirty || formControl.touched);
  }

  /**
   * If toggling to "No", and user has entered at least 1 additional expense, prompt the 
   * user with a confirm dialog to delete the listed expenses
   * 
   * If toggling to "Yes", or only 1 expense has been entered, complete the toggle action
   * by updating validation and clearing the single expense in the "No" case, or displaying 
   * an empty expense in the "Yes" case
   */
  handleToggle(event: any): void {
    if (this.displayExpenses && this.formGroups.length > 1) {
      const modal = this.modalService.open(ConfirmDialogComponent, {
        centered: true
      });
      const dialogData: DialogData = {
        title: translate('confirmDialog.areYouSure'),
        bodyText: [`${translate('confirmDialog.clearAllExpenses')} ${this.category.Name}.`],
        showCancel: true,
        action: {
          text: translate('confirmDialog.yesClear'),
          click: 'submit'
        }
      };
      modal.componentInstance.data = dialogData;
      modal.result.then(result => {
        /**
         * If the user confirms clearing expenses, update the toggle, validation and provider list
         * Else if they select cancel, leave the toggle as checked
         */
        if (!!result) {
          const el = event.srcElement;
          el.focus();
          this.completeToggle();
        } else {
          event.srcElement.checked = true;
        }
      }, _ => {});
    } else {
      this.completeToggle();
    }
  }

  completeToggle(): void {
    this.displayExpenses = !this.displayExpenses;
    this.handleValidation(this.displayExpenses);
    if (this.displayExpenses) {
      this.handleProviderList();
    }
    this.toggleExpense.emit({ isOpen: this.displayExpenses, index: this.categoryIndex });
  }
}
