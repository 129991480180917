import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/core/constants';
import { FormStatus, FormStatusInfo } from 'src/app/homeowner-application/interfaces';
import { AnalyticsActions } from 'src/app/store/actions';

@Component({
  selector: 'app-tile',
  templateUrl: './app-tile.component.html',
  styleUrls: ['./app-tile.component.scss']
})
export class AppTileComponent implements OnInit {

  @Input() isSubmitted = false;
  @Input() link!: string;
  @Input() status$!: Observable<FormStatusInfo>;
  @Input() pageName!: string;

  FormStatus = FormStatus;

  constructor(private store: Store) { }

  ngOnInit(): void {}

  trackNav(): void {
    const eventLabel = this.link.split('/')[2];
    this.store.dispatch(AnalyticsActions.logCustomEvent({
      event: {
        action: Constants.HORIZONTAL_NAV,
        category: Constants.NAVIGATION_CATEGORY,
        label: eventLabel
      }
    }));
  }
}
