import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';
import { AppFormState, ApplicationSubmmitedId, FormStatus, FormStatusInfo, HomeownerApplicationApiData, ProgramSummary } from 'src/app/homeowner-application/interfaces';
import { fromAppInfo } from '../reducers';
import { getActiveProgramSummary, getDataToSubmit } from './app-data-selector.functions';
import * as transform from './app-form-selector.functions';
import { selectConfigDisplayMortgageLender } from './config.selectors';
import { selectFeatureToggles, selectFeatureTogglesFeature } from './feature-toggles.selectors';

export const selectAppInfoFeature = createFeatureSelector<HomeownerApplicationApiData>(fromAppInfo.appInfoFeatureKey);

export const selectAppData = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state
);

export const selectIsUpdating = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.isUpdating
);

export const selectIsSubmissionComplete = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.isSubmissionComplete
);

export const selectAppDataToSubmit = createSelector(
  selectAppInfoFeature,
  selectFeatureToggles,
  (state: HomeownerApplicationApiData, toggles: FeatureToggles) => getDataToSubmit(state, toggles)
);

/**
 * Return an object including all main forms: personal, employment, property, mortgage, financial
 */
export const selectMainFlowForms = createSelector(
  selectAppInfoFeature,
  selectFeatureTogglesFeature,
  selectConfigDisplayMortgageLender,
  (state: HomeownerApplicationApiData, toggles: FeatureToggles, displayMortgageLender: boolean) => {
    const forms: AppFormState = {
      personal: transform.stateDataToPersonalForm(state),
      employment: transform.stateDataToEmploymentForm(state),
      property: transform.stateDataToPropertyForm(state, toggles),
      mortgage: transform.stateDataToMortgageForm(state, toggles, displayMortgageLender),
      financial: transform.stateDataToFinancialForm(state),
      notEligible: transform.stateDataToNotEligibleForm(state),
      signatures: transform.stateDataToSignatureForm(state, toggles)
    }
    return forms;
  }
);

export const selectAreMainFormsValid = createSelector(
  selectMainFlowForms,
  (state: AppFormState) => {
    const perValid = state.personal.valid;
    const empValid = state.employment.valid;
    const proValid = state.property.valid;
    const morValid = state.mortgage.valid;
    const finValid = state.financial.valid;
    return perValid && empValid && proValid && morValid && finValid;
  }
);

export const selectInitialFormStatusSet = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.initialFormStatusesSet
);

export const selectPersonalForm = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => transform.stateDataToPersonalForm(state)
);

export const selectEmploymentForm = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => transform.stateDataToEmploymentForm(state)
);

export const selectPropertyForm = createSelector(
  selectAppInfoFeature,
  selectFeatureTogglesFeature,
  (state: HomeownerApplicationApiData, toggles: FeatureToggles) => transform.stateDataToPropertyForm(state, toggles)
);

export const selectMortgageForm = createSelector(
  selectAppInfoFeature,
  selectFeatureTogglesFeature,
  selectConfigDisplayMortgageLender,
  (state: HomeownerApplicationApiData, toggles: FeatureToggles, displayMortgageLender: boolean) => transform.stateDataToMortgageForm(state, toggles, displayMortgageLender)
);

export const selectFinancialForm = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => transform.stateDataToFinancialForm(state)
);

export const selectNotEligibleForm = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => transform.stateDataToNotEligibleForm(state)
);

export const selectSignatureForm = createSelector(
  selectAppInfoFeature,
  selectFeatureTogglesFeature,
  (state: HomeownerApplicationApiData, toggles: FeatureToggles) => transform.stateDataToSignatureForm(state, toggles)
);

export const selectIsActiveApplication = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.IsActive
);

export const selectDisbursements = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.Disbursements
);

export const selectProgramSummary = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => getActiveProgramSummary(state)
);

export const selectProgramSummaries = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.ProgramSummaries
);

/**
 * Return true if the eligible expenses feature is enabled, the program requires expenses,
 * and the user has not entered any eligible expenses
 */
export const selectPropertyRequiresMissingEligibleExpenses = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => {
    const isMissingExpenses = !state.hasEligibleExpense;
    const programSummary = getActiveProgramSummary(state);
    const isExpenseRequired = !!programSummary?.IsEligibleExpensesEnabled && !!programSummary?.RequireExpenses;
    return isExpenseRequired ? isMissingExpenses : false;
  }
);

export const selectIsAppSubmitted = createSelector(
  selectProgramSummary,
  (programSummary: ProgramSummary | null) => {
    return !!programSummary?.Stage?.Id && (programSummary.Stage.Id >= ApplicationSubmmitedId);
  }
);

export const selectHasSubmittedApplication = createSelector(
  selectProgramSummaries,
  (programSummaries: ProgramSummary[] | undefined) => {
    let hasSubmittedApplication = false;
    programSummaries?.forEach(program => {
      if (!!program?.Stage?.Id && program.Stage.Id >= ApplicationSubmmitedId) {
        hasSubmittedApplication = true;
        return;
      }
    });
    return hasSubmittedApplication;
  }
)

export const selectApplicationStage = createSelector(
  selectProgramSummary,
  (programSummary: ProgramSummary | null) => {
    return programSummary?.Stage?.Id || -1
  }
);

export const selectApplicationStatus = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.ApplicationStatus
);

export const selectPersonalFormStatus = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.personalFormStatus
);

export const selectEmploymentFormStatus = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.employmentFormStatus
);

/**
 * Return the status of the Property form
 * 
 * If IsEligibleExpensesEnabled == true and RequireExpenses == true, the form status can only be valid
 * if the user has entered at least 1 eligible expense
 * 
 * If eligible expenses are not enabled or expenses are not required, the status is solely based
 * on the status of the Angular form field validations
 */
export const selectPropertyFormStatus = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => {
    const status = getPropertyFormStatus(state);
    const propertyFormStatus: FormStatusInfo = { 
      ...state.propertyFormStatus, 
      status: status
    };
    return propertyFormStatus;
  }
);

/**
 * PropertyFormStatus is based on the form state (Valid or Invalid)
 * 
 * If IsEligibleExpensesEnabled is true and RequireExpenses is true, the status is
 *   also based on whether the user has added any eligible expenses. This is determined by
 *   looking in the state (what the user has updated on the application) as well as at the 
 *   current value of the form (hasEligibleExpenses gets set to true/false based on the user
 *   adding/deleting eligible expenses).
 */
export function getPropertyFormStatus(state: HomeownerApplicationApiData): FormStatus {
  const currentFormStatus = state.propertyFormStatus.status;
  const programSummary = getActiveProgramSummary(state);
  const isExpenseRequired = !!programSummary?.IsEligibleExpensesEnabled && !!programSummary?.RequireExpenses;
  return isExpenseRequired ? (state.hasEligibleExpense && currentFormStatus === FormStatus.Valid ? FormStatus.Valid : FormStatus.Invalid) : currentFormStatus;
}

export const selectMortgageFormStatus = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.mortgageFormStatus
);

export const selectFinancialFormStatus = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.financialFormStatus
);

export const selectUpdateSignaturesApiError = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.updateSignaturesApiError
);

export const selectSubmitApiError = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.submitApiError
);

export const selectPrequalStep = createSelector(
  selectAppInfoFeature,
  (state: HomeownerApplicationApiData) => state.PrequalStep
);