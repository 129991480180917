import { createAction, props } from '@ngrx/store';
import { Address } from 'src/app/core/services/address-validation.service';

export const handleUserSessionStorage = createAction(
  '[User] Handle Session Storage'
);

export const handleUserSessionStorageSuccess = createAction(
  '[User] Handle Session Storage Success'
);

export const resetAll = createAction(
  '[User] Reset All State to Initial Values'
);

export const reset = createAction(
  '[User] Reset State'
);

export const resetOriginalEmail = createAction(
  '[User] Reset Original Email',
  props<{ email: string }>()
);

export const updateId = createAction(
  '[User] Update Id and Logged In State',
  props<{ id: string }>()
);

export const userIdUpdatedSuccess = createAction(
  '[User] Update Id Updated Success'
);

export const updateIsBrowserDeprecated = createAction(
  '[User] Update Is Browser Deprecated',
  props<{ isDeprecated: boolean }>()
);

export const updateUserAddress = createAction(
  '[User] Update User Address',
  props<{ address: Address }>()
);

export const updateIsForceChangePassword = createAction(
  '[User] Update Is Forced to Change Password',
  props<{ isForce: boolean }>()
);

export const updateIsForcedLogout = createAction(
  '[User] Update Is Forced to Logout',
  props<{ isForced: boolean }>()
);

export const updateIsUpdatingRegistrationCode = createAction(
  '[User] Update Is Updating Registration Code',
  props<{ isUpdating: boolean }>()
);

export const updateJustChangedPassword = createAction(
  '[User] Update Just Changed Password',
  props<{ isChanged: boolean }>()
);

export const updateJustPrequalified = createAction(
  '[User] Update Just Prequalified',
  props<{ justPrequalified: boolean }>()
);

export const updateJustResetPassword = createAction(
  '[User] Update Just Reset Password',
  props<{ isReset: boolean }>()
);

export const updateUsername = createAction(
  '[User] Update Username',
  props<{ username: string }>()
);

export const updateEmail = createAction(
  '[User] Update Email Address',
  props<{ email: string }>()
);

export const updateEmailSuccess = createAction(
  '[User] Update Email Address Success',
  props<{ data: any }>()
);

export const updateEmailError = createAction(
  '[User] Update Email Address Error',
  props<{ error: any }>()
);

export const updateIsUpdatingEmail = createAction(
  '[User] Update isUpdatingEmail',
  props<{ value: boolean }>()
);

export const updateNavigationFragment = createAction(
  '[User] Update Navigation Fragment',
  props<{ navigationFragment: string }>()
);

export const updateIsEmailAlertsEnabledFromForm = createAction(
  '[User] Update IsEmailAlertsEnabled ',
  props<{ form: any }>()
);

export const updateSmartyStreetValidationCheck = createAction(
  '[User] Update validateSmartyStreetAddress',
  props<{ validateSmartyStreetAddress: boolean }>()
);