import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureToggles } from 'src/app/core/interfaces/toggles.interface';
import { fromFeatureToggles } from '../reducers';

export const selectFeatureTogglesFeature = createFeatureSelector<FeatureToggles>(fromFeatureToggles.togglesFeatureKey);

export const selectFeatureToggles = createSelector(
  selectFeatureTogglesFeature,
  (state: FeatureToggles) => state
);

export const selectFeatureTogglesLoading = createSelector(
  selectFeatureTogglesFeature,
  (state: FeatureToggles) => state.isLoading
);

export const selectIsCommunicationsEnabled = createSelector(
  selectFeatureTogglesFeature,
  (state: FeatureToggles) => !!state.EnableHomeownerPortalCommunications
);

export const selectIsMultiAppEnabled = createSelector(
  selectFeatureTogglesFeature,
  (state: FeatureToggles) => !!state.EnableHomeownerPortalMultipleApplications
);