import { Action, createReducer, on } from '@ngrx/store';
import { Address } from 'src/app/core/services/address-validation.service';
import { ApiActions, AppInfoActions, MultiApplicationActions, UserActions } from '../actions';
import { getProfileDataFromForm } from '../selectors/user-selector.functions';

export interface UserState {
  address: Address,
  id: string,
  isBrowserDeprecated: boolean,
  isForceChangePassword: boolean,
  isForcedLogout: boolean,
  isLoggedIn: boolean,
  isUpdatingRegistrationCode: boolean,
  justChangedPassword: boolean,
  justPrequalified: boolean,
  justResetPassword: boolean,
  name: string,
  username: string,
  emailSuccess: any,
  emailError: any,
  isUpdatingEmail: boolean,
  navigationFragment: string,
  isEmailAlertsEnabled: boolean,
  validateSmartyStreetAddress: boolean
}

export const initialState: UserState = {
  address: {
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    County: '',
    ZipCode: ''
  },
  id: '',
  isBrowserDeprecated: false,
  isForceChangePassword: false,
  isForcedLogout: false,
  isLoggedIn: false,
  isUpdatingRegistrationCode: false,
  justChangedPassword: false,
  justPrequalified: false,
  justResetPassword: false,
  name: '',
  username: '',
  emailSuccess: null,
  emailError: null,
  isUpdatingEmail: true,
  navigationFragment: '',
  isEmailAlertsEnabled: false,
  validateSmartyStreetAddress: false
};

const userReducer = createReducer(
  initialState,
  on(UserActions.reset, (state) => {
    return ({ ...state, ...initialState })
  }),
  on(UserActions.resetOriginalEmail, (state, { email }) => {
    return ({ ...state, username: email, isUpdatingEmail: false })
  }),
  on(UserActions.updateId, (state, { id }) => {
    return ({ ...state, id: id, isLoggedIn: !!id })
  }),
  on(UserActions.updateIsBrowserDeprecated, (state, { isDeprecated }) => {
    return ({ ...state, isBrowserDeprecated: isDeprecated })
  }),
  on(UserActions.updateIsForceChangePassword, (state, { isForce }) => {
    return ({ ...state, isForceChangePassword: isForce })
  }),
  on(UserActions.updateIsForcedLogout, (state, { isForced }) => {
    return ({ ...state, isForcedLogout: isForced })
  }),
  on(UserActions.updateUserAddress, (state, { address }) => {
    return ({ ...state, address: address })
  }),
  on(UserActions.updateJustChangedPassword, (state, { isChanged }) => {
    return ({ ...state, justChangedPassword: isChanged })
  }),
  on(UserActions.updateJustPrequalified, (state, { justPrequalified }) => {
    return ({ ...state, justPrequalified: justPrequalified })
  }),
  on(UserActions.updateJustResetPassword, (state, { isReset }) => {
    return ({ ...state, justResetPassword: isReset })
  }),
  on(UserActions.updateIsUpdatingRegistrationCode, (state, { isUpdating }) => {
    return ({ ...state, isUpdatingRegistrationCode: isUpdating })
  }),
  on(UserActions.updateUsername, (state, { username }) => {
    return ({ ...state, username: username })
  }),
  on(AppInfoActions.updateEmail, (state, { email }) => {
    return ({ ...state, username: email, isUpdatingEmail: true })
  }),
  on(UserActions.updateEmailSuccess, (state, { data }) => {
    return ({ ...state, emailSuccess: data, isUpdatingEmail: false, emailError: null })
  }),
  on(UserActions.updateEmailError, (state, { error }) => {
    return ({ ...state, emailError: error, isUpdatingEmail: false, emailSuccess: null })
  }),
  on(UserActions.updateNavigationFragment, (state, { navigationFragment }) => {
    return ({ ...state, navigationFragment: navigationFragment })
  }),
  on(MultiApplicationActions.loadApplicationsSuccess, (state, { data }) => {
    return ({ ...state, name: `${data.BorrowerFirstName} ${data.BorrowerLastName}`, isEmailAlertsEnabled: data.IsEmailAlertsEnabled})
  }),
  on(AppInfoActions.updateAppState, (state, { data }) => {
    return ({ ...state, name: `${data.BorrowerFirstName} ${data.BorrowerLastName}`, isEmailAlertsEnabled: data.IsEmailAlertsEnabled})
  }),
  on(ApiActions.putAppDataSuccess, (state, { data }) => {
    return ({ ...state, name: `${data.BorrowerFirstName} ${data.BorrowerLastName}`, isEmailAlertsEnabled: data.IsEmailAlertsEnabled})
  }),
  on(UserActions.updateIsEmailAlertsEnabledFromForm, (state, { form }) => {
    const formData = getProfileDataFromForm(form);
    return ({ ...state, isEmailAlertsEnabled: formData.IsEmailAlertsEnabled})
  }),
  on(UserActions.updateSmartyStreetValidationCheck, (state, { validateSmartyStreetAddress }) => {
    return ({ ...state, validateSmartyStreetAddress: validateSmartyStreetAddress })
  }),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}