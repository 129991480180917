/**
 * This file is the source of truth for all Homeowner Portal routes
 */ 

export const LOGIN_ROUTE = 'login';

export const PASSWORD_ROUTE = 'password';
export const FORGOT_ROUTE = 'forgot';
export const RESET_ROUTE = 'reset';
export const CHANGE_ROUTE = 'change';
export const PASSWORD_CHANGE_ROUTE = `/${PASSWORD_ROUTE}/${CHANGE_ROUTE}`;
export const PASSWORD_FORGOT_ROUTE = `/${PASSWORD_ROUTE}/${FORGOT_ROUTE}`;

export const DASHBOARD_ROUTE = 'dashboard';
export const APPLICATIONS_ROUTE = 'applications';
export const APPLICATION_DETAILS_ROUTE = 'details';
export const DASHBOARD_APPLICATIONS_ROUTE = `/${DASHBOARD_ROUTE}/${APPLICATIONS_ROUTE}`;
export const DASHBOARD_DETAILS_ROUTE = `/${DASHBOARD_ROUTE}/${APPLICATION_DETAILS_ROUTE}`;

export const PROFILE_ROUTE = 'profile';
export const CREATE_ROUTE = 'create';
export const EDIT_ROUTE = 'edit';
export const VERIFY_ROUTE = 'verify';
export const PROFILE_CREATE_ROUTE = `/${PROFILE_ROUTE}/${CREATE_ROUTE}`;
export const PROFILE_EDIT_ROUTE = `/${PROFILE_ROUTE}/${EDIT_ROUTE}`;

export const PREQUAL_ROUTE = 'prequalification';
export const PROGRAM_SELECTION_ROUTE = 'program-selection';
export const QUESTIONS_ROUTE = 'questions';
export const PREQUAL_PROGRAM_SELECTION_ROUTE = `/${PREQUAL_ROUTE}/${PROGRAM_SELECTION_ROUTE}`;
export const PREQUAL_QUESTIONS_ROUTE = `/${PREQUAL_ROUTE}/${QUESTIONS_ROUTE}`;

export const STATIC_ROUTE = 'static';
export const NOT_FOUND_ROUTE = '404';
export const NOT_ELIGIBLE_ROUTE = 'not-eligible';
export const NO_LONGER_ACTIVE_ROUTE = 'no-longer-active';
export const STATIC_NOT_ELIGIBLE_ROUTE = `/${STATIC_ROUTE}/${NOT_ELIGIBLE_ROUTE}`;
export const STATIC_NO_LONGER_ACTIVE_ROUTE = `/${STATIC_ROUTE}/${NO_LONGER_ACTIVE_ROUTE}`;
export const TERMS_OF_USE_ROUTE = 'terms-of-use';
export const STATIC_TERMS_OF_USE_ROUTE = `/${STATIC_ROUTE}/${TERMS_OF_USE_ROUTE}`;
export const CONTACT_US_ROUTE = 'contact-us';
export const STATIC_CONTACT_US_ROUTE = `/${STATIC_ROUTE}/${CONTACT_US_ROUTE}`;
export const PRIVACY_POLICY_ROUTE = 'privacy-policy';
export const STATIC_PRIVACY_POLICY_ROUTE = `/${STATIC_ROUTE}/${PRIVACY_POLICY_ROUTE}`;
export const DISCRIMINATION_ROUTE = 'discrimination';
export const STATIC_DISCRIMINATION_ROUTE = `/${STATIC_ROUTE}/${DISCRIMINATION_ROUTE}`;
export const SITEMAP_ROUTE = 'sitemap';
export const STATIC_SITEMAP_ROUTE = `/${STATIC_ROUTE}/${SITEMAP_ROUTE}`;

export const APPLICATION_ROUTE = 'applications';
export const PERSONAL_ROUTE = 'personal';
export const EMPLOYMENT_ROUTE = 'employment';
export const PROPERTY_ROUTE = 'property';
export const MORTGAGE_ROUTE = 'mortgage';
export const FINANCIAL_ROUTE = 'financial';
export const APP_SUMMARY_ROUTE = 'application-summary';
export const SUPPORTING_FILES_ROUTE = 'supporting-files';
export const SIGN_SUBMIT_ROUTE = 'sign-submit';
export const COMMS_ROUTE = 'communications';