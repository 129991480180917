export const environment = {
  applicationStageCount: 8,
  apiUrl: 'https://api.test.prolinkplus.prolinksolutions.com/api',
  clientName: 'prolink',
  cognitoAuthenticationFlowtype: 'USER_SRP_AUTH',
  cognitoRegion: 'us-east-1',
  cognitoUserPoolId: 'us-east-1_e6NjsfPwN',
  cognitoUserPoolWebClientId: 'hgup39vj4qgq0gvb19l9fvr4k',
  googleAnalyticsId: '',
  hotjarId: '',
  loadZenDesk: false,
  production: false,
  allowLanguageChanges: true,
  recaptchaSiteKey: '6LfbqTUbAAAAANRLGLTL7l3E6_e126BPBOZTnUF0',
  setTimeout: 30,
  smartyStreetsKey: '',
  stateAbbreviation: 'AL',
  stateId: 1,
  timeOut: 600000,
  timestamp: '2/14/2023 4:02:34 PM',
  title: 'ProLink+',
  uploadFileSize: 50000000,
  zenDeskUrl: '',
  showReasonableAccommodation: false,
  showLanguageAssistance: false,
  showDiscriminationComplaints: false,
  showLoginPageHeroBanner: true,
  supportedLanguages: [ { name: 'English', value:'en'}, { name: 'Español', value:'es'}]
};