import { createAction, props } from '@ngrx/store';
import { HomeownerApplicationApiData } from 'src/app/homeowner-application/interfaces';
import { ApplicationsDashboardData } from '../reducers/multi-application.reducers';

export const loadApplications = createAction(
  '[Applications] GET Applications'
);

export const loadApplicationsSuccess = createAction(
  '[Applications] GET Applications Success',
  props<{ data: ApplicationsDashboardData }>()
);

export const loadApplicationsError = createAction(
  '[Applications] GET Applications Error',
  props<{ error: any }>()
);

export const alreadyLoadedApplications = createAction(
  '[Applications] Applications Already Fetched'
);

export const loadApplicationById = createAction(
  '[Applications] GET Application by Id',
  props<{ applicationId: number }>()
);

export const goToApplicationDashboard = createAction(
  '[Applications] Navigate user to application dashboard',
  props<{ applicationId: number }>()
);

export const goToApplicationDashboardSuccess = createAction(
  '[Applications] Successful Navigation to application dashboard'
);

export const loadApplicationByIdSuccess = createAction(
  '[Applications] GET Application by Id Success',
  props<{ application: HomeownerApplicationApiData }>()
);

export const loadApplicationByIdError = createAction(
  '[Applications] GET Applications by Id Error',
  props<{ error: any }>()
);

export const reset = createAction(
  '[Applications] Reset State'
);

export const resetSelectedApplication = createAction(
  '[Applications] Reset Selected Application'
);

export const programSelectionComplete = createAction(
  '[Applications] Program Selection Complete'
);

export const copyAppDetailsAfterProgramSelection = createAction(
  '[Applications] Copy Application Details After Program Selection',
);

export const copyDetailsOntoCurrentAppAfterProgramSelection = createAction(
  '[Applications] Update State with Copied Details After Program Selection',
  props<{ currentApp: HomeownerApplicationApiData | undefined, appToCopy: HomeownerApplicationApiData }>()
);

export const prequalComplete = createAction(
  '[Applications] Prequalification Complete'
);

export const doNotCopyAppDetails = createAction(
  '[Applications] No Application Details to Copy'
);