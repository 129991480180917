import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CREATE_ROUTE, FORGOT_ROUTE, PASSWORD_ROUTE, PROFILE_ROUTE } from 'routes';
import { ValidationClass } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../../../login/login-page/login-page.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() applicationIntakeMessage!: string | null;
  @Input() areFeatureTogglesLoading!: boolean | null;
  @Input() signupEnabled?: boolean;
  @Input() loginForm!: FormGroup;
  @Input() isLoading!: any;
  @Input() hasSubmitted!: any;

  ValidClass = ValidationClass;

  constructor() { }

  ngOnInit(): void {}

  get forgotPasswordRoute(): string {
    return `/${PASSWORD_ROUTE}/${FORGOT_ROUTE}`;
  }

  get profileCreateRoute(): string {
    return `/${PROFILE_ROUTE}/${CREATE_ROUTE}`;
  }

  onSubmit(): void {}

  isInvalid(path: string, control?: AbstractControl | null): boolean {
    const formControl = !!control ? control : this.loginForm.get(path);
    return !!formControl && formControl.invalid && (formControl.dirty || formControl.touched);
  }

  get showReasonableAccommodation(): boolean {
    return environment.showReasonableAccommodation;
  }

  get showDiscriminationComplaints(): boolean {
    return environment.showDiscriminationComplaints;
  }

  get showLanguageAssistance(): boolean {
    return environment.showLanguageAssistance;
  }
}
