import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseFormComponent } from 'src/app/homeowner-application/components/base-form/base-form.component';
import { ValidationClass } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../../../../profile/profile-page/profile-page.component.scss']
})
export class ProfileComponent extends BaseFormComponent implements OnInit {

  ValidClass = ValidationClass;

  @Input() alert!: any;
  @Input() form!: FormGroup;
  @Input() hasOptedIn!: boolean;
  @Output() continue: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() turnMfaOn: EventEmitter<any> = new EventEmitter();
  @Output() turnMfaOff: EventEmitter<any> = new EventEmitter();
  @Output() changePw: EventEmitter<any> = new EventEmitter();
  @Output() changeEmail: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  ngOnDestroy(): void { }

  get f() {
    return this.form.controls;
  }

  toContinue(): void {
    this.continue.emit();
  }

  goBack(): void {
    this.back.emit();
  }

  mfaOptIn(): void {
    this.turnMfaOn.emit();
  }

  mfaOptOut(): void {
    this.turnMfaOff.emit();
  }

  changePassword() {
    this.changePw.emit();
  }

  updateEmail() {
    this.changeEmail.emit();
  }

  showButton(button: number): boolean {
    switch(button) {
      case 1: 
        return this.form.get('isMFAEnabled')?.value && this.hasOptedIn;
      case 2:
        return this.form.get('isMFAEnabled')?.value && !this.hasOptedIn;
      case 3:
        return !this.form.get('isMFAEnabled')?.value && this.hasOptedIn;
    }
    return false;
  }

  get canUpdateEmail(): boolean {
    return this.isInvalid('newEmail') || this.isInvalid('confirmNewEmail') || !!!this.form.get('newEmail')?.value || !!!this.form.get('confirmNewEmail')?.value;
  }

  get canUpdatePhone(): boolean {
    return this.isInvalid('confirmCellNumber') || this.isInvalid('cellNumber') || !!!this.form.get('confirmCellNumber')?.value || !!!this.form.get('cellNumber')?.value
  }
}
