import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Constants } from 'src/app/core/constants';
import { ConfigurationObject } from 'src/app/core/interfaces/configuration.interface';
import { fromConfig } from '../reducers';
import { ConfigurationState } from '../reducers/config.reducers';

export const selectConfigFeature = createFeatureSelector<ConfigurationState>(fromConfig.configFeatureKey);

function configStringIsTrue(content: string): boolean {
  return content.toLowerCase() === 'true';
}

/**
 * Return the boolean representation of a Content string for a specific configuration Context
 * @param configurations 
 * @param context 
 * @returns 
 */
function getBooleanContentFromContext(configurations: ConfigurationObject[], context: string): boolean {
  const matchedConfig = configurations.filter(c => c.Context === context)[0];
  return !!matchedConfig ? configStringIsTrue(matchedConfig.Content) : false;
}

/**
 * Return the string Content for a specific configuration Context
 * @param configurations 
 * @param context 
 * @returns 
 */
function getStringContentFromContext(configurations: ConfigurationObject[], context: string): string {
  const matchedConfig = configurations.filter(c => c.Context === context)[0];
  return !!matchedConfig ? matchedConfig.Content : '';
}

/**
 * Return the number Content for a specific configuration Context
 * @param configurations 
 * @param context 
 * @returns 
 */
 function getNumberContentFromContext(configurations: ConfigurationObject[], context: string): number {
  const matchedConfig = configurations.filter(c => c.Context === context)[0];
  return !!matchedConfig && !isNaN(Number(matchedConfig.Content)) ? Number(matchedConfig.Content) : 0;
}

export const selectConfig = createSelector(
  selectConfigFeature,
  (state: ConfigurationState) => state
);


export const selectConfigAuthFlow = createSelector(
  selectConfigFeature,
  (state: ConfigurationState) => getStringContentFromContext(state.configList, Constants.CONFIG_AUTH_FLOW)
);



export const selectConfigDisplayMortgageLender = createSelector(
  selectConfigFeature,
  (state: ConfigurationState) => getBooleanContentFromContext(state.configList, Constants.CONFIG_DISPLAY_MORTGAGE_LENDER)
);

export const selectConfigMonthlyIncomeWarningThreshold = createSelector(
  selectConfigFeature,
  (state: ConfigurationState) => getNumberContentFromContext(state.configList, Constants.CONFIG_MONTHLY_INCOME_WARNING_THRESHOLD)
);
