import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ListApiService } from 'src/app/core/services/list-api.service';
import { FeatureTogglesActions } from '../actions';

@Injectable()
export class FeatureTogglesEffects {

  getToggles$ = createEffect(() => this.actions$.pipe(
    ofType(FeatureTogglesActions.getToggles),
    mergeMap(() => {
      return this.listApi.getFeatureToggles()
        .pipe(
          map(toggles => {
            return FeatureTogglesActions.updateFeatureToggles({ toggles })
          }),
          catchError(error => {
            return of(FeatureTogglesActions.getTogglesFailure(error))
          })
        )
    }))
  );
 
  constructor(private actions$: Actions, private listApi: ListApiService) {}
}