import { createAction, props } from '@ngrx/store';

export const closeMenu = createAction(
  '[Menu] Close'
);

export const toggleMenu = createAction(
  '[Menu] Toggle'
);
  
export const updateActiveIndex = createAction(
  '[Menu] Update Active Index',
  props<{ activeIndex: number }>()
);

export const updatePageType = createAction(
  '[Menu] Update Page Type',
  props<{ pageType: string }>()
);