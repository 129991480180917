import { createAction, props } from '@ngrx/store';
import { ConfigurationObject } from 'src/app/core/interfaces/configuration.interface';

export const getConfigurations = createAction(
  '[API] GET Configurations'
);

export const updateConfigurations = createAction(
  '[Configuration State] Update Configurations',
  props<{ configurations: ConfigurationObject[] }>()
);

export const getConfigurationsFailure = createAction(
  '[API] GET Configurations Error',
  props<{ error: string }>()
);