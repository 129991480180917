import { createAction, props } from '@ngrx/store';
import { AlertType } from 'src/app/shared/interfaces';

export const displayToast = createAction(
  '[Messages] Display Toast',
  props<{ message: string, alertType: AlertType }>()
);

export const displayToastError = createAction(
  '[Messages] Display Error Toast'
);

export const displayToastSuccess = createAction(
  '[Messages] Display Success Toast'
);

export const getCountdownMessage = createAction(
  '[Messages] Get Countdown Message'
);

export const getIsLoading = createAction(
  '[Messages] Get Is Loading'
);

export const getLoadingMessage = createAction(
  '[Messages] Get Loading Message'
);

export const getVerifyAlertMessage = createAction(
  '[Messages] Get Verify Alert Message'
);

export const reset = createAction(
  '[Messages] Reset State'
);

export const updateCountdownMessage = createAction(
  '[Messages] Update Countdown Message',
  props<{ message: string }>()
);

export const updateIsLoading = createAction(
  '[Messages] Update Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateLoadingMessage = createAction(
  '[Messages] Update Loading Message',
  props<{ message: string }>()
);

export const updateVerifyAlertMessage = createAction(
  '[Messages] Update Verify Alert Message',
  props<{ message: string }>()
);