<ng-container *transloco="let t; read:'appSigning'">
  <ng-container *ngIf="toggles.IsDocusignEnabled; else nonDocusignSignatures">
    <h3 class="mb-3">{{t('docusign.header')}}</h3>
    <div class="hardship-card mb-4">
      <p class="mb-2"><b>{{t('docusign.importantInfo')}}</b></p>
      <span [innerHTML]="t('docusign.youNeedTo')"></span>
      <ul>
        <li [innerHTML]="t('docusign.willBeEmailed')"></li>
        <li>{{t('docusign.youMustSign')}}</li>
        <li [innerHTML]="t('docusign.willNotBeAble')"></li>
      </ul>
      <p class="mb-5">{{t('docusign.resendInfo')}}</p>

      <div class="hardship-container">
        <div class="hardship-status">
          <ng-container *ngIf="signersNeeded.length == 0; else signaturesNeeded">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-file-earmark-check svg-valid" viewBox="0 0 16 16">
              <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
            </svg>
          </ng-container>
          <ng-template #signaturesNeeded>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark svg-sig-needed" viewBox="0 0 16 16">
              <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
            </svg>
          </ng-template>
        </div>
        <div class="needed-container">
          <h4>{{t('docusign.documents')}}</h4>
          <p class="mb-0">{{t('docusign.packageIncludes')}}</p>
          <ul class="mt-2">
            <li>{{t('docusign.applicationPackage')}}</li>
          </ul>
        </div>
        <div class="signature-container">
          <label class="inner-title fw-500 pt-2 with-divider w-100">{{t('docusign.signatures')}}</label>
          <div *ngFor="let signer of signers" class="signature">
            <p class="my-0">{{signer.Name}}</p>
            <p class="signature-status mt-1 mb-3">
              <ng-container *ngIf="!!signer.SignedOn; else notSigned">
                {{t('docusign.signedOn')}}:  {{fcs.formatDate(signer.SignedOn)}}
              </ng-container>
              <ng-template #notSigned>
                {{ !!signer.SentOn ? t('docusign.pendingSentOn') + fcs.formatDate(signer.SentOn) : '' }} <span *ngIf="!!signer.SentOn && !signer.SignedOn">| <a href="javascript:void(0);" title="Resend documents" (click)="reSendDocuments(signer.Role)">{{t('docusign.resendDocuments')}}</a>
                </span>
              </ng-template>
            </p>
          </div>
          <ng-container *ngIf="{value: isLoading$ | async} as context">
            <button *ngIf="getNeedsSent" [disabled]="!!context.value" (click)="sendDocuments()" class="btn btn-primary upload-button" type="button">
              <span *ngIf="!!context.value" class="spinner-border spinner-border-sm mr-1" aria-hidden="true" role="status"></span>
              <ng-container *ngIf="!context.value">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-list-check" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </ng-container>{{!!context.value ? t('docusign.sending') : t('docusign.sendForSignatures')}}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #nonDocusignSignatures>
    <fieldset [disabled]="isApplicationSubmitted">
      <h3>{{t('manual.header')}}</h3>
      <div class="hardship-card" [formGroup]="form">
        <div class="hardship-container">
          <div class="hardship-status">
            <ng-container *ngIf="form.valid; else signaturesNeeded">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-file-earmark-check svg-valid" viewBox="0 0 16 16">
                <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
              </svg>
            </ng-container>
            <ng-template #signaturesNeeded>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark svg-sig-needed" viewBox="0 0 16 16">
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
              </svg>
            </ng-template>
          </div>
          <div class="needed-container">
            <h4>{{t('docusign.hardshipAffidavitHeader')}}</h4>
            <p class="mb-0">{{t('docusign.hardshipAffidavitText')}}</p>
          </div>
          <div class="signature-container">
            <label class="inner-title fw-500 pt-2 with-divider w-100">{{t('manual.signatures')}}</label>
            <div class="nondocusign-signature-container">
              <div class="field">
                <label for="BorrowerSignature" class="required">{{t('manual.bSignature')}}</label>
                <input type="text" class="form-control" id="BorrowerSignature" placeholder="{{t('manual.fullName')}}" formControlName="BorrowerSignature" required>
                <form-message *ngIf="isInvalid('BorrowerSignature')" [cssClass]="ValidClass.Danger" [field]="form.get('BorrowerSignature')"></form-message>
              </div>
              <div class="field">
                <label for="BorrowerSignatureDate" class="required">{{t('manual.dateSigned')}}</label>
                <div class="input-group">
                  <input class="form-control d-inline-block datepicker" placeholder="MM/DD/YYYY" id="BorrowerSignatureDate" type="date" max="9999-12-31" formControlName="BorrowerSignatureDate" readonly="true" required>
                </div>
                <form-message *ngIf="isInvalid('BorrowerSignatureDate')" [cssClass]="ValidClass.Danger" [field]="form.get('BorrowerSignatureDate')"></form-message>
              </div>
            </div>

            <ng-container *ngIf="personalForm$ | async as pf">
              <div class="nondocusign-signature-container mt-4" *ngIf="!!pf.get('Borrower.IsThereACoBorrower')?.value">
                <div class="field">
                  <label for="CoBorrowerSignature" class="required">{{t('manual.cbSignature')}}</label>
                  <input type="text" class="form-control" id="CoBorrowerSignature" placeholder="{{t('manual.fullName')}}" formControlName="CoBorrowerSignature">
                  <form-message *ngIf="isInvalid('CoBorrowerSignature')" [cssClass]="ValidClass.Danger" [field]="form.get('CoBorrowerSignature')"></form-message>
                </div>
                <div class="field">
                  <label for="CoBorrowerSignatureDate" class="required">{{t('manual.dateSigned')}}</label>
                  <div class="input-group">
                    <input class="form-control d-inline-block datepicker" placeholder="MM/DD/YYYY" id="CoBorrowerSignatureDate" type="date" max="9999-12-31" formControlName="CoBorrowerSignatureDate" readonly="true" required>
                  </div>
                  <form-message *ngIf="isInvalid('CoBorrowerSignatureDate')" [cssClass]="ValidClass.Danger" [field]="form.get('CoBorrowerSignatureDate')"></form-message>
                </div>
              </div>
              <div class="nondocusign-signature-container mt-4" *ngIf="!pf.get('Borrower.IsCoBorrowerSpouse')?.value && fcs.hasSpouse(pf.get('Borrower.MaritalStatus')?.value)">
                <div class="field">
                  <label for="SpouseSignature" class="required">{{t('manual.sSignature')}}</label>
                  <input type="text" class="form-control" id="SpouseSignature" placeholder="{{t('manual.fullName')}}" formControlName="SpouseSignature" required>
                  <form-message *ngIf="isInvalid('SpouseSignature')" [cssClass]="ValidClass.Danger" [field]="form.get('SpouseSignature')"></form-message>
                </div>
                <div class="field">
                  <label for="SpouseSignatureDate" class="required">{{t('manual.dateSigned')}}</label>
                  <div class="input-group">
                    <input class="form-control d-inline-block datepicker" placeholder="MM/DD/YYYY" id="SpouseSignatureDate" type="date" max="9999-12-31" formControlName="SpouseSignatureDate" readonly="true" required>
                  </div>
                  <form-message *ngIf="isInvalid('SpouseSignatureDate')" [cssClass]="ValidClass.Danger" [field]="form.get('SpouseSignatureDate')"></form-message>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </fieldset>
  </ng-template>
</ng-container>