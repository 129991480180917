import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ConfigurationObject } from 'src/app/core/interfaces/configuration.interface';
import { environment } from 'src/environments/environment';
import { ConfigurationActions } from '../actions';

@Injectable()
export class ConfigurationEffects {

  getToggles$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigurationActions.getConfigurations),
    mergeMap(() => {
      return this.http.get<ConfigurationObject[]>(`${environment.apiUrl}/configurations`)
        .pipe(
          map(configurations => {
            return ConfigurationActions.updateConfigurations({ configurations })
          }),
          catchError(error => {
            return of(ConfigurationActions.getConfigurationsFailure(error))
          })
        )
    }))
  );
 
  constructor(private actions$: Actions, private http: HttpClient) {}
}