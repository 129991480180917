import * as fromRouter from '@ngrx/router-store';
import { routerReducer } from '@ngrx/router-store';
import { Action } from '@ngrx/store';

export interface RouterState {
  router: fromRouter.RouterReducerState<any>;
}

export function reducer(state: RouterState | undefined, action: Action) {
  return routerReducer(state, action);
}