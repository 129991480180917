<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title" tabindex="0" role="heading">{{data.title}}</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
  </div>
  <div class="modal-body" tabindex="0" role="text">
    <div *ngFor="let text of data.bodyText; let i=index;" [ngClass]="{'mb-3': data.bodyText.length>1 && !(i>=data.bodyText.length-1)}">
      {{text}}
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-form-group float-right">
      <button *ngIf="data.showCancel" class="btn btn-outline-primary" (click)="cancel()">{{ secondaryActionText || t('confirmDialog.noCancel') }}</button>
      <button class="btn btn-primary ml-2" (click)="handleAction()">{{data.action.text}}</button>
    </div>
  </div>
</ng-container>