import { Action, createReducer, on } from '@ngrx/store';
import { ModalsActions } from '../actions';

export interface AppModalState {
  isModalOpen: boolean
}

export const initialState: AppModalState = {
  isModalOpen: false
};

const modalsReducer = createReducer(
  initialState,
  on(ModalsActions.updateIsModalOpen, (state, { isOpen }) => {
    return ({ ...state, isModalOpen: isOpen })
  })
);

export function reducer(state: AppModalState | undefined, action: Action) {
  return modalsReducer(state, action);
}