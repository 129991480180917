import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppModalState } from '../reducers/modals.reducers';

export const modalsFeatureKey = 'modals';

export const getModalsFeatureState = createFeatureSelector
<AppModalState>(modalsFeatureKey);

export const selectIsModalOpen = createSelector(
  getModalsFeatureState,
  (state: AppModalState) => state.isModalOpen
);