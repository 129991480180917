import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ModalsActions } from 'src/app/store/actions';
import { IAuthService, NavService } from '../../services';
import { CognitoAuthService } from '../../services/cognito-auth-service.service';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html'
})
export class TimeoutModalComponent implements OnInit, OnDestroy {

  @Input() statusMessage?: string;

  constructor(
    public modal: NgbActiveModal,
    private navService: NavService,
    private store: Store,
    @Inject(CognitoAuthService) private authService: IAuthService
  ) { 
    this.store.dispatch(ModalsActions.updateIsModalOpen({ isOpen: true }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.store.dispatch(ModalsActions.updateIsModalOpen({ isOpen: false }));
  }

  close() { 
    this.modal.dismiss();
  }

  logout() {
    this.modal.dismiss();
    this.authService.logout()
    .pipe(map(_ => this.navService.goToLogin()))
    .subscribe();
  }

}
