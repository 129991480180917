import { Action, createReducer, on } from '@ngrx/store';
import { ConfigurationObject } from 'src/app/core/interfaces/configuration.interface';
import { ConfigurationActions } from '../actions';

export const configFeatureKey = 'configurations';

export interface ConfigurationState {
  configList: ConfigurationObject[]
}

export const initialState: ConfigurationState = {
  configList: []
};

const configReducer = createReducer(
  initialState,
  on(ConfigurationActions.updateConfigurations, (state, { configurations }) => {
    return ({ 
      ...state, 
      configList: configurations
    })
  }),
);

export function reducer(state: ConfigurationState | undefined, action: Action) {
  return configReducer(state, action);
}