import { createAction, props } from '@ngrx/store';
import { SupportingFile, UploadedFile } from 'src/app/homeowner-application/interfaces';

export const updateSupportingAndUploadedFiles = createAction(
  '[Supporting Files] Update Supporting & Uploaded Files State',
  props<{ sFiles: SupportingFile[], uFiles: UploadedFile[] }>()
);

export const updateUploadedFiles = createAction(
  '[Supporting Files] Update Uploaded Files State',
  props<{ files: UploadedFile[] }>()
);

export const loadFilesState = createAction(
  '[Supporting Files] Check if Supporting and Uploaded Files Exist in State'
);

export const fetchFiles = createAction(
  '[Supporting Files] Fetch Supporting and Uploaded Files'
);

export const reset = createAction(
  '[Supporting Files] Reset State'
);