<ng-container *ngIf="pageType$ | async as pageType">
  <div class="sidebar-sticky" id="appNavbar" *transloco="let t;">
    <ul class="navbar-nav flex-column">
      <ng-container *ngIf="isLoggedIn$ | async">
        <ng-container *ngIf="!!(activeApplication$ | async) && pageType==='dynamic'">
          <li class="nav-item bypass-block-menu d-none d-lg-block" tabindex="0" (keyup.enter)="skipTo('main-content')">
            <a class="nav-link nav-no-icon mx-md-2 bypass-link" routerlink="javascript:void(0);" (click)="skipTo('main-content')">
              <span class="menu-item-title">{{t('menu.skipMain')}}</span>
            </a>
          </li>
          <li *ngIf="isMultiAppEnabled$ | async" class="nav-item" (click)="smallMenuItemSelected('applications dashboard')">
            <a class="nav-link nav-no-icon mx-md-2" [routerLink]="appsDashboardRoute">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#015BBD" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
              <span class="menu-item-title btn-link multi-menu-item">{{t('pages.backToAppsDashboard')}}</span>
            </a>
          </li>
          <ng-container *ngIf="selectedAppId$ | async as appId">
            <li class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.Dashboard }" (click)="smallMenuItemSelected('dashboard')">
              <a class="nav-link nav-no-icon mx-md-2" [routerLink]="appsDetailsDashboardRoute+'/'+appId">
                <span class="menu-item-title">{{t('pages.dashboard')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li *ngIf="!isSubmitted" class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.PersonalInformation }" (click)="smallMenuItemSelected('personal_information')">
              <a class="nav-link mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+personalRoute">
                <app-menu-status-icon [status$]="personalStatus$"></app-menu-status-icon>
                <span>{{t('pages.personalInformation')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li *ngIf="!isSubmitted" class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.Employment }" (click)="smallMenuItemSelected('employment')">
              <a class="nav-link mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+employmentRoute">
                <app-menu-status-icon [status$]="employmentStatus$"></app-menu-status-icon>
                <span>{{t('pages.employment')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li *ngIf="!isSubmitted" class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.Property }" (click)="smallMenuItemSelected('property')">
              <a class="nav-link mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+propertyRoute">
                <app-menu-status-icon [status$]="propertyStatus$"></app-menu-status-icon>
                <span>{{t('pages.property')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li *ngIf="!isSubmitted" class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.Mortgage }" (click)="smallMenuItemSelected('mortgage')">
              <a class="nav-link mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+mortgageRoute">
                <app-menu-status-icon [status$]="mortgageStatus$"></app-menu-status-icon>
                <span>{{t('pages.mortgage')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li *ngIf="!isSubmitted" class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.Financial }" (click)="smallMenuItemSelected('financial')">
              <a class="nav-link mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+financialRoute">
                <app-menu-status-icon [status$]="financialStatus$"></app-menu-status-icon>
                <span>{{t('pages.financial')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.SupportingFiles }" (click)="smallMenuItemSelected('supporting_files')">
              <a class="nav-link nav-no-icon mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+supportingFilesRoute">
                <app-menu-status-icon [status$]="supportingFilesStatus$"></app-menu-status-icon>
                <span>{{t('pages.supportingFiles')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.ApplicationSummary }" (click)="smallMenuItemSelected('application_summary')">
              <a class="nav-link nav-no-icon mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+summaryRoute">
                <span class="menu-item-title">{{t('pages.applicationSummary')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li *ngIf="!isSubmitted" class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.SignSubmit }" (click)="smallMenuItemSelected('sign_and_submit')">
              <a class="nav-link nav-no-icon mx-md-2" [routerLink]="'/'+appsRoute+'/'+appId+'/'+signSubmitRoute">
                <span class="menu-item-title">{{t('pages.signSubmit')}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
            <li *ngIf="isCommunicationsEnabled$ | async" class="nav-item" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.Communications }" (click)="smallMenuItemSelected('communications')">
              <a class="nav-link nav-no-icon mx-md-2" [ngClass]="{'unread-messages': hasUnreadMessages$ | async}" [routerLink]="'/applications/'+appId+'/communications'">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-text" viewBox="0 0 16 16">
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                  <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                </svg>
                <span class="menu-item-title">{{t('pages.communications')}}</span>
                <div *ngIf="hasUnreadMessages$ | async" >
                  <span class="badge rounded-pill bg-danger text-bg-danger">{{(unreadMessageCount$ | async)}}</span>
                </div>
              </a>
            </li>
          </ng-container>
        </ng-container>
        <li class="nav-item d-lg-none d-md-none" [ngClass]="{ 'active': (activeIndex$ | async) === PageIndex.Profile }" (click)="smallMenuItemSelected('profile')">
          <a class="nav-link nav-no-icon mx-md-2" [routerLink]="editProfileRoute">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            </svg>
            <span class="menu-item-title">{{t('pages.profile')}}</span>
          </a>
        </li>
      </ng-container>
      <li class="nav-item d-lg-none d-md-none" *ngIf="helpLinks | async as helpLink">
        <a class="nav-link nav-no-icon mx-md-2" [href]="util.getHelpLink(currentRoute, helpLink, prequalStep)" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
          </svg>
          <span class="menu-item-title">{{t('commonWords.help')}}</span>
        </a>
      </li>
    </ul>
    <div class="border-right"></div>
  </div>
</ng-container>