import { Component, Input, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { NO_LONGER_ACTIVE_ROUTE, STATIC_ROUTE } from 'routes';
import { Observable } from 'rxjs/internal/Observable';
import { filter, first, map } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { Disbursement } from 'src/app/homeowner-application/interfaces';
import { FormConditionsService } from 'src/app/homeowner-application/services/form-conditions.service';
import { MultiApplicationActions } from 'src/app/store/actions';
import { ApplicationDashboardInfo } from 'src/app/store/reducers/multi-application.reducers';
import { selectIsUpdatingMulti } from 'src/app/store/selectors';
import { ProgramStatus } from '../../interfaces/form-values.interface';
import { NavService } from '../../services';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {

  @Input() application!: ApplicationDashboardInfo;
  @Input() totalPrograms: number = 0;

  constructor(private fcs: FormConditionsService, private ns: NavService, private store: Store) { }

  ngOnInit(): void { }

  goToProgramDashboard(applicationId: number | undefined): void {
    if (!!applicationId) {
      this.store.dispatch(MultiApplicationActions.loadApplicationById({applicationId: applicationId}));
    }
    if (this.application?.ApplicationStatus?.Status?.toUpperCase() == "INACTIVE") {
      this.ns.goToRoute([STATIC_ROUTE, NO_LONGER_ACTIVE_ROUTE]);
    } else {
      this.store.select(selectIsUpdatingMulti).pipe(
        filter(isUpdating => !isUpdating),
        first(),
        map(_ => {
          this.store.dispatch(MultiApplicationActions.goToApplicationDashboard({ applicationId: applicationId || -1 }));
        })
      ).subscribe()
    }
  }

  get canShowDisbursements(): boolean {
    return !!this.application?.Disbursements;
  }

  get appIsInProgress(): boolean {
    return this.highestStage === 1 && this.application?.ApplicationStatus?.Status?.toUpperCase() !== "INACTIVE";
  }

  get appIsInactive(): boolean {
    return this.application?.ApplicationStatus?.Status?.toUpperCase() === "INACTIVE";
  }

  get hasUnreadMessages(): boolean {
    return this.application?.HasUnreadMessages
  }

  get inactiveBgColorClass(): string {
    return 'stage-0-bg-color';
  }

  get notInactiveBgColorClass(): string {
    return `stage-${this.highestStage}-bg-color`;
  }

  get stageText(): string {

    //If only one program associated with the application:
    if(this.application?.ProgramSummaries?.length == 1) {
      switch(this.application?.ProgramSummaries[0].Status.Name) {
        //If Status = Not Eligible
        case ProgramStatus.NotEligible:
          return translate('appDashboard.notEligible');
        //If Status is Inactive
        case ProgramStatus.Inactive:
          //If in stages 1-7
          if((this.application?.ProgramSummaries[0].Stage?.Id || 0) > 0 
            && (this.application?.ProgramSummaries[0].Stage?.Id || 0) < 8){
            return translate('appDashboard.notActive')
          //If in stage 8
          } else if ((this.application?.ProgramSummaries[0].Stage?.Id || 0) == 8){
            return translate('appDashboard.complete')
          }
          break;
        //All other statuses:
        default:
          //Stage 1
          if((this.application?.ProgramSummaries[0].Stage?.Id || 0) == 1){
            return translate('appDashboard.inProgress');
          //Stage 2-5
          } else if((this.application?.ProgramSummaries[0].Stage?.Id || 0) >= 2 
            && (this.application?.ProgramSummaries[0].Stage?.Id || 0) <= 5) {
              return translate('appDashboard.underReview');
          //Stage 6
          } else if((this.application?.ProgramSummaries[0].Stage?.Id || 0) == 6){
            return translate('appDashboard.funding');
          //Stage 7
          } else if((this.application?.ProgramSummaries[0].Stage?.Id || 0) == 7){
            return translate('appDashboard.fundingComplete');
          ////Stage 8
          } else if ((this.application?.ProgramSummaries[0].Stage?.Id || 0) == 8){
            return translate('appDashboard.complete')
          } else {
            return "";
          }
      }
    //If more than one program associated with the application:
    } else if (this.application?.ProgramSummaries?.length || 0 > 1) {
      //If all programs are in Stage 1
      if(this.application?.ProgramSummaries?.every((summary) => (summary.Stage?.Id || 0) == 1)) {
          //w/ Status = Not Eligible
          if(this.application?.ProgramSummaries?.every((summary) => summary.Status?.Name == ProgramStatus.NotEligible)) {
            return translate('appDashboard.multipleNotEligible')
          //at least one but not all is not 'Not Eligible'
          } else if (this.application?.ProgramSummaries?.some(summary => summary.Status?.Name == ProgramStatus.NotEligible)) {
            return translate('appDashboard.multipleInProgress')
          }
          //All others
          else {
            return translate('appDashboard.mulitplePleaseReview')
          }
      //If all programs are in Stage 8
      } else if (this.application?.ProgramSummaries?.every((summary) => (summary.Stage?.Id || 0) == 8)) {
        return translate('appDashboard.multipleComplete')
      //All others,
      } else {
        return translate('appDashboard.mulitplePleaseReview')
      }
    }

    return "";
  }

  get date(): string {
    const currentDate = new Date().toString();
    return this.fcs.formatDate(currentDate, Constants.DATE_FORMAT_FULL_MONTH_DAY_YEAR) || '';
  }

  get nextDisbursementDate(): string {
    if (!!this.application?.Disbursements) {
      const remaining = this.application?.Disbursements.filter(disb => !!disb.Status && disb.Status.toUpperCase() == 'PENDING');
      remaining.sort((a: Disbursement, b: Disbursement) => {
        if (!!a.DisbursementDate && !!b.DisbursementDate) {
          return new Date(a.DisbursementDate).getTime() - new Date(b.DisbursementDate).getTime();
        } else {
          return -1;
        }
      });
      return !!remaining[0]?.DisbursementDate ? this.fcs.formatDate(remaining[0].DisbursementDate, Constants.DATE_FORMAT_FULL_MONTH_DAY_YEAR) : '';
    } else {
      return '';
    }
  }

  get remainingDisbursementsCount(): number {
    if (!!this.application?.Disbursements) {
      const remaining = this.application?.Disbursements.filter(disb => !!disb.Status && disb.Status.toUpperCase() == 'PENDING');
      return remaining.length;
    } else {
      return 0;
    }
  }

  get totalApprovedAmount(): number {
    let totalApproved = 0;
    this.application?.ProgramSummaries?.forEach(program => !!program?.TotalApprovedAmount ? totalApproved = totalApproved + program?.TotalApprovedAmount : totalApproved = totalApproved)
    return totalApproved;
  }

  get totalAmountDisbursed(): number {
    let totalAmount = 0;
    const completedDisbursements = this.application.Disbursements?.filter(disb => !!disb.Status && disb.Status.toUpperCase() == 'DISBURSED');
    completedDisbursements?.forEach(disb => !!disb?.Amount ? totalAmount = totalAmount + disb?.Amount : totalAmount = totalAmount);
    return totalAmount;
  }

  get submissionDate(): string {
    if (this.application?.ApplicationStatus?.Status?.toUpperCase() == "INACTIVE" || this.highestStage == 0) {
      return 'N/A';
    } else {
      return !!this.application?.ApplicationStatus?.SubmissionDate ? translate('appDashboard.appSubmitted') + ' ' + this.fcs.formatDate(this.application?.ApplicationStatus?.SubmissionDate, Constants.DATE_FORMAT_FULL_MONTH_DAY_YEAR) : translate('appDashboard.appNotSubmitted');
    }
  }

  get nextBtnText(): string {
    const notEligiblePrograms = this.application?.ProgramSummaries?.filter(program => program.Status.Name.toUpperCase() == "NOT ELIGIBLE" && program.Stage?.Id == 1);
    if (this.application?.ApplicationStatus?.Status?.toUpperCase() == "INACTIVE" || (!!notEligiblePrograms?.length && notEligiblePrograms?.length > 0)) {
      return translate('appDashboard.learnMore');
    }
    if (this.highestStage == 1) {
      return translate('appDashboard.completeApp');
    } else {
      return translate('appDashboard.viewDetails');
    }
  }

  get highestStage(): number {
    let highestStage = -1;
    let notEligible = true;
    let complete = true;
    this.application?.ProgramSummaries?.forEach(program => {
      if (!!program?.Stage?.Id) {
        highestStage = program?.Stage?.Id > highestStage ? program?.Stage?.Id : highestStage;
        complete = program?.Stage?.Id == 8 && complete ? true : false;
        notEligible = program?.Status?.Name.toUpperCase() == "NOT ELIGIBLE" && notEligible ? true : false;
      }
    });
    return notEligible ? 0 : complete ? 8 : highestStage;
  }

  get programName(): string {
    let programName = 'appDashboard.ProgramNameDefault';
    let hasUpdatedProgramName = false;
    this.application?.ProgramSummaries?.forEach(summary => {
      if (!!summary?.Name && !hasUpdatedProgramName) {
        programName = summary?.Name;

        programName = `Program.${summary?.Name}${summary?.Id}`;
        hasUpdatedProgramName = true;
      }
    });
    
    return programName; 
  }
}
