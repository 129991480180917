import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserProfile } from 'src/app/homeowner-application/interfaces';
import { UserState } from '../reducers/user.reducers';
import { stateDataToUserProfile } from './user-selector.functions';

export const userFeatureKey = 'user';
export const selectUserFeature = createFeatureSelector<UserState>(userFeatureKey);

export const selectUser = createSelector(
  selectUserFeature,
  (state: UserState) => state
);

export const selectUserAddress = createSelector(
  selectUserFeature,
  (state: UserState) => state.address
);

export const selectUserBrowserIsDeprecated = createSelector(
  selectUserFeature,
  (state: UserState) => state.isBrowserDeprecated
);

export const selectUserId = createSelector(
  selectUserFeature,
  (state: UserState) => state.id
);

export const selectUserIsForceChangePassword = createSelector(
  selectUserFeature,
  (state: UserState) => state.isForceChangePassword
);

export const selectUserIsForcedLogout = createSelector(
  selectUserFeature,
  (state: UserState) => state.isForcedLogout
);

export const selectUserIsLoggedIn = createSelector(
  selectUserFeature,
  (state: UserState) => state.isLoggedIn
);

export const selectUserJustChangedPassword = createSelector(
  selectUserFeature,
  (state: UserState) => state.justChangedPassword
);

export const selectUserJustPrequalified = createSelector(
  selectUserFeature,
  (state: UserState) => state.justPrequalified
);

export const selectUserJustResetPassword = createSelector(
  selectUserFeature,
  (state: UserState) => state.justResetPassword
);

export const selectUserIsUpdatingRegistrationCode = createSelector(
  selectUserFeature,
  (state: UserState) => state.isUpdatingRegistrationCode
);

export const selectUsername = createSelector(
  selectUserFeature,
  (state: UserState) => state.username
);

export const selectEmailSuccess = createSelector(
  selectUserFeature,
  (state: UserState) => state.emailSuccess
);

export const selectEmailError = createSelector(
  selectUserFeature,
  (state: UserState) => state.emailError
);

export const selectNavigationFragment = createSelector(
  selectUserFeature,
  (state: UserState) => state.navigationFragment
);

export const selectSmartyStreetValidationCheck = createSelector(
  selectUserFeature,
  (state: UserState) => state.validateSmartyStreetAddress
);

export const selectUserProfile = createSelector(
  selectUserFeature,
  (state: UserState) => {
    const userProfile: UserProfile = stateDataToUserProfile(state)
    return userProfile;
  }
);
