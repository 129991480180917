import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { DASHBOARD_APPLICATIONS_ROUTE } from 'routes';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { ApiActions, MultiApplicationActions } from 'src/app/store/actions';
import { selectApplicationsDashboard, selectCanAddPrograms, selectProgramsList } from 'src/app/store/selectors';
import { ConfirmDialogComponent } from '../components';
import { DialogData } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProgramSelectionGuard implements CanActivate {

  constructor(
    private store: Store, 
    private router: Router,
    private modalService: NgbModal
  ) {}

  /**
   * Load applications data & programs if not already available
   * 
   * Nav to the program-selection route if 
   *   - at least 1 program is available to select
   * 
   * Nav to the apps dashboard if 
   *   - no programs available to select
   *   - an error occurs in fetching data
   */
  canActivate(_: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(MultiApplicationActions.loadApplications());
    this.store.dispatch(ApiActions.loadProgramsList());
    
    const appsDashboard$ = this.store.select(selectApplicationsDashboard);
    const programsList$ = this.store.select(selectProgramsList);

    return combineLatest([appsDashboard$, programsList$]).pipe(
      filter(([appsDashboard, programs]) => !!appsDashboard && !!appsDashboard.Applications && !!programs),
      first(),
      mergeMap(_ => this.store.select(selectCanAddPrograms).pipe(
        tap(canAddPrograms => {
          /**
           * If the user attempts to navigate to the program selection page
           * but no programs are available, display a message to the user.
           */
          if (!canAddPrograms) {
            const title = translate('confirmDialog.cannotAddProgramTitle');
            const bodyText = translate('confirmDialog.cannotAddProgramBody');
            const dialogData: DialogData = {
              title: title,
              bodyText: [bodyText],
              showCancel: false,
              action: {
                text: translate('confirmDialog.ok'),
                click: ''
              }
            };
            const modal = this.modalService.open(ConfirmDialogComponent, {
              centered: true,
            });
            modal.componentInstance.data = dialogData;
          }
        }),
        map(canAddPrograms => !!canAddPrograms || this.router.parseUrl(DASHBOARD_APPLICATIONS_ROUTE))
      )),
      catchError((e) => of(this.router.parseUrl(DASHBOARD_APPLICATIONS_ROUTE)))
    );
  }
}
