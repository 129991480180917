import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  Translation,
  translocoConfig, TranslocoLoader,
  TranslocoModule, TRANSLOCO_CONFIG, TRANSLOCO_LOADER
} from '@ngneat/transloco';
import { combineLatest, forkJoin, merge, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ListApiService } from '../core/services/list-api.service';

/**
 * Transloco used to manage translations throughout application via 
 * key/value pairs stored in app/assets/i18n/{environment}/*.json
 */
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

  private stateAbbreviation = environment.stateAbbreviation;
  constructor(private http: HttpClient, private listApi: ListApiService) {}

  getTranslation(lang: string) {
    const staticTranslations$ = this.http.get<Translation>(`/assets/i18n/${this.stateAbbreviation}/${lang}.json`);
    const picklistTranslations$ = this.listApi.getListTranslations(lang);

    return combineLatest([staticTranslations$, picklistTranslations$]).pipe(
      map(([statictrans, picklisttrans]) => {
        let picklistjson = JSON.parse(picklisttrans);
        return {...statictrans, ...picklistjson};
      })
    );
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: environment.supportedLanguages.map((lang) => { return lang.value }),
        defaultLang: 'en',
        /**
         * Remove this option if your application doesn't support changing language in runtime.
         */
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          allowEmpty: true,
          useFallbackTranslation: true,
          logMissingKey: false
        },
        fallbackLang: 'en'
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
