import { FormGroup } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { ApplicationMessageContainer, MessageInput } from 'src/app/application-messaging/interfaces';

export const loadMessages = createAction(
  '[Application Messaging API] GET Messages'
);

export const loadMessagesSuccess = createAction(
  '[Application Messaging API] GET Messages Success',
  props<{ messageContainer: ApplicationMessageContainer}>()
);

export const loadMessagesError = createAction(
  '[Application Messaging API] GET Messages Error',
  props<{ error: any }>()
);

export const markMessagesAsRead = createAction(
  '[Application Messaging API] Mark Messages Read'
);

export const markMessagesAsReadSuccess = createAction(
  '[Application Messaging API] Mark Messages Read Success',
  props<{ messageContainer: ApplicationMessageContainer }>()
);

export const markMessagesAsReadError = createAction(
  '[Application Messaging API] Mark Messages Read Error',
  props<{ error: any }>()
);

export const createMessage = createAction(
  '[Application Messaging API] Create Message',
  props<{ message: MessageInput }>()
);

export const createMessageSuccess = createAction(
  '[Application Messaging API] Create Message Success',
  props<{ messageContainer: ApplicationMessageContainer }>()
);

export const createMessageError = createAction(
  '[Application Messaging API] Create Message Error',
  props<{ error: any }>()
);

export const reset = createAction(
  '[Application Messaging State] Reset Application Messaging State'
);

export const updateReplyForm = createAction(
  '[Application Messaging State] Update Reply Form',
  props<{ formValue: FormGroup }>()
);