import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { DASHBOARD_DETAILS_ROUTE } from 'routes';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { selectFeatureToggles, selectSelectedApplicationId } from 'src/app/store/selectors';

@Injectable({
  providedIn: 'root'
})
export class MultiApplicationGuard implements CanActivate {

  constructor(private store: Store, private router: Router) {}

  /**
   * If the multi-app feature is enabled, allow the user to navigate to the route
   * Else navigate the user to their application's details dashboard
   */
  canActivate(_: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const featureToggles$ = this.store.select(selectFeatureToggles);
    const selectedAppId$ = this.store.select(selectSelectedApplicationId);

    return combineLatest([featureToggles$, selectedAppId$]).pipe(
      first(), 
      filter(([toggles, _]) => !!toggles), 
      map(([toggles, appId]) => !!toggles.EnableHomeownerPortalMultipleApplications || this.router.parseUrl(`${DASHBOARD_DETAILS_ROUTE}/${appId}`))
    );
  }
}
