import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants';
import { AnalyticsActions, UserActions } from 'src/app/store/actions';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { IAuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CognitoAuthService implements IAuthService {
  user: any;
  challenged: boolean = false;
  redirectUrl: string = '/dashboard/applications';

  getIdToken() : Observable<string> {
    return from(Auth.currentSession())
      .pipe(
        map(session => session.getIdToken().getJwtToken())
      );
  }

  getAccessToken(): Observable<string> {
    return from(Auth.currentSession())
      .pipe(
        map(session => session.getAccessToken().getJwtToken())
      );
  }

  constructor(private alertService: AlertService, private store: Store) {}

  register(uName: string, pWord: string, phone: string): Observable<boolean> {
    const username = uName.toLowerCase();
    return from(Auth.signUp({ 
      username: username, 
      password: pWord, 
      attributes: { 
        email: username,
        phone_number: `+1${phone}`
      } 
    }))
    .pipe(map(
      response => {
        this.user = response.user;
        this.store.dispatch(AnalyticsActions.logCustomEvent({
          event: {
            action: Constants.SIGNUP_ACTION,
            category: Constants.AUTH_CATEGORY,
            label: Constants.SUCCESS_EVENT_LABEL
          }
        }));
        return true;
      },
      (error: any) => {
        this.store.dispatch(AnalyticsActions.logCustomEvent({
          event: {
            action: Constants.SIGNUP_ACTION,
            category: Constants.AUTH_CATEGORY,
            label: Constants.ERROR_EVENT_LABEL,
            value: error?.message
          }
        }));
        return false;
      }
    ));
  }

  registerVerify(username: string, code: string) : Observable<boolean> {
    return from(Auth.confirmSignUp(username, code))
      .pipe(map(
        _ => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.CONFIRM_SIGNUP_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        },
        (error: any) => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.CONFIRM_SIGNUP_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ));
  }

  resendCode(username: string): Observable<any> {
    return from (Auth.resendSignUp(username))
      .pipe(map(
        _ => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.RESEND_CODE_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        },
        (error: any) => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.RESEND_CODE_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ));
  }

  login(username: string, password: string): Observable<boolean> {
    const userVal = username.toLowerCase();
    return from(Auth.signIn(userVal, password))
      .pipe(map(
        response => {
          this.challenged = response.challengeName == Constants.AUTH_SMS_MFA;
          this.user = response;
          if (response?.preferredMFA == Constants.AUTH_NO_MFA || !this.challenged) {
            this.store.dispatch(UserActions.updateId({ id: response.username }));
          }
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGIN_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        }, 
        (error: any) => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGIN_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ));
  }

  loginMfaUserSRPAuth(code: string): Observable<boolean> {
    return from(Auth.confirmSignIn(this.user, code))
      .pipe(map(
        _ => {
          this.store.dispatch(UserActions.updateId({ id: this.user.username }));
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGIN_MFA_USR_SRP_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        },
        (error: any) => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGIN_MFA_USR_SRP_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ));
  }

  loginMfaUserPasswordAuth(code: string): Observable<boolean> {
    this.user.username = this.user.challengeParam.USER_ID_FOR_SRP;
    return from(Auth.confirmSignIn(this.user, code))
      .pipe(map(
        _ => {
          this.store.dispatch(UserActions.updateId({ id: this.user.username }));
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGIN_MFA_USR_PASSWORD_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        },
        (error: any) => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGIN_MFA_USR_PASSWORD_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ));
  }

  loginMfa(code: string) : Observable<boolean> {
    if (!!this.user) {
      if (environment.cognitoAuthenticationFlowtype == Constants.AUTH_USER_SRP_AUTH) {
       return this.loginMfaUserSRPAuth(code);
      } else if (environment.cognitoAuthenticationFlowtype == Constants.AUTH_USER_PASSWORD_AUTH) {
        return this.loginMfaUserPasswordAuth(code);
      } else {
        console.log("unsupported authentication flow");
        return of(false);
      }
    } else {
      return of(false);
    }
  }

  rememberDevice() : boolean {
    this.user.getCachedDeviceKeyAndPassword();
    this.user.setDeviceStatusRemembered({
      onSuccess: (result: any) => {
        this.store.dispatch(AnalyticsActions.logCustomEvent({
          event: {
            action: Constants.REMEMBER_DEVICE_ACTION,
            category: Constants.AUTH_CATEGORY,
            label: Constants.SUCCESS_EVENT_LABEL
          }
        }));
        return true;
      },
      onFailure: (error: any) => {
        this.store.dispatch(AnalyticsActions.logCustomEvent({
          event: {
            action: Constants.REMEMBER_DEVICE_ACTION,
            category: Constants.AUTH_CATEGORY,
            label: Constants.ERROR_EVENT_LABEL,
            value: error?.message
          }
        }));
         return false;
      }
    });
    return true;
  }

  private clearAppData(): void {
    this.user = null;
    this.store.dispatch(UserActions.resetAll());
  }

  logout(): Observable<boolean> {
    return from(Auth.signOut())
      .pipe(map(
        _ => {
          this.clearAppData();
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGOUT_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        },
        (error: any) => {
          sessionStorage.clear();
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.LOGOUT_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ))
  }

  async changeEmail(newEmail: string): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.updateUserAttributes(user, {
      email: newEmail
    });
  }

  async changePassword(oldPassword: string, newPassword: string) : Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  async changePhone(newPhone: string): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.updateUserAttributes(user, {
      phone_number: `+1${newPhone}` 
    });
  }  

  async updateMFA(value: boolean): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    const mfaMethod = value ? Constants.AUTH_SMS_MFA_TYPE : Constants.AUTH_NO_MFA;
    return Auth.setPreferredMFA(user, mfaMethod);
  }

  async getMFA(): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.getPreferredMFA(user);
  }

  async getUser(): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  }

  forgotPassword(username: string) : Observable<boolean> {
    return from(Auth.forgotPassword(username))
      .pipe(map(
        _ => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.FORGOT_PASSWORD_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        },
        (error: any) => {
          this.alertService.error(error.message);
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.FORGOT_PASSWORD_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ));
  }

  resetPassword(username: string, code: string, newPassword: string) : Observable<boolean> {
    return from(Auth.forgotPasswordSubmit(username, code, newPassword))
      .pipe(map(
        _ => {
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.RESET_PASSWORD_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.SUCCESS_EVENT_LABEL
            }
          }));
          return true;
        },
        (error: any) => {
          this.alertService.error(error.message);
          this.store.dispatch(AnalyticsActions.logCustomEvent({
            event: {
              action: Constants.RESET_PASSWORD_ACTION,
              category: Constants.AUTH_CATEGORY,
              label: Constants.ERROR_EVENT_LABEL,
              value: error?.message
            }
          }));
          return false;
        }
      ));
  }
}
